
const initialState = {

};

// getters
const getters = {

};

// mutations
const mutations = {

};

// actions
const actions = {
  enrich(context, { model, id }) {
    return this._vm.$http.put(`/api/${model}/${id}/enrich`).then((response) => response.data.body);
  },

};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
