<template>
  <div>
    <Breadcrumbs :title="$t('app.settings.users.users')" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-no-border">
              <h5 class="d-inline-block">
                {{ $t('app.settings.users.users') }}
              </h5>
              <button class="btn btn-link text-primary pr-0 btn-sm pull-right" @click="showNewUserForm()">
                <i class="fa fa-plus"></i> {{ $t('app.settings.users.add-user') }}
              </button>

              <div class="card-header-right text-right mr-0 pr-0">
                <!--
                <ul class="list-unstyled card-option">
                  <li>
                    <i class="fa fa-cog"></i>
                  </li>
                </ul>
                -->
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="our-product">
                <div class="table-responsive">
                  <table class="table table-bordernone">
                    <tbody class="f-w-500">
                      <tr class="pointer display-on-hover-parent" v-for="user in members" :key="user.id">
                        <td @click.stop="goToUserDetail(user)">
                          <div class="media">
                            <img
                              v-if="user.avatarUrl"
                              style="width: 48px"
                              class="img-fluid m-r-15 rounded-circle"
                              :src="user.avatarUrl"
                              alt
                            />
                            <img
                              v-if="!user.avatarUrl"
                              class="img-fluid m-r-15 rounded-circle"
                              src="@/assets/images/user/user.png"
                              alt
                            />
                            <div class="media-body">
                              <span>{{ user.firstName }}</span>
                              <p class="font-roboto">{{ user.phonenumber || '' }}</p>
                            </div>
                          </div>
                        </td>
                        <td @click.stop="goToUserDetail(user)">
                          <span>{{ user.lastName }}</span>
                        </td>
                        <td @click="goToUserDetail(user)">
                          <span>{{ user.email }}</span>
                        </td>

                        <td>
                          <span class="badge" :class="user.isActiveInOrganisation ? 'badge-success' : 'badge-dark'">{{
                            user.isActiveInOrganisation
                              ? $t('app.settings.users.active')
                              : $t('app.settings.users.disabled')
                          }}</span>
                          <!--
                          <button
                            class="btn btn-primary btn-simple mr-1"
                            @click.prevent="goToUserEdit(user)"
                          >

                            <feather type="edit" />
                          </button>
                          <button @click.prevent="deleteUser(user)" class="btn display-on-hover btn-danger btn-simple">
                            <i class="fa fa-trash" />
                          </button>
                          -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-no-border">
              <h5 class="d-inline-block">
                {{ $t('app.settings.users.invite') }}
              </h5>
              <small class="badge badge-primary badge-sm text-sm text-light ml-2">{{ invitations.length }}</small>
              <div class="card-header-right text-right mr-0 pr-0"></div>
            </div>
            <div class="card-body pt-0">
              <div class="our-product">
                <div class="table-responsive">
                  <table class="table table-bordernone">
                    <tbody class="f-w-500">
                      <tr class="pointer display-on-hover-parent" v-for="invite in invitations" :key="invite.id">
                        <td>
                          <div class="media">
                            <div class="media-body">
                              <span>{{ invite.email }}</span>
                              <p class="font-roboto">{{ invite.phonenumber || '' }}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span>{{ invite.createdOn | formatDate }}</span>
                        </td>
                        <td>
                          <button
                            class="btn btn-icon"
                            data-tooltip="Supprimer l'invitation"
                            @click="deleteInvitation(invite)"
                          >
                            <feather type="trash-2" class="text-danger"></feather>
                          </button>
                        </td>
                        <!--
                        <td>
                          <button
                            class="btn display-on-hover btn-primary btn-simple mr-1"
                            @click.prevent="goToUserEdit(user)"
                          >
                            <i class="fa fa-edit" />
                          </button>
                          <button @click.prevent="deleteUser(user)" class="btn display-on-hover btn-danger btn-simple">
                            <i class="fa fa-trash" />
                          </button>
                        </td>
                        -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import swal from 'sweetalert2/dist/sweetalert2';

export default {
  name: 'UsersPage',
  data() {
    return {
      selectedUser: {},
      isCreating: false,
      mode: 'edit',
    };
  },
  computed: {
    ...mapState('member', {
      members: (state) => state.items,
      selectedMember: (state) => state.selectedItem,
    }),
    ...mapState('invitation', {
      invitations: (state) => state.items,
    }),
    ...mapState('user', ['user', 'organisation']),
  },
  mounted() {
    this.$store.dispatch('member/getItems', {
      params: { includeDisabled: true },
    });
    this.$store.dispatch('invitation/getItems');
  },

  beforeRouteEnter(to, from, next) {
    if (to.params.id) {
      next((vm) =>
        vm.$store.dispatch('user/getUser', to.params.id).then((user) => {
          vm.mode = to.path.includes('/edit') ? 'edit' : 'view';
          vm.editUser(user);
        })
      );
      return;
    }
    next();
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.id) {
      this.$store.dispatch('user/getUser', to.params.id).then((user) => {
        this.mode = to.path.includes('/edit') ? 'edit' : 'view';
        this.editUser(user);
      });
      next();
      return;
    }
    next();
  },

  methods: {
    goToUserDetail(user) {
      this.$store.commit('member/selectedItem', user);
      this.$router.push(`/app/settings/member/${user.id || user}`);
    },

    goToUserEdit(user) {
      this.$router.push(`/app/settings/member/${user.id || user}/edit`);
      this.$forceUpdate();
    },

    editUser(user) {
      //  this.mode = 'edit';
      this.isCreating = true;
      this.selectedUser = user;
    },

    showNewUserForm() {
      /* eslint-disable */
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,63}))$/;
      /* eslint-enable */
      swal
        .fire({
          reverseButtons: true,
          input: 'email',
          title: this.$t('common.labels.enterUserEmailAddress'),
          confirmButtonText: this.$t('common.buttons.submit'),
          cancelButtonText: this.$t('common.buttons.cancel'),
          showCancelButton: true,
          inputValidator: (value) =>
            new Promise((resolve) => {
              if (!value) {
                resolve(this.$t('common.labels.enterEmailAddress'));
              }
              if (!re.test(value)) {
                resolve(this.$t('common.labels.enterValidEmail'));
              }
              resolve();
            }),
          preConfirm: (value) =>
            this.$http
              .post('/api/member', { email: value.toLowerCase() })
              .then((res) => res)
              .catch((err) => {
                swal.showValidationMessage(
                  `${
                    this.parseErrorResponse(err.response) === 'error_unknown_email'
                      ? this.$t('common.messages.accountWithEmailNotExisting')
                      : this.apiErrorCallback(err)
                  }`
                );
              }),
          allowOutsideClick: () => !swal.isLoading(),
        })
        .then((result) => {
          if (result.value) {
            swal.fire(
              this.$t('common.messages.requestSuccess'),
              this.$t('common.messages.checkEmailForAcceptInvitation'),
              'success'
            );
            this.$store.dispatch('invitation/getItems');
          }
        });
    },

    saveUser(user) {
      this.$store.dispatch('member/updateItem', user);
      this.isCreating = false;
      this.$router.push('/app/settings/member');
    },

    deleteUser(user) {
      this.$store.dispatch('member/deleteItem', user);
      this.isCreating = false;
    },

    hideNewUserForm() {
      this.isCreating = false;
      this.selectedUser = {};
      this.$router.push('/app/settings/member');
    },

    closeNewUserForm() {
      this.$store.dispatch('member/getItems', { params: { active: 'ALL' } });
      this.isCreating = false;
      this.$router.push('/app/settings/member');
    },

    async deleteInvitation(invite) {
      const confirmed = await this.$awConfirm('Êtes-vous sur de vouloir effectuer cette action ?');
      if (confirmed) {
        this.$store.dispatch('invitation/deleteItem', invite).then(() => this.$store.dispatch('invitation/getItems'));
      }
    },
  },
};
</script>

<style lang="scss">
.aw-form .form-element {
  margin-bottom: 0px;
}
</style>
