<template>
  <div>
    <Breadcrumbs title="Utilisateur" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <awesomeForm
            v-if="$route.params.id"
            identity="user"
            :item="{ id: $route.params.id }"
            :mode="mode"
            :url="`/api/user`"
            @closeRequested="closeNewUserForm"
            @cancel="closeNewUserForm"
            @edit="mode = 'edit'"
            :actions="{ delete: false, edit: true }"
            :standalone="false"
            :useRouterMode="true"
            :title="'Profile'"
            :nestedModels="$store.getters.modelsMap.user.nestedModels"
            displayMode="page"
            :displayedFields="displayFields"
          >
            <template #view-form-title="{ item }">{{ item.firstName }} {{ item.lastName }}</template>
            <template #edit-form-title="{ item }">{{ item.firstName }} {{ item.lastName }}</template>
          </awesomeForm>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import swal from 'sweetalert2/dist/sweetalert2';

export default {
  data() {
    return {
      selectedUser: {},
      isCreating: false,
      mode: 'view',
      displayFields: [
        'avatarUrl',
        'firstName',
        'lastName',
        'email',
        'phonenumber',
        'organisationRoles',
        'isActiveInOrganisation',
        'notificationsEnabled',
        'emailNotificationsEnabled',
      ],
    };
  },
  computed: {
    ...mapState('member', {
      members: (state) => state.items,
      selectedMember: (state) => state.selectedItem,
    }),
    ...mapState('invitation', {
      invitations: (state) => state.items,
    }),
    ...mapState('user', ['user', 'organisation']),
  },
  mounted() {
    this.$store.dispatch('member/getItems');
    this.$store.dispatch('invitation/getItems');
  },

  beforeRouteEnter(to, from, next) {
    if (to.params.id) {
      next((vm) =>
        vm.$store.dispatch('user/getUser', to.params.id).then((user) => {
          vm.mode = to.path.includes('/edit') ? 'edit' : 'view';
          vm.editUser(user);
        })
      );
      return;
    }
    next();
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.id) {
      this.$store.dispatch('user/getUser', to.params.id).then((user) => {
        this.mode = to.path.includes('/edit') ? 'edit' : 'view';
        this.editUser(user);
      });
      next();
      return;
    }
    next();
  },

  methods: {
    goToUserDetail(user) {
      this.$store.commit('member/selectedItem', user);
      this.$router.push(`/app/settings/member/${user.id || user}`);
    },

    goToUserEdit(user) {
      this.$router.push(`/app/settings/member/${user.id || user}/edit`);
      this.$forceUpdate();
    },

    editUser(user) {
      //  this.mode = 'edit';
      this.isCreating = true;
      this.selectedUser = user;
    },

    showNewUserForm() {
      /* eslint-disable */
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,63}))$/;
      /* eslint-enable */
      swal
        .fire({
          reverseButtons: true,
          input: 'email',
          title: this.$t('common.labels.enterUserEmailAddress'),
          confirmButtonText: this.$t('common.buttons.submit'),
          cancelButtonText: this.$t('common.buttons.cancel'),
          showCancelButton: true,
          inputValidator: (value) =>
            new Promise((resolve) => {
              if (!value) {
                resolve(this.$t('common.labels.enterEmailAddress'));
              }
              if (!re.test(value)) {
                resolve(this.$t('common.labels.enterValidEmail'));
              }
              resolve();
            }),
          preConfirm: (value) =>
            this.$http
              .post('/api/member', { email: value.toLowerCase() })
              .then((res) => res)
              .catch((err) => {
                swal.showValidationMessage(
                  `${
                    this.parseErrorResponse(err.response) === 'error_unknown_email'
                      ? this.$t('common.messages.accountWithEmailNotExisting')
                      : this.apiErrorCallback(err)
                  }`
                );
              }),
          allowOutsideClick: () => !swal.isLoading(),
        })
        .then((result) => {
          if (result.value) {
            swal.fire(
              this.$t('common.messages.requestSuccess'),
              this.$t('common.messages.checkEmailForAcceptInvitation'),
              'success'
            );
            this.$store.dispatch('invitation/getItems');
          }
        });
    },

    saveUser(user) {
      this.$store.dispatch('member/updateItem', user);
      this.isCreating = false;
      this.$router.push('/app/settings/member');
    },

    deleteUser(user) {
      this.$store.dispatch('member/deleteItem', user);
      this.isCreating = false;
    },

    hideNewUserForm() {
      this.isCreating = false;
      this.selectedUser = {};
      this.$router.push('/app/settings/member');
    },

    closeNewUserForm() {
      this.$router.push('/app/settings/member');
    },

    async deleteInvitation(invite) {
      const confirmed = await this.$awConfirm('Êtes-vous sur de vouloir effectuer cette action ?');
      if (confirmed) {
        this.$store.dispatch('invitation/deleteItem', invite).then(() => this.$store.dispatch('invitation/getItems'));
      }
    },
  },
};
</script>

<style lang="scss">
.aw-form .form-element {
  margin-bottom: 0px;
}
</style>
