/* eslint-disable import/prefer-default-export */

export const candidate = {
  identity: 'candidate',
  icon: '',
  schema: {
    properties: {
      id: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}',
        },
      },
      organisationId: {
        field: {
          readonly: true,
          visible: false,
        },
        column: {
          visible: false,
        },
      },
      tags: {
        column: {
          hidden: false,
        },
      },
      notes: {
        column: {
          visible: false,
        },
      },
      createdOn: {
        nullable: true,
        field: {
          readonly: true,
          // visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        },
      },
      createdBy: {
        nullable: true,
        field: {
          readonly: true,
          // visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        },
      },
      lastModifiedOn: {
        nullable: true,
        field: {
          readonly: true,
          // visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        },
      },
      lastModifiedBy: {
        nullable: true,
        field: {
          readonly: true,
          // visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        },
      },
    },
  },
  // actions: { create: true, edit: true, delete: true },
  options: {
    mode: 'remote',
    customInlineActions: [],
  },
  customInlineActions: [
    {
      name: 'send-mail',
      label: 'common.buttons.contact',
      class: 'btn btn-sm btn-simple btn-awtable-inline-action btn-icon ml-2 text-white',
      icon: 'fa fa-envelope',
      // visible: ({ item }) => item.email,
      disabled: ({ item }) => !item || !item.email,
      action: ({
        item, action, location, props, id
      }, context) => {
        context.$store.dispatch('email/setShowEmailComposerModal',
          {
            show: true,
            recipientObject: item,
            recipientAddress: item && item.email
          });
      },
    },
  ],
  customBulkActions: [
    {
      name: 'send-bulk-mail',
      label: 'Contact',
      classes: 'btn-sm btn-warning ml-2 mr-2',
      title: 'contact',
      icon: 'fa fa-envelope',
      // disabled: ({ items }) => !(items && items.find(item => item.email)),
      action: ({
        items, action, location, props, id
      }, context) => {
        context.$store.dispatch('email/setShowEmailComposerModal', {
          show: true,
          recipientAddress: items && items.filter((item) => item.email).map((item) => item.email),
        });
      },
    },
  ],
  nestedSchemas: [],
};
