<template>
  <div id="custom-field-values-widget" class="card">
    <div class="card-header">
      <h5>{{ $t('common.labels.customFields') }}</h5>
      <button v-if="model === 'lead'" class="btn btn-icon pull-right btn-sm" @click="toggleMode">
        <feather :type="mode === 'view' ? 'edit-3' : 'check'" class="m-0 text-primary" />
      </button>

      <hr class="mb-0" />
    </div>
    <AwesomeForm
      :title="false"
      :identity="model"
      :mode="mode"
      :item="itemComputed"
      displayMode="page"
      :displayedFields="['customFields']"
      :standalone="true"
      fetch-disabled
      :actions="{ noActions: false, edit: true, cancel: true }"
      @change="saveItem"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import { AwesomeForm } from 'vue-aw-components';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);

export default {
  name: 'CustomFieldValuesWidget',
  props: {
    model: [String],
    item: Object,
    modelId: [Number, String],
  },
  data () {
    return {
      fetchedItem: null,
      mode: 'view',
    };
  },
  mounted () {
    this.getItem();
  },
  watch: {
    model (to, from) {
      this.getItem();
    },
    modelId (to, from) {
      this.getItem();
    },
  },
  computed: {
    apiRoot () {
      return `/api/${this.model === 'recruitmentProcess' ? 'recruitment-process' : this.model}/${this.modelId}`;
    },
    itemComputed () {
      const item = this.item || this.fetchedItem || {};
      return {
        id: item.id,
        customFields: item.customFields,
      };
    },
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return;
      }
      return dayjs(date).format('DD/MM/YYYY  hh:mm');
    },

    getItem () {
      // if we already havbe the item, we don't need to fetch it again
      if (this.item) {
        return;
      }
      return this.$http
        .get(this.apiRoot)
        .then((result) => {
          if (result && result.data && result.data.body) {
            this.fetchedItem = result.data.body;
          }
        })
        .catch((err) => console.warn(err));
    },

    saveItem (newState) {
      if (this.apiRoot) {
        this.$http.put(this.apiRoot, newState).then((result) => {
          if (result && result.data && result.data.body) {
            this.fetchedItem = result.data.body;
          }
        });
      }
    },
    toggleMode () {
      if (this.mode === 'view') {
        this.mode = 'edit';
      } else {
        this.mode = 'view';
      }
    },
  },
  components: { AwesomeForm },
};
</script>
<style lang="scss">
#custom-field-values-widget {
  .card-header h5 {
    display: inline-block;
  }
  .field-group.subgroup-auto {
    border: none;
  }
  div.modal-footer,
  div.modal-header,
  .aw-form-group-legend {
    display: none;
  }
}
</style>
