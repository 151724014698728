<template>
  <div>
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 footer-copyright text-center">
            <p class="mb-0">
              Copyright 2022 © <a href="https://so.cimple.to" target="_blank">{{ config.appName }}</a> by
              <a href="https://www.enyosolutions.com" target="_blank">Enyosolutions</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import json from '../data/menu';
import config from '../config';

export default {
  name: 'footerpage',
  data() {
    return {
      customizer: false,
      data: `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(json))}`,
      config,
    };
  },
};
</script>
