<template>
  <div>
    <Breadcrumbs main="Dashboard" :title="$t('app.dashboard.dashboard')" class="">
      <template #left> </template>
    </Breadcrumbs>
    <div class="d-flex ml-3 mb-3">
      <MemberSelector v-model="userId"></MemberSelector>
      <date-range-picker
        v-if="showDateRangePicker"
        class="form-group vgt-date-range pl-2"
        :placeholder="$t('common.field.start')"
        @update="loadStatistics()"
        v-model="dateRange"
        :opens="'right'"
        :singleDatePicker="'range'"
        :auto-apply="true"
        :timePicker="false"
        :ranges="dateRanges"
        :locale-data="{ format: 'dd-mm-yyyy' }"
      >
      </date-range-picker>
      <button
        v-if="!showDateRangePicker"
        @click="showDateRangePicker = true"
        class="btn btn-primary btn-sm mx-2 py-0 px-2"
        id="clear-date-range-picker"
      >
        {{ $t('app.dashboard.date-filter-button') }}
      </button>
      <button
        v-if="showClearDatesButton"
        @click="clearDateRangePicker()"
        :disabled="!dateRange.startDate && !dateRange.endDate"
        class="btn btn-primary btn-sm mx-2 py-0 px-2"
        id="clear-date-range-picker"
      >
        {{ $t('app.dashboard.clear-date-filter-button') }}
      </button>
    </div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 col-xl-3 col-lg-6">
          <px-card class="bg-secondary static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center"><feather type="users" class="middle"></feather></div>
                <div class="media-body">
                  <span class="m-0"> {{ $t('app.dashboard.clients') }} </span>
                  <h4 class="mb-0 counter">
                    {{ ($store.state.client.clients && $store.state.client.clientsCount) || 0 }}
                  </h4>
                  <feather type="users" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-sm-6 col-xl-3 col-lg-6">
          <px-card class="bg-success static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center"><feather type="box" class="middle"></feather></div>
                <div class="media-body">
                  <span class="m-0">{{ $t('app.dashboard.open-positions') }}</span>
                  <h4 class="mb-0 counter">
                    {{ $store.state.position.itemsCount || '0' }}
                  </h4>
                  <feather type="box" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-sm-6 col-xl-3 col-lg-6">
          <px-card class="bg-info static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center">
                  <feather type="message-square" class="middle"></feather>
                </div>
                <div class="media-body">
                  <span class="m-0"> {{ $t('app.dashboard.in-process-candidates') }} </span>
                  <h4 class="mb-0 counter">
                    {{ $store.state.recruitmentProcess.recruitmentProcessesCount || 0 }}
                  </h4>
                  <feather type="message-square" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-sm-6 col-xl-3 col-lg-6">
          <px-card class="bg-secondary static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center"><feather type="watch" class="middle"></feather></div>
                <div class="media-body">
                  <span class="m-0"> {{ $t('app.dashboard.blocked-processes') }} </span>
                  <h4 class="mb-0 counter">
                    {{ $store.state.recruitmentProcess.blockedRecruitmentProcessesCount || '0' }}
                  </h4>
                  <feather type="watch" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
      <!-- Charts starts-->
      <div class="row">
        <div class="col-xl-12">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top mb-3">
                <h5 class="m-0">{{ $t('app.dashboard.recruitment-process') }}</h5>
              </div>
            </div>
            <div class="rp-graph text-dark">
              <chartist
                v-if="chartData && chartData.showChart"
                type="Bar"
                ratio="VueChartList"
                :data="chartData"
                :options="chartistOptions"
              ></chartist>
              <no-result v-else />
            </div>
          </px-card>
        </div>
      </div>
      <!-- Charts ends-->
      <div class="row">
        <div class="col-xl-4 xl-50 news box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.general-stats') }}</h5>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="generalStatistics"
                :perRow="1"
                :perPage="10"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template v-slot:list-items="{ items }">
                  <div class="news-update stat-block w-100 py-2 mb-0" v-for="item in items" :key="item.id">
                    <p class="text-dark mb-0">
                      {{ $t(`app.models.${item.model}`) }}
                      <small class="muted badge badge-info f-w-700 pull-right"> {{ item.count }}</small>
                    </p>
                  </div>
                </template>
              </awesome-list>
            </div>
          </px-card>
        </div>
        <div class="col-xl-4 xl-50 news box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div id="#events-table" class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.events') }}</h5>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="$store.state.statistics && $store.state.statistics.commentsByEvent"
                :perRow="1"
                :perPage="10"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template
                  v-if="$store.state.statistics.commentsByEvent && $store.state.statistics.commentsByEvent.length > 0"
                  v-slot:list-item="{ item }"
                >
                  <div class="news-update stat-block w-100 py-2 mb-0">
                    <p v-if="item.eventTypeCode" class="text-dark mb-0">
                      {{ displayRecruitmentStageLabel(item.eventTypeCode) || item.eventTypeCode }}
                      <small class="muted badge badge-info f-w-700 pull-right"> {{ item.value }}</small>
                    </p>
                  </div>
                </template>
                <template v-else v-slot:list-empty-state></template>
              </awesome-list>
            </div>
          </px-card>
        </div>
        <div class="col-xl-4 xl-50 news box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.positions-by-client') }}</h5>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="$store.state.statistics && $store.state.statistics.positionsByClient"
                :perRow="1"
                :perPage="10"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template
                  v-slot:list-item="{ item }"
                  v-if="$store.state.statistics && $store.state.statistics.positionsByClient.length > 0"
                >
                  <div class="d-flex align-item-center news-update stat-block w-100 py-2 mb-">
                    <div v-if="item.Client">
                      <img
                        v-if="item.Client && item.Client.avatarUrl"
                        class="img-fluid img-40 rounded-circle mr-3 pull-left"
                        :src="item.Client.avatarUrl"
                        alt="Image description"
                        @error="replaceImage"
                      />
                      <img
                        v-else
                        class="img-fluid img-40 rounded-circle mr-3 pull-left"
                        src="../../assets/images/user/user.png"
                        alt="Image description"
                      />
                    </div>
                    <p v-if="item.Client" class="text-dark my-auto">
                      {{ item.Client ? `${item.Client.name}` : '' }}
                    </p>
                    <small v-if="item.value" class="muted badge badge-info f-w-700 ml-auto my-auto">
                      {{ item.value }}</small
                    >
                  </div>
                </template>
                <template v-else v-slot:list-empty-state></template>
              </awesome-list>
            </div>
          </px-card>
        </div>
      </div>
      <div class="row second-chart-list third-news-update">
        <div class="col-xl-4 xl-50 appointment-sec box-col-6">
          <div class="row">
            <div class="col-xl-12">
              <px-card :actions="false">
                <div slot="headerCustom">
                  <div class="header-top mb-2">
                    <h5 class="m-0">{{ $t('app.dashboard.new-clients') }}</h5>
                    <div class="card-header-right-icon">
                      <select class="button btn btn-primary d-none">
                        <option>{{ $t('stats.today') }}</option>
                        <option>Tomorrow</option>
                        <option>Yesterday</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <awesome-list
                      :actions="{ refresh: false, pagination: false, search: false }"
                      :perRow="1"
                      :useGridSystem="false"
                      :showHeader="false"
                      :perPage="5"
                      :apiUrl="'/api/client?sort[createdOn]=desc'"
                    >
                      <template v-slot:list-item="{ item }">
                        <div class="w-100 py-2 mb-1 news-update">
                          <router-link :to="`/app/client/${item.id}`">
                            <small
                              v-if="item.status"
                              :class="{
                                'badge-primary': item.status === 'pending',
                                'badge-success': item.status === 'active',
                                'badge-default': item.status === 'inactive',
                              }"
                              class="muted badge pull-right f-w-300 my-1"
                            >
                              {{ item.status }}</small
                            >
                            <div class="text-dark text-truncate d-flex align-item-center">
                              <img
                                v-if="item.avatarUrl"
                                class="img-fluid img-40 rounded-circle mr-2"
                                :src="item.avatarUrl"
                                alt="Image description"
                                @error="replaceImage"
                              />
                              <img
                                v-else
                                class="img-fluid img-40 rounded-circle mr-2"
                                src="../../assets/images/user/user.png"
                                alt="Image description"
                              />
                              <p class="d-inline-block" style="line-height: 1.2">
                                {{ item.name }}
                                <br /><small class="m-0 font-primary"> created {{ formatDate(item.createdOn) }}</small>
                              </p>
                            </div>
                          </router-link>
                        </div>
                      </template>
                    </awesome-list>
                  </div>
                </div>
                <div slot="footer">
                  <div class="bottom-btn">
                    <router-link to="/app/candidate">{{ $t('common.buttons.plus') }}</router-link>
                  </div>
                </div>
              </px-card>
            </div>
          </div>
        </div>
        <div class="col-xl-4 xl-50 appointment-sec box-col-6">
          <div class="row">
            <div class="col-xl-12">
              <px-card :actions="false">
                <div slot="headerCustom">
                  <div class="header-top mb-2">
                    <h5 class="m-0">{{ $t('app.dashboard.new-candidates') }}</h5>
                    <div class="card-header-right-icon">
                      <select class="button btn btn-primary d-none">
                        <option>{{ $t('stats.today') }}</option>
                        <option>Tomorrow</option>
                        <option>Yesterday</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <awesome-list
                      :actions="{ refresh: false, pagination: false, search: false }"
                      :perRow="1"
                      :useGridSystem="false"
                      :showHeader="false"
                      :perPage="5"
                      :apiUrl="'/api/candidate?sort[createdOn]=desc'"
                    >
                      <template v-slot:list-item="{ item }">
                        <div class="w-100 py-2 mb-1 news-update">
                          <router-link :to="`/app/candidate/${item.id}`">
                            <small
                              v-if="item.status"
                              :class="{
                                'badge-primary': item.status === 'pending',
                                'badge-success': item.status === 'active',
                                'badge-default': item.status === 'inactive',
                              }"
                              class="muted badge pull-right f-w-300 my-1"
                            >
                              {{ item.status }}</small
                            >
                            <div class="text-dark text-truncate d-flex align-item-center">
                              <img
                                v-if="item.picture"
                                class="img-fluid img-40 rounded-circle mr-2"
                                :src="item.picture"
                                alt="Image description"
                                @error="replaceImage"
                              />
                              <img
                                v-else
                                class="img-fluid img-40 rounded-circle mr-2"
                                src="../../assets/images/user/user.png"
                                alt="Image description"
                              />
                              <p class="d-inline-block" style="line-height: 1.2">
                                {{ item.firstName }} {{ item.lastName }} <br /><small class="m-0 font-primary">
                                  created {{ formatDate(item.createdOn) }}</small
                                >
                              </p>
                            </div>
                          </router-link>
                        </div>
                      </template>
                    </awesome-list>
                  </div>
                </div>
                <div slot="footer">
                  <div class="bottom-btn">
                    <router-link to="/app/candidate">{{ $t('common.buttons.plus') }}</router-link>
                  </div>
                </div>
              </px-card>
            </div>
          </div>
        </div>
        <div class="col-xl-4 xl-50 appointment-sec box-col-6">
          <div class="row">
            <div class="col-xl-12">
              <px-card :actions="false">
                <div slot="headerCustom">
                  <div class="header-top mb-2">
                    <h5 class="m-0">{{ $t('app.dashboard.new-recruitments') }}</h5>
                    <div class="card-header-right-icon">
                      <select class="button btn btn-primary d-none">
                        <option>{{ $t('stats.today') }}</option>
                        <option>Tomorrow</option>
                        <option>Yesterday</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <awesome-list
                      :actions="{ refresh: false, pagination: false, search: false }"
                      :perRow="1"
                      :useGridSystem="false"
                      :showHeader="false"
                      :perPage="5"
                      :apiUrl="'/api/recruitment-process?sort[createdOn]=desc'"
                    >
                      <template v-slot:list-item="{ item }">
                        <div class="news-update w-100 py-2 mb-3">
                          <router-link :to="`/app/recruitment-process/${item.id}`">
                            <p class="text-dark text-truncate">
                              {{ item.Candidate ? item.Candidate.firstName : '-' }}
                              {{ item.Candidate ? item.Candidate.lastName : '-' }} ✨
                              {{ item.Position ? item.Position.title : '-' }}
                              <small v-if="item.stage" class="muted badge badge-info pull-right f-w-300 my-1">
                                {{ displayRecruitmentStageLabel(item.stage) || item.stage }}</small
                              >
                            </p>
                            <p
                              v-if="item.Client && item.Client.name"
                              class="m-0 text-left font-primary"
                              style="font-size: 12px; line-height: 0.5"
                            >
                              Client - {{ item.Client.name }} <br /><br />
                              <small class="m-0 font-primary">
                                {{ $t('app.dashboard.stage-updated') }} {{ formatDate(item.stageLastUpdatedOn) }}
                              </small>
                            </p>
                          </router-link>
                        </div>
                      </template>
                    </awesome-list>
                  </div>
                </div>
                <div slot="footer">
                  <div class="bottom-btn">
                    <router-link to="/app/recruitment-process">{{ $t('common.buttons.plus') }}</router-link>
                  </div>
                </div>
              </px-card>
            </div>
          </div>
        </div>
        <div class="col-xl-6 xl-50 appointment-sec box-col-6">
          <div class="row">
            <div class="col-xl-12">
              <px-card :actions="false">
                <div slot="headerCustom">
                  <div class="header-top mb-2">
                    <h5 class="m-0">{{ $t('app.dashboard.last-interviews') }}</h5>
                    <div class="card-header-right-icon">
                      <select class="button btn btn-primary d-none">
                        <option>{{ $t('stats.today') }}</option>
                        <option>Tomorrow</option>
                        <option>Yesterday</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <awesome-list
                      :actions="{ refresh: false, pagination: false, search: false }"
                      :perRow="1"
                      :useGridSystem="false"
                      :showHeader="false"
                      :perPage="5"
                      :rows="$store.state.interview && $store.state.interview.items"
                      :apiUrl="interviewApiUrl"
                    >
                      <template v-slot:list-item="{ item }">
                        <div class="news-update w-100 py-2 mb-3">
                          <router-link :to="`/app/interview/${item.id}`">
                            <small
                              v-if="item.completed && item.result"
                              :class="{
                                'badge-primary': item.result === 'uncertain',
                                'badge-success': item.result === 'positive',
                                'badge-danger': item.result === 'negative',
                              }"
                              class="muted badge f-w-300 pull-right"
                            >
                              {{ item.result }}</small
                            >
                            <div class="text-dark text-truncate pr-2">
                              {{ item.Candidate.firstName }} {{ item.Candidate.lastName }} 🔥
                              {{ item.Position ? item.Position.title : '-' }}
                            </div>
                            <small class="m-0 font-primary"
                              >Occured {{ item.date ? formatDate(item.date) : '' }}
                            </small>
                          </router-link>
                        </div>
                      </template>
                    </awesome-list>
                  </div>
                </div>
                <div slot="footer">
                  <div class="bottom-btn">
                    <router-link to="/app/interview">{{ $t('common.buttons.plus') }}</router-link>
                  </div>
                </div>
              </px-card>
            </div>
          </div>
        </div>
        <div v-if="organisation.featuresHumanResources" class="col-xl-6 xl-50 news box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top mb-2">
                <h5 class="m-0">{{ $t('app.dashboard.blocked-processes') }}</h5>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="blockedRecruitmentProcesses"
                :perRow="1"
                :useGridSystem="true"
                :showHeader="false"
                :segmentField="'stage'"
              >
                <template
                  v-if="blockedRecruitmentProcesses && blockedRecruitmentProcesses.length > 0"
                  v-slot:list-item="{ item }"
                >
                  <div class="news-update w-100 py-2 mb-3">
                    <router-link :to="`/app/recruitment-process/${item.id}`">
                      <p class="text-dark text-truncate">
                        {{ item.Candidate ? item.Candidate.firstName : '-' }}
                        {{ item.Candidate ? item.Candidate.lastName : '-' }} ✨
                        {{ item.Position ? item.Position.title : '-' }}
                        <small v-if="item.stage" class="muted badge badge-primary pull-right f-w-300 my-1">
                          {{ displayRecruitmentStageLabel(item.stage) || item.stage }}</small
                        >
                      </p>
                      <p
                        v-if="item.Client && item.Client.name"
                        class="m-0 text-left font-primary"
                        style="font-size: 12px; line-height: 0.5"
                      >
                        Client - {{ item.Client.name }} <br /><br />
                        <small class="m-0 font-primary">
                          {{ $t('app.dashboard.stage-updated') }} {{ formatDate(item.stageLastUpdatedOn) }}
                        </small>
                      </p>
                    </router-link>
                  </div>
                </template>
                <template v-else v-slot:list-empty-state></template>
              </awesome-list>
            </div>
            <div slot="footer">
              <div class="bottom-btn">
                <router-link to="/app/recruitment-process">{{ $t('common.buttons.plus') }}</router-link>
              </div>
            </div>
          </px-card>
        </div>
        <div
          :class="organisation.featuresClientConversations ? `col-xl-12 news box-col-12` : `col-xl-12 news box-col-12`"
        >
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 id="#" class="m-0">
                  {{ $t('app.dashboard.latest-comments') }}
                  <small>
                    {{ `(${totalCommentCount})` }}
                  </small>
                </h5>
                <div class="d-inline-flex justify-content-around">
                  <div class="card-header-right-icon pl-4">
                    <select name="" v-model="currentEventType" class="form-control" id="">
                      <!-- @change="setCurrentEventType" -->
                      <option value="" disabled>{{ $t('app.dashboard.event') }}</option>
                      <option value="--">--</option>
                      <option :key="eventType.code" :value="eventType.code" v-for="eventType in eventTypeList">
                        {{ eventType.code }}
                      </option>
                    </select>
                  </div>
                  <div class="card-header-right-icon pl-4 mw-30">
                    <select name="" v-model="currentModelType" class="form-control" id="">
                      <!-- @change="setCurrentEventType" -->
                      <option value="" disabled>{{ $t('app.dashboard.category') }}</option>
                      <option value="--">--</option>
                      <option
                        :key="commentModelType.model"
                        :value="commentModelType.model"
                        v-for="commentModelType in myCommentModelTypeList"
                      >
                        {{ $t(`app.models.${commentModelType.model}`) }}
                      </option>
                    </select>
                  </div>
                  <div v-if="checkIfModelIsSelectedAndHasItems" class="card-header-right-icon pl-4">
                    <select name="" v-model="currentModelItem" class="form-control">
                      <!-- @change="setCurrentEventType" -->
                      <option value="" disabled>Item</option>
                      <option value="--">--</option>
                      <option :key="modelItem.id" :value="modelItem.id" v-for="modelItem in modelItemList">
                        {{ modelItem.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="myComments"
                :perRow="1"
                :useGridSystem="true"
                :showHeader="false"
                :perPage="commentPerPage"
              >
                <template v-if="myComments && myComments.length > 0" v-slot:list-item="{ item }">
                  <div class="news-update w-100 py-2 mb-3">
                    <router-link
                      v-if="item.ModelRelations"
                      :to="`/app/${item.ModelRelations[0].model}/${item.ModelRelations[0].modelId}`"
                    >
                      <div>
                        <!-- {{ item.content }} -->
                        <small v-if="item.eventTypeCode" class="muted badge badge-primary pull-right f-w-300">{{
                          displayRecruitmentStageLabel(item.eventTypeCode) || item.eventTypeCode
                        }}</small>
                        <div class="comment-content" v-if="item.content" v-html="marked(item.content)"></div>
                        <div class="d-flex flex-wrap" v-if="item.ModelRelations">
                          <div v-for="model of item.ModelRelations" :key="model.model + model.modelId">
                            <div v-if="hasModelData(model) && !isUserModelRelation(model)">
                              <router-link
                                :to="`/app/${
                                  model.model === 'recruitmentProcess' ? 'recruitment-process' : model.model
                                }/${model.modelId}`"
                              >
                                <!-- <div
                                  v-if="model.model === 'recruitmentProcess'"
                                  class="badge badge-info fw-500 mr-2 mb-2 p-2"
                                >
                                  {{ $t('app.dashboard.go-to-recruitment-process') }}
                                </div> -->
                                <div class="text-muted btn-sm fw-500 mr-2 mb-2 p-1">
                                  <i class="fa fa-angle-double-right text-muted"></i>
                                  {{ $t(`app.models.${model.model}`) }} :
                                  {{
                                    model.modelData.name ||
                                    model.modelData.title ||
                                    model.modelData.username ||
                                    (model.modelData.firstName &&
                                      model.modelData.firstName + ' ' + model.modelData.lastName) ||
                                    model.modelId
                                  }}
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-wrap" v-if="item.AllModelRelations">
                          <div v-for="model of item.AllModelRelations" :key="model.model + model.modelId">
                            <div v-if="hasModelData(model) && !isUserModelRelation(model)">
                              <router-link
                                :to="`/app/${
                                  model.model === 'recruitmentProcess' ? 'recruitment-process' : model.model
                                }/${model.modelId}`"
                              >
                                <div class="text-muted btn-sm fw-500 mr-2 mb-2 p-1">
                                  <i class="fa fa-angle-double-right text-secondary"></i>
                                  {{ $t(`app.models.${model.model}`) }} :
                                  {{
                                    model.modelData.name ||
                                    model.modelData.title ||
                                    model.modelData.username ||
                                    (model.modelData.firstName &&
                                      model.modelData.firstName + ' ' + model.modelData.lastName) ||
                                    model.modelId
                                  }}
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="m-0 mt-2 text-left font-primary" style="font-size: 12px; line-height: 0.5">
                        {{ item.CreatedBy ? item.CreatedBy.firstName + ' ' + item.CreatedBy.lastName : '' }}
                        <br /><br />
                        <small class="m-0 font-primary">{{ item.createdOn | formatDate }}</small>
                      </p>
                    </router-link>
                  </div>
                </template>
                <template v-else v-slot:list-empty-state></template>
              </awesome-list>
            </div>
            <div aria-label="Page navigation example text-center w-100" class="d-flex justify-content-center">
              <ul class="pagination">
                <li v-if="!isFirstCommentPageDisplayed" class="page-item">
                  <a class="page-link" @click="goToPage(commentPage - 1)" href="#">{{
                    $t('common.buttons.previous')
                  }}</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">{{ commentPage }}</a>
                </li>
                <li v-if="hasNextCommentPageResults" class="page-item">
                  <a class="page-link" href="#" @click="goToPage(commentPage + 1)">{{ $t('common.buttons.next') }}</a>
                </li>
              </ul>
            </div>
          </px-card>
        </div>
        <div v-if="organisation.featuresClientConversations" class="col-xl-6 xl-50 news box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.latest-conversations') }}</h5>
                <div class="card-header-right-icon">
                  <select class="button btn btn-primary" disabled>
                    <option>{{ $t('stats.today') }}</option>
                    <option>{{ $t('stats.this_week') }}</option>
                    <option>{{ $t('stats.this_month') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="$store.state.request && $store.state.request.dashboardRequests"
                :perRow="1"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template v-slot:list-item="{ item }">
                  <div class="news-update w-100 py-2 mb-4">
                    <router-link :to="`/app/request/${item.id}`">
                      <div class="w-80">
                        <small v-if="item && item.status" class="muted badge badge-primary float-right f-w-300 mt-1">
                          {{ $t(`request.statuses.${item.status}`) }}</small
                        >
                        <p class="text-dark text-truncate pr-2">
                          {{ item.title }}
                        </p>
                        <small class="text-muted font-italic"
                          >{{ item.description ? item.description.substr(0, 100) : '' }}...
                        </small>
                      </div>
                    </router-link>
                  </div>
                </template>
              </awesome-list>

              <div class="news-update d-none">
                <h6>Déclaration des bénéficiaires effectifs</h6>
                <span>Il semble que notre déclaration des bénéficiaires ...</span>
              </div>
            </div>
            <div slot="footer">
              <div class="bottom-btn">
                <router-link to="/app/request">{{ $t('common.buttons.plus') }}</router-link>
              </div>
            </div>
          </px-card>
        </div>

        <div v-if="organisation.featuresClientConversations" class="col-xl-6 xl-50 notification box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.latest-messages') }}</h5>
                <div class="card-header-right-icon">
                  <select class="button btn btn-primary">
                    <option>{{ $t('stats.today') }}</option>
                    <option>{{ $t('stats.this_week') }}</option>
                    <option>{{ $t('stats.this_month') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div slot="with-padding" class="d-none">
              <div class="media">
                <div class="media-body">
                  <p>
                    20-04-2021
                    <span>10:10</span>
                  </p>
                  <small>
                    Attestation de vigilance
                    <span class="dot-notification"></span> </small
                  ><br />
                  <span>Nous attendons une réponse de l'urssaf, je vous la ferais parvenir dès que je la reçois</span>
                </div>
              </div>
              <div class="media">
                <div class="media-body">
                  <p>
                    20-04-2021
                    <span class="pl-1">Il y a quelques instants</span>
                    <span class="badge badge-secondary">New</span>
                  </p>
                  <small>
                    Mise en demeure
                    <span class="dot-notification"></span> </small
                  ><br />
                  <span>Voici mes retours sur le projet de courrier. Je vous laisse faire les modifications.</span>
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
    <div v-if="isTutorialOverlayDisplayed" class="page-dashboard-overlay" />

    <!-- <v-tour class="bg-light text-dark" name="firstVisitTour" :steps="steps" :callbacks="tourCallbacks">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            class="bg-light text-dark"
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template>
              <div slot="actions" class="v-step__actions">
                <button
                  v-if="tour.currentStep !== 3"
                  @click="() => finishTour(tour, true)"
                  class="btn btn-link text-secondary"
                >
                  Passer la visite
                </button>
                <button @click="() => finishTour(tour)" class="btn btn-primary">J'ai compris</button>
              </div>
            </template>
          </v-step>
        </transition>
      </template></v-tour
    > -->
  </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import qs from 'qs';
import { mapState } from 'vuex';
import marked from 'marked';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import MemberSelector from '../../components/user/MemberSelector.vue';
import NoResult from '../../components/basic/NoResult.vue';
import recruitmentStagesMixin from '../../mixins/recruitmentStagesMixin';
import defaultImage from '../../assets/images/user/user.png';

const primary = localStorage.getItem('primary_color') || '#7366ff';
const secondary = localStorage.getItem('secondary_color') || '#f73164';

export default {
  name: 'HrDashboard',
  components: {
    MemberSelector,
    DateRangePicker,
    NoResult,
  },
  mixins: [recruitmentStagesMixin],
  computed: {
    ...mapState('request', ['dashboardRequests']),
    ...mapState('client', ['recentClients']),
    ...mapState('user', ['user', 'organisation']),
    ...mapState('statistics', ['models', 'commentsByEvent', 'recruitmentProcessesByStage']),
    generalStatistics() {
      let result;
      if (this.$store.state.statistics) {
        result = this.$store.state.statistics.models;
        if (!this.organisation.featuresClientConversations) {
          result = result.filter((m) => m.model !== 'request');
        }
      }
      return result;
    },
    chartData() {
      let labels = [];
      let series = [];
      if (this.recruitmentProcessesByStage && this.recruitmentProcessesByStage.length > 0) {
        labels = this.recruitmentProcessesByStage.map((stage) => `${stage.code} (${stage.count})`);
        series = this.recruitmentProcessesByStage.map((stage) => ({
          meta: stage.code,
          value: stage.count,
        }));
        return {
          labels,
          series: [series],
          showChart: labels.length > 0 && series.length > 0,
        };
      }
      return { labels, series: [series] };
    },
    blockedRecruitmentProcesses() {
      let blockedRecruitmentProcesses = [];
      if (this.$store.state.recruitmentProcess.recruitmentProcesses) {
        blockedRecruitmentProcesses = this.$store.state.recruitmentProcess.recruitmentProcesses.filter(
          (rp) =>
            !(rp.stage === 'hired' || rp.stage === 'rejected') &&
            dayjs(rp.stageLastUpdatedOn) < dayjs(this.todayDate).subtract(7, 'days')
        );
      }
      return blockedRecruitmentProcesses;
    },
    hasNextCommentPageResults() {
      return !(this.commentPage > Math.floor(this.totalCommentCount / this.commentPerPage));
    },
    isFirstCommentPageDisplayed() {
      return this.commentPage === 1;
    },
    checkIfModelIsSelectedAndHasItems() {
      if (this.currentModelType !== '' && this.currentModelType !== '--' && this.modelItemList.length > 0) {
        return true;
      }
      return false;
    },
    showClearDatesButton() {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        return true;
      }
      return false;
    },
    dateRanges() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      const yesterdayStart = new Date();
      yesterdayStart.setDate(today.getDate() - 1);
      yesterdayStart.setHours(0, 0, 0, 0);
      const yesterdayEnd = new Date();
      yesterdayEnd.setDate(today.getDate() - 1);
      yesterdayEnd.setHours(23, 59, 59, 999);
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);
      return {
        Today: [today, todayEnd],
        Yesterday: [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999),
        ],
      };
    },

    interviewApiUrl() {
      return `/api/interview?sort[createdOn]=desc&filters[date][$lte]=${this.todayDate}&filters[completed]=1`;
    },
  },
  data() {
    return {
      openPositionsCount: 0,
      newClients: [],
      primary,
      secondary,
      isTutorialOverlayDisplayed: false,
      myComments: [],
      commentPage: 1,
      commentPerPage: 8,
      totalCommentCount: 0,
      eventTypeList: [],
      userId: null,
      showDateRangePicker: false,
      showFilterByDateButton: true,
      todayDate: new Date().toISOString().substr(0, 10),
      dateRange: {
        startDate: null,
        endDate: null,
      },
      myCommentModelTypeList: [
        { model: 'candidate', label: 'Candidate' },
        { model: 'client', label: 'Client' },
        { model: 'contact', label: 'Contact' },
        { model: 'request', label: 'Request' },
        { model: 'interview', label: 'Interview' },
        { model: 'recruitmentProcess', label: 'Recruitment' },
        { model: 'position', label: 'Position' },
      ],
      chartistOptions: { plugins: [ChartistTooltip()], horizontalBars: false },
      modelItemList: [],
      currentEventType: '',
      currentModelType: '',
      currentModelItem: '',
      currentMember: '',
    };
  },
  mounted() {
    this.$store.dispatch('request/getDashboardRequests', { params: { perPage: 5, sort: { createdOn: 'desc' } } });
    this.$store
      .dispatch('client/getClients', { save: false, params: { perPage: 5, sort: { createdOn: 'desc' } } })
      .then((clients) => {
        this.$store.commit('client/recentClients', clients);
      });
    this.$store.dispatch('recruitmentProcess/getRecruitmentProcesses', {
      order: ['createdOn', 'desc'],
      filters: { stage: { $notIn: ['hired', 'rejected'] } },
    });
    this.$store.dispatch('recruitmentProcess/getBlockedRecruitmentProcesses', {
      filters: {
        stage: { $notIn: ['rejected', 'hired', ''] },
        stageLastUpdatedOn: {
          // eslint-disable-next-line no-undef
          $lt: new Date(new Date() - 7 * 24 * 60 * 60 * 1000),
        },
      },
    });
    this.$store.dispatch('recruitmentProcess/getRecruitmentStages');
    this.$store.dispatch('statistics/getCountByModel');
    this.$store.dispatch('statistics/getCommentsGroupedByEvent');
    this.$store.dispatch('statistics/getPositionsGroupedByClient');
    this.$store.dispatch('statistics/getRecruitmentProcessesGroupedByStage');
    this.$store.dispatch('client/getClients', { save: true });
    this.$store.dispatch('position/getItems', {
      save: true,
      params: {
        filters: {
          status: 'open',
        },
      },
    });
    this.getComments();
    this.getEventTypeList();
  },

  watch: {
    userId() {
      this.$store.dispatch('statistics/getCommentsGroupedByEvent', {
        createdBy: this.userId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      });
      this.$store.dispatch('statistics/getPositionsGroupedByClient', {
        recruiterId: this.userId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      });
    },
    dateRange: {
      handler(newValue, oldValue) {
        this.getComments();
      },
      deep: true,
    },
    currentEventType() {
      if (this.currentEventType === '--') {
        this.currentEventType = '';
        return;
      }
      this.commentPage = 1;
      this.getComments();
    },
    currentModelType(newValue, oldValue) {
      this.currentModelItem = '';
      if (this.currentModelType === '--') {
        this.modelItemList = [];
        this.currentModelType = '';
        return;
      }

      this.commentPage = 1;
      this.getComments();

      if (this.currentModelType === '') {
        return;
      }
      this.getItemList();
    },

    currentModelItem() {
      if (this.currentModelItem === '--') {
        this.currentModelItem = '';
        return;
      }

      if (this.currentModelItem === '') {
        this.getComments();
        return;
      }
      this.commentPage = 1;
      this.getComments();
    },
  },
  methods: {
    hasModelData(item) {
      if (!item.model || !item.modelId || !item.modelData) {
        return false;
      }
      return true;
    },

    replaceImage(error) {
      error.target.src = defaultImage;
    },
    // getOpenPositionsCount() {
    //   return this.$http
    //     .get('/api/position', {
    //       params: {
    //         filter: {
    //           status: 'open'
    //         }
    //       },
    //       paramsSerializer(params) {
    //         return qs.stringify(params, {
    //           arrayFormat: 'repeat',
    //         });
    //       },
    //     });
    // },
    isUserModelRelation(item) {
      if (item.model === 'user') {
        return true;
      }
      return false;
    },
    marked(content) {
      const linkedContent = content.replace(/@\[(.+):(.+):(.*)\]/g, '<span class="text-info">$3</span>');
      return marked(linkedContent);
    },
    formatDate(date) {
      return dayjs(date).fromNow();
    },
    clearDateRangePicker() {
      this.showDateRangePicker = false;
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.loadStatistics();
    },

    goToPage(page = 0) {
      if (page - 1 >= 0) {
        this.commentPage = page;
        this.getComments();
      }
    },

    loadStatistics() {
      this.$store.dispatch('statistics/getCountByModel', {
        startDate: this.dateRange.startDate && new Date(this.dateRange.startDate.setHours(0, 0, 0, 0)),
        endDate: this.dateRange.endDate && new Date (this.dateRange.endDate.setHours(23, 59, 0, 0)),
      });
      this.$store.dispatch('statistics/getCommentsGroupedByEvent', {
        createdBy: this.userId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      });
      this.$store.dispatch('statistics/getPositionsGroupedByClient', {
        recruiterId: this.userId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      });
    },

    formatData(modelData) {
      let formattedData = [];

      if (!Array.isArray(modelData) && !modelData.length > 0) {
        return formattedData;
      }
      // eslint-disable-next-line no-return-assign
      formattedData = modelData.map((item) => {
        if (item.model === 'user' || item.model === 'candidate') {
          return {
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
        }

        if (item.model === 'position' || item.model === 'request') {
          return {
            id: item.id,
            label: item.title,
          };
        }
        if (item.model === 'client') {
          return {
            id: item.id,
            label: item.name,
          };
        }

        if (item.model === 'recruitmentProcess') {
          return {
            id: item.id,
            label: item.id,
          };
        }
        return {
          id: item.id,
          label: item.id,
        };
      });

      return formattedData;
    },

    goToClientDetail(client) {
      this.$router.push(`/app/client/${client.id || client}`);
    },

    getComments() {
      const requestFilters = {
        includeModelData: true,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      };
      if (this.currentEventType !== '') {
        requestFilters.eventTypeCode = this.currentEventType;
      }

      if (this.currentModelType !== '') {
        requestFilters.model = this.currentModelType;
      }

      if (this.currentModelItem !== '') {
        requestFilters.modelId = this.currentModelItem;
      }
      return this.$http
        .get('/api/comment', {
          params: {
            page: this.commentPage - 1,
            perPage: this.commentPerPage,
            filters: requestFilters,
          },
          paramsSerializer(params) {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
            });
          },
        })
        .then(({ data }) => {
          if (!data.body) {
            return;
          }

          if (data.body && data.totalCount > 0) {
            this.myComments = data.body;
          } else {
            this.myComments = [];
          }

          this.totalCommentCount = data.totalCount;
        })
        .catch((err) => console.warn(err.message));
    },

    getItemList() {
      this.$http
        .get(`/api/${this.currentModelType === '' ? 'all' : this.currentModelType}/all/comment/get-item-list`)
        .then(({ data }) => {
          let formattedItemList = [];

          formattedItemList = this.formatData(
            data.body.map((item) => {
              item.ModelData.model = item.model;
              return item.ModelData;
            })
          );
          this.modelItemList = formattedItemList;
          return data;
        })
        .catch((err) => console.warn(err));
    },

    getEventTypeList() {
      const p1 = this.$http.get(`/api/event-type?filters[organisationId]=${this.organisation.id}`);
      const p2 = this.$http.get(`api/recruitment-stage?filters[organisationId]=${this.organisation.id}`);
      Promise.all([p1, p2]).then(([response1, response2]) => {
        this.eventTypeList = uniqBy([...this.eventTypeList, ...response1.data.body, ...response2.data.body], 'code');
      });
    },

    isMobile() {
      return window.outerWidth < 992;
    },
  },
};
</script>

<style lang="scss">
#clear-date-range-picker {
  height: 30px;
}

.page-dashboard-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(78, 76, 93, 0.8);
}
</style>
