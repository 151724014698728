/* eslint-disable import/prefer-default-export */

export const card = {
  identity: 'card',
  icon: '',
  schema: {
    properties: {
    },
  },
  // actions: { create: true, edit: true, delete: true },
  options: {
  },
  customFormTopActions: [
  ],
  customInlineActions: [
    {
      name: 'send-mail',
      label: 'common.buttons.contact',
      class: 'btn btn-sm btn-simple btn-awtable-inline-action btn-icon ml-2 text-white',
      icon: 'fa fa-envelope text-info',
      visible: ({ item }) => item?.email,
      action: ({
        item, action, location, props, id
      }, context) => {
        context.$store.dispatch('email/setShowEmailComposerModal', {
          show: true,
          recipientObject: item,
          recipientAddress: item && item.email
        });
      },
    },
  ],
  customBulkActions: [
  ],
  nestedSchemas: [],
};
