/* eslint-disable import/prefer-default-export */

export const board = {
  identity: 'board',
  icon: '',
  schema: {
    properties: {
    },
  },
  // actions: { create: true, edit: true, delete: true },
  options: {
  },
  customFormTopActions: [

  ],
  customInlineActions: [
    {
      name: 'open',
      label: 'common.buttons.open',
      class: 'btn btn-sm btn-simple btn-awtable-inline-action btn-icon ml-2 text-white',
      icon: 'fa fa-info text-info',
      visible: ({ item }) => item.email,
      action: ({
        item, action, location, props, id
      }, context) => {
        context.$route.push(`/app/board/${item.id}?ts=${Date.now()}`);
      },
    },
  ],
  customBulkActions: [
  ],
  nestedSchemas: [],
};
