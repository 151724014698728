<template>
  <div>
    <b-modal
      id="modal-1"
      ref="modal"
      :title="$t('common.labels.publicCandidateTitle') + ' ' + position.title + ''"
      :visible="shouldDisplayModal"
      :ok-title="$t('common.buttons.submit')"
      :cancel-title="$t('common.buttons.close')"
      @cancel="handleCloseModal"
      @closeRequested="handleCloseModal"
      @hidden="handleCloseModal"
      @ok="handleOk"
    >
      <b-form ref="form" @submit.stop.prevent="">
        <b-form-group
          :label="$t('common.field.firstName')"
          label-for="firstName"
          :invalid-feedback="$t('common.field.firstName') + ' is required'"
        >
          <b-form-input
            type="text"
            required
            autocomplete="off"
            v-model="candidate.firstName"
            id="firstName"
            ref="firstName"
            :state="candidateState.firstName"
          />
        </b-form-group>
        <b-form-group
          :label="$t('common.field.lastName')"
          label-for="lastName"
          :invalid-feedback="$t('common.field.lastName') + ' is required'"
        >
          <b-form-input
            type="text"
            required
            autocomplete="off"
            v-model="candidate.lastName"
            id="lastName"
            ref="lastName"
            :state="candidateState.lastName"
          />
        </b-form-group>
        <b-form-group
          :label="$t('common.field.email')"
          label-for="email"
          :invalid-feedback="$t('common.field.email') + ' is required'"
        >
          <b-form-input
            type="email"
            required
            autocomplete="off"
            v-model="candidate.email"
            id="email"
            ref="email"
            :state="candidateState.email"
          />
        </b-form-group>
        <b-form-group
          :label="$t('common.field.phone')"
          label-for="phone"
          :invalid-feedback="$t('common.field.phone') + ' is required'"
        >
          <b-form-input
            type="text"
            required
            autocomplete="off"
            v-model="candidate.phone"
            id="phone"
            ref="phone"
            :state="candidateState.phone"
          />
        </b-form-group>
        <b-form-group :label="$t('common.field.cv')" label-for="cv">
          <div class="base64-upload">
            <p style="min-height: 70px; font-size: 12px; text-align: center">
              {{ fileName }} <i class="fa fa-upload fa-2x" v-if="!fileName"></i>
            </p>
            <input id="cv" ref="cv" multiple type="file" :accept="'*'" @change="onFileInputChange" />
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'PublicCandidate',
  props: {
    shouldDisplayModal: {
      type: Boolean,
      required: true,
    },
    organisationId: {
      type: String,
      default: null,
    },
    position: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    // this.getDocuments();
  },

  data() {
    return {
      candidate: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      candidateState: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
      },
      show: false,
      fileToUpload: null,
      fileName: '',
    };
  },

  methods: {
    handleCloseModal() {
      this.$emit('closeModal');
    },

    async handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      const success = await this.submitForm();
      if (success) {
        this.$emit('closeModal');
      }
    },

    checkFormValidity() {
      // Check each individual field to display message
      const firstNameValid = this.$refs.firstName.checkValidity();
      const lastNameValid = this.$refs.lastName.checkValidity();
      const emailValid = this.$refs.email.checkValidity();
      const phoneValid = this.$refs.phone.checkValidity();
      const formValid = this.$refs.form.checkValidity();

      this.candidateState.firstName = firstNameValid;
      this.candidateState.lastName = lastNameValid;
      this.candidateState.email = emailValid;
      this.candidateState.phone = phoneValid;

      return formValid;
    },

    async submitForm() {
      try {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return false;
        }

        await this.$http.post(
          '/api/public-candidate',
          {
            firstName: this.candidate.firstName,
            lastName: this.candidate.lastName,
            email: this.candidate.email,
            phonenumber: this.candidate.phone,
            positionId: this.position.id,
            cv: this.fileToUpload,
            source: 'widget',
            tags: ['widget'],
          },
          {
            headers: {
              'x-cimple-organisation-id': this.organisationId,
            },
          }
        );
        this.$awNotify({
          title: this.$t('common.messages.createPublicCandidateMessage'),
          type: 'success',
        });
        this.candidate = {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        };
        this.candidateState = {
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
        };
        this.fileToUpload = null;
        this.fileName = '';

        return true;
      } catch (err) {
        this.apiErrorCallback(err);
        return false;
      }
    },

    onFileInputChange(event) {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        this.getBase64(file);
      }
    },

    getBase64(file) {
      const reader = new FileReader();

      reader.addEventListener('load', (e) => {
        this.src = e.target.result;
        const [, base64] = this.src.split(',');
        this.fileName = file.name;
        this.fileToUpload = {
          size: file.size,
          type: file.type,
          name: file.name,
          base64,
        };
        this.$emit('change', {
          size: file.size,
          type: file.type,
          name: file.name,
          base64,
        });
      });
      reader.readAsDataURL(file);
    },
  },
};
</script>
