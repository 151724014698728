import Vue from 'vue';
import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
import CripVueLoading from 'crip-vue-loading';
// import BootstrapVue from 'bootstrap-vue';
import VueGoodTablePlugin from 'vue-good-table';
import VueFeather from 'vue-feather';
import PortalVue from 'portal-vue';
import Draggable from 'vuedraggable';
import VueTour from 'vue-tour';
import vueKanban from 'vue-kanban';
import VTooltip from 'v-tooltip';
import VueShortkey from 'vue-shortkey';
import VueChartist from 'vue-chartist';
import * as VueGoogleMaps from 'vue2-google-maps';
import 'cleave.js';

import 'chartist/dist/chartist.min.css';
import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.min';

import VueAwesomeComponents from 'vue-aw-components/src/plugin';

import { FormGenerator, notificationsMixin } from 'vue-aw-components';

import axios from 'axios';
import _ from 'lodash';

// import en from 'vue-aw-components/src/translations/en.json';
import fr from 'vue-aw-components/src/translations/fr.json';
import en from 'vue-aw-components/src/translations/en.json';
// import { Integrations } from '@sentry/tracing';
// import Sentry from './services/Sentry';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import GlobalComponents from './globalComponents';
import App from './App.vue';
import router from './router';
import config from './config';
import localeFr from './config/locales/fr.json';
import localeEn from './config/locales/en.json';
import Breadcrumbs from './components/Breadcrumbs.vue';
import store from './store';

// import VueLoading from 'vue-loading-overlay';

import PxCard from './components/Pxcard.vue';

// Import Theme scss
import './assets/scss/app.scss';

Vue.use(VueFeather);
// Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(PortalVue);
Vue.use(VueTour);
Vue.use(vueKanban);
Vue.use(VTooltip);
Vue.use(VueShortkey);
// register all global components
Vue.use(GlobalComponents);
Vue.use(VueChartist);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCx5YIxDhkjLZZRdQ7r9RFTLPXeq1i6aHw',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    /// / If you want to set the version, you can do so:
    // v: '3.26',
  },

  /// / If you intend to programmatically custom event listener code
  /// / (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  /// / instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  /// / you might need to turn this on.
  // autobindAllEvents: false,

  /// / If you want to manually install components, e.g.
  /// / import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  /// / Vue.component('GmapMarker', GmapMarker)
  /// / then set installComponents to 'false'.
  /// / If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN || '',
    ignoreErrors: ['NavigationDuplicated', 'no_organisation_in_header', 'Request failed with status code 401'],
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],

    beforeSend(event) {
      event.timestamp = new Date(event.timestamp).toISOString();
      event.breadcrumbs.forEach((breadcrumb) => {
        breadcrumb.timestamp = new Date(breadcrumb.timestamp).toISOString();
      });
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.3 : 1,
  });
}
Vue.use(FormGenerator, {
  fields: _.values(FormGenerator.fieldsLoader),
});

Vue.use(VueGoodTablePlugin);
Vue.config.productionTip = isProduction;
axios.defaults.withCredentials = process.env.NODE_ENV === 'production';

Vue.prototype.$http = axios.create({
  baseURL: config.apiUrl,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(`${config.appKey}_token`)}`,
  },
});

Vue.use(CripVueLoading, { axios: Vue.prototype.$http });

Vue.component(PxCard.name, PxCard);
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.component('Draggable', Draggable);

// global variables
window.myApp = {
  locale: config.defaultLocale || 'en',
};

let vInstance;
Vue.prototype.$http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      const url = error.response && error.response.config && error.response.config.url;
      if (
        localStorage.getItem(`${config.appKey}_token`)
        && url
        && url.indexOf('/login') === -1
        && url.indexOf('/reset-password') === -1
        && url.indexOf('/forgot') === -1
        && url.indexOf('/register') === -1
        && url.indexOf('/models') === -1
      ) {
        store.dispatch('user/logout');
        if (vInstance && vInstance.$router) {
          vInstance.$router.push('/login');
        }
        // v.$notify("Your session has expired");
      }
    }
    return Promise.reject(error);
  }
);

Vue.use(VueAwesomeComponents, {
  config: {
    modelsStorePath: 'models',
    rolesStorePath: 'user.user.organisationRoles',
    extendedRolesStorePath: 'user.extendedRoles',
    primaryKey: 'id',
  },
  AwesomeCrud: {
    props: {
      primaryKey: { type: String, default: 'id' },
      modelsStorePath: {
        type: String,
        default: 'models',
      },
      options: { detailPageMode: 'sidebar' },
    },
  },
  AwesomeTable: {
    props: {
      primaryKey: {
        type: String,
        default: 'id',
      },
      modelsStorePath: {
        type: String,
        default: 'models',
      },
    },
  },
  AwesomeList: {
    props: {
      primaryKey: {
        type: String,
        default: 'id',
      },
      modelsStorePath: {
        type: String,
        default: 'models',
      },
    },
  },
  AwesomeForm: {
    props: {
      primaryKey: {
        type: String,
        default: 'id',
      },
      modelsStorePath: {
        type: String,
        default: 'models',
      },
    },
  },
});

const i18n = new VueI18n({
  locale: store.state.currentLocale || config.defaultLocale, // set locale
  messages: _.merge(
    { fr, en },
    {
      fr: localeFr,
      en: localeEn,
    }
  ),
  silentTranslationWarn: process.env.VUE_APP_NO_I18N_LOGS || isProduction,
});

function init() {
  vInstance = new Vue({
    router,
    store,
    i18n,
    mixins: [notificationsMixin],
    render: (h) => h(App),
  }).$mount('#app');

  if (!isProduction) {
    window.myApp.vue = vInstance;
  }
  // set a cookie on the main domain for autoredirect
  // setLoginCookie();

  if (config.features.whiteLabel) {
    store.dispatch('layout/setColorScheme', {
      primary: config.display.primaryColor,
      secondary: config.display.secondaryColor,
    });
  }
}

// REFRESH DATA
if (localStorage.getItem(`${config.appKey}_token`)) {
  store
    .dispatch('user/refreshUser')
    .then((user) => {
      let locale;
      try {
        locale = localStorage.getItem(`${config.appKey}_locale`);
      } catch (err) {
        locale = config.defaultLocale;
      }
      if (user && user.locale) {
        locale = user.locale;
      }
      store.commit('currentLocale', locale);
      store.dispatch('refreshListOfValues');
      // if we are in whitelabel mode then alow editing colors
      setTimeout(() => {
        if (
          vInstance
          && vInstance.$route.name === 'Login'
          && user
          && user[config.primaryKey]
          && !document.location.search.includes('t=')
          && !document.location.search.includes('noRedirect=')
        ) {
          vInstance.$router.push('/');
        }
      }, 300);

      if (window.gtag && user && user[config.primaryKey]) {
        window.gtag('config', 'G-X47H5145TC', {
          user_id: user[config.primaryKey],
        });
      }
    })
    .catch((err) => {
      console.error('userRefresh issue', err);
      if (vInstance && vInstance.$route.name !== 'Login') {
        vInstance.$router.push('/login');
      }
    })
    .finally(() => {
      init();
    });
} else {
  init();
}
