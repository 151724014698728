import client from './client';
import data from './data';
import email from './email';
import layout from './layout';
import menu from './menu';
import recruitmentProcess from './recruitmentProcess';
import request from './request';
import statistics from './statistics';
import storeMaker from './_storeMaker';
import user from './user';
import config from '../../config';

const { PUBLIC_ORG_ID } = config;

export default {
  board: storeMaker('board', {}),
  card: storeMaker('card', {}),
  candidate: storeMaker('candidate', {}),
  client,
  company: storeMaker('company', {
    actions: {
      async convertToLead({ state, dispatch, rootState }, leadData) {
        const tags = leadData.tags || [];
        tags.push('convertedFromCompany');
        if (leadData.organisationId !== PUBLIC_ORG_ID) {
          tags.push(`companyId:${leadData.id}`);
        }
        const lead = {
          name: `${leadData.name}`,
          company: `${leadData.name}`,
          phonenumber: leadData.Contacts?.[0]?.phonenumber,
          email: leadData.Contacts?.[0]?.email,
          linkedin: leadData.linkedin || leadData.linkedIn,
          websiteUrl: leadData.websiteUrl,
          description: leadData.description,
          source: 'in-app',
          affectedToUserId: rootState.user.user.id,
          tags,
          avatarUrl: leadData.avatarUrl,
          temperature: leadData.temperature,
          quality: leadData.quality,
        };
        // create a lead from the company
        const createdLead = await dispatch('lead/createItem', lead, { root: true });
        // if the lead has contacts import them
        if (createdLead?.id && Array.isArray(leadData.Contacts)) {
          const promises = leadData.Contacts.map((contact) => {
            // if it's a public contact recreate it to allow modification
            if (leadData.organisationId !== PUBLIC_ORG_ID) {
              return dispatch('contact/updateItem',
                { id: contact.id, leadId: createdLead.id },
                { root: true });
            }
            return dispatch('contact/createItem',
              { ...contact, id: undefined, leadId: createdLead.id, createdOn: new Date() },
              { root: true });
          });
          await Promise.all(promises);
          // if the company is not public, link it to the lead
          if (leadData.organisationId !== PUBLIC_ORG_ID) {
            await dispatch('updateItem', {
              id: leadData.id,
              name: leadData.name,
              leadId: createdLead.id,
              tags: [...(leadData.tags || []), 'convertedToLead', `leadId:${createdLead.id}`]
            });
          }
        }
        return createdLead;
      }
    }
  }),
  contact: storeMaker('contact', {
    actions: {
      async convertToLead({ state, dispatch, rootState }, leadData) {
        const lead = {
          name: `${leadData.firstName} ${leadData.lastName}`,
          company: `${leadData.company || leadData.Company?.name || ''}`,
          phonenumber: leadData.phonenumber || '',
          email: leadData.email || '',
          linkedin: leadData.linkedin || '',
          clientId: leadData.clientId,
          description: leadData.description || leadData?.Company?.description || '',
          source: 'in-app',
          affectedToUserId: rootState.user.user.id,
          tags: leadData.tags,
          avatarUrl: leadData.avatarUrl,
        };

        const createdLead = await dispatch('lead/createItem', lead, { root: true });
        if (createdLead?.id) {
          // if the company is not public, link it to the lead
          if (createdLead.organisationId !== PUBLIC_ORG_ID) {
            await dispatch('updateItem', { id: leadData.id, leadId: createdLead.id });
          }
        }
        return createdLead;
      }
    }
  }),
  data,
  displaySegment: storeMaker('displaySegment', {}, { apiUrl: 'display-segment' }),
  email,
  interview: storeMaker('interview', {}),
  invitation: storeMaker('invitation', {}, { apiUrl: 'user-invitation' }),
  layout,
  lead: storeMaker('lead', {}),
  leadStage: storeMaker('leadStage', {}, { apiUrl: 'lead-stage' }),
  member: storeMaker('member', {}),
  menu,
  position: storeMaker('position', {}),
  recruitmentProcess,
  recruitmentStage: storeMaker('recruitmentStage', {}, { apiUrl: 'recruitment-stage' }),
  request,
  requestStatus: storeMaker('requestStatus', {}, { apiUrl: 'request-status' }),
  statistics,
  task: storeMaker('task', {}),
  user,
};
