<template>
  <div>
    <b-modal
      id="custom-fields-crud"
      size="lg"
      :visible="showCustomFieldTableModal"
      @cancel="handleCloseCustomFieldTableModal"
      @closeRequested="handleCloseCustomFieldTableModal"
      @hidden="handleCloseCustomFieldTableModal"
    >
      <template #modal-title>
        <h3 class="mt-3 mb-0">{{ $t('common.labels.customFields') }}</h3>
        <hr class="text-primary mt-1 bg-info" />
      </template>
      <AwesomeTable
        ref="customFieldsTable"
        :actions="{
          edit: true,
          delete: true,
          refresh: true,
          create: true,
        }"
        :url="`/api/custom-field?filters[model]=${identity}`"
        :perRow="1"
        :perPage="20"
        :showHeader="false"
        :columns="['name']"
        @edit="handleEditCustomField"
        @delete="handleDeleteCustomFieldForm"
        @create="
          showCreateCustomFieldForm = true;
        "
      >
        <template #table-header-right>
          <button
            class="btn btn-outline-primary mr-1"
            @click="
              showCreateCustomFieldForm = true;
            "
          >
            + {{ $t('common.buttons.addField') }}
          </button>
        </template>
      </AwesomeTable>
      <!-- custom field edit modal -->
      <awesomeForm
        v-if="showUpdateCustomFieldForm"
        identity="customField"
        mode="edit"
        :item="customFieldToUpdate"
        displayMode="modal"
        url="/api/custom-field"
        :actions="{
          delete: true,
          close: true,
          cancel: true,
        }"
        :useRouterMode="false"
        @delete="handleDeleteCustomFieldForm"
        @cancel="closeUpdateCustomFieldForm"
        @save="closeUpdateCustomFieldForm"
        @closeRequested="closeUpdateCustomFieldForm"
      ></awesomeForm>
      <!-- custom field create modal -->
      <awesomeForm
        v-if="showCreateCustomFieldForm"
        identity="customField"
        mode="create"
        :item="{ model: identity }"
        displayMode="modal"
        url="/api/custom-field"
        :useRouterMode="false"
        @cancel="closeCreateCustomFieldForm"
        @save="closeCreateCustomFieldForm"
        @closeRequested="closeCreateCustomFieldForm"
      ></awesomeForm>
      </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import { AwesomeTable } from 'vue-aw-components';

export default {
  name: 'CustomFieldsCrud',
  props: {
    showCustomFieldTableModal: { type: Boolean, default: false },
    identity: String,
    handleCloseCustomFieldTableModal: Function,
  },
  components: {
    AwesomeTable,
    BModal,
  },
  data() {
    return {
      showCreateCustomFieldForm: false,
      showUpdateCustomFieldForm: false,
    }
  },
  methods: {
    refreshCustomFieldsTable() {
      this.$refs.customFieldsTable.getItems();
    },

    closeCreateCustomFieldForm() {
      this.showCreateCustomFieldForm = false;
      this.refreshCustomFieldsTable();
      this.$store.dispatch('getModels');
    },
    closeUpdateCustomFieldForm() {
      this.refreshCustomFieldsTable();
      this.showUpdateCustomFieldForm = false;
      this.$store.dispatch('getModels');
    },
    openCreateCustomFieldForm() {
      this.showCreateCustomFieldForm = true;
    },
    handleEditCustomField(event) {
      this.customFieldToUpdate = event;
      this.showUpdateCustomFieldForm = true;
      this.customFieldFormMode = 'edit';
    },
    async handleDeleteCustomFieldForm(event) {
      const confirmed = await this.$awConfirm('Êtes-vous sur de vouloir effectuer cette action ?');
      if (confirmed && event.id) {
        this.$http.delete(`/api/custom-field/${event.id}`).then(() => {
          this.closeUpdateCustomFieldForm();
          this.$store.dispatch('getModels');
          this.refreshCustomFieldsTable();
        });
      }
    },
  }
}

</script>

<style lang="scss">
#custom-fields-crud {
  .aw-table-card-body { 
    thead {
    display: none;
    };
    .vgt-checkbox-col {
      display: none;
    };
  }
  .modal-content {
    .modal-body {
    padding-top: 0 !important;
    }
  }
}
</style>