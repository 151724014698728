<template>
  <div>
    <Breadcrumbs
      main="client"
      :title="'Client: ' + clientTitle"
      :isEditingLayout="isEditingLayout"
      @layout-edit-toggle="toggleLayoutEdit"
      @layout-reset="resetLayout"
      can-edit-layout
    >
    </Breadcrumbs>
    <div class="container-fluid">
      <div class="text-center" v-if="!selectedClient">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" fill="var(--primary, ')">
          <circle transform="translate(8 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
          <circle transform="translate(16 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0.3"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
          <circle transform="translate(24 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0.6"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
        </svg>
      </div>
      <div v class="row m-0" :class="isMobile ? 'flex-column' : 'justify-content-between'">
        <template v-if="organisation.featuresHumanResources">
          <div class="">
            <px-card class="bg-secondary static-top-widget-card pt-3">
              <div slot="with-padding">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center"><feather type="file-text" class="middle"></feather></div>
                  <div class="media-body">
                    <span class="m-0">{{ $t('app.client-detail.open-positions') }}</span>
                    <h4 class="mb-0 counter">
                      {{ openPositionList.totalCount || 0 }}
                    </h4>
                    <feather type="file-text" class="icon-bg"></feather>
                  </div>
                </div>
              </div>
            </px-card>
          </div>
          <div class="">
            <px-card class="bg-success static-top-widget-card pt-3">
              <div slot="with-padding">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center"><feather type="users" class="middle"></feather></div>
                  <div class="media-body">
                    <span class="m-0">{{ $t('app.client-detail.recruited-candidates') }}</span>
                    <h4 class="mb-0 counter">
                      {{ finishedRecruitmentList.totalCount || 0 }}
                    </h4>
                    <feather type="users" class="icon-bg"></feather>
                  </div>
                </div>
              </div>
            </px-card>
          </div>
          <div class="">
            <px-card class="bg-primary static-top-widget-card pt-3">
              <div slot="with-padding">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center"><feather type="users" class="middle"></feather></div>
                  <div class="media-body">
                    <span class="m-0"> {{ $t('app.client-detail.in-process-candidates') }}</span>
                    <h4 class="mb-0 counter">
                      {{ ongoingRecruitmentList.totalCount || 0 }}
                    </h4>
                    <feather type="users" class="icon-bg"></feather>
                  </div>
                </div>
              </div>
            </px-card>
          </div>
        </template>
        <template v-if="organisation.featuresClientConversations">
          <div class="d-none">
            <px-card class="bg-primary static-top-widget-card pt-3">
              <div slot="with-padding">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center">
                    <feather type="message-square" class="middle"></feather>
                  </div>
                  <div class="media-body">
                    <span class="m-0">{{ $t('app.client-detail.total-requests') }}</span>
                    <h5 class="mb-0 counter"></h5>
                  </div>
                </div>
              </div>
            </px-card>
          </div>
          <div class="">
            <px-card class="bg-secondary static-top-widget-card pt-3">
              <div slot="with-padding">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center">
                    <feather type="message-square" class="middle"></feather>
                  </div>
                  <div class="media-body">
                    <span class="m-0">{{ $t('app.client-detail.totalRequests') }}</span>
                    <h4 class="mb-0 counter">
                      {{ (selectedClientStats && selectedClientStats.totalRequests) || 0 }}
                    </h4>
                    <feather type="message-square" class="icon-bg"></feather>
                  </div>
                </div>
              </div>
            </px-card>
          </div>
          <div class="">
            <px-card class="bg-success static-top-widget-card pt-3">
              <div slot="with-padding">
                <div class="media static-top-widget">
                  <div class="align-self-center text-center">
                    <feather type="message-square" class="middle"></feather>
                  </div>
                  <div class="media-body">
                    <span class="m-0">{{ $t('app.client-detail.openRequests') }}</span>
                    <h4 class="mb-0 counter">
                      {{ (selectedClientStats && selectedClientStats.openRequests) || 0 }}
                    </h4>
                    <feather type="message-square" class="icon-bg"></feather>
                  </div>
                </div>
              </div>
            </px-card>
          </div>
        </template>
      </div>
      <hr />
      <div class="email-wrap bookmark-wrap" v-if="selectedClient">
        <DraggableLayout
          classes="row"
          responsive
          dragoverBubble="true"
          @change="onLayoutChange"
          @close="toggleLayoutEdit"
          @reset="resetLayout"
          v-model="pageLayout"
          :disabled="isEditingLayout"
          :isEditing="isEditingLayout"
        >
          <template slot-scope="{ itemId }">
            <client-info-widget
              v-if="itemId === 'client'"
              :key="itemId"
              show-view-button
              show-edit-button
              show-delete-button
              class="card draggable"
              :client="selectedClient"
              @view="$router.push({ path: `/app/client/${selectedClient.id}/view` })"
              @edit="$router.push({ path: `/app/client/${selectedClient.id}/edit` })"
              @delete="deleteClient(selectedClient)"
            />

            <div class="card draggable" :key="itemId" v-if="itemId === 'contacts'">
              <ContactsWidget
                v-if="selectedClient.Contacts"
                :contacts="selectedClient.Contacts"
                :clientId="selectedClient.id"
                @change="onContactsChange"
              ></ContactsWidget>
            </div>
            <TasksWidget
              class="draggable"
              :key="itemId"
              v-if="itemId === 'tasks'"
              model="client"
              :modelId="selectedClient.id"
            />
            <CommentsWidget
              class="draggable"
              :key="itemId"
              v-if="itemId === 'comments'"
              model="client"
              :modelId="selectedClient.id"
              enable-events
            />
            <DocumentsWidget
              class="draggable"
              :key="itemId"
              v-if="itemId === 'documents'"
              model="client"
              :modelId="selectedClient.id"
            />
            <MetadataWidget
              class="draggable"
              v-if="itemId === 'metadata'"
              model="client"
              :key="itemId"
              :item="client"
              :modelId="selectedClient.id"
            />
            <CustomFieldValuesWidget
             v-if="itemId === 'custom-field-value'"
              class="draggable"
              model="client"
              :modelId="selectedClient.id"
              :item="selectedClient"
            />
            <px-card
              class="draggable"
              :key="itemId"
              v-if="organisation.featuresHumanResources && itemId === 'positions'"
              :actions="false"
            >
              <div slot="headerCustom">
                <div class="header-top">
                  <h5 id="#" class="m-0">{{ $t('app.client-detail.positions') }}</h5>
                  <button class="btn btn-link p-1" @click="createNewPosition(selectedClient)">
                    <feather type="plus" class="text-primary" />
                  </button>
                </div>
              </div>
              <div class="position-aws-list">
                <awesome-list
                  :actions="{
                    refresh: false,
                    pagination: true,
                    search: false,
                    filter: true,
                    advancedFiltering: true,
                  }"
                  url="/api/position"
                  :perRow="1"
                  :useGridSystem="true"
                  :showHeader="false"
                  :segmentField="'status'"
                  :apiQueryParams="{ filters: { clientId: $route.params.id } }"
                  :columns="[{ field: 'status', enum: ['open', 'on hold', 'closed'] }]"
                >
                  <template v-slot:list-item="{ item }">
                    <div class="news-update w-100 py-2">
                      <router-link :to="`/app/position/${item.id}`">
                        <small
                          v-if="item.status"
                          class="muted badge badge-primary  f-w-300 float-right"
                        >
                          {{ item.status }}</small
                        >
                        <p class="text-dark">
                          {{ item.title }}
                        </p>
                        <p class="m-0 text-left" style="font-size: 14px; line-height: 0.5">
                          {{ item.CreatedBy ? item.CreatedBy.firstName + ' ' + item.CreatedBy.lastName : '' }}

                          <small class="text-muted" style="font-size: 8px; line-height: 0.5">
                            {{ $t('AwesomeCrud.labels.createdOn') }} {{ item.createdOn | formatDate }}</small
                          >
                        </p>
                      </router-link>
                    </div>
                  </template>
                </awesome-list>
              </div>
            </px-card>

            <px-card
              class="draggable"
              :key="itemId"
              v-if="organisation.featuresHumanResources && itemId === 'recruitments'"
            >
              <div slot="headerCustom">
                <div class="header-top">
                  <h5 id="#" class="m-0">{{ $t('app.client-detail.recruitments') }}</h5>
                </div>
              </div>
              <div class="recruitment-aws-list">
                <awesome-list
                  :actions="{
                    refresh: false,
                    pagination: true,
                    search: false,
                    filter: true,
                    advancedFiltering: true,
                  }"
                  url="api/recruitment-process"
                  sortField="order"
                  :perRow="1"
                  :useGridSystem="true"
                  :showHeader="false"
                  :segmentField="'stage'"
                  :apiQueryParams="{ filters: { clientId: $route.params.id } }"
                  :columns="[
                    {
                      field: 'stage',
                      relation: 'recruitmentStage',
                      relationKey: 'code',
                      relationLabel: 'label',
                      relationUrl: '/api/recruitment-stage',
                    },
                  ]"
                >
                  <template v-slot:list-item="{ item }">
                    <div class="news-update w-100 py-2 mb-3">
                      <router-link :to="`/app/recruitment-process/${item.id}`">
                        <small
                          v-if="item.id && item.stage"
                          class="muted badge badge-primary  f-w-300 pull-right"
                        >
                          {{ displayRecruitmentStageLabel(item.stage) || item.stage }}</small
                        >
                        <p class="m-0 text-left" style="font-size: 14px; line-height: 0.5">
                          Candidat :
                          {{ item.Candidate ? item.Candidate.firstName + ' ' + item.Candidate.lastName : '- -' }}
                          <br /><br />
                        </p>
                        <h6 class="mb-0">
                          <!-- {{ item.content }} -->
                          <small
                            >{{ $t('app.client-detail.position') }} :
                            {{ item.Position ? item.Position.title : '-' }}</small
                          >
                        </h6>
                        <small class="text-muted" style="font-size: 8px; line-height: 0.5">
                          {{ $t('AwesomeCrud.labels.createdOn') }}
                          {{ item.createdOn | formatDate }}</small
                        >
                      </router-link>
                    </div>
                  </template>
                </awesome-list>
              </div>
            </px-card>

            <px-card
              class="draggable"
              v-if="organisation.featuresClientConversations && itemId === 'requests'"
              key="requests"
              :actions="true"
            >
              <div class="email-right-aside bookmark-tabcontent">
                <div class="card email-body radius-left">
                  <div class="pl-0">
                    <div class="tab-content">
                      <div
                        class="tab-pane fade active show"
                        id="pills-created"
                        role="tabpanel"
                        aria-labelledby="pills-created-tab"
                      >
                        <div class="card mb-0">
                          <div class="card-header align-items-center">
                            <h5 class="d-inline-block">
                              {{ $t('app.client-detail.requests') }}
                            </h5>
                            <button class="btn btn-link p-1 pull-right" @click="openNewRequestForm()">
                              <feather type="plus" class="text-primary" />
                            </button>
                          </div>
                          <div class="card-body p-0">
                            <div class="request-details" style="overflow: auto; width: 100%">
                              <div class="">
                                <RequestsKanban
                                  :requests="requests"
                                  :actions="{ addList: false, refresh: false }"
                                  @requestAdded="updateRequestStatus"
                                  @requestClicked="goToRequestDetail"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </px-card>

            <px-card
              class="draggable"
              v-if="organisation.featuresClientConversations && itemId === 'leads'"
              key="leads"
              :actions="true"
            >
              <div class="email-right-aside bookmark-tabcontent">
                <div class="card email-body radius-left">
                  <div class="pl-0">
                    <div class="tab-content">
                      <div
                        class="tab-pane fade active show"
                        id="pills-created"
                        role="tabpanel"
                        aria-labelledby="pills-created-tab"
                      >
                        <div class="card mb-0">
                          <div class="card-header align-items-center">
                            <h5 class="d-inline-block">
                              {{ $t('app.client-detail.leads') }}
                            </h5>
                            <button class="btn btn-link p-1 pull-right" @click="openNewLeadForm()">
                              <feather type="plus" class="text-primary" />
                            </button>
                          </div>
                          <div class="card-body p-0">
                            <div class="request-details" style="overflow: auto; width: 100%">
                              <div class="">
                                <LeadsKanban
                                  :leads="leads"
                                  :actions="{ addList: false, refresh: false }"
                                  @leadAdded="updateLeadStage"
                                  @leadClicked="goToLeadDetail"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </px-card>

            <px-card
              v-if="organisation.featuresClientConversations && itemId === 'emails'"
              :actions="false"
              class="draggable"
              key="emails"
            >
              <div slot="headerCustom">
                <div class="header-top">
                  <h5 id="#" class="m-0">{{ $t('app.client-detail.emails') }}</h5>
                </div>
              </div>
              <div class="recruitment-aws-list">
                <awesome-list
                  :actions="{
                    refresh: true,
                    pagination: true,
                    search: false,
                    filter: true,
                    advancedFiltering: true,
                  }"
                  url="api/message"
                  sortField="order"
                  :perRow="1"
                  :useGridSystem="true"
                  :showHeader="false"
                  :apiQueryParams="{
                    emails: clientEmails,
                    clientId: selectedClient.id,
                    sort: { sentOrReceivedOn: 'desc' },
                  }"
                  :columns="[]"
                  mode="remote"
                >
                  <template v-slot:list-item="{ item }">
                    <div class="news-update w-100 py-2 mb-3">
                      <router-link :to="`/app/mailbox/${item.id}`">
                        <h6 class="mb-2">
                          <!-- {{ item.content }} -->

                          <small class="text-info pull-right" style="font-size: 8px; line-height: 0.5">
                            {{ item.parsedContent.date | formatDate }}</small
                          >
                          <small class="text-muted">From:</small>
                          <small class="text-info">{{ item.fromEmail }}</small>
                          <span class="mx-1 text-muted">|</span>
                          <small class="text-muted">to:</small>
                          <small class="text-primary">{{
                            (item.to && item.to.length && item.to.map((m) => `${m.address}`).join('; ')) ||
                            item.parsedContent.toEmails
                          }}</small>
                          <template v-if="item.parsedContent.cc">
                            <span class="mx-1 text-dark">|</span>
                            <small class="text-muted">cc:</small>
                            <small class="badge badge-primary">{{ item.toEmail || item.parsedContent.cc }}</small>
                          </template>
                          <br />
                          <div class="text-truncate">
                            <small class="text-muted">Subject:</small>
                            <small class="text-dark">{{ item.subject || item.parsedContent.subject }}</small>
                          </div>
                        </h6>
                        <div
                          class="m-0 text-left text-truncate text-muted d-none"
                          style="font-size: 12px; line-height: 1"
                        >
                          {{ item.snippet || item.parsedContent.snippet }}
                          <br />
                        </div>
                      </router-link>
                    </div>
                  </template>
                </awesome-list>
              </div>
            </px-card>
          </template>
        </DraggableLayout>

        <div class="row">
          <div class="col-xl-3 box-col-6"></div>
          <div class="col-xl-9 news box-col-9"></div>
        </div>
      </div>
    </div>
    <awesomeForm
      v-if="showCreateRequestForm"
      identity="request"
      :model="requestModel"
      :item="newRequest"
      :mode="'create'"
      :url="`/api/request`"
      @cancel="showCreateRequestForm = false"
      @closeRequested="closeRequestCreateForm()"
      :standalone="false"
      :useRouterMode="false"
    ></awesomeForm>
    <awesomeForm
      v-if="showCreateLeadForm"
      identity="lead"
      :model="leadModel"
      :item="newLead"
      :mode="'create'"
      :url="`/api/lead`"
      @cancel="showCreateLeadForm = false"
      @closeRequested="closeLeadCreateForm()"
      :standalone="false"
      :useRouterMode="false"
    ></awesomeForm>
    <awesomeForm
      v-if="showClientForm"
      identity="client"
      :item="selectedClient"
      :mode="showClientFormMode"
      displayMode="modal"
      :url="`/api/client`"
      @cancel="closeEditForm"
      @closeRequested="closeEditForm()"
      @edit="showClientFormMode = 'edit'"
      @view="showClientFormMode = 'view'"
      @delete="deleteClient(selectedClient)"
      :standalone="false"
      :useRouterMode="false"
    ></awesomeForm>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import RequestsKanban from '../components/RequestsKanban.vue';
import LeadsKanban from '../components/LeadsKanban.vue';
import TasksWidget from '../components/shared/TasksWidget.vue';
import DocumentsWidget from '../components/shared/DocumentsWidget.vue';

import CommentsWidget from '../components/shared/CommentsWidget.vue';
import DraggableLayout from '../components/basic/DraggableLayout.vue';
import manageLayoutMixin from '../mixins/manageLayoutMixin';
import ClientInfoWidget from '../components/shared/ClientInfoWidget.vue';
import MetadataWidget from '../components/shared/MetadataWidget.vue';
import CustomFieldValuesWidget from '../components/shared/CustomFieldValuesWidget.vue';
import ContactsWidget from '../components/shared/ContactsWidget.vue';
import RecruitmentStagesMixin from '../mixins/recruitmentStagesMixin';

export default {
  name: 'ClientDetail',
  components: {
    CommentsWidget,
    DocumentsWidget,
    DraggableLayout,
    RequestsKanban,
    LeadsKanban,
    TasksWidget,
    ClientInfoWidget,
    ContactsWidget,
    MetadataWidget,
    CustomFieldValuesWidget,
  },
  mixins: [manageLayoutMixin, RecruitmentStagesMixin],
  data() {
    return {
      requests: [],
      leads: [],
      showCreateRequestForm: false,
      showCreateLeadForm: false,
      showCreateContactForm: false,
      showClientForm: false,
      newRequest: {},
      newLead: {},
      newContact: {},
      positionList: [],
      openPositionList: [],
      finishedRecruitmentList: [],
      ongoingRecruitmentList: [],
      recruitmentList: [],
      recruitmentStages: [],
      showClientFormMode: 'view',
      isEditingLayout: false,
      formMode: 'create',
      defaultPageLayout: [
        {
          id: 'header',
          group: 'layout',
          classes: 'col-12',
          cols: 12,
          layout: [],
        },
        {
          id: 'left-sidebar',
          group: 'layout',
          classes: 'col-xl-3 box-col-6',
          cols: 3,
          layout: ['client', 'contacts', 'tasks', 'comments', 'documents', 'inbox', 'metadata', 'custom-field-value'],
        },
        {
          id: 'main-body',
          group: 'layout',
          classes: 'col-xl-9 box-col-6',
          displayMode: 'tabs',
          layout: ['positions', 'recruitments', 'leads', 'requests', 'emails'],
        },
        {
          id: 'right-sidebar',
          group: 'layout',
          classes: 'd-none',
          layout: [],
        },
        {
          id: 'footer',
          group: 'layout',
          classes: 'col-12',
          layout: [],
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('client/getClient', this.$route.params.id, {}).catch((err) => {
      this.apiErrorCallback(err);
      this.$router.push('/app/client');
    });
    this.$store.dispatch('client/getClientStats', this.$route.params.id, {});
    this.newRequest.organisationId = this.organisation.id;
    this.newRequest.clientId = (this.selectedClient && this.selectedClient.id) || this.$route.params.id;
    this.newLead.organisationId = this.organisation.id;
    this.newLead.clientId = (this.selectedClient && this.selectedClient.id) || this.$route.params.id;
    this.resetRequestObject();
    this.resetLeadObject();
    this.getRequests();
    this.getLeads();
    this.getRecruitmentStages();
    this.getTopCardsInformation();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.id && to.path.includes('/edit')) {
        vm.$store.dispatch('client/getClient', to.params.id).then((client) => {
          vm.goToClientEdit(client);
        });
      } else if (to.params.id && to.path.includes('/view')) {
        vm.$store.dispatch('client/getClient', to.params.id).then((client) => {
          vm.goToClientView(client);
        });
      }
    });
  },

  async beforeRouteUpdate(to, from, next) {
    if (to.params.id && to.path.includes('/edit')) {
      await this.$store.dispatch('client/getClient', to.params.id).then((client) => {
        this.goToClientEdit(client);
        next();
      });
      return;
    }
    if (to.params.id && to.path.includes('/view')) {
      await this.$store.dispatch('client/getClient', to.params.id).then((client) => {
        this.goToClientView(client);
        next();
      });
      return;
    }
    next();
  },

  watch: {},
  computed: {
    ...mapState('client', ['clients', 'selectedClient', 'selectedClientStats']),
    ...mapState('user', ['user', 'organisation']),
    ...mapGetters('client', ['clientTitle', 'clientAddress']),

    requestModel() {
      let model = this.$store.state.models.find((m) => m.identity === 'request');
      if (model) {
        model = cloneDeep(model);
        model.schema.properties.clientId.field.visible = false;
      }
      return model;
    },
    leadModel() {
      let model = this.$store.state.models.find((m) => m.identity === 'lead');
      if (model) {
        model = cloneDeep(model);
        model.schema.properties.clientId.field.visible = false;
      }
      return model;
    },
    clientEmails() {
      if (!this.selectedClient) {
        return [];
      }
      const emails = [];
      if (this.selectedClient.email) {
        emails.push(this.selectedClient.email);
      }
      if (this.selectedClient.Contacts) {
        this.selectedClient.Contacts.forEach((contact) => {
          if (contact.email) {
            emails.push(contact.email);
          }
        });
      }
      return emails;
    },

    isMobile() {
      return window.outerWidth < 992;
    },
  },
  methods: {
    getImgUrl(path) {
      // eslint-disable-next-line
      return require(`@/assets/images/${path}`);
    },
    createNewPosition(client) {
      this.$router.push(`/app/position/new?item[clientId]=${client.id || client}`).catch(console.warn);
    },
    async getTopCardsInformation() {
      const finalStages = await this.$http
        .get(`/api/recruitment-stage?filters[isFinal]=1&filters[organisationId]=${this.organisation.id}`)
        .then((res) => {
          if (res && res.data && res.data.body && res.data.body.length) {
            return res.data.body.map((stage) => stage.code);
          }
        });
      const p1 = this.$http.get(`/api/position?filters[clientId]=${this.$route.params.id}`).then(({ data }) => data);

      const p2 = this.$http
        .get(`/api/position?filters[clientId]=${this.$route.params.id}&filters[status]=open`)
        .then(({ data }) => data);

      const p3 = this.$http
        .get(`api/recruitment-process?filters[clientId]=${this.$route.params.id}&filters[stage]=hired`)
        .then(({ data }) => data);

      const p4 = this.$http
        .get(`api/recruitment-process?filters[clientId]=${this.$route.params.id}`)
        .then(({ data }) => {
          if (data && data.body && data.body.length) {
            return { totalCount: data.body.filter((process) => !finalStages.includes(process.stage)).length };
          }
        });

      Promise.all([p1, p2, p3, p4])
        .then((result) => {
          if (result[0]) {
            // eslint-disable-next-line prefer-destructuring
            this.positionList = result[0];
          }
          if (result[1]) {
            // eslint-disable-next-line prefer-destructuring
            this.openPositionList = result[1];
          }
          if (result[2]) {
            // eslint-disable-next-line prefer-destructuring
            this.finishedRecruitmentList = result[2];
          }
          if (result[3]) {
            // eslint-disable-next-line prefer-destructuring
            this.ongoingRecruitmentList = result[3];
          }
        })
        .catch((err) => console.warn('[getTopCardsInformation]', err));
    },

    async getRecruitmentStages() {
      this.recruitmentStages = await this.$http
        .get('api/recruitment-stage')
        .then(({ data }) => data.body.map((stage) => ({ key: stage.code, label: stage.label })))
        .catch((err) => console.warn(err));
    },

    resetRequestObject() {
      this.newRequest = {
        clientId: this.$route.params.id,
        organisationId: this.organisation.id,
      };
    },

    resetLeadObject() {
      this.newLead = {
        clientId: this.$route.params.id,
        organisationId: this.organisation.id,
      };
    },

    resetContactObject() {
      this.newContact = {
        clientId: this.$route.params.id,
        organisationId: this.organisation.id,
      };
    },

    getRequests() {
      return this.$store
        .dispatch('request/getRequests', {
          save: false,
          params: {
            filters: {
              clientId: this.$route.params.id,
            },
            searchMode: 'exact',
          },
        })
        .then(({ body }) => {
          this.requests = body;
        });
    },

    getLeads() {
      return this.$store
        .dispatch('lead/getItems', {
          save: false,
          params: {
            filters: {
              clientId: this.$route.params.id,
            },
            searchMode: 'exact',
          },
        })
        .then(({ body }) => {
          this.leads = body;
        });
    },

    goToRequestDetail(data) {
      this.$store.commit('request/selectedRequest', data);
      this.$router.push(`/app/request/${data.id}`);
    },

    async updateRequestStatus({ element, newIndex }, list) {
      element.status = list.id;
      await this.$store.dispatch('request/updateRequest', {
        id: element.id,
        status: list.id,
        kanbanOrder: newIndex,
      });
      this.getRequests();
    },

    goToLeadDetail(data) {
      this.$store.commit('lead/selectedItem', data);
      this.$router.push(`/app/lead/${data.id}`);
    },

    async updateLeadStage({ element, newIndex }, list) {
      element.stage = list.id;
      await this.$store.dispatch('lead/updateItem', {
        id: element.id,
        stage: list.id,
        name: element.name,
        kanbanOrder: newIndex,
      });
      this.getRequests();
    },

    async closeRequestCreateForm() {
      this.showCreateRequestForm = false;
      this.getRequests();
    },
    async closeLeadCreateForm() {
      this.showCreateLeadForm = false;
      this.getLeads();
    },

    async deleteClient(client) {
      const confirmation = await this.$awConfirm(this.$t('common.messages.are_you_sure'));
      if (confirmation) {
        try {
          await this.$store.dispatch('client/deleteClient', client.id || client);
          this.$router.push('/app/client');
          this.isCreating = false;
        } catch (err) {
          this.apiErrorCallback(err);
        }
      }
    },

    openNewRequestForm() {
      this.newRequest.organisationId = this.organisation.id;
      this.newRequest.clientId = (this.selectedClient && this.selectedClient.id) || this.$route.params.id;
      // this.newRequest.source = 'inapp';
      this.newRequest.source = '';
      this.showCreateRequestForm = true;
    },

    openNewLeadForm() {
      this.newLead.organisationId = this.organisation.id;
      this.newLead.clientId = (this.selectedClient && this.selectedClient.id) || this.$route.params.id;
      // this.newLead.source = 'inapp';
      this.newLead.source = '';
      this.showCreateLeadForm = true;
    },

    closeEditForm() {
      this.showClientForm = false;
      if (this.showClientFormMode === 'edit') {
        this.$store.dispatch('client/getClient', this.$route.params.id, {});
      }
      this.$router.push({ path: `/app/client/${this.$route.params.id}` });
    },

    goToClientView(client) {
      this.showClientFormMode = 'view';
      this.showClientForm = true;
      this.$store.commit('client/selectedClient', client);
      this.$forceUpdate();
    },

    goToClientEdit(client) {
      this.showClientFormMode = 'edit';
      this.showClientForm = true;
      this.$store.commit('client/selectedClient', client);
    },

    onContactsChange() {
      this.$store.dispatch('client/getClient', this.$route.params.id, {});
    },
  },
};
</script>

<style lang="scss">
.page-ClientDetail {
  .card.aw-kanban-card {
    .card-header {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  /*
.request-details {
  // overflow: auto;
}
*/
  div.client-stats {
    padding-left: 20px;
    font-style: italic;
  }

  div.recruitment-aws-list {
    .float-left.col-6.pl-0 {
      max-width: 100% !important;
      padding-left: 15px !important;
    }
    div.card.aw-segment-table-wrapper {
      margin: 5px 0 !important;
    }
  }

  div.position-aws-list {
    .float-left.col-6.pl-0 {
      max-width: 100% !important;
      padding-left: 15px !important;
    }
    div.card.aw-segment-table-wrapper {
      margin: 5px 0 !important;
    }
  }

  .news-update {
    padding: 30px 15px;
    border-bottom: 1px solid #ecf3fa;
    margin-bottom: 5px;
    &:first-of-type() {
      border-top: 1px solid #ecf3fa;
    }
  }
  a:hover {
    cursor: pointer;
  }
}
</style>
