<template>
  <!--
  <div></div>
-->
  <div class="wrapper wrapper-full-page">
    <div class="full-page section-image" data-color="black" data-image="/static/img/full-screen-image-2.jpg">
      <div class="content">
        <div class="container">
          <div class="col-md-8 col-sm-10 ml-auto mr-auto">
            <form-wizard
              :color="config.display && config.display.primaryColor"
              title="Onboarding"
              subtitle="Configurons maintenant votre compte."
              ref="wizard"
              nextButtonText="Suivant"
              backButtonText="Précédent"
              finishButtonText="Terminer"
              @on-change="onTabChange"
              @on-complete="() => $router.push('/app/dashboard')"
            >
              <tab-content title="Votre organisation" :beforeChange="submitFormStep1">
                <ValidationObserver ref="formStep1">
                  <form @submit.prevent="">
                    <div class="form-group">
                      <p>
                        Nous allons maintenant configurer la boite mail que vos clients utilisent pour vous contacter.
                        Cimple a besoin de cette configuration pour détecter les demandes qui arrivent par mail.
                      </p>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('common.labels.company') }}*</label>
                      <validation-provider rules="required" v-slot="{ errors }">
                        <input
                          v-model="organisation.name"
                          name=""
                          type="text"
                          class="form-control"
                          :placeholder="$t('common.placeholders.yourCompanyName')"
                          required
                          autocomplete="off"
                        />
                        <span class="text-warning">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <!-- <div class="form-group">
                      <label>{{ $t('common.labels.companyType') }}</label>
                      <select
                        v-model="organisation.type"
                        name="firstName"
                        type="text"
                        class="form-control"
                        :placeholder="$t('common.placeholders.companyType')"
                        required
                        autocomplete="off"
                      >
                        <option value="accounting">Comptabilité</option>
                        <option value="hr">Ressource hummaines</option>
                        <option value="juridique">juridique</option>
                      </select>
                    </div> -->
                  </form>
                </ValidationObserver>
              </tab-content>
              <tab-content title="Vos boites mail partagées">
                <ValidationObserver ref="formStep2">
                  <form @submit.prevent="">
                    <p>
                      Nous allons maintenant configurer la boite mail que vos clients utilisent pour vous contacter.
                      Cimple a besoin de cette configuration pour détecter les demandes qui arrivent par mail.
                    </p>
                    <hr />
                    <p>Merci de cliquer sur le bouton correspondant au type de boite mail que vous utilisez</p>

                    <div class="form-group">
                      <button
                        @click="requestGmailEmailAccess()"
                        name=""
                        type="button"
                        class="btn btn-outline-danger btn-block"
                        autocomplete="off"
                      >
                        <i class="fa fa-google"></i>
                        Gmail
                      </button>
                    </div>

                    <div class="form-group d-none">
                      <button
                        @click="mailbox.type = 'outlook'"
                        name=""
                        type="button"
                        class="btn btn-outline-info btn-block"
                        autocomplete="off"
                      >
                        <i class="fa fa-microsoft"></i>
                        Outlook
                      </button>
                    </div>

                    <div class="form-group">
                      <button
                        @click="mailbox.type = 'other'"
                        name=""
                        type="button"
                        class="btn btn-outline-secondary btn-block"
                        autocomplete="off"
                      >
                        Autres
                      </button>
                    </div>
                    <hr />
                    <template v-if="mailboxAddresses && mailboxAddresses.length">
                      <h3 class="text-primary">Vos addresses déjà configurées.</h3>
                      <div class="list-group">
                        <div
                          class="list-group-item pointer"
                          v-for="address in mailboxAddresses"
                          :key="address.name"
                          @click="selectEmail(address)"
                        >
                          {{ address.email }} <i class="fa fa-edit pull-right"></i>
                        </div>
                      </div>
                    </template>
                  </form>
                </ValidationObserver>
              </tab-content>
              <tab-content title="Les règles de mail" :beforeChange="submitFormStep3">
                <h4>Créons maintenant quelques règles pour rendre {{ mailbox.email }} plus efficace.</h4>
                <h5></h5>
                <ValidationObserver ref="formStep3">
                  <form @submit.prevent="">
                    <hr />
                    <div class="card">
                      <div class="card-header"><h5>A quoi sert cette boite mail ?</h5></div>
                      <validation-provider rules="required" v-slot="{ errors }">
                        <div class="card-body">
                          <select
                            v-model="mailbox.destination"
                            class="form-control"
                            type="radio"
                            name="emailToTreat"
                            id="emailToTreat1"
                          >
                            <option value="client">Aux demandes clients</option>
                            <option value="candidate">Aux échanges rh</option>
                          </select>
                        </div>

                        <span class="text-warning">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="card">
                      <div class="card-header"><h5>Quels messages devons nous traiter ?</h5></div>
                      <validation-provider rules="required" v-slot="{ errors }">
                        <div class="card-body">
                          <div class="form-check">
                            <input
                              v-model="mailbox.fetchTarget"
                              class="form-check-input"
                              type="radio"
                              value="all"
                              name="emailToTreat"
                              id="emailToTreat1"
                            />
                            <label class="form-check-label" for="emailToTreat1"> Traiter tous les emails reçus. </label>
                          </div>
                          <div class="form-check">
                            <input
                              v-model="mailbox.fetchTarget"
                              class="form-check-input"
                              type="radio"
                              value="clients"
                              name="emailToTreat"
                              id="emailToTreat2"
                            />
                            <label class="form-check-label" for="emailToTreat2">
                              Traiter seulement les emails reçus des clients connus.
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              v-model="mailbox.fetchTarget"
                              class="form-check-input"
                              type="radio"
                              value="disabled"
                              name="emailToTreat"
                              id="emailToTreat3"
                            />
                            <label class="form-check-label" for="emailToTreat3">
                              Ne pas traiter les emails reçus.
                            </label>
                          </div>
                          <span class="text-warning">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <transition name="fade">
                      <div class="card" v-if="mailbox.fetchTarget && mailbox.fetchTarget != 'disabled'">
                        <div class="card-header">
                          <h5>Comment allons nous traiter les messages ?</h5>
                        </div>
                        <validation-provider rules="required" v-slot="{ errors }">
                          <div class="card-body">
                            <span class="text-warning">{{ errors[0] }}</span>
                            <div class="form-check">
                              <input
                                v-model="mailbox.fetchConditions"
                                class="form-check-input"
                                type="radio"
                                value="all"
                                id="fetchConditions1"
                              />
                              <label class="form-check-label" for="fetchConditions1">
                                Créer un nouveau sujet pour tous les emails reçus
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                v-model="mailbox.fetchConditions"
                                class="form-check-input"
                                type="radio"
                                value="keywords"
                                id="fetchConditions2"
                              />
                              <label class="form-check-label" for="fetchConditions2">
                                Créer un nouveau sujet seulement si le mail contient certains mots clés.
                              </label>
                            </div>

                            <transition name="fade">
                              <div
                                class="form-group"
                                v-if="mailbox.fetchConditions && mailbox.fetchConditions == 'keywords'"
                              >
                                <label class="form-check-label" for="fetchKeywords"> Saisissez vos mots clés </label>
                                <v-select
                                  v-model="mailbox.fetchKeywords"
                                  :taggable="true"
                                  :multiple="true"
                                  :select-on-tab="true"
                                  :disabled="false"
                                ></v-select>
                              </div>
                            </transition>
                          </div>
                        </validation-provider>
                      </div>
                    </transition>
                  </form>
                </ValidationObserver>
              </tab-content>
              <tab-content title="Finalisation">
                <h3>Et voila c'est terminé. Vouz pouvez commencer à gérer vos demandes</h3>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
      <button @click="logout()" class="btn btn-danger" style="position: absolute; bottom: 10px; right: 10px">
        {{ $t('common.labels.logout') }}
      </button>
      <div class="full-page-background" :style="{ backgroundImage: `url('${config.display.backgroundImage}')` }" />
    </div>
  </div>
</template>

<script>
import { apiErrorsMixin } from 'vue-aw-components';
import { FormWizard, TabContent } from 'vue-form-wizard';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import vSelect from 'vue-select';
import { required } from 'vee-validate/dist/rules';

import config from '@/config';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

extend('required', {
  ...required,
  message: 'Ce champ est obligatoire',
});

export default {
  name: 'Onboarding',
  components: {
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [apiErrorsMixin],
  computed: {
    user() {
      return this.$store.state.user && this.$store.state.user.user;
    },
    storeOrganisation() {
      if (this.$store.state.user && this.$store.state.user.organisation) {
        return this.$store.state.user.organisation;
      }
      if (this.user && this.user.MainOrganisation) {
        return this.user.MainOrganisation;
      }
      if (this.$store.state.user.Organisations && this.$store.state.user.Organisations[0]) {
        return this.$store.state.user.Organisations[0];
      }
      return null;
    },
  },
  data() {
    return {
      organisation: {
        name: null,
        type: null,
      },
      mailbox: {
        type: null,
        fetchTarget: null, // all | client
        fetchConditions: null,
      },
      organisationFetchSettings: {},
      pageLoaded: false,
      config,
      startIndex: 0,
      firstStepCompleted: false,
      mailboxAddresses: [],
      selectedEmailIndex: null,
    };
  },
  created() {},
  watch: {
    storeOrganisation(newItem) {
      if (newItem) {
        this.organisation = newItem;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 200);
    this.getMailboxes();
    if (this.storeOrganisation && this.storeOrganisation.id) {
      this.firstStepCompleted = true;
      setTimeout(() => {
        this.$refs.wizard.nextTab();
      }, 150);
    }

    // prettier-ignore
    if (
      this.$route.query.code
      && this.$route.query.scope
      && this.$route.query.scope.includes('gmail')
    ) {
      this.sendGmailEmailAccess().then(orgEmail => {
        this.secondStepCompleted = true;
        this.$refs.wizard.nextTab();
        this.$awNotify(`email ajouté ! ${orgEmail.email}`);
      });
    }
  },
  methods: {
    beforeTabSwitch(data) {
      return true;
    },
    async submitFormStep1() {
      // this.$notifications.clear();
      if (this.firstStepCompleted) {
        return true;
      }
      try {
        const success = await this.$refs.formStep1.validate();
        if (!success) {
          return false;
        }
        const organisationId = this.storeOrganisation && this.storeOrganisation.id;
        const request = organisationId
          ? this.$http.put(`/api/organisation/${organisationId}`, this.organisation)
          : this.$http.post('/api/organisation', this.organisation);

        request.then((res) => {
          const { body } = res.data;

          if (body && body.id) {
            this.$store.commit('user/organisation', body);
            return this.$store.dispatch('user/refreshUser');
          }
        });
      } catch (err) {
        this.apiErrorCallback(err);
      }
      return true;
    },
    async submitFormStep3() {
      try {
        const success = await this.$refs.formStep3.validate();
        if (!success) {
          return false;
        }
        const res = await this.saveEmailConfig();
        const { body } = res.data;
        if (body && body.id) {
          this.$store.commit('user/organisation', body);
          this.$store.dispatch('user/refreshUser');
        }
      } catch (err) {
        this.apiErrorCallback(err);
        return false;
      }
      return true;
    },

    requestGmailEmailAccess() {
      const organisationId = this.storeOrganisation && this.storeOrganisation.id;
      if (!organisationId) {
        this.$awNotify('Mssing organisationId');
        return;
      }
      this.$http.get('/api/auth/gmail').then((res) => {
        window.location.href = res.data.body;
      });
      this.mailbox.type = 'gmail';
    },

    sendGmailEmailAccess() {
      this.mailbox.code = this.$route.query.code;
      this.mailbox.type = 'gmail';
      return this.$http.post('/api/mailbox', this.mailbox).then((email) => {
        this.mailbox = email;
      });
    },

    saveEmailConfig() {
      return this.$http.put(
        `/api/mailbox/${this.mailbox.id}`,
        this.mailbox
        // {
        //   destination: this.mailbox.destination,
        //   fetchTarget: this.mailbox.fetchTarget,
        //   fetchConditions: this.mailbox.fetchConditions,
        //   fetchKeywords: this.mailbox.fetchKeywords,
        // }
      );
    },

    getMailboxes() {
      if (!(this.storeOrganisation && this.storeOrganisation.id)) {
        return;
      }
      this.mailbox.code = this.$route.query.code;
      return this.$http.get('/api/mailbox').then(({ data }) => {
        this.mailboxAddresses = data.body;
      });
    },

    prepareStep2() {
      this.getMailboxes();
    },

    async prepareStep3() {
      const org = await this.$store.dispatch('user/getOrganisation');
      this.organisationFetchSettings = { ...org };

      return org;
    },

    onTabChange(prev, next) {
      switch (next) {
      case 1:
        this.prepareStep2();
        break;
      case 2:
        this.prepareStep3();
        break;

      default:
        break;
      }
    },

    logout() {
      this.$store.dispatch('user/logout');
      this.$router.push('/login');
    },

    selectEmail(email) {
      this.mailbox = email;
      this.$refs.wizard.nextTab();
    },
  },
};
</script>
