<template>
  <div class="rule-action-display">
    <label class="badge badge-primary">{{ $t('fields.ruleAction.type.' + item.type) }}</label>
    <span v-if="item.leadStage && item.type === 'createLead'" for="">
      with the status <b class="badge badge-info">{{ item.leadStage }}, </b>
    </span>
    <span v-if="item.requestStatus && item.type === 'createRequest'" for="">
      with the status <b class="badge badge-info">{{ item.requestStatus }}, </b>
    </span>
    <span v-if="item.forwardTo" for="">
      to <b class="badge badge-info"> {{ item.forwardTo }}, </b>
    </span>
    <span v-if="item.targetUserId" for="">
      And Affect it to
      <MemberSelectorDropdown
        class="badge badge-primary p-0 ml-1 mt-1"
        :value="item.targetUserId"
      ></MemberSelectorDropdown>
    </span>
  </div>
</template>

<script>
export default {
  name: 'RuleActionDisplay',
  components: {},
  model: {
    prop: 'item',
    event: 'input',
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  watch: {
    value() {},
  },
  methods: {
    rate(star) {},
  },
};
</script>

<style scoped lang="scss">
</style>
