<template>
  <table v-if="items && items.length > 0" class="table table-bordernone">
    <tbody class="f-w-500">
      <tr class="pointer parent" v-for="client in items" :key="client.id">
        <td @click="goToClientDetail(client)">
          <div class="media">
            <UserAvatar
              :user="client"
              :styles="{
                fontSize: '1rem',
                width: '40px',
                height: '40px',
                lineHeight: 1.8,
                fontWeight: '300',
              }"
              size="40px"
            ></UserAvatar>
            <div class="media-body">
              <span>{{ client.name }}</span>
              <p class="font-roboto font-weight-light">{{ client.email }}</p>
            </div>
          </div>
        </td>
        <td @click="goToClientDetail(client)">
          <span class="font-weight-light">{{ client.company }}</span>
        </td>
        <td @click="goToClientDetail(client)">
          <span class="font-weight-light">{{ client.phonenumber }}</span>
        </td>
        <td @click="goToClientDetail(client)">
          <div class="f-w-300" v-if="organisation.featuresHumanResources">
            <b class="text-primary">{{ client.totalOpenPositions }}</b>
            {{ $t('app.clients.open-positions') }}
          </div>
          <span class="f-w-300" v-if="organisation.featuresClientConversations"
            ><b class="text-primary">{{ client.totalOpenRequests }}</b>
            {{ $t('app.clients.open-requests') }}</span
          >
        </td>
        <td class="d-flex">
          <button
            class="btn child-edit btn-transparent btn-simple mr-1 p-0"
            @click.prevent="goToClientEdit(client)"
          >
            <feather type="edit-3" class="text-primary"></feather>
          </button>
          <button
            v-if="userHasRole('ADMIN') || client.createdBy == user.id"
            @click.prevent="deleteClient(client)"
            class="btn child-delete btn-transparent btn-simple p-0"
          >
            <feather type="trash-2" class="text-secondary"></feather>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemsPerRow: {
      type: [Number, String],
      default: 1,
    },
  },
  computed: {
    ...mapState('client', ['clients']),
    ...mapState('user', ['user', 'organisation']),
  },
  data() {
    return {
      selectedClient: {},
      isCreating: false,
      mode: 'create',
      searchClientsDebounced: () => {},
    };
  },
  methods: {
    goToClientDetail(client) {
      this.$store.commit('client/selectedClient', client);
      this.$router.push(`/app/client/${client.id || client}`);
    },

    goToClientEdit(client) {
      this.$router.push(`/app/client/${client.id || client}/edit`);
      this.$forceUpdate();
    },

    editClient(client) {
      this.mode = 'edit';
      this.isCreating = false;
      this.selectedClient = client;
    },

    showNewClientForm() {
      this.mode = 'create';
      this.isCreating = true;
    },

    saveClient(client) {
      this.$store.dispatch('client/updateClient', client);
      this.isCreating = false;
      this.$router.push('/app/client');
    },

    async deleteClient(client) {
      const confirmed = await this.$awConfirm('Êtes-vous sur ?');
      if (confirmed) {
        this.$store
          .dispatch('client/deleteClient', client.id)
          .then(() => this.$store.dispatch('client/getClients', { save: true }));
        this.isCreating = false;
      }
    },

    hideNewClientForm() {
      this.isCreating = false;
      this.selectedClient = {};
      this.$router.push('/app/client');
    },

    closeNewClientForm() {
      this.$store.dispatch('client/getClients', { save: true });
      this.isCreating = false;
      this.$router.push('/app/client');
    },

    searchClients($event) {
      this.$store.dispatch('client/getClients', { save: true, params: { search: $event.target.value } });
    },

  }
};
</script>

<style lang="scss" scoped>
.aw-form .form-element {
  margin-bottom: 0px;
}

.child-edit {
  visibility: hidden;
}
.child-delete {
  visibility: hidden;
}

.parent {
  &:hover {
    .child-edit,
    .child-delete {
      visibility: visible;
    }
  }
}
.aw-crud-client .card-header.aw-list-header {
  box-shadow: none !important;
}
</style>
<style lang="scss">
.aw-crud-client .card-header.aw-list-header {

  &:after {
    content: none;
    display: none;
  }
}
</style>