/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import FlashPlugin from './mixins/rerenderDebugPlugin';
import 'socket.io-client/dist/socket.io';
import Socket from './services/Socket';

import CompanyDisplay from './components/company/CompanyDisplay.vue';
import CustomFieldValuesWidget from './components/shared/CustomFieldValuesWidget.vue';
import EditableText from './components/basic/EditableText.vue';
import FieldTinyMce from './components/fields/FieldTinyMce.vue';
import FieldEmojiPicker from './components/fields/FieldEmojiPicker.vue';
import FieldBooleanExpression from './components/fields/FieldBooleanExpression.vue';

import MappingField from './components/webhook/MappingField.vue';
import MemberSelectorDropdown from './components/user/MemberSelectorDropdown.vue';
import NoResult from './components/basic/NoResult.vue';
import Rating from './components/basic/Rating.vue';
import RuleActionDisplay from './components/basic/RuleActionDisplay.vue';
import Temperature from './components/basic/Temperature.vue';
import UserAvatar from './components/user/UserAvatar.vue';

import apiErrorsMixin from './mixins/apiErrorsMixin';
import rolesMixin from './mixins/rolesMixin';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component('CompanyDisplay', CompanyDisplay);
    Vue.component('CustomFieldValuesWidget', CustomFieldValuesWidget);
    Vue.component('EditableText', EditableText);
    Vue.component('fieldTinyMce', FieldTinyMce);
    Vue.component('FieldEmojiPicker', FieldEmojiPicker);
    Vue.component('fieldBooleanExpression', FieldBooleanExpression);
    Vue.component('MappingField', MappingField);
    Vue.component('MemberSelectorDropdown', MemberSelectorDropdown);
    Vue.component('NoResult', NoResult);
    Vue.component('Rating', Rating);
    Vue.component('Rating', RuleActionDisplay);
    Vue.component('Temperature', Temperature);
    Vue.component('UserAvatar', UserAvatar);
    Vue.filter('formatDate', (date, format = 'DD.MM.YYYY à HH:mm') => {
      if (!date) return '';
      return dayjs(date).format(format);
    });
    Vue.mixin(apiErrorsMixin);
    Vue.mixin(rolesMixin);
    Socket.install(Vue);

    if (process.env.NODE_ENV !== 'production') {
      Vue.use(FlashPlugin, {
        enabled: false,
        logUpdatedComponents: true,
      });
    }
  },
};

export default GlobalComponents;
