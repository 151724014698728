<template>
  <b-modal
    title=""
    size="xl"
    :visible="visible"
    @cancel="handleCloseModal"
    @closeRequested="handleCloseModal"
    @hidden="handleCloseModal"
    @ok="handleOk"
  >
    <template #modal-title>
      <h3 class="mt-3 mb-0">{{ 'Import ' + model }} ({{ csvValues.length }})</h3>
      <hr class="text-primary mt-1 bg-info" />
    </template>
    <div>
      <div v-if="errors.length">
        <div v-for="(topLevel, idx) in errors" class="text-danger" :key="idx">
          <template v-if="topLevel.errors && topLevel.errors.length">
            {{ topLevel.message }}
            <div v-for="(error, idx2) in topLevel.errors" :key="idx2">- {{ error.message || error }}</div>
          </template>
          <template v-else>
            {{ topLevel.message || topLevel }}
          </template>
        </div>
      </div>
      <div v-if="!file">
        <vue-dropzone
          ref="myDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-success="onDropzoneSuccess"
          @vdropzone-file-added="onDropzoneSuccess"
        ></vue-dropzone>
      </div>
      <div v-if="file" class="mt-3">
        <div v-if="isUploading" class="progress">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            :style="{ width: (uploadCount / csvValues.length) * 100 + '%' }"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <template v-if="!isUploading && !isCompleted">
          <div id="collision-detector" class="mt-3">
            <h4>Options</h4>
            <div>
              <div class="form-group">
                <label for="collision-detection">Stop import on errors</label>
                <input type="checkbox" v-model="stopOnError" :value="true" />
              </div>
              <div class="form-group">
                <label for="collision-detection">Detect duplicates</label>
                <input type="checkbox" v-model="detectDuplicates" :value="true" />
              </div>
              <template v-if="detectDuplicates">
                <div class="form-group">
                  <label for="collision-detection">Fields for detection</label>
                  <v-select
                    v-if="detectDuplicates"
                    v-model="duplicatesDetectionFields"
                    class="form-control"
                    id="collision-detection-select"
                    :options="databaseColumnsArray"
                    :reduce="(option) => option.value"
                    multiple
                    taggable
                  />
                </div>
                <div class="form-group">
                  <label for="collision-detection">Update duplicates</label>
                  <input type="checkbox" v-model="updateOnDuplicates" :value="true" />
                </div>
              </template>
            </div>
          </div>

          <h4>Mapping</h4>
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeTab === 'source' }"
                id="source-tab"
                data-toggle="tab"
                @click="activeTab = 'source'"
                role="tab"
                aria-controls="source"
                aria-selected="true"
                >By Source (CSV)</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeTab === 'destination' }"
                id="destination-tab"
                data-toggle="tab"
                @click="activeTab = 'destination'"
                role="tab"
                aria-controls="destination"
                aria-selected="false"
                >By Destination (Database)</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div
              class="tab-pane"
              id="source"
              role="tabpanel"
              aria-labelledby="source-tab"
              :class="{ active: activeTab === 'source' }"
            >
              <table class="table table-bordered mt-3 table-responsive">
                <thead>
                  <tr>
                    <th v-for="(column, index) in columns" :key="index">{{ column }}</th>
                  </tr>
                  <tr>
                    <th v-for="(column, index) in columns" :key="index">
                      <select v-model="mapping[index]" class="form-control">
                        <option :value="null">-- Select Database Column --</option>
                        <option :value="false">-- Do not Import --</option>
                        <option v-for="(dbColumnDef, dbColumn) in databaseColumns" :value="dbColumn" :key="dbColumn">
                          {{ dbColumn }}
                        </option>
                      </select>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(rows, index) in topCsvValues" :key="index">
                    <td v-for="(field, index2) in rows" :key="index2">{{ field }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane"
              id="destination"
              :class="{ active: activeTab === 'destination' }"
              role="tabpanel"
              aria-labelledby="destination-tab"
            >
              <table class="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th>Cimple Column</th>
                    <th>CSV Column</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dbColumnDef, dbColumn) in databaseColumns" :key="dbColumn">
                    <td>{{ dbColumn }}</td>
                    <td>
                      <vSelect
                        v-model="reverseMapping[dbColumn]"
                        class="form-control"
                        placeholder="-- Select CSV Column(s) --"
                        :options="columns"
                        multiple
                        taggable
                        searchable
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <button size="sm" class="text-danger btn-link btn float-left" @click="handleCloseModal()">Cancel</button>
        <div class="float-right">
          <button variant="primary" size="sm" class="btn btn-primary ml-1" @click="handleOk">Import</button>
          <button v-if="isCompleted" variant="primary" size="sm" class="btn btn-primary ml-1" @click="restartImport">
            Restart
          </button>
          <button variant="primary" size="sm" class="btn btn-dark ml-1" @click="handleCloseModal">Close</button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import VueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Papa from 'papaparse';
import { BModal } from 'bootstrap-vue';
import vSelect from 'vue-select';
import pMap from 'p-map';
import _ from 'lodash';

export default {
  components: {
    VueDropzone,
    BModal,
    vSelect,
  },
  props: {
    model: {
      type: String,
      default: '',
    },
    storePath: { type: String, default: 'state.models' },
    visible: { type: Boolean, default: false },
  },
  data () {
    return {
      dropzoneOptions: {
        url: '/dummy-url',
        autoProcessQueue: false,
        maxFiles: 1,
        acceptedFiles: '.csv',
      },
      file: null,
      columns: [],
      csvValues: [],
      mappedCsvValues: [],
      mapping: {},
      reverseMapping: {},
      activeTab: 'source',
      errors: [],
      detectDuplicates: false,
      duplicatesDetectionFields: null,
      uploadCount: 0,
      isUploading: false,
      isCompleted: false,
      updateOnDuplicates: false,
      destinationArrayPagination: 0,
      stopOnError: true,
    };
  },
  computed: {
    modelComputed () {
      const models = _.get(this.$store, this.storePath);
      if (!models) {
        return;
      }
      const model = models.find((m) => m.identity === this.model);
      return { ...model };
    },
    databaseColumns () {
      if (!this.modelComputed?.schema?.properties) {
        return {};
      }
      return Object.entries(this.modelComputed.schema.properties)
        .filter(
          ([key, value]) =>
            key !== 'organisationId' && value.type !== 'array' && (value.type !== 'object' || key === 'customFields')
        )
        .reduce((obj, [key, value]) => {
          if (key === 'customFields') {
            Object.keys(value.properties).forEach((customFieldKey) => {
              obj[`customFields.${customFieldKey}`] = value.properties[customFieldKey];
            });
          } else {
            obj[key] = value;
          }
          return obj;
        }, {});
    },
    databaseColumnsPaginated () {
      if (this.destinationArrayPagination === undefined) {
        return this.databaseColumnsArray;
      }
      return this.databaseColumnsArray.slice(
        this.destinationArrayPagination * 10,
        this.destinationArrayPagination * 10 + 10
      );
    },
    databaseColumnsArray () {
      if (!this.databaseColumns) {
        return [];
      }
      return Object.entries(this.databaseColumns).map(([key, value]) => ({
        label: value.title || value?.column?.title || key,
        value: key,
      }));
    },

    topCsvValues () {
      return this.csvValues.slice(0, 10);
    },
  },
  nounted () {},
  methods: {
    onDropzoneSuccess (file) {
      this.file = file;
      this.parseCsv(file);
    },
    parseCsv (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          this.columns = results.meta.fields;
          this.csvValues = results.data;
          this.mapping = {
            ...this.columns.reduce((obj, column, index) => {
              if (this.databaseColumns[column]) {
                obj[index] = column;
              }
              return obj;
            }, {}),
          };
          this.reverseMapping = {
            ...this.databaseColumnsArray.reduce((obj, col, key) => {
              obj[col.value] = [];
              if (this.columns.includes(col.value)) {
                obj[col.value].push(col.value);
              }
              return obj;
            }, {}),
          };
        },
      });
    },
    importCsv () {
      // Implement your import logic here, e.g., send the data to your API
      // console.log('Mapping:', this.mapping);
      // console.log('File:', this.file);
    },

    handleCloseModal () {
      this.$emit('closeModal');
      this.file = null;
      this.errors = [];
    },

    unflatten (data) {
      const result = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const i in data) {
        const keys = i.split('.');
        keys.reduce(
          // eslint-disable-next-line no-return-assign
          (r, e, j) =>
            // eslint-disable-next-line no-return-assign, no-nested-ternary
            r[e] || (r[e] = Number.isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? data[i] : {}) : []),
          result
        );
      }
      return result;
    },

    async handleOk (bvModalEvent) {
      try {
        this.errors = [];
        bvModalEvent.preventDefault();
        // if the mapping if by source then convert the csvValues to the database columns
        if (this.activeTab === 'source') {
          this.mappedCsvValues = this.csvValues.map((csvValue) => {
            const newCsvValue = {};
            Object.keys(csvValue).forEach((key) => {
              const index = this.columns.indexOf(key);
              const dbColumn = this.mapping[index];
              if (dbColumn) {
                newCsvValue[dbColumn] = csvValue[key];
              }
            });

            return this.unflatten(newCsvValue);
          });
        } else {
          // if the mapping is by destination then convert the csvValues to the database columns
          this.mappedCsvValues = this.csvValues.map((csvValue) => {
            const newCsvValue = {};
            Object.keys(this.databaseColumns).forEach((key) => {
              const index = this.reverseMapping[key];
              if (!index) {
                return;
              }
              // transform columns that from the csv, and keep the rest
              const dbColumn = Array.isArray(index)
                ? index.map((field) => (this.columns.includes(field) ? csvValue[field] ?? '' : field)).join(' ')
                : this.columns[index];
              if (dbColumn) {
                newCsvValue[key] = dbColumn;
              }
            });
            return this.unflatten(newCsvValue);
          });
        }
        this.uploadCount = 0;
        this.isUploading = true;
        const success = await pMap(
          this.mappedCsvValues,
          async (value) => {
            this.uploadCount += 1;
            if (this.detectDuplicates && Array.isArray(this.duplicatesDetectionFields)) {
              let filters = '';
              this.duplicatesDetectionFields.forEach((field) => {
                if (value[field]) {
                  filters += `filters[${field}]=${value[field]}&`;
                }
              });
              // search of a duplicate value in the api
              const duplicate = await this.$http.get(`${this.modelComputed.apiUrl}?${filters}`, {
                params: {},
              });
              if (duplicate.data.length) {
                if (this.updateOnDuplicates) {
                  if (duplicate.data.length > 1) {
                    this.errors.push(
                      `Multiple duplicates found for ${this.duplicatesDetectionFields.join(', ')} => ${duplicate.data
                        .map((d) => d.id)
                        .join(', ')}`
                    );
                    return;
                  }
                  const { id } = duplicate.data[0];
                  // @todo update only the fields that a required
                  // @todo check if the user has permission to update the record
                  await this.$http.put(`${this.modelComputed.apiUrl}/${id}`, {
                    ...duplicate.data[0],
                    ...value,
                  });
                  return;
                }
                this.errors.push(
                  `Duplicate found for ${this.duplicatesDetectionFields.join(', ')} => ${duplicate.data[0].id}}`
                );
                return;
              }
            }
            return this.$http.post(this.modelComputed.apiUrl, value);
          },
          {
            concurrency: 5,
            stopOnError: this.stopOnError,
          }
        );
        if (success) {
          if (this.$notify) {
            this.$notify({
              type: 'success',
              title: `${this.mappedCsvValues.length} values Imported successfully`,
            });
          } else if (this.$awNotify) {
            this.$awNotify({
              type: 'success',
              title: `${this.mappedCsvValues.length} values Imported successfully`,
            });
          }
          this.$emit('closeModal');
          this.isUploading = false;
          this.isCompleted = true;
        }
      } catch (e) {
        this.isUploading = false;
        this.isCompleted = true;
        if (e.response && e.response.data) {
          console.warn('Error warn:', e.response.data);
          this.errors.push(e.response.data);
          return;
        }
        this.errors.push(e.message);
      }
    },

    restartImport () {
      this.isCompleted = false;
      this.isUploading = false;
      this.uploadCount = 0;
      this.file = null;
      this.errors = [];
    },
  },
};
</script>

<style>
.form-control .vs__dropdown-toggle {
  border: none;
  padding: 0;
  height: 100%;
}
</style>
