<template>
  <div>
    <table class="table table-bordered mt-3">
      <thead>
        <tr>
          <th>Cimple Column</th>
          <th>Incoming Column</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(dbColumnDef, dbColumn) in databaseColumns" :key="dbColumn">
          <td>{{ dbColumn }}</td>
          <td>
            <vSelect
              required
              v-model="reverseMapping[dbColumn]"
              placeholder="-- Fill Incoming Column(s) --"
              :options="columns"
              multiple
              taggable
              searchable
              @input="updateFieldValue"
              @change="updateFieldValue"
              @create="updateFieldValue"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import _ from 'lodash';


export default {
  components: {
    vSelect,
  },
  props: {
    model: {
      type: Object,
    },
    storePath: { type: String, default: 'state.models' },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      columns: [],
      mapping: {},
      reverseMapping: {},
      errors: [],
    };
  },
  computed: {
    modelComputed() {
      const models = _.get(this.$store, this.storePath);
      if (!models) {
        return;
      }
      const model = models.find((m) => m.identity === this.model?.action?.split('.')[0] || null);
      return { ...model };
    },
    databaseColumns() {
      if (!this.modelComputed?.schema?.properties) {
        return [];
      }
      return Object.entries(this.modelComputed.schema.properties)
        .filter(([key, value]) => key !== 'organisationId' && value.type !== 'array' && value.type !== 'object')
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
    },
  },
  mounted() {
    if (this.$attrs.value && Object.keys(this.$attrs.value).length) {
      const formmattedReversMapping = {};
      Object.keys(this.$attrs.value).map((key) => {
        formmattedReversMapping[key] = this.$attrs.value[key].split(' ');
      })
      this.reverseMapping = formmattedReversMapping;
    }
  },
  methods: {
    async updateFieldValue() {
      if (this.reverseMapping && Object.keys(this.reverseMapping).length) {
        const formattedMapping = {}
        Object.keys(this.reverseMapping).forEach((key) => {
          formattedMapping[key] = this.reverseMapping[key].join(' ');
        })
        this.$emit('input', formattedMapping);
        this.$emit('change', formattedMapping);
      }
    },
  },
};
</script>

<style>
.form-control .vs__dropdown-toggle {
  border: none;
  padding: 0;
  height: 100%;
}
.field-custom-component {
  width: 100%;
}
</style>
