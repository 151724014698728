/* eslint-disable import/prefer-default-export */

export const contact = {
  identity: 'contact',
  icon: '',
  schema: {
    properties: {
    },
  },
  // actions: { create: true, edit: true, delete: true },
  options: {
  },
  customFormTopActions: [
    {
      name: 'expand',
      label: 'common.buttons.changeDisplay',
      title: 'common.buttons.changeDisplay',
      class: 'btn btn-sm btn-outline-primary btn-icon text-primary',
      icon: '',
      type: 'dropdown',
      dropdownDirection: 'dropleft',
      children: ['page', 'modal'],
      // visible: ({ item }) => item.email,
      disabled: ({ item }) => !item || !item.id,
      action: ({
        item, action, location, props, id,
        dropdownItem
      }, context) => {
        context.$router.replace({
          name: context.$route.name,
          query: { ...context.$route.query, detailPageMode: dropdownItem },
          params: context.$route.params,
        });
      },
    },
  ],
  customInlineActions: [
    {
      name: 'send-mail',
      label: 'common.buttons.contact',
      class: 'btn btn-sm btn-simple btn-awtable-inline-action btn-icon ml-2 text-white',
      icon: 'fa fa-envelope text-info',
      visible: ({ item }) => item.email,
      action: ({
        item, action, location, props, id
      }, context) => {
        context.$store.dispatch('email/setShowEmailComposerModal', {
          show: true,
          recipientObject: item,
          recipientAddress: item && item.email
        });
      },
    },
    {
      name: 'create-lead',
      label: 'common.buttons.createLead',
      class: 'btn btn-sm btn-simple btn-awtable-inline-action btn-icon ml-2 text-white',
      icon: 'fa fa-refresh text-info',
      // visible: ({ item }) => item.email,
      action: async ({
        item, action, location, props, id
      }, context) => {
        const lead = await context.$store.dispatch('contact/convertToLead', item);
        context.$awNotify(
          context.$t('common.messages.contactConverted', {
            contactId: item.id,
            leadId: lead.id
          }),
        );
      },
    },
  ],
  customBulkActions: [
    {
      name: 'send-bulk-mail',
      label: 'Contact',
      classes: 'btn-sm btn-warning ml-2 mr-2',
      title: 'contact',
      icon: 'fa fa-envelope',
      // disabled: ({ items }) => !(items && items.find(item => item.email)),
      action: ({
        items, action, location, props, id
      }, context) => {
        context.$store.dispatch('email/setShowEmailComposerModal', {
          show: true,
          recipientAddress: items && items.filter((item) => item.email).map((item) => item.email),
        });
      },
    },
    {
      name: 'create-lead',
      label: 'common.buttons.createLead',
      class: 'btn-sm btn-info ml-2 mr-2',
      icon: 'fa fa-refresh',
      action: async ({
        items, action, location, props, id
      }, context) => {
        if (items && items.length) {
          await items.map((item) => context.$store.dispatch('contact/convertToLead', item))
        }
        context.$awNotify(
          context.$t('common.messages.contactsConverted'),
        );
      },
    },
  ],
  nestedSchemas: [],
};
