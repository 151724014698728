import qs from 'qs';
import dayjs from 'dayjs';

const initialState = {
  requests: [],
  dashboardRequests: [],
  selectedRequest: null,
  selectedRequestStats: null,
  requestsStatuses: [],
};

// getters
const getters = {
  requestTitle: (state) => (state.selectedRequest ? state.selectedRequest.title : 'request'),
  requestCreatedOn: (state) =>
    (state.selectedRequest ? dayjs(state.selectedRequest.createdOn).format('DD.MM.YYYY à HH:mm') : 'e'),
  requestAddress: (state) =>
    (state.selectedRequest
    && (state.selectedRequest.address
      || state.selectedRequest.postalCode
      || state.selectedRequest.city
      || state.selectedRequest.country)
      ? `${state.selectedRequest.address || ''},
      ${state.selectedRequest.postalCode || ''} ${state.selectedRequest.city || ''
    } ${state.selectedRequest.country || ''}`
      : ''),
};

// mutations
const mutations = {
  requests(state, data) {
    state.requests = data;
  },
  dashboardRequests(state, data) {
    state.dashboardRequests = data;
  },
  selectedRequest(state, data) {
    state.selectedRequest = data;
  },
  selectedRequestStats(state, data) {
    state.selectedRequestStats = data;
  },
  requestsStatuses(state, data) {
    state.requestsStatuses = data;
  },
};

// actions
const actions = {
  getRequests(context, options = {}) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      console.warn('[STORE][REQUEST] user or organisation not defined');
      return Promise.resolve([]);
    }
    return this._vm.$http
      .get('/api/request', {
        ...options,
        paramsSerializer(params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat',
          });
        },
      })
      .then((res) => {
        if (res.data && res.data.body) {
          if (options && options.save) {
            context.commit('requests', res.data.body);
          }
          return res.data;
        }
        return [];
      });
  },
  getRequest(context, id) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      throw new Error('user_is_not_connected');
    }
    return this._vm.$http.get(`/api/request/${id}`).then((res) => {
      if (res.data && res.data.body) {
        context.commit('selectedRequest', res.data.body);
        return res.data.body;
      }
      return [];
    });
  },

  createRequest(context, request) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }
    return this._vm.$http.post('/api/request', request).then((res) => {
      if (res.data && res.data.body) {
        return res.data.body;
      }
      return [];
    });
  },

  updateRequest(context, request) {
    const { id } = request;
    if (!id) {
      throw new Error('error_missing_id_in_put_request');
    }
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }
    return this._vm.$http.put(`/api/request/${id}`, request).then((res) => {
      if (res.data && res.data.body) {
        return res.data.body;
      }
      return [];
    });
  },

  deleteRequest(context, request) {
    const { id } = request;
    if (!id) {
      throw new Error('error_missing_id_in_put_request');
    }
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }
    return this._vm.$http.delete(`/api/request/${id}`, request);
  },

  archiveRequest(context, request) {
    return context.dispatch('updateRequest', { ...request, archived: true });
  },

  reopenRequest(context, request) {
    return context.dispatch('updateRequest', {
      ...request,
      archived: false,
    });
  },

  getRequestStats(context, id, options = {}) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }
    const { selectedRequestStats } = context.state;
    if (selectedRequestStats && selectedRequestStats.id && selectedRequestStats.id === id && !options.force) {
      return Promise.resolve(selectedRequestStats);
    }
    context.commit('selectedRequestStats', null);
    return this._vm.$http.get(`/api/request/${id}/stats`, options).then((res) => {
      if (res.data && res.data.body) {
        context.commit('selectedRequestStats', res.data.body);
        return res.data.body;
      }
      return [];
    });
  },

  getDashboardRequests(context, options = {}) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      console.warn('[STORE][REQUEST] user or organisation not defined');
      return Promise.resolve([]);
    }
    return context.dispatch('getRequests', { ...options, save: false }).then((res) => {
      if (res && res.body && Array.isArray(res.body)) {
        context.commit('dashboardRequests', res.body);
        return res;
      }
      return [];
    });
  },

  getRequestsStatuses(context, options = {}) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      console.warn('[STORE][REQUEST] user or organisation not defined');
      return Promise.resolve([]);
    }
    return this._vm.$http.get('/api/request-status?sort[order]=asc').then(({ data }) => {
      if (data.body && Array.isArray(data.body)) {
        context.commit('requestsStatuses', data.body);
        return data;
      }
      return [];
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
