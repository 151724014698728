<template>
  <div class="dashboard-tour-show-step-0">
    <div class="logo-wrapper">
      <router-link to="/">
        <img
          class="img-fluid for-light d-none"
          src="../assets/images/logo/logo.png"
          alt=""
          style="max-height: 30px; vertical-align: middle"
        />
        <img
          class="img-fluid"
          :src="showLogo.logo"
          alt=""
          style="max-height: 30px; vertical-align: middle; margin: auto"
        />
      </router-link>
      <div class="back-btn p-1" @click="toggleSidebar">
        <i class="fa fa-angle-left text-primary"></i>
      </div>
      <div v-if="sidebarIsOpen" class="toggle-sidebar from-sidebar d-none" @click="toggleSidebar">
        <feather class="status_toggle middle" :key="topIconType" :type="topIconType" id="sidebar-toggle"></feather>
      </div>
    </div>
    <div class="logo-icon-wrapper d-none">
      <router-link to="/">
        <img class="img-fluid logo-icon" :src="showLogo.logoSmall" alt="" />
      </router-link>
    </div>
    <div class="margin"></div>
    <nav class="sidebar-main h-100 pt-1">
      <li
        class="left-arrow"
        :class="{
          'd-none': layout.settings.layout_type == 'rtl' ? hideLeftArrowRTL : hideLeftArrow,
        }"
        @click="
          layout.settings.sidebar.type === 'horizontal_sidebar' && layout.settings.layout_type === 'rtl'
            ? scrollToLeftRTL()
            : scrollToLeft()
        "
      >
        <feather type="arrow-left" class="text-white"></feather>
      </li>
      <div id="sidebar-menu" class="" style="margin-bottom: 50px">
        <ul
          class="sidebar-links custom-mini-scrollbar"
          id="myDIV"
          :style="[
            layout.settings.sidebar.type == 'horizontal_sidebar'
              ? layout.settings.layout_type == 'rtl'
                ? { 'margin-right': margin + 'px' }
                : { 'margin-left': margin + 'px' }
              : { margin: '0px' },
          ]"
        >
          <li class="back-btn">
            <router-link to="/">
              <img class="img-fluid" src="../assets/images/logo/logo-icon.png" alt=""
            /></router-link>
            <div class="mobile-back text-right">
              <span>Back</span><i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
            </div>
          </li>
          <li class="sidebar-list sidebar-main-title p-1">
            <select
              name=""
              id=""
              class="form-control org-select"
              v-if="user && user.Organisations && user.Organisations.length"
              :disabled="!user.Organisations || user.Organisations.length < 2"
              @change="changeOrganisation($event)"
            >
              <option
                :selected="organisation && organisation.id === org.id"
                :value="org.id"
                v-for="org in user.Organisations"
                :key="org.id"
              >
                {{ org.name }}
              </option>
              <option value="new-organisation" :key="-1">+ Create a new organisation</option>
            </select>
          </li>
          <!-- 1st Level Menu -->
          <template v-for="(menuItem, index) in menuItems">
            <li
              :key="index"
              :class="{
                active: menuItem.active,
                'menu-open': menuItem.alwaysOpen,
                [` sidebar-menu-item-${menuItem.title}`]: true,
                'sidebar-main-title': menuItem.type == 'headtitle',
                [(menuItem.class || '') + ` sidebar-menu-item-${index}` || `sidebar-menu-item-${index}`]: true,
              }"
              class="sidebar-list"
            >
              <div>
                <!-- link title -->
                <div v-if="menuItem.type == 'headtitle'">
                  <h6 class="lan-1">{{ menuItem.headTitle1 }}</h6>
                  <p class="lan-2">{{ menuItem.headTitle2 }}</p>
                </div>
                <!-- Sub -->
                <label :class="'badge badge-' + menuItem.badgeType" v-if="menuItem.badgeType">{{
                  computePath(menuItem.badgeValue)
                }}</label>
                <a
                  href="javascript:void(0)"
                  class="sidebar-link sidebar-title"
                  v-if="menuItem.type === 'sub'"
                  @click="setNavActive(menuItem, index)"
                >
                  <feather
                    v-if="menuItem.icon && menuItem.icon.length > 2"
                    :type="menuItem.icon"
                    class="top"
                  ></feather>
                  <i v-if="menuItem.icon && menuItem.icon.length === 2" class="emoji-icon">{{ menuItem.icon }}</i>
                  <span> {{ getTitle(menuItem) }} </span>
                  <div
                    class="according-menu"
                    v-if="
                      !menuItem.alwaysOpen &&
                      menuItem.children &&
                      menuItem.children.length &&
                      menuItem.children.length &&
                      menuItem.children.length
                    "
                  ></div>
                </a>

                <!-- Link -->
                <router-link
                  :to="computePath(menuItem.path)"
                  class="sidebar-link sidebar-title"
                  v-if="menuItem.type === 'link'"
                  router-link-exact-active
                >
                  <feather
                    v-if="menuItem.icon && menuItem.icon.length > 2"
                    :type="menuItem.icon"
                    class="top"
                  ></feather>
                  <i v-if="menuItem.icon && menuItem.icon.length === 2" class="emoji-icon">{{ menuItem.icon }}</i>
                  <span>
                    {{ menuItem.title }}
                  </span>
                </router-link>
                <!-- External Link -->
                <a
                  :href="computePath(menuItem.path)"
                  class="sidebar-link sidebar-title"
                  v-if="menuItem.type === 'extLink'"
                  @click="setNavActive(menuItem, index)"
                >
                  <feather
                    v-if="menuItem.icon && menuItem.icon.length > 2"
                    :type="menuItem.icon"
                    class="top"
                  ></feather>
                  <i v-if="menuItem.icon && menuItem.icon.length === 2" class="emoji-icon">{{ menuItem.icon }}</i>
                  <span>
                    {{ getTitle(menuItem) }}
                  </span>
                </a>
                <!-- External Tab Link -->
                <a
                  :href="computePath(menuItem.path)"
                  target="_blank"
                  class="sidebar-link sidebar-title"
                  v-if="menuItem.type == 'extTabLink'"
                  @click="setNavActive(menuItem, index)"
                >
                  <feather
                    v-if="menuItem.icon && menuItem.icon.length > 2"
                    :type="menuItem.icon"
                    class="top"
                  ></feather>
                  <i v-if="menuItem.icon && menuItem.icon.length === 2" class="emoji-icon">{{ menuItem.icon }}</i>
                  <span>
                    {{ getTitle(menuItem) }}
                  </span>
                </a>
                <!-- 2nd Level Menu -->
                <ul class="sidebar-submenu" v-if="menuItem.children" :class="{ 'menu-open': menuItem.active }">
                  <li
                    v-for="(childrenItem, index) in menuItem.children"
                    :key="index"
                    :class="{
                      active: childrenItem.active,
                      [`sidebar-submenu-item-${index}`]: true,
                      [`sidebar-submenu-item-${childrenItem.title}`]: true,
                    }"
                  >
                    <!-- Sub -->
                    <a
                      class="submenu-title"
                      href="javascript:void(0)"
                      v-if="childrenItem.type == 'sub'"
                      @click="setNavActive(childrenItem, index)"
                    >
                      {{ getTitle(childrenItem) }}
                      <label
                        :class="'badge badge-' + childrenItem.badgeType + ' pull-right'"
                        v-if="childrenItem.badgeType"
                        >{{ computePath(childrenItem.badgeValue) }}</label
                      >
                    </a>
                    <!-- Link -->
                    <router-link
                      class="submenu-title"
                      :to="computePath(childrenItem.path)"
                      v-if="childrenItem.type == 'link'"
                      router-link-exact-active
                    >
                      {{ getTitle(childrenItem) }}
                      <label
                        :class="'badge badge-' + childrenItem.badgeType + ' pull-right'"
                        v-if="childrenItem.badgeType"
                        >{{ computePath(childrenItem.badgeValue) }}</label
                      >
                    </router-link>
                    <!-- External Link -->
                    <a
                      :href="computePath(childrenItem.path)"
                      v-if="childrenItem.type == 'extLink'"
                      class="submenu-title"
                    >
                      {{ childrenItem.title }}
                      <label
                        :class="'badge badge-' + childrenItem.badgeType + ' pull-right'"
                        v-if="childrenItem.badgeType"
                        >{{ computePath(childrenItem.badgeValue) }}</label
                      >
                    </a>
                    <!-- External Tab Link -->
                    <a
                      class="submenu-title"
                      :href="computePath(childrenItem.path)"
                      target="_blank"
                      v-if="childrenItem.type == 'extTabLink'"
                    >
                      {{ getTitle(childrenItem) }}
                      <label
                        :class="'badge badge-' + childrenItem.badgeType + ' pull-right'"
                        v-if="childrenItem.badgeType"
                        >{{ computePath(childrenItem.badgeValue) }}</label
                      >
                    </a>
                    <a
                      class="submenu-title"
                      href="#"
                      v-if="childrenItem.type === 'button'"
                      @click="setNavActive(childrenItem, index)"
                    >
                      {{ getTitle(childrenItem) }}
                      <label
                        :class="'badge badge-' + childrenItem.badgeType + ' pull-right'"
                        v-if="childrenItem.badgeType"
                        >{{ computePath(childrenItem.badgeValue) }}</label
                      >
                    </a>
                    <!-- 3rd Level Menu -->
                    <ul
                      class="nav-sub-childmenu submenu-content d-none"
                      v-if="childrenItem.children && childrenItem.children.length"
                      :class="{ opensubchild: childrenItem.active }"
                    >
                      <li v-for="(childrenSubItem, index) in childrenItem.children" :key="index">
                        <!-- Link -->
                        <router-link
                          :to="computePath(childrenSubItem.path)"
                          v-if="childrenSubItem.type == 'link'"
                          router-link-exact-active
                        >
                          {{ getTitle(childrenSubItem) }}
                          <label
                            :class="'badge badge-' + childrenSubItem.badgeType + ' pull-right'"
                            v-if="childrenSubItem.badgeType"
                            >{{ computePath(childrenItem.badgeValue) }}</label
                          >
                        </router-link>
                        <!-- External Link -->
                        <router-link
                          :to="computePath(childrenSubItem.path)"
                          v-if="childrenSubItem.type == 'extLink'"
                          router-link-exact-active
                        >
                          {{ getTitle(childrenSubItem) }}
                          <label
                            :class="'badge badge-' + childrenSubItem.badgeType + ' pull-right'"
                            v-if="childrenSubItem.badgeType"
                            >{{ computePath(childrenItem.badgeValue) }}</label
                          >
                        </router-link>
                        <!-- External Tab Link -->
                        <button
                          v-if="childrenSubItem.type == 'button'"
                          router-link-exact-active
                          @click="setNavActive(childrenSubItem, index)"
                        >
                          {{ getTitle(childrenSubItem) }}
                          <label
                            :class="'badge badge-' + childrenSubItem.badgeType + ' pull-right'"
                            v-if="childrenSubItem.badgeType"
                            >{{ computePath(childrenItem.badgeValue) }}</label
                          >
                        </button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <li
        class="right-arrow"
        :class="{
          'd-none': layout.settings.layout_type == 'rtl' ? hideRightArrowRTL : hideRightArrow,
        }"
        @click="
          layout.settings.sidebar.type == 'horizontal_sidebar' && layout.settings.layout_type == 'rtl'
            ? scrollToRightRTL()
            : scrollToRight()
        "
      >
        <feather type="arrow-right"></feather>
      </li>
    </nav>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import config from '@/config';

const defaultLogo = require('../assets/images/logo/logo-white.png');
const defaultLogoSmall = require('../assets/images/logo/logo-icon.png');

export default {
  name: 'Sidebar',
  data () {
    return {
      width: 0,
      height: 0,
      margin: 0,
      hideRightArrowRTL: false,
      hideLeftArrowRTL: false,
      hideRightArrow: false,
      hideLeftArrow: false,
      menuWidth: 0,
      clicked: false,
      config,
      defaultLogo,
      defaultLogoSmall,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      sidebar: (state) => state.layout.sidebarType,
      user: (state) => state.user.user,
      organisation: (state) => state.user.organisation,
      sidebarIsOpen: (state) => state.menu.sidebarIsOpen,
      boards: (state) => state.board.items,
    }),
    showLogo () {
      // if CUSTOM_LOGO env variable is not set to true or if
      // the organisation did not upload a custom logo via Features pages
      // the Cimple default logo will be displayed
      if (this.config.features.customLogo && this.organisation && this.organisation.logo) {
        return {
          logo: this.organisation.logo,
          logoSmall: this.organisation.logoSmall || defaultLogoSmall,
        };
      }
      return {
        logo: defaultLogo,
        logoSmall: defaultLogoSmall,
      };
    },
    topIconType () {
      return this.sidebarIsOpen ? 'chevron-left' : 'lock';
    },

    isMobile () {
      return window.outerWidth < 992;
    },
  },
  created () {
    window.addEventListener('resize', this.handleResize);
    // this.handleResize();
    if (this.width < 991) {
      this.layout.settings.sidebar.type = 'default';
    }
    const val = this.sidebar;
    if (val === 'default') {
      this.layout.settings.sidebar.type = 'default';
      this.layout.settings.sidebar.body_type = 'default';
    } else if (val === 'compact') {
      this.layout.settings.sidebar.type = 'compact-wrapper';
      this.layout.settings.sidebar.body_type = 'sidebar-icon';
    } else if (val === 'compact-icon') {
      this.layout.settings.sidebar.type = 'compact-page';
      this.layout.settings.sidebar.body_type = 'sidebar-hover';
    } else if (val === 'horizontal') {
      this.layout.settings.sidebar.type = 'horizontal_sidebar';
      this.layout.settings.sidebar.body_type = '';
    }
    setTimeout(() => {
      const elmnt = document.getElementById('myDIV');
      this.menuWidth = elmnt.offsetWidth;
      if (this.menuWidth > window.innerWidth) {
        this.hideRightArrow = false;
        this.hideLeftArrowRTL = false;
      } else {
        this.hideRightArrow = true;
        this.hideLeftArrowRTL = true;
      }
    }, 500);
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  },
  async mounted () {
    // injects the boards into the menu
    this.$store.dispatch('board/getItems').then(({ body: boards }) => {
      const { data } = this.$store.state.menu;
      const indexOfSettings = data.findIndex((item) => item.title === 'settings-settings');
      const entriesToInject = [];

      boards.forEach((board) => {
        entriesToInject.push({
          icon: board.icon,
          id: board.id,
          title: board.menuTitle || board.title,
          menuTitle: board.title,
          path: `/app/board-${board.id}`,
          type: 'sub',
          showInHeader: true,
          children: [
            {
              icon: board.icon || 'briefcase',
              title: board.menuTitle || board.title,
              menuTitle: board.title,
              path: `/app/board-${board.id}`,
              type: 'link',
              showInHeader: true,
            },
            {
              icon: 'file-text',
              title: 'notesAndTemplates',
              menuTitle: '',
              path: `/app/template/for/board-${board.identity || board.id}`,
              type: 'link',
              showInHeader: true,
            },
            {
              icon: 'settings',
              title: 'settings',
              menuTitle: 'settings-settings',
              path: `/app/board/${board.id}/edit`,
              type: 'link',
              showInHeader: true,
            },
          ],
        });
      });

      data.splice(indexOfSettings, 0, ...entriesToInject);
      this.$store.commit('menu/setMenu', data);
    });

    // filters nav to remove disa led features
    this.$store.dispatch('menu/filterNavByFeature');
    // link menus with the one in the header
    this.updateActiveMenu();
    // set the favicon
    if (this.showLogo.logoSmall) {
      document.querySelector('link[rel="icon"]').setAttribute('href', this.showLogo.logoSmall);
    }
  },
  watch: {
    $route () {
      this.updateActiveMenu();
      if (/Mobi|Android|ios/i.test(navigator.userAgent)) {
        // close sidebar after navigation
        this.$store.commit('menu/closeSidebar');
      }
    },
    sidebarIsOpen (newVal) {
      this.updateCrispChat();
    },
  },
  methods: {
    // getImgUrl(path) {
    //   const images = require.context('../assets/', false, /\.png$/)
    //   return images(`./${path}`);
    // },
    async toggleSidebar () {
      document.body.classList.remove('modal-open');
      await this.$store.commit('menu/closeSidebar');
    },
    // hide crisp chat on mobile to avoid overlapping,
    // and show it when sidebar is opened
    updateCrispChat () {
      // if (this.isMobile) {
      //   const $crispElement = document.querySelector('.crisp-client');
      //   if (!$crispElement) {
      //     return;
      //   }
      //   if (this.sidebarIsOpen) {
      //     $crispElement.style.visibility = 'visible';
      //     $crispElement.style.opacity = '1';
      //   } else {
      //     $crispElement.style.opacity = 0;
      //     $crispElement.style.visibility = 'hidden';
      //   }
      // }
    },
    setNavActive (item) {
      if (!item) {
        return;
      }
      this.$store.dispatch('menu/setNavActive', item);
      if (item.type === 'sub' && item.showInHeader && !this.isMobile) {
        const path = item.path || (item.children && item.children[0].path);
        this.$router.push(this.computePath(path));
      } else if (item.onClick) {
        item.onClick();
      }
    },
    handleResize () {
      this.width = window.innerWidth - 310;
    },
    scrollToRightRTL () {
      this.temp = this.width + this.margin;
      // Checking condition for remaing margin
      if (this.temp === 0) {
        this.margin = this.temp;
        this.hideRightArrowRTL = true;
      } else {
        this.margin += this.width;
        this.hideRightArrowRTL = false;
        this.hideLeftArrowRTL = false;
      }
    },
    scrollToLeftRTL () {
      // If Margin is reach between screen resolution
      if (this.margin <= -this.width) {
        this.margin += -this.width;
        this.hideLeftArrowRTL = true;
      } else {
        this.margin += -this.width;
        this.hideRightArrowRTL = false;
      }
    },
    scrollToLeft () {
      // If Margin is reach between screen resolution
      if (this.margin >= -this.width) {
        this.margin = 0;
        this.hideLeftArrow = true;
      } else {
        this.margin += this.width;
        this.hideRightArrow = false;
      }
    },
    scrollToRight () {
      this.temp = this.menuWidth + this.margin;
      // Checking condition for remaing margin
      if (this.temp < this.menuWidth) {
        this.margin = -this.temp;
        this.hideRightArrow = true;
      } else {
        this.margin += -this.width;
        this.hideLeftArrow = false;
      }
    },
    computePath (path) {
      if (typeof path === 'function') {
        return path({ context: this, state: this.$store.state });
      }
      if (path) {
        return path;
      }
    },

    changeOrganisation (event) {
      if (event.target.value === 'new-organisation') {
        // eslint-disable-next-line no-alert
        this.$store.dispatch('user/createOrganisation', prompt("What's the name of your organisation?"));
        return;
      }
      this.$store.dispatch('user/getOrganisation', event.target.value).then(() => {
        setTimeout(() => {
          document.location.href = `/?ts=${Date.now()}`;
        }, 100);
      });
    },

    updateActiveMenu () {
      this.menuItems.forEach((item) => {
        let path = this.computePath(item.path);
        if (path === this.$route.path) {
          this.$store.dispatch('menu/setActiveRoute', item);
        }
        if (!item.children) {
          return false;
        }
        item.children.forEach((subItem) => {
          path = this.computePath(subItem.path);
          if (path === this.$route.path) {
            this.$store.dispatch('menu/setActiveRoute', subItem);
          }
          if (!subItem.children) {
            return false;
          }
          subItem.children.forEach((subSubItem) => {
            path = this.computePath(subSubItem.path);
            if (path === this.$route.path) {
              this.$store.dispatch('menu/setActiveRoute', subSubItem);
            }
          });
        });
      });
    },

    getTitle (menu) {
      let title;
      if (this.$te(`app.sidebar.${menu.menuTitle}`)) {
        title = this.$t(`app.sidebar.${menu.menuTitle}`);
      } else if (this.$te(`app.sidebar.${menu.title}`)) {
        title = this.$t(`app.sidebar.${menu.title}`);
      }
      if (title) {
        if (typeof title === 'object') {
          return '';
        }
        return title;
      }
      return menu.title;
    },
  },
};
</script>

<style scoped>
.emoji-icon {
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
  font-style: normal;
}
</style>
