<template>
  <div>
    <Breadcrumbs title="Organisation" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <awesomeForm
            v-if="organisation && organisation.id"
            identity="organisation"
            :item="{ id: organisation.id }"
            :mode="mode"
            url="/api/organisation"
            @closeRequested="updateOrganisation"
            @cancel="mode = 'view'"
            @edit="mode = 'edit'"
            :actions="{ delete: false, edit: true, autosave: true }"
            :standalone="true"
            :useRouterMode="false"
            :title="'Organisation'"
            displayMode="page"
            :displayHeader="false"
            :displayedFields="[
              'name',
              config.features.customLogo ? 'logo' : null,
              config.features.customLogo ? 'logoSmall' : null,
              'featuresHumanResources',
              'featuresClientConversations',
              'featuresUnifiedInbox',
              'featuresCrm',
              'currency',
              'slaResponseTime',
              'leadUsersCanSeeAllLeads',
              'requestUsersCanSeeAllRequests',
              'supportForGeocoding',
            ]"
          >
          </awesomeForm>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '@/config';

export default {
  name: 'Organisation',
  data () {
    return {
      selectedUser: {},
      isCreating: false,
      mode: 'edit',
      config,
    };
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
  },
  mounted () {},
  methods: {
    updateOrganisation () {
      this.$store.dispatch('user/getOrganisation', this.organisation.id).then(() => {
        this.$store.dispatch('menu/filterNavByFeature');
      });
      this.mode = 'edit';
    },
  },
};
</script>

<style lang="scss">
.aw-form .form-element {
  margin-bottom: 0px;
}
</style>
