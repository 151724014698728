var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items && _vm.items.length > 0)?_c('div',{staticClass:"card-body people-list",class:{
    'table-responsive': _vm.isMobile,
    'row p-0 m-0': _vm.itemsPerRow > 1,
  }},_vm._l((_vm.items),function(item){return _c('router-link',{key:item.id,attrs:{"to":("/app/" + _vm.model + "/" + (item.id))}},[_c('div',{staticClass:"pointer parent p-1 pl-0 bg-white d-flex mt-2 mb-2 people-list-item",class:{
        'col-6': _vm.itemsPerRow === 2,
        'col-4': _vm.itemsPerRow === 3,
        'col-3': _vm.itemsPerRow === 4,
        'col-2': _vm.itemsPerRow === 6,
      }},[_c('div',{staticClass:"col-12 col-md-4"},[_c('div',{staticClass:"media m-r-15"},[(item.picture)?_c('img',{staticClass:"img-fluid rounded-circle",staticStyle:{"width":"40px","height":"40px"},attrs:{"src":item.picture,"alt":""},on:{"error":_vm.replaceImage}}):_vm._e(),(!item.picture)?_c('UserAvatar',{attrs:{"user":item,"styles":{
              fontSize: '1rem',
              width: '40px',
              height: '40px',
              lineHeight: 1.8,
              fontWeight: '300',
            }}}):_vm._e(),_c('div',{staticClass:"media-body"},[_c('span',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))]),_c('div',{staticClass:"font-roboto font-weight-light"},[_vm._v(_vm._s(item.email))]),_c('div',{staticClass:"font-roboto font-weight-light"},[_vm._v(_vm._s(item.phonenumber))]),_c('div',{staticClass:"d-flex"},[(item.linkedin)?_c('a',{staticClass:"text-info",attrs:{"target":"_blank","href":item.linkedin,"data-tooltip":item.linkedin,"data-tooltip-position":"left"}},[_c('feather',{attrs:{"type":"linkedin","size":"15"}})],1):_vm._e()])])],1)]),_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"fw-400"},[_vm._v(_vm._s(item.currentPosition || item.position))]),(item.currentPosition && item.currentCompany)?[_vm._v(" @ ")]:_vm._e(),_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(item.currentCompany))])],2),_c('div',{staticClass:"col-12 col-md-3"},[(item.Company)?_c('CompanyDisplay',{attrs:{"item":item.Company}}):_vm._e(),(item.RecruitmentProcesses)?_c('div',{attrs:{"data-tooltip":"In process for the position of "}},_vm._l((item.RecruitmentProcesses),function(process){return _c('div',{key:process.id},[(process.Position)?_c('div',[_c('feather',{staticClass:"text-bold",attrs:{"type":"package","size":"15"}}),_c('span',{staticClass:"ml-1 font-weight-light"},[_vm._v(_vm._s(process.Position && process.Position.title))])],1):_vm._e(),(process.Client)?_c('div',[_c('feather',{staticClass:"text-bold",attrs:{"type":"map-pin","size":"15"}}),_c('span',{staticClass:"ml-1 font-weight-light"},[_vm._v(_vm._s(process.Client && process.Client.name))])],1):_vm._e()])}),0):_vm._e()],1),_c('div',{staticClass:"d-none col-2"},[_c('button',{staticClass:"btn child-edit btn-transparent btn-simple mr-1 p-0"},[_c('feather',{staticClass:"text-primary",attrs:{"type":"edit-3"}})],1),_c('button',{staticClass:"btn child-delete btn-transparent btn-simple p-0"},[_c('feather',{staticClass:"text-secondary",attrs:{"type":"trash-2"}})],1)])])])}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }