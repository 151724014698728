<template>
  <div class="pt-1 pl-2" @click.stop="voidAction()">
    <div class="" v-if="itemComputed">
      <div class="media">
        <div class="w-100">
          <router-link :to="`/app/company/${itemComputed.id}`" class="f-w-500 text-info">{{
            itemComputed.name
          }}</router-link>
          <div class="mt-2 text-truncate f-10 text-mute">
            {{ itemComputed.activities || itemComputed.codeApe || itemShortDescription }}
          </div>
        </div>
        <div class="" v-if="itemComputed.avatarUrl">
          <UserAvatar
            :user="itemComputed"
            :styles="{
              fontSize: '0.5rem',
              width: '20px',
              height: '20px',
              lineHeight: 1.8,
              fontWeight: '300',
            }"
            size="20px"
          ></UserAvatar>
        </div>
      </div>
      <div class="company-info-footer mb-2 d-flex justify-content-between align-items-baseline">
        <div class="contact-info" v-if="itemComputedAddress">
          <feather type="map-pin" size="12" class="display-on-hover text-info mr-1"></feather>
          <label> {{ itemComputedAddress }} </label>
        </div>
        <div class="">
          <a
            v-if="itemComputed.phonenumber"
            :href="'tel:' + itemComputed.phonenumber"
            :data-tooltip="itemComputed.phonenumber"
            target="_blank"
          >
            <feather type="phone" size="12" class="display-on-hover text-info mr-1"></feather>
          </a>
        </div>
        <div class="display-on-hover-parent">
          <a
            v-if="itemComputed.email"
            :href="'mailto:' + itemComputed.email"
            :data-tooltip="itemComputed.email"
            target="_blank"
          >
            <feather type="at-sign" size="12" class="text-info mr-1"></feather>
          </a>
        </div>
        <div class="">
          <a
            v-if="itemComputed.linkedin"
            :href="itemComputed.linkedin"
            :data-tooltip="itemComputed.linkedin"
            target="_blank"
          >
            <feather type="linkedin" size="14" class="text-info"></feather>
          </a>
        </div>
        <div class="">
          <a
            v-if="itemComputed.websiteUrl"
            :href="itemComputed.websiteUrl"
            :data-tooltip="itemComputed.websiteUrl"
            target="_blank"
          >
            <feather type="globe" size="14"></feather>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { displayMixin } from 'vue-aw-components';
import marked from 'marked';
import striptags from 'striptags';

export default {
  mixins: [displayMixin],
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapState('client', ['clients']),
    ...mapState('user', ['user', 'organisation']),
    itemComputed() {
      return this.item || this.model?.Company || { name: this.model?.company };
    },
    itemDescription() {
      return this.itemComputed && this.itemComputed.description ? marked(this.itemComputed.description) : '';
    },
    itemShortDescription() {
      return striptags(this.itemDescription).slice(0, 100);
    },
    itemComputedAddress() {
      if (!this.itemComputed) {
        return '';
      }
      let address = this.itemComputed.city ? this.itemComputed.city : '';
      if (this.itemComputed.country) {
        address += address ? `, ${this.itemComputed.country}` : this.itemComputed.country;
      }
      return address;
    },
  },
  data() {
    return {};
  },
  methods: {
    voidAction() {},
    marked,
    striptagsFn: striptags,
  },
};
</script>

<style lang="scss" scoped>
.aw-form .form-element {
  margin-bottom: 0px;
}

.child-edit {
  visibility: hidden;
}
.child-delete {
  visibility: hidden;
}

.parent {
  &:hover {
    .child-edit,
    .child-delete {
      visibility: visible;
    }
  }
}
.contact-info {
  position: relative;
  left: 0;
}
.company-info-footer {
  max-width: 70%;
}
</style>
