<template>
  <awesome-kanban
    :lists="leadsComputed"
    titleField="name"
    descriptionField="name"
    usersField="AffectedTo"
    userImageField="avatarUrl"
    :actions="actions"
    @cardClicked="goToLeadDetail"
    @cardAdded="updateLeadStage"
  />
</template>

<script>
import { mapState } from 'vuex';
import { uniq } from 'lodash';

export default {
  name: 'LeadsKanban',
  props: {
    leads: Array,
    actions: Object,
  },
  data() {
    return {
      isDetailPage: false,
    };
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    ...mapState('leadStage', ['items']),
    leadsComputed() {
      const stages = this.items;
      const list = stages.map((stage) => ({
        title: stage.label || this.$t(stage.code || stage),
        id: stage.code || stage,
        content: [],
      }));

      list.unshift({
        title: this.$t('request.statuses.unsorted'),
        id: 'unsorted',
      });

      if (this.leads && this.leads.length) {
        list.forEach((l) => {
          l.content = this.leads
            .filter((r) => (l.id === 'unsorted' ? (!r.stage && r.stage !== false) : (r.stage === l.id)))
            .map((r) => {
              let labels = r.tags || [];
              if (r.Client && r.Client.company) {
                labels.push(r.Client.company);
              }
              if (r.Contact && r.Contact.id) {
                labels.push(`${r.Contact.firstName} ${r.Contact.lastName}`);
              }
              labels = uniq(labels);
              return {
                ...r,
                id: r.id,
                stage: r.stage,
                title: r.title,
                date: r.dueDate,
                description: r.description ? this.stripContent(r.description) : '',
                users:
                  r.AffectedTo && r.AffectedTo.avatarUrl
                    ? [{ src: r.AffectedTo && r.AffectedTo.avatarUrl, label: '' }]
                    : [],
                labels,
              };
            });
          l.title += `(${l.content.length})`;
        });
      }
      return list;
    },
  },
  mounted() {
    this.$store.dispatch('lead/getItems', { save: true });
    this.$store.dispatch('leadStage/getItems', { save: true });
  },
  methods: {
    goToLeadDetail(data) {
      this.$emit('leadClicked', data);
    },

    async updateLeadStage(payload, list) {
      this.$emit('leadAdded', payload, list);
    },

    stripContent(string, size = 100) {
      return `${string.replace(/<\/?[^>]+>/gi, ' ').substr(0, size || 100)}...`;
    },
  },
};
</script>

<style lang="scss">
.email-right-aside .request-details .aw-kanban {
  & > .float-left {
    display: none;
  }
  & > .float-right {
    display: none;
  }
}
.awesome-kanban {
  .list-actions {
    .dropdown > button {
      background: transparent !important;
      color: var(--primary);
    }
  }
  .card {
    .card-body {
      padding: 15px;

      .card-title {
        font-size: 15px;
        white-space: normal !important;
      }
      .card-text {
        font-size: 12px;
      }
    }
  }
}
</style>
