<template>
  <div>
    <px-card :title="$t('AwesomeCrud.metadata')" :actions="false" class="draggable" bodyClasses="pb-3">
      <template #headerCustom>
        <hr class="mb-0" />
      </template>
      <div slot="with-padding">
        <div class="draggable">
          <div class="mb-2 d-flex justify-content-between align-items-baseline" v-if="itemComputed.AffectedTo">
            {{
              $t(
                `AwesomeCrud.labels.${['recruitmentProcess', 'position'].includes(model) ? 'recruiter' : 'affectedTo'}`
              )
            }}
            :
            <router-link :to="`../settings/member/${itemComputed.recruiterId || itemComputed.affectedTo}`">
              {{ itemComputed.AffectedTo.firstName }} {{ itemComputed.AffectedTo.lastName }}
            </router-link>
          </div>
          <div class="mb-2 d-flex justify-content-between align-items-baseline" v-if="itemComputed.Interviewer">
            {{ $t(`AwesomeCrud.labels.interviewer`) }} :
            <router-link :to="`../settings/member/${itemComputed.interviewerId}`">
              {{ itemComputed.Interviewer.firstName }} {{ itemComputed.Interviewer.lastName }}
            </router-link>
          </div>
          <div class="mb-2 d-flex justify-content-between align-items-baseline" v-if="itemComputed.createdOn">
            {{ $t(`AwesomeCrud.labels.createdOn`) }} : <span> {{ formatDate(itemComputed.createdOn) }}</span>
          </div>
          <div
            class="mb-2 d-flex justify-content-between align-items-baseline"
            v-if="itemComputed.createdBy && itemComputed.CreatedBy"
            classes
          >
            {{ $t(`AwesomeCrud.labels.createdBy`) }} :
            <router-link :to="`../settings/member/${itemComputed.createdBy}`">
              {{ itemComputed.CreatedBy.firstName }} {{ itemComputed.CreatedBy.lastName }}
            </router-link>
          </div>
          <div class="mb-2 d-flex justify-content-between align-items-baseline" v-if="itemComputed.stageLastUpdatedOn">
            {{ $t(`AwesomeCrud.labels.stageLastUpdatedOn`) }} :
            <span>{{ formatDate(itemComputed.stageLastUpdatedOn) }}</span>
          </div>
          <div
            class="mb-2 d-flex justify-content-between align-items-baseline"
            v-if="itemComputed.stageLastUpdatedBy && itemComputed.StageLastUpdatedBy"
            classes
          >
            {{ $t(`AwesomeCrud.labels.stageLastUpdatedBy`) }} :
            <router-link :to="`../settings/member/${itemComputed.stageLastUpdatedBy}`">
              {{ itemComputed.StageLastUpdatedBy.firstName }} {{ itemComputed.StageLastUpdatedBy.lastName }}
            </router-link>
          </div>
          <div
            class="mb-2 d-flex justify-content-between align-items-baseline"
            v-if="itemComputed.statusLastUpdatedOn"
          >
            {{ $t(`AwesomeCrud.labels.stageLastUpdatedOn`) }} :
            <span>{{ formatDate(itemComputed.statusLastUpdatedOn) }}</span>
          </div>
          <div
            class="mb-2 d-flex justify-content-between align-items-baseline"
            v-if="itemComputed.statusLastUpdatedBy && itemComputed.StatusLastUpdatedBy"
            classes
          >
            {{ $t(`AwesomeCrud.labels.stageLastUpdatedBy`) }} :
            <router-link :to="`../settings/member/${itemComputed.statusLastUpdatedBy}`">
              {{ itemComputed.StatusLastUpdatedBy.firstName }} {{ itemComputed.StatusLastUpdatedBy.lastName }}
            </router-link>
          </div>
          <div class="mb-2 d-flex justify-content-between align-items-baseline" v-if="itemComputed.lastModifiedOn">
            {{ $t(`AwesomeCrud.labels.lastModifiedOn`) }} : <span>{{ formatDate(itemComputed.lastModifiedOn) }}</span>
          </div>
          <div
            class="mb-2 d-flex justify-content-between align-items-baseline"
            v-if="itemComputed.lastModifiedBy && itemComputed.LastModifiedBy"
          >
            {{ $t(`AwesomeCrud.labels.lastModifiedBy`) }} :
            <router-link :to="`../settings/member/${itemComputed.lastModifiedBy}`">
              {{ itemComputed.LastModifiedBy.firstName }} {{ itemComputed.LastModifiedBy.lastName }}
            </router-link>
          </div>
          <div class="mb-2 d-flex justify-content-between align-items-baseline" v-if="itemComputed.source">
            {{ $t(`common.labels.source`) }} :
            <router-link :to="`/app/${model}?filters[source]=${itemComputed.source}`">
              {{ itemComputed.source }}
            </router-link>
          </div>
        </div>
      </div>
    </px-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);

export default {
  name: 'MetadataWidget',
  props: {
    model: String,
    modelId: [Number, String],
    item: Object,
  },
  data() {
    return {
      fetchedTtem: {},
    };
  },
  mounted() {
    this.getItem();
  },
  watch: {
    model(to, from) {
      this.getItem();
    },
    modelId(to, from) {
      this.getItem();
    },
  },
  computed: {
    apiRoot() {
      return `/api/${this.model === 'recruitmentProcess' ? 'recruitment-process' : this.model}/${this.modelId}`;
    },
    formatMetadata() {
      const metadata = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const prop of Object.keys(this.item)) {
        if (this.metadataFields.includes(prop)) {
          metadata.push({ prop, value: this.item[prop] });
        }
      }
      return metadata;
    },
    itemComputed() {
      return this.item || this.fetchedTtem;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return;
      }
      return dayjs(date).format('DD/MM/YYYY  hh:mm');
    },
    getItem() {
      if (!this.model || !this.modelId) {
        return;
      }
      if (this.item) {
        return;
      }
      return this.$http
        .get(this.apiRoot)
        .then((result) => {
          if (result && result.data && result.data.body) {
            this.item = result.data.body;
          }
        })
        .catch((err) => console.warn(err));
    },
  },
};
</script>
