<template>
  <div>
    <Breadcrumbs
      :parents="[{ title: 'Leads', url: `/app/lead` }]"
      :main="this.selectedItem && this.selectedItem.id"
      :title="$t('app.models.lead') + ': ' + getLeadTitle(this.selectedItem)"
      :user="this.selectedItem && this.selectedItem.AffectedTo"
      :isEditingLayout="isEditingLayout"
      :canEditLayout="true"
      @layout-edit-toggle="toggleLayoutEdit"
      @layout-reset="resetLayout"
    >
      <template #left></template>
      <template #right>
        <div class="d-inline-flex mr-5">
          <div class="btn-group ml-2 btn-sm">
            <router-link
              v-if="$store.state.lead.previousItemId"
              class="btn btn-outline-primary btn-sm"
              :to="`/app/lead/${$store.state.lead.previousItemId}`"
            >
              <feather type="arrow-left" size="1.1em" class="mr-1" />
            </router-link>
            <router-link
              v-if="$store.state.lead.nextItemId"
              class="btn btn-outline-primary btn-sm"
              :to="`/app/lead/${$store.state.lead.nextItemId}`"
            >
              <feather type="arrow-right" size="1.1em" class="mr-1 mb-0" />
            </router-link>
          </div>
        </div>
      </template>
      <template #dropdown>
        <router-link to="/app/settings/details/settings.loadItem" class="dropdown-item"
          ><feather type="settings" size="1.1em" class="mr-1" />{{ $t('app.sidebar.settings-settings') }}</router-link
        >
      </template>
    </Breadcrumbs>
    <div class="container-fluid">
      <div class="text-center" v-if="!selectedItem">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64" fill="var(--theme-info)">
          <circle transform="translate(8 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
          <circle transform="translate(16 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0.3"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
          <circle transform="translate(24 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0.6"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
        </svg>
      </div>
      <div class="email-wrap bookmark-wrap" v-if="selectedItem">
        <ul class="d-flex pull-right flex-row fixed-actions-bar">
          <li class="mr-1" v-if="selectedItem">
            <MemberSelectorDropdown
              :value="selectedItem.affectedToUserId"
              direction="up"
              @change="(id) => affectItemToUser({ id })"
              class="d-inline-flex mr-2"
            />
          </li>
          <li class="mr-1">
            <div class="dropdown dropup">
              <button
                class="btn dropdown-toggle btn-sm text-uppercase"
                :class="{
                  'btn-dark': selectedItem.archived,
                  'btn-primary': !selectedItem.archived,
                }"
                id="dropdownMenuButton"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                v-on:click="stageDropdownToggle = !stageDropdownToggle"
              >
                {{ selectedItem.stage || $t('common.buttons.changeStatus') }}
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                v-if="leadModel && leadModel.schema && leadModel.schema.properties"
                :class="{ show: stageDropdownToggle }"
              >
                <a
                  v-for="stage in leadStages"
                  :key="stage.code"
                  class="dropdown-item"
                  @click.prevent="
                    changeItemStage(stage.code);
                    stageDropdownToggle = false;
                  "
                  href="#"
                  >{{ stage.label || stage.code }}
                </a>
              </div>
            </div>
          </li>
          <li>
            <button v-if="selectedItem && selectedItem.email" class="btn btn-info btn-sm" @click="openEmailModal">
              <feather type="send" size="1rem" class="pull-left mr-1"></feather>
              <span>{{ $t('common.buttons.sendMail') }}</span>
            </button>
          </li>
          <li class="">
            <button class="btn btn-secondary btn-sm ml-1" @click="showEditForm = true">
              <feather type="edit-3" size="1rem" class="pull-left mr-1"></feather>
              <span>{{ $t('common.buttons.edit') }}</span>
            </button>
          </li>
          <li class="">
            <button v-if="!selectedItem.archived" @click="archiveLead" class="btn bg-dark btn-sm ml-1">
              <feather type="archive" size="1rem" class="pull-left mr-1"></feather>
              <span>{{ $t('common.buttons.archive') }}</span>
            </button>
          </li>
          <li class="">
            <button v-if="selectedItem.archived" @click="reopenLead" class="btn bg-dark btn-sm">
              <feather type="unlock" size="1rem" class="pull-left mr-1"></feather>
              <span>{{ $t('common.buttons.reopen') }}</span>
            </button>
          </li>
          <li class="">
            <div class="dropdown d-inline-block dropup">
              <button
                class="btn bg-dark btn-sm text-white ml-1"
                id="dropdownMenuButton"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                v-on:click="toggleFolderDropdown()"
                data-tooltip="Créer à partir de ce lead..."
              >
                <feather class="text-white" size="1rem" type="more-horizontal"></feather>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
                :class="{ show: folderDropdownIsOpen }"
              >
                <template v-if="!selectedItem.clientId">
                  <button class="dropdown-item" @click="createClient(selectedItem)">
                    <feather class="text-primary" type="user-plus" size="15"></feather>
                    {{ $t('app.mailbox.create-client') }}
                  </button>
                  <button
                    class="dropdown-item pl-2"
                    :title="$t('app.mailbox.create-client-and-request')"
                    :data-tooltip="$t('app.mailbox.create-client-and-request')"
                    @click="createClientAndRequest(selectedItem)"
                  >
                    <samp>
                      <feather class="text-primary" type="user-plus" size="15"></feather>
                      <feather class="text-primary" type="mail" size="15"></feather>
                    </samp>
                    {{ $t('app.mailbox.create-client-and-request') }}
                  </button>
                </template>
                <button
                  class="dropdown-item"
                  @click="createRequest(selectedItem)"
                  :title="$t('app.mailbox.create-request')"
                  :data-tooltip="$t('app.mailbox.create-request')"
                  v-if="organisation.featuresClientConversations && !selectedItem.requestId && selectedItem.clientId"
                >
                  <samp>
                    <feather class="text-primary" type="mail" size="15"></feather>
                    <feather
                      class="text-primary"
                      type="plus"
                      size="10"
                      style="position: absolute; top: 3px; left: 15px"
                    ></feather>
                  </samp>
                  {{ $t('app.mailbox.create-request') }}
                </button>
                <button
                  class="dropdown-item"
                  @click="goToRequestDetail"
                  :title="$t('app.mailbox.see-request')"
                  :data-tooltip="$t('app.mailbox.see-request')"
                  v-if="selectedItem.requestId"
                >
                  <feather class="text-primary" type="message-square" size="15"></feather>
                  {{ $t('app.mailbox.see-request') }}
                </button>
                <button
                  class="dropdown-item"
                  @click="goToClientDetail"
                  :title="$t('app.mailbox.see-client')"
                  :data-tooltip="$t('app.mailbox.see-client')"
                  v-if="selectedItem.clientId"
                >
                  <feather class="text-primary" type="dollar-sign" size="15"></feather>
                  {{ $t('app.mailbox.see-client') }}
                </button>
                <button
                  class="dropdown-item"
                  @click="enrich('lead', selectedItem.id)"
                  type="button"
                  :title="$t('app.mailbox.see-client')"
                  :data-tooltip="$t('app.mailbox.see-client')"
                  v-if="selectedItem.id"
                >
                  <feather class="text-warning" type="zap" size="15"></feather>
                  {{ $t('common.buttons.enrich') }}
                </button>
                <button class="dropdown-item" @click="toggleFolderDropdown(false)">
                  <feather class="text-primary" type="x" size="15"></feather>
                  {{ $t('common.buttons.cancel') }}
                </button>
              </div>
            </div>
          </li>
        </ul>
        <DraggableLayout
          classes="row"
          dragoverBubble="true"
          responsive
          @change="onLayoutChange"
          @close="toggleLayoutEdit"
          @reset="resetLayout"
          v-model="pageLayout"
          canEditingLayout
          :isEditing="isEditingLayout"
        >
          <template slot-scope="{ itemId }">
            <div class="card draggable" v-if="itemId === 'lead'" :key="itemId">
              <div class="card-body pt-1">
                <div class="email-app-sidebar">
                  <div class="media">
                    <div class="media-size-email">
                      <img
                        v-if="selectedItem && (selectedItem.avatarUrl || selectedItem.logoUrl)"
                        class="mr-3 rounded-circle"
                        :src="selectedItem.avatarUrl || selectedItem.logoUrl"
                        alt=""
                      />

                      <img v-else class="mr-3 rounded-circle" src="../assets/images/user/user.png" alt="" />
                    </div>
                    <div class="media-body">
                      <h6 class="f-w-600">{{ selectedItem.company || selectedItem.name }}</h6>
                      <p>
                        <EditableText
                          :disabled="selectedItem.archived"
                          v-model="selectedItem.name"
                          showSaveButton
                          class=""
                          @change="changeLeadName"
                        />
                      </p>
                    </div>
                  </div>
                  <div
                    class="mb-2 d-flex justify-content-between align-items-baseline display-on-hover-parent"
                    v-if="selectedItem"
                  >
                    <feather type="phone" size="15" class="text-dark mr-1"></feather>
                    <label>
                      <EditableText
                        :disabled="selectedItem.archived"
                        v-model="selectedItem.phonenumber"
                        placeholder="-"
                        showSaveButton
                        class=""
                        style="min-width: 50px"
                        @change="saveLead"
                        type="text"
                      >
                        <template #default="{ value, onClick }">
                          <div class="text-primary text-truncate pointer">
                            <div class="d-inline-flex" v-if="!value">
                              {{ ' - ' }}
                            </div>
                            <a v-if="value" :href="'tel:' + value" class="text-primary">{{ value }}</a>
                            <feather
                              @click="onClick"
                              type="edit-3"
                              size="12"
                              class="display-on-hover text-info pull-right"
                            ></feather>
                          </div>
                        </template>
                      </EditableText>
                    </label>
                  </div>
                  <div
                    class="mb-2 d-flex justify-content-between align-items-baseline display-on-hover-parent"
                    v-if="selectedItem"
                  >
                    <feather type="at-sign" size="15" class="text-dark mr-1"></feather>
                    <label class="text-truncate" :title="selectedItem.email">
                      <EditableText
                        :disabled="selectedItem.archived"
                        v-model="selectedItem.email"
                        :value="selectedItem.email"
                        placeholder="-"
                        showSaveButton
                        class=""
                        style="min-width: 50px"
                        @change="saveLead"
                        type="text"
                      >
                        <template #default="{ value, onClick }">
                          <div class="text-primary text-truncate pointer f-w-300">
                            <span @click.prevent.stop="contactItem(selectedItem)">{{ value }}</span>
                            <feather
                              @click="onClick"
                              type="edit-3"
                              size="12"
                              class="display-on-hover text-info ml-1"
                            ></feather>
                          </div>
                        </template>
                      </EditableText>
                    </label>
                  </div>
                  <div
                    class="mb-2 d-flex justify-content-between align-items-baseline display-on-hover-parent"
                    v-if="selectedItem"
                  >
                    <feather
                      type="linkedin"
                      size="15"
                      :class="selectedItem.linkedin ? 'text-info' : 'text-muted'"
                    ></feather>
                    <label class="text-truncate" :title="selectedItem.linkedin">
                      <EditableText
                        :disabled="selectedItem.archived"
                        v-model="selectedItem.linkedin"
                        :value="selectedItem.linkedin"
                        placeholder="-"
                        showSaveButton
                        class=""
                        style="min-width: 50px"
                        @change="saveLead"
                        type="url"
                      >
                        <template #default="{ value, onClick }">
                          <a
                            target="_blank"
                            :href="selectedItem.linkedin"
                            :data-tooltip="selectedItem.linkedin"
                            class="text-info text-truncate f-w-300"
                            v-if="value"
                          >
                            <feather
                              type="edit"
                              size="10"
                              class="ml-1 display-on-hover pointer"
                              @click.prevent.stop="onClick"
                            ></feather>
                            {{ selectedItem.linkedin }}
                          </a>
                        </template>
                      </EditableText>
                    </label>
                  </div>
                  <div class="d-flex justify-content-between align-items-baseline" v-if="selectedItem">
                    <feather
                      type="globe"
                      size="15"
                      :class="selectedItem.websiteUrl ? 'text-info' : 'text-muted'"
                    ></feather>
                    <label>
                      <EditableText
                        :disabled="selectedItem.archived"
                        v-model="selectedItem.websiteUrl"
                        :value="selectedItem.websiteUrl"
                        placeholder="-"
                        showSaveButton
                        class=""
                        style="min-width: 50px"
                        @change="saveLead"
                        type="url"
                      >
                        <template #default="{ value, onClick }">
                          <a
                            target="_blank"
                            :href="selectedItem.websiteUrl"
                            :data-tooltip="selectedItem.websiteUrl"
                            class="text-info display-on-hover-parent text-right f-w-300"
                            v-if="value"
                          >
                            <feather
                              type="edit"
                              size="10"
                              class="text-muted display-on-hover p-0 mr-1"
                              @click.prevent.stop="onClick"
                            ></feather>
                            {{ selectedItem.websiteUrl }}
                          </a>
                        </template>
                      </EditableText>
                    </label>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-baseline display-on-hover-parent"
                    v-if="selectedItem"
                  >
                    {{ $t(`common.labels.dealValue`) }} :
                    <label>
                      <EditableText
                        :disabled="selectedItem.archived"
                        v-model="selectedItem.dealValue"
                        :value="selectedItem.dealValue || ''"
                        placeholder="-"
                        showSaveButton
                        class=""
                        style="min-width: 50px"
                        @change="saveLead"
                        type="number"
                      >
                        <template #default="{ value, onClick }">
                          <div @click="onClick" class="text-primary">
                            {{ value || ' - ' }} {{ organisation.currency || '€' }}
                            <feather
                              type="edit-3"
                              size="10"
                              class="text-muted display-on-hover p-0"
                              style="position: absolute; right: 5px"
                              @click.prevent.stop="onClick"
                            ></feather>
                          </div>
                        </template>
                      </EditableText>
                    </label>
                  </div>

                  <div class="mb-0 d-flex justify-content-between align-items-baseline" v-if="selectedItem">
                    {{ $t(`common.labels.temperature`) }} :
                    <span data-tooltip="Temperature">
                      <Rating v-model="selectedItem.temperature" @input="saveLead()">
                        <template v-slot:star="{ active }">
                          <span style="font-size: 10px" :style="!active ? 'filter: grayscale(1)' : ''">🔥</span>
                        </template>
                      </Rating>
                    </span>
                  </div>
                  <div class="mb-0 d-flex justify-content-between align-items-baseline" v-if="selectedItem">
                    {{ $t(`common.labels.quality`) }} :
                    <label data-tooltip="quality">
                      <Rating v-model="selectedItem.quality" @input="saveLead()" />
                    </label>
                  </div>
                  <div class="mb-0" v-if="selectedItem">
                    <button
                      variant="link"
                      class="p-0 btn btn-sm text-info"
                      style="display: flex; align-items: center"
                      @click="toggleDescription = !toggleDescription"
                    >
                      <p class="text-info">Description</p>
                      <feather
                        :type="toggleDescription ? 'chevron-right' : 'chevron-down'"
                        size="12"
                        class="text-info"
                      />
                    </button>
                    <div
                      class="lead-details collapse fade"
                      :class="toggleDescription ? 'show' : 'collapse'"
                      style="overflow: auto; width: 100%"
                    >
                      <div class="">
                        <div>
                          <small v-html="marked(itemDescription.replace(/\n/gm, '<br/>'), { breaks: true })"></small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <template v-if="itemId === 'conversation'">
              <div :key="itemId" class="draggable">
                <div class="card d-none" v-if="!selectedItem.archived">
                  <div class="card-body p-4">
                    <textarea
                      name=""
                      id="lead-detail-answer-input"
                      v-model="newMessageContent"
                      cols="30"
                      rows="10"
                      class="form-control"
                      :placeholder="$t('common.buttons.contactLead')"
                      :disabled="!selectedItem.email"
                    ></textarea>
                    <button class="ml-1 btn btn-primary flex-end pull-right mt-1" @click="sendMessage(true)">
                      <feather
                        type="message-square"
                        size="1rem"
                        class="pull-left mr-1 mt-1"
                        v-if="!isSending"
                      ></feather>
                      <feather type="loader" animation="spin" class="pull-left mr-1" v-if="isSending"></feather>
                      {{ $t('common.buttons.reply') }}
                    </button>
                    <button
                      :disabled="!selectedItem.email"
                      class="ml-1 btn btn-info flex-end pull-right mt-1 d-none"
                      @click="sendMessage(true)"
                    >
                      <i
                        :class="{
                          'fa fa-paper-plane': !isSending,
                          'fa fa-circle-o-notch fa-spin': isSending,
                        }"
                      ></i>
                      {{ $t('common.buttons.replyByEmail') }}
                    </button>
                  </div>
                </div>
                <div class="">
                  <template v-if="selectedItem.Messages && selectedItem.Messages.length">
                    <ConversationList :messages="sortedMails" />
                  </template>
                </div>
              </div>
            </template>
            <template v-if="itemId === 'map'">
              <div :key="itemId" class="draggable">
                <div class="card p-2" v-if="supportsMapModeComputed && selectedItemPosition.lat">
                  <div class="card-body">
                    <GmapMap
                      :center="selectedItemPosition"
                      :zoom="14"
                      map-type-id="terrain"
                      style="width: 100%; height: 300px"
                      :options="{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                      }"
                    >
                      <GmapMarker :position="selectedItemPosition" :clickable="true" @click="toggleMapInfoWindow(m)" />
                    </GmapMap>
                  </div>
                </div>
              </div>
            </template>
            <ClientInfoWidget
              class="draggable"
              v-if="itemId === 'client'"
              :key="itemId"
              :clientId="selectedItem.clientId"
              @change="loadItem"
            />
            <ContactsWidget
              class="draggable"
              v-if="itemId === 'contacts'"
              :key="itemId"
              :contacts="selectedItem.Contacts"
              :leadId="selectedItem.id"
              @change="loadItem"
            ></ContactsWidget>
            <TasksWidget
              class="draggable"
              :key="itemId"
              v-if="itemId === 'tasks'"
              model="lead"
              :modelId="selectedItem.id"
            />
            <CommentsWidget
              class="draggable"
              :key="itemId"
              v-if="itemId === 'comments'"
              model="lead"
              enable-events
              :modelId="selectedItem.id"
            />
            <DocumentsWidget
              class="draggable"
              :key="itemId"
              v-if="itemId === 'documents'"
              model="lead"
              :modelId="selectedItem.id"
            ></DocumentsWidget>
            <InboxWidget
              v-if="itemId === 'mailbox'"
              model="lead"
              :modelId="parseInt(selectedItem.id)"
              :emails="[selectedItem.email]"
              embedded
              widget
              style="max-height: 400px"
            />
            <MetadataWidget
              v-if="itemId === 'metadata'"
              class="draggable"
              model="lead"
              :key="itemId"
              :modelId="selectedItem.id"
              :item="selectedItem"
            />
            <CustomFieldValuesWidget
              v-if="itemId === 'customFields' && selectedItem"
              :item="selectedItem"
              :key="itemId"
              :modelId="selectedItem.id"
              class="draggable"
              model="lead"
            />
          </template>
        </DraggableLayout>

        <div class="row">
          <div class="col-xl-3 box-col-6">
            <div class="email-left-aside">
              <div class="card d-none">
                <div class="card-body">
                  <ul class="nav main-menu" role="tablist">
                    <li class="nav-item">
                      <h6 class="f-w-600">Taches</h6>
                    </li>
                  </ul>
                  <div class="appointment-table table-responsive">
                    <table class="table table-bordernone">
                      <tbody>
                        <tr>
                          <td>
                            <img
                              class="img-fluid img-40 rounded-circle mb-3"
                              src="@/assets/images/appointment/app-ent.jpg"
                              alt="Image description"
                            />
                            <div class="status-circle bg-primary"></div>
                          </td>
                          <td class="img-content-box">
                            <span class="d-block">Mélanie Lauren</span>
                            <span class="font-roboto">Now</span>
                          </td>
                          <td>
                            <p class="m-0 font-primary">28 Sept</p>
                          </td>
                          <td class="text-right">
                            <div class="button btn btn-primary">
                              Done
                              <i class="fa fa-check-circle ml-2"></i>
                            </div>
                          </td>
                        </tr>
                        <tr class="mt-4">
                          <td>
                            <img
                              class="img-fluid img-40 rounded-circle"
                              src="@/assets/images/appointment/app-ent.jpg"
                              alt="Image description"
                            />
                            <div class="status-circle bg-primary"></div>
                          </td>
                          <td class="img-content-box">
                            <span class="d-block">John Loren</span>
                            <span class="font-roboto">11:00</span>
                          </td>
                          <td>
                            <p class="m-0 font-primary">22 Sept</p>
                          </td>
                          <td class="text-right">
                            <div class="button btn btn-danger">
                              Pending
                              <i class="fa fa-check-circle ml-2"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card" v-if="selectedItem.Client && !selectedItem.Contact && selectedItem.Client.name">
                <div class="card-body">
                  <div class="email-app-sidebar">
                    <div class="media">
                      <div class="media-size-email">
                        <img
                          v-if="selectedItem.Client.avatarUrl"
                          class="mr-3 rounded-circle"
                          src="selectedItem.Client.avatarUrl"
                          alt=""
                        />
                        <img v-else class="mr-3 rounded-circle" src="../assets/images/user/user.png" alt="" />
                      </div>
                      <div class="media-body">
                        <h6 class="f-w-600">{{ selectedItem.Client && selectedItem.Client.name }}</h6>
                        <p>{{ selectedItem.Client.company }}</p>
                      </div>
                    </div>
                    <ul class="nav main-menu" role="tablist">
                      <li class="nav-item"></li>
                      <li class="nav-item"><span class="main-title"></span></li>
                      <li>
                        <a href="#pills-created"
                          ><span class="title"> {{ selectedItem.Client.phonenumber }}</span></a
                        >
                      </li>
                      <li>
                        <a class="show" href="#pills-favourites"
                          ><small class="title"> {{ selectedItem.Client.email }}</small></a
                        >
                      </li>
                      <li>
                        <a href="pills-shared" aria-selected="false"><small class="title"> 25 demandes</small></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-md-12 box-col-12"></div>
        </div>
      </div>
    </div>
    <awesomeForm
      v-if="showEditForm"
      identity="lead"
      :item="selectedItem"
      :mode="mode"
      :url="`/api/lead`"
      @cancel="hideItemEditForm()"
      @delete="deleteLead()"
      @closeRequested="closeItemEditForm()"
      :standalone="false"
      :useRouterMode="false"
    ></awesomeForm>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Swal from 'sweetalert2/dist/sweetalert2';
import dayjs from 'dayjs';
import marked from 'marked';
import _ from 'lodash';
import ContactsWidget from '../components/shared/ContactsWidget.vue';
import TasksWidget from '../components/shared/TasksWidget.vue';
import DocumentsWidget from '../components/shared/DocumentsWidget.vue';
import CommentsWidget from '../components/shared/CommentsWidget.vue';
import MetadataWidget from '../components/shared/MetadataWidget.vue';
import ConversationList from '../components/shared/ConversationList.vue';
import InboxWidget from '../components/shared/InboxWidget.vue';
import DraggableLayout from '../components/basic/DraggableLayout.vue';
import Rating from '../components/basic/Rating.vue';
import manageLayoutMixin from '../mixins/manageLayoutMixin';
import avatarMethodsMixin from '../mixins/avatarMethodsMixin';
import ClientInfoWidget from '../components/shared/ClientInfoWidget.vue';
import CustomFieldValuesWidget from '../components/shared/CustomFieldValuesWidget.vue';

export default {
  name: 'LeadDetail',
  components: {
    CommentsWidget,
    ContactsWidget,
    ConversationList,
    DocumentsWidget,
    DraggableLayout,
    InboxWidget,
    Rating,
    TasksWidget,
    MetadataWidget,
    ClientInfoWidget,
    CustomFieldValuesWidget,
  },
  mixins: [manageLayoutMixin, avatarMethodsMixin],
  data () {
    return {
      newMessageContent: '',
      isSending: false,
      showEditForm: false,
      stageDropdownToggle: false,
      userDropdownToggle: false,
      mode: 'edit',
      isEditingLayout: false,
      defaultPageLayout: [
        {
          id: 'header',
          group: 'layout',
          classes: 'col-12',
          layout: [],
        },
        {
          id: 'left-sidebar',
          group: 'layout',
          classes: 'col-xl-4 box-col-6',
          layout: [
            'lead',
            'map',
            'client',
            'contacts',
            'mailbox',
            'documents',
            'metadata',
            'conversation',
            'customFields',
          ],
        },
        {
          id: 'main-body',
          group: 'layout',
          displayMode: 'list',
          classes: 'col-xl-8 box-col-6',
          layout: ['comments', 'tasks'],
        },
        {
          id: 'left-footer',
          group: 'layout',
          classes: 'bloc-1 col-xl-4',
          layout: [],
        },
        {
          id: 'middle-footer',
          group: 'layout',
          classes: 'bloc-2 col-4',
          layout: [],
        },
        {
          id: 'right-footer',
          group: 'layout',
          classes: 'bloc-3 col-xl-4',
          layout: [],
        },
        {
          id: 'footer',
          group: 'layout',
          classes: 'col-12',
          layout: [],
        },
      ],
      selectedClientStats: {},
      folderDropdownIsOpen: false,
      toggleDescription: false,
    };
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.$store.commit('lead/selectedItem', null);
      vm.loadItem(to.params.id);
    });
  },

  beforeRouteUpdate (to, from, next) {
    next();
  },

  async mounted () {
    if (this.selectedItem && `${this.selectedItem.id}` !== this.$route.params.id) {
      await this.$store.commit('lead/selectedItem', null);
      return;
    }

    if (!this.leadStages || this.leadStages.length === 0) {
      this.$store.dispatch('leadStage/getItems');
    }

    // listener are ready
    this.$socket.on(`/lead/${this.$route.params.id}`, this.setItem);
  },
  beforeDestroy () {
    this.$socket.off(`/lead/${this.$route.params.id}`, this.setItem);
  },
  computed: {
    ...mapState('lead', ['selectedItem']),
    ...mapState('user', ['user', 'organisation']),
    ...mapState({
      leadStages: (state) => state.leadStage.items,
    }),
    sortedMails () {
      return _.orderBy(this.selectedItem.Messages, ['sentOrReceivedOn'], ['desc']);
    },
    leadModel () {
      const { state } = this.$store;
      const m = state.models && Array.isArray(state.models) && state.models.find((model) => model.identity === 'lead');
      if (m && m.schema.properties.affectedToUserId) {
        // m.schema.properties.affectedToUserId.relationUrl = '/api/user';
      }
      return m;
    },

    leadCreatedOn () {
      return dayjs(this.selectedItem.createdOn).format('DD/MM/YYYY');
    },

    clientName () {
      return this.selectedItem && this.selectedItem.Client
        ? this.selectedItem.Client.company || this.selectedItem.Client.name || ''
        : '';
    },
    itemDescription () {
      return this.selectedItem && this.selectedItem.description ? marked(this.selectedItem.description) : '';
    },
    client () {
      return (this.selectedItem && this.selectedItem.Client) || null;
    },
    supportsMapModeComputed () {
      return this.organisation.Settings && this.organisation.Settings.supportForGeocoding;
    },

    selectedItemPosition () {
      if (this.selectedItem?.latitude) {
        return {
          lat: this.formatGeoCoordinate(this.selectedItem.latitude),
          lng: this.formatGeoCoordinate(this.selectedItem.longitude),
        };
      }
      if (this.selectedItem?.customFields?.latitude) {
        return {
          lat: this.formatGeoCoordinate(this.selectedItem.customFields.latitude),
          lng: this.formatGeoCoordinate(this.selectedItem.customFields.longitude),
        };
      }
      return {};
    },
  },
  methods: {
    setItem (item) {
      this.$store.commit('lead/selectedItem', item);
    },
    async loadItem (id) {
      if (!id) {
        id = this.$route.params.id;
        if (!id || Number.isNaN(parseInt(id, 10))) {
          return;
        }
      }
      const [item] = await Promise.all([
        this.$store.dispatch('lead/getItem', this.$route.params.id, {}),
        this.$store.dispatch('member/getItems', { cache: true }),
      ]);
      this.checkForDescriptionOpening();
      return item;
    },
    marked,
    getImgUrl (path) {
      // eslint-disable-next-line
      return require(`@/assets/images/${path}`);
    },

    toggleFolderDropdown (status) {
      if (status !== undefined) {
        this.folderDropdownIsOpen = !!status;
        return;
      }
      this.folderDropdownIsOpen = !this.folderDropdownIsOpen;
    },

    async sendMessage (notifyByEmail = false) {
      if (!this.newMessageContent) {
        return;
      }
      this.isSending = true;
      try {
        await this.$http.post(`/api/lead/${this.selectedItem.id}/message`, {
          content: this.newMessageContent,
          leadId: this.selectedItem.id,
          notifyByEmail,
        });
        this.newMessageContent = '';
        this.$store.dispatch('lead/getItem', this.selectedItem.id);
      } catch (err) {
        console.warn(err.message);
      }
      this.isSending = false;
    },

    changeItemStage (stage) {
      this.$store
        .dispatch('lead/updateItem', {
          id: this.selectedItem.id,
          name: this.selectedItem.name
            ? this.selectedItem.name
            : `${this.selectedItem.firstName || ''} ${this.selectedItem.lastName || ''}`,
          stage,
        })
        .then(() => {
          this.$awNotify('Statut mis à jour ✅');
          this.$store.dispatch('lead/getItem', this.selectedItem.id);
        });
    },

    affectItemToUser (user) {
      if (user && user.id === this.selectedItem.affectedToUserId) {
        return;
      }
      this.$store
        .dispatch('lead/updateItem', {
          id: this.selectedItem.id,
          name: this.selectedItem.name,
          affectedToUserId: user.id,
        })
        .then(() => {
          this.$awNotify('Demande affectée ✅');
          this.$store.dispatch('lead/getItem', this.selectedItem.id);
        });
    },

    async closeLead (stage) {
      // const { value: targetComment } = await Swal.fire({
      //   title: 'Commentaires',
      //   input: 'textarea',
      //   //  inputLabel: 'ex: demandée traitée | doublon.',
      //   showCancelButton: true,
      //   reverseButtons: true,
      // });
      // if (targetComment) {
      //   await this.$http.post(`/api/request/${this.selectedItem.id}/comment`, {
      //     model: 'request',
      //     modelId: this.selectedItem.id,
      //     content: `Fermé par ${this.user.firstName} ${
      //       this.user.lastName
      //     } le ${this.formatDate()}</br>${targetComment}`,
      //   });
      //   this.getComments();
      // }

      this.$store
        .dispatch('lead/updateItem', {
          id: this.selectedItem.id,
          name: this.selectedItem.name,
          stage: stage && typeof stage === 'string' ? stage : 'done',
        })
        .then(() => {
          this.$awNotify('Requête terminée ! 🎉');
          return this.$store.dispatch('lead/getItem', this.selectedItem.id);
        });
    },
    async archiveLead (stage) {
      return this.$store
        .dispatch('lead/updateItem', {
          id: this.selectedItem.id,
          name: this.selectedItem.name,
          archived: true,
        })
        .then(() => {
          this.$router.push('/app/lead');
          // this.$store.dispatch('lead/getItem', this.selectedItem.id);
        });
    },
    async reopenLead (stage) {
      return this.$store
        .dispatch('lead/updateItem', {
          id: this.selectedItem.id,
          name: this.selectedItem.name,
          archived: false,
        })
        .then(() => this.$store.dispatch('lead/getItem', this.selectedItem.id));
    },
    async deleteLead () {
      return this.$store
        .dispatch('lead/deleteItem', {
          id: this.selectedItem.id,
        })
        .then(() => this.$router.push('/app/lead'));
    },

    hideItemEditForm () {
      this.showEditForm = false;
    },

    showItemEditForm () {
      this.showEditForm = true;
    },

    closeItemEditForm () {
      this.$store.dispatch('lead/getItem', this.selectedItem.id);
      this.showEditForm = false;
    },

    formatDate (date) {
      return dayjs(date).format('DD.MM.YYYY à HH:mm');
    },

    changeLeadName () {
      this.$store
        .dispatch('lead/updateItem', {
          id: this.selectedItem.id,
          name: this.selectedItem.name,
        })
        .then(() => {
          this.$store.dispatch('lead/getItem', this.selectedItem.id);
        });
    },

    saveLead () {
      if (typeof this.selectedItem?.dealValue === 'string') {
        this.selectedItem.dealValue = parseFloat(this.selectedItem.dealValue);
      }
      return this.$store.dispatch('lead/updateItem', this.selectedItem);
    },

    contactItem (item) {
      this.$store.dispatch('email/setShowEmailComposerModal', {
        show: true,
        recipientAddress: item && item.email,
        subject: item.Message ? `Re: ${item.Message.subject}` : '',
        body: '',
        recipientObject: item,
      });
    },

    getLeadTitle () {
      if (!this.selectedItem) {
        return '-';
      }
      if (this.selectedItem.name) {
        return this.selectedItem.name;
      }
      if (this.selectedItem.title) {
        return this.selectedItem.title;
      }
      if (this.selectedItem.company) {
        return this.selectedItem.company;
      }

      if (this.selectedItem.email) {
        return this.selectedItem.email;
      }

      if (this.selectedItem.id) {
        return this.selectedItem.id;
      }
    },

    openEmailModal () {
      this.$store.dispatch('email/setShowEmailComposerModal', {
        show: true,
        recipientAddress: this.selectedItem && this.selectedItem.email,
        recipientObject: this.selectedItem,
      });
    },

    createRequest (lead) {
      const clientId = lead.clientId || (this.client ? this.client.id : null);
      return this.$store
        .dispatch('request/createRequest', {
          clientId,
          title: lead.name,
          description: lead.description,
          mailboxId: lead.mailboxId,
          messageId: lead.id,
          externalId: lead.externalId,
          source: lead.source,
          affectedToUserId: lead.affectedToUserId,
        })
        .then((request) => {
          this.$awNotify({ type: 'success', title: 'La nouvelle demande à bien été créée !' });
          this.$store.dispatch('lead/updateItem', {
            id: this.selectedItem.id,
            requestId: request.id,
            name: this.selectedItem.name,
          });
          this.selectedItem.requestId = request.id;
        })
        .catch((err) => this.$awNotify({ type: 'danger', title: err.message }));
    },

    createClientAndRequest (lead) {
      this.createClient(lead).then(() => this.createRequest(lead));
    },

    createClient (lead) {
      let from = null;
      let fromEmail = null;
      const contacts = lead && lead.Contacts;
      // TODO select the right email from which to create the client
      if (lead && lead.Message && lead.Message.length === 1) {
        from = lead.Message[0].parsedContent.from;
        fromEmail = lead.Message[0].parsedContent.fromEmail;
      }
      return this.$store
        .dispatch('client/createClient', {
          name: from || lead.name,
          email: fromEmail ? fromEmail.toLowerCase() : '',
          company: from ? from.toLowerCase() : '',
        })
        .then((client) => {
          this.$awNotify({ type: 'success', title: 'Le nouveau client à bien été créé !' });
          this.$store.dispatch('lead/updateItem', {
            id: this.selectedItem.id,
            clientId: client.id,
            name: this.selectedItem.name,
          });
          if (contacts && contacts.length > 1) {
            contacts.map((contact) =>
              this.$store.dispatch('contact/updateItem', {
                id: contact.id,
                clientId: client.id,
                firstName: contact.firstName,
              })
            );
          }
          this.selectedItem.clientId = client.id;
        })
        .catch((err) => this.$awNotify({ type: 'danger', title: err.message }));
    },

    goToRequestDetail (data) {
      this.$router.push(`/app/request/${this.selectedItem.requestId}`);
    },
    goToClientDetail (data) {
      this.$router.push(`/app/client/${this.selectedItem.clientId}`);
    },

    async enrich (model, modelId) {
      const result = await this.$store.dispatch('data/enrich', { model, id: modelId });
      if (result && result[0]) {
        this.$awNotify({
          title: this.$t('common.messages.success'),
          message: this.$t('common.messages.itemEnriched'),
          type: 'success',
        });
        setTimeout(() => {
          // if after a few seconds the linkedin is still not set, we reload the item
          if (!this.selectedItem.linkedin) {
            this.loadItem();
          }
        }, 2000);
      }
    },

    checkForDescriptionOpening () {
      this.toggleDescription = this.selectedItem?.description && this.selectedItem.description.length < 800;
    },

    formatGeoCoordinate (latOrLng) {
      if (latOrLng) {
        return typeof latOrLng === 'string' ? parseFloat(latOrLng).toFixed(8) : latOrLng;
      }
      return latOrLng;
    },
  },
  watch: {
    '$route.params.id': {
      handler () {
        this.loadItem(this.$route.params.id);
      },
    },
  },
};
</script>

<style lang="scss">
.reverse-message {
  text-align: right;
  flex-direction: row-reverse;
}

.task-completed {
  *,
  span {
    text-decoration: line-through;
    color: #999;
  }
}

.comment-section .list-group,
.task-section .list-group {
  img {
    width: 20px;
    height: 20px;
  }

  .comment-content {
    white-space: break-spaces;
  }
}

.vdatetime.form-control {
  .vdatetime-input {
    border: none;
    width: 100%;
  }
}
</style>
