<template>
  <div>
    <px-card
      v-if="model && modelId"
      :title="`Documents [${documents ? documents.length : 0}]`"
      :actions="false"
      class="task-widget task-section"
    >
      <template #headerCustom>
        <div class="" style="position: absolute; top: 10px; right: 10px">
          <button class="btn bg-transparent text-info p-1" @click.prevent="promptUrl" data-tooltip="Url">
            <feather type="link" size="1rem"></feather>
          </button>
          <button
            class="btn bg-transparent text-primary p-1"
            @click.prevent="$refs.fileInput.click()"
            data-tooltip="Upload"
          >
            <feather type="file-plus" size="1rem"></feather>
          </button>
        </div>
        <hr class="mb-0" />
      </template>
      <div slot="with-padding">
        <div class="todo">
          <div class="todo-list-wrapper">
            <div class="todo-list-container">
              <div class="todo-list-body">
                <ul id="todo-list">
                  <li v-for="(document, index) in documents" :key="index" class="task">
                    <div class="task-container">
                      <a :href="document.url" class="btn btn-icon p-0" title="Mark Complete" target="_blank">
                        <h4 class="task-label">
                          <template v-if="document.mimeType">
                            <img
                              v-if="document.mimeType.startsWith('image')"
                              class="img-20 pull-left mr-2"
                              :alt="document.CreatedBy && document.CreatedBy.firstName"
                              :src="document.CreatedBy && document.url"
                            />
                            <span v-else class="img-40">
                              <i
                                class="fa mr-1"
                                :class="{
                                  'fa-file-pdf-o text-danger': document.mimeType === 'application/pdf',
                                  'fa-file-word-o': document.mimeType === 'application/word',
                                  'fa-file-powerpoint': document.mimeType === 'application/powerpoint',
                                  'fa-file-zip': document.mimeType === 'application/zip',
                                  'fa-file-excel-o text-success':
                                    document.mimeType === 'application/excel' || document.mimeType.includes('sheet'),
                                  'fa-file-audio-o text-info':
                                    document.mimeType.includes('audio') || document.mimeType.includes('mp3'),
                                  'fa-file-video-o text-warning':
                                    document.mimeType.includes('video') || document.mimeType.includes('mp4'),
                                  'fa-file-image-o': document.mimeType.includes('image'),
                                }"
                              ></i>
                            </span>
                          </template>
                          <span v-else class="img-40">
                            <i class="fa mr-1 fa-file-o"></i>
                          </span>
                          <small>{{ document.fileName }}</small>
                        </h4>
                      </a>
                      <span class="task-action-btn">
                        <span
                          v-if="canDisplayDocument(document)"
                          @click.prevent.stop="openDocument(document)"
                          class="btn btn-icon p-0"
                          title="Delete Task"
                        >
                          <feather class="text-info" type="eye" size="15" />
                        </span>
                        <span @click="deleteDocument(document)" class="btn btn-icon p-0" title="Delete Task">
                          <feather class="text-secondary" type="trash-2" size="15" />
                        </span>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="todo-list-footer">
                <hr />
                <div
                  class="new-task-wrapper"
                  :class="{ visible: !disabled }"
                  @dragenter="fileZoneHover = true"
                  @dragleave="fileZoneHover = false"
                  @dragend="fileZoneHover = false"
                  @drop="fileZoneHover = false"
                >
                  <div class="base64-upload pointer" :class="{ 'bg-primary': fileZoneHover }">
                    <p style="min-height: 70px; font-size: 12px; text-align: center">
                      {{ fileName }} <i class="fa fa-upload fa-2x" v-if="!fileName"></i>
                    </p>
                    <input
                      class="pointer dropzone"
                      multiple
                      type="file"
                      ref="fileInput"
                      :disabled="disabled"
                      :accept="'*'"
                      @change="onFileInputChange"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </px-card>

    <b-modal id="document-modal" :title="openedDocument.fileName" size="xl" hide-footer ref="documentModal">
      <div
        style="background: transparent; width: 100%; text-align: center; height: 100%"
        v-if="openedDocument && openedDocument.url"
      >
        <iframe
          :src="openedDocument.url"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="100%"
          style="background: transparent; height: 100%"
          :style="{
            'min-height': openedDocument.mimeType == 'application/pdf' ? '85vh' : '0',
          }"
        ></iframe>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import marked from 'marked';
import swal from 'sweetalert2/dist/sweetalert2';
import { BModal } from 'bootstrap-vue';
import rolesMixin from '@/mixins/rolesMixin';

export default {
  name: 'DocumentsWidget',
  components: {
    BModal,
  },
  props: {
    model: String,
    modelId: [String, Number],
    disabled: Boolean,
  },
  mixins: [rolesMixin],
  data() {
    return {
      isSending: false,
      showEditForm: false,
      markAllAsRead: true,
      isAddingTask: false,
      documents: [],
      fileToUpload: null,
      file: null,
      imageStyle: Object,
      type: '',
      placeholder: 'Ajouter un document par glissez déposer un document...',
      accept: '',
      fileName: '',
      openedDocument: {},
      fileZoneHover: false,
    };
  },
  mounted() {
    this.getDocuments();
    this.documentUser = this.user.id;
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    apiRoot() {
      return `/api/${this.model}/${this.modelId}/document`;
    },
  },
  methods: {
    marked,
    getImgUrl(path) {
      // eslint-disable-next-line
      return require(`@/assets/images/${path}`);
    },

    getDocuments() {
      this.$http.get(`${this.apiRoot}?perPage=50`).then(({ data }) => {
        this.documents = data;
      });
    },

    createDocumentFromUrl(url) {
      const fileName = url.split('/').pop();
      this.$http
        .post(`${this.apiRoot}`, {
          url,
          fileName,
          isExternal: true,
        })
        .then(() => {
          this.getDocuments();
        });
    },
    deleteDocument(document) {
      if (document && document.id) {
        this.$awConfirm('Etes vous sur ?').then(
          (confirmation) =>
            confirmation &&
            this.$http.delete(`${this.apiRoot}/${document.id}`).then(() => {
              this.documentTitle = '';
              this.getDocuments();
            })
        );
      }
    },

    onFileInputChange(event) {
      if (event.target.files && event.target.files.length > 0) {
        const promises = Object.values(event.target.files).map((file) => {
          const formData = new FormData();
          formData.append('file', file);

          return this.$http.post(this.apiRoot, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        });

        Promise.all(promises)
          .then(() => {
            this.getDocuments();
          })
          .catch((err) => {
            console.warn('FAILURE!!', err);
          });
      }
    },

    onBase64InputChange(event) {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', (e) => {
          this.src = e.target.result;
          const [, base64] = this.src.split(',');
          this.fileName = file.name;
          this.fileToUpload = file;
          this.$emit('change', {
            size: file.size,
            type: file.type,
            name: file.name,
            base64,
          });
        });
        reader.readAsDataURL(file);
      }
    },

    openDocument(document) {
      this.openedDocument = document;
      if (this.$refs.documentModal) {
        this.$refs.documentModal.show('documentModal');
      }
      this.$forceUpdate();
    },

    canDisplayDocument(document) {
      return (
        document &&
        document.mimeType &&
        (document.mimeType === 'application/pdf' || document.mimeType.startsWith('image'))
      );
    },

    promptUrl() {
      swal
        .fire({
          reverseButtons: true,
          input: 'url',
          title: this.$t('common.labels.enterFileUrl'),
          confirmButtonText: this.$t('common.buttons.submit'),
          cancelButtonText: this.$t('common.buttons.cancel'),
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed && result.value) {
            this.createDocumentFromUrl(result.value);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.task-widget {
  .card {
    .card-header {
      padding: 15px;
      h5 {
        font-size: 1em;
        font-weight: 600;
        color: var(--primary);
      }
    }
  }
}
.reverse-message {
  text-align: right;
  flex-direction: row-reverse;
}

.task-todo {
  color: var(--primary);
  span,
  div > h4,
  .task-label {
    color: var(--primary) !important;
  }
}

.task-completed {
  *,
  span {
    text-decoration: line-through;
    color: #999;
  }
}

.comment-section .list-group,
.task-section .list-group {
  img {
    width: 20px;
    height: 20px;
  }

  .comment-content {
    white-space: break-spaces;
    margin-bottom: 1px;
  }
}

.vdatetime.form-control {
  .vdatetime-input {
    border: none;
    width: 100%;
  }
}
</style>

<style scoped>
.base64-upload {
  position: relative;
}

.base64-upload:hover p {
  border: 1px solid var(--primary);
}
.base64-upload-hover p {
  border: 1px solid var(--primary);
}
.base64-upload p {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: left;
  padding: 5px;
}
img {
  width: 100%;
  height: 100%;
}
input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}
.dropzone:hover {
  background-color: #f5f5f5;
}
</style>
