<template>
  <div>
    <Breadcrumbs main="Dashboard" :title="$t('app.sidebar.history')" class="">
      <template #left> </template>
    </Breadcrumbs>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row second-chart-list third-news-update">
        <div class="col-xl-12 news box-col-12">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 id="#" class="m-0">
                  {{ $t('app.dashboard.latest-comments') }}
                  <small>
                    {{ `(${totalCommentCount})` }}
                    <i class="fa fa-circle-o-notch fa-spin" v-if="isLoading"></i>
                  </small>
                </h5>
                <div class="d-inline-flex justify-content-around">
                  <div class="card-header-right-icon pl-4">
                    <MemberSelector v-model="userId"></MemberSelector>
                  </div>
                  <div class="card-header-right-icon pl-4">
                    <date-range-picker
                      class="form-group vgt-date-range pl-2"
                      :placeholder="$t('common.field.start')"
                      @update="getComments()"
                      v-model="dateRange"
                      :opens="'right'"
                      :singleDatePicker="'range'"
                      :auto-apply="true"
                      :timePicker="false"
                      :ranges="dateRanges"
                      :locale-data="{ format: 'dd-mm-yyyy' }"
                    >
                    </date-range-picker>
                    <button
                      v-if="showClearDatesButton"
                      @click="clearDateRangePicker()"
                      :disabled="!dateRange.startDate && !dateRange.endDate"
                      class="btn btn-primary btn-sm mx-2 py-0 px-2"
                      id="clear-date-range-picker"
                    >
                      {{ $t('app.dashboard.clear-date-filter-button') }}
                    </button>
                  </div>
                  <div class="card-header-right-icon pl-4">
                    <select name="" v-model="currentEventType" class="form-control" id="">
                      <!-- @change="setCurrentEventType" -->
                      <option value="" disabled>{{ $t('app.dashboard.event') }}</option>
                      <option value="--">--</option>
                      <option :key="eventType.code" :value="eventType.code" v-for="eventType in eventTypeList">
                        {{ eventType.code }}
                      </option>
                    </select>
                  </div>
                  <div class="card-header-right-icon pl-4 mw-30">
                    <select name="" v-model="currentModelType" class="form-control" id="">
                      <!-- @change="setCurrentEventType" -->
                      <option value="" disabled>{{ $t('app.dashboard.category') }}</option>
                      <option value="--">--</option>
                      <option
                        :key="commentModelType.model"
                        :value="commentModelType.model"
                        v-for="commentModelType in myCommentModelTypeList"
                      >
                        {{ $t(`app.models.${commentModelType.model}`) }}
                      </option>
                    </select>
                  </div>
                  <div v-if="checkIfModelIsSelectedAndHasItems" class="card-header-right-icon pl-4">
                    <select name="" v-model="currentModelItem" class="form-control">
                      <!-- @change="setCurrentEventType" -->
                      <option value="" disabled>Item</option>
                      <option value="--">--</option>
                      <option :key="modelItem.id" :value="modelItem.id" v-for="modelItem in modelItemList">
                        {{ modelItem.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-inline-block p-2" v-if="stats && stats.byType">
              <span :key="stat.eventTypeCode" class="badge badge-info" v-for="stat in stats.byType"
                >{{ stat.eventTypeCode || '[UNSET]' }} : {{ stat.count }}</span
              >
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="myComments"
                :perRow="1"
                :useGridSystem="true"
                :showHeader="false"
                :perPage="commentPerPage"
              >
                <template v-if="myComments && myComments.length > 0" v-slot:list-item="{ item }">
                  <div class="news-update w-100 py-2 mb-3">
                    <router-link
                      v-if="item.ModelRelations"
                      :to="`/app/${item.ModelRelations[0].model}/${item.ModelRelations[0].modelId}`"
                    >
                      <div>
                        <!-- {{ item.content }} -->
                        <small v-if="item.eventTypeCode" class="muted badge badge-primary pull-right f-w-300">{{
                          displayRecruitmentStageLabel(item.eventTypeCode) || item.eventTypeCode
                        }}</small>
                        <div class="comment-content" v-if="item.content" v-html="marked(item.content)"></div>
                        <div class="d-flex flex-wrap" v-if="item.ModelRelations">
                          <div v-for="model of item.ModelRelations" :key="model.model + model.modelId">
                            <div v-if="hasModelData(model) && !isUserModelRelation(model)">
                              <router-link
                                :to="`/app/${
                                  model.model === 'recruitmentProcess' ? 'recruitment-process' : model.model
                                }/${model.modelId}`"
                              >
                                <!-- <div
                                  v-if="model.model === 'recruitmentProcess'"
                                  class="badge badge-info fw-500 mr-2 mb-2 p-2"
                                >
                                  {{ $t('app.dashboard.go-to-recruitment-process') }}
                                </div> -->
                                <div class="text-muted btn-sm fw-300 mr-2 mb-2 p-1">
                                  <i class="fa fa-angle-double-right text-info"></i>
                                  {{ $t(`app.models.${model.model}`) }} :
                                  {{
                                    model.modelData.name ||
                                    model.modelData.title ||
                                    model.modelData.username ||
                                    (model.modelData.firstName &&
                                      model.modelData.firstName + ' ' + model.modelData.lastName) ||
                                    model.modelId
                                  }}
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-wrap" v-if="item.AllModelRelations">
                          <div v-for="model of item.AllModelRelations" :key="model.model + model.modelId">
                            <div v-if="hasModelData(model) && !isUserModelRelation(model)">
                              <router-link
                                :to="`/app/${
                                  model.model === 'recruitmentProcess' ? 'recruitment-process' : model.model
                                }/${model.modelId}`"
                              >
                                <div class="text-muted btn-sm fw-300 mr-2 mb-2 p-1 ft-12">
                                  <i class="fa fa-angle-double-right text-info"></i>
                                  {{ $t(`app.models.${model.model}`) }} :
                                  {{
                                    model.modelData.name ||
                                    model.modelData.title ||
                                    model.modelData.username ||
                                    (model.modelData.firstName &&
                                      model.modelData.firstName + ' ' + model.modelData.lastName) ||
                                    model.modelId
                                  }}
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="m-0 mt-2 text-left font-primary" style="font-size: 12px; line-height: 0.5">
                        <user-avatar v-if="item.CreatedBy" :user="item.CreatedBy" size="20px" />
                        <small class="m-0 text-muted">{{ item.createdOn | formatDate }}</small>
                      </p>
                    </router-link>
                  </div>
                </template>
                <template v-else v-slot:list-empty-state></template>
              </awesome-list>
            </div>
            <div aria-label="Page navigation example text-center w-100" class="d-flex justify-content-center">
              <ul class="pagination">
                <li v-if="!isFirstCommentPageDisplayed" class="page-item">
                  <a class="page-link" @click="goToPage(commentPage - 1)" href="#">{{
                    $t('common.buttons.previous')
                  }}</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">{{ commentPage }}</a>
                </li>
                <li v-if="hasNextCommentPageResults" class="page-item">
                  <a class="page-link" href="#" @click="goToPage(commentPage + 1)">{{ $t('common.buttons.next') }}</a>
                </li>
              </ul>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- <v-tour class="bg-light text-dark" name="firstVisitTour" :steps="steps" :callbacks="tourCallbacks">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            class="bg-light text-dark"
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template>
              <div slot="actions" class="v-step__actions">
                <button
                  v-if="tour.currentStep !== 3"
                  @click="() => finishTour(tour, true)"
                  class="btn btn-link text-secondary"
                >
                  Passer la visite
                </button>
                <button @click="() => finishTour(tour)" class="btn btn-primary">J'ai compris</button>
              </div>
            </template>
          </v-step>
        </transition>
      </template></v-tour
    > -->
  </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import qs from 'qs';
import { mapState } from 'vuex';
import marked from 'marked';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import MemberSelector from '../../components/user/MemberSelector.vue';
// import NoResult from '../../components/basic/NoResult.vue';
import recruitmentStagesMixin from '../../mixins/recruitmentStagesMixin';
import defaultImage from '../../assets/images/user/user.png';

const primary = localStorage.getItem('primary_color') || '#7366ff';
const secondary = localStorage.getItem('secondary_color') || '#f73164';

export default {
  name: 'HistoryDashboard',
  components: {
    MemberSelector,
    DateRangePicker,
    // NoResult,
  },
  mixins: [recruitmentStagesMixin],
  computed: {
    ...mapState('request', ['dashboardRequests']),
    ...mapState('client', ['recentClients']),
    ...mapState('user', ['user', 'organisation']),
    ...mapState('statistics', ['models', 'commentsByEvent', 'recruitmentProcessesByStage']),
    generalStatistics () {
      let result;
      if (this.$store.state.statistics) {
        result = this.$store.state.statistics.models;
        if (!this.organisation.featuresClientConversations) {
          result = result.filter((m) => m.model !== 'request');
        }
      }
      return result;
    },
    chartData () {
      let labels = [];
      let series = [];
      if (this.recruitmentProcessesByStage && this.recruitmentProcessesByStage.length > 0) {
        labels = this.recruitmentProcessesByStage.map((stage) => `${stage.code} (${stage.count})`);
        series = this.recruitmentProcessesByStage.map((stage) => ({
          meta: stage.code,
          value: stage.count,
        }));
        return {
          labels,
          series: [series],
          showChart: labels.length > 0 && series.length > 0,
        };
      }
      return { labels, series: [series] };
    },
    blockedRecruitmentProcesses () {
      let blockedRecruitmentProcesses = [];
      if (this.$store.state.recruitmentProcess.recruitmentProcesses) {
        blockedRecruitmentProcesses = this.$store.state.recruitmentProcess.recruitmentProcesses.filter(
          (rp) =>
            !(rp.stage === 'hired' || rp.stage === 'rejected') &&
            dayjs(rp.stageLastUpdatedOn) < dayjs(this.todayDate).subtract(7, 'days')
        );
      }
      return blockedRecruitmentProcesses;
    },
    hasNextCommentPageResults () {
      return !(this.commentPage > Math.floor(this.totalCommentCount / this.commentPerPage));
    },
    isFirstCommentPageDisplayed () {
      return this.commentPage === 1 || this.commentPage === 0;
    },
    checkIfModelIsSelectedAndHasItems () {
      if (this.currentModelType !== '' && this.currentModelType !== '--' && this.modelItemList.length > 0) {
        return true;
      }
      return false;
    },
    showClearDatesButton () {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        return true;
      }
      return false;
    },
    dateRanges () {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      const yesterdayStart = new Date();
      yesterdayStart.setDate(today.getDate() - 1);
      yesterdayStart.setHours(0, 0, 0, 0);
      const yesterdayEnd = new Date();
      yesterdayEnd.setDate(today.getDate() - 1);
      yesterdayEnd.setHours(23, 59, 59, 999);
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);
      return {
        Today: [today, todayEnd],
        Yesterday: [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999),
        ],
      };
    },

    interviewApiUrl () {
      return `/api/interview?sort[createdOn]=desc&filters[date][$lte]=${this.todayDate}&filters[completed]=1`;
    },
  },
  data () {
    return {
      openPositionsCount: 0,
      newClients: [],
      primary,
      secondary,
      isTutorialOverlayDisplayed: false,
      isLoading: false,
      myComments: [],
      commentPage: 1,
      commentPerPage: 20,
      totalCommentCount: 0,
      eventTypeList: [],
      userId: null,
      showDateRangePicker: false,
      showFilterByDateButton: true,
      todayDate: new Date().toISOString().substr(0, 10),
      dateRange: {
        startDate: null,
        endDate: null,
      },
      myCommentModelTypeList: [
        { model: 'candidate', label: 'Candidate' },
        { model: 'client', label: 'Client' },
        { model: 'contact', label: 'Contact' },
        { model: 'request', label: 'Request' },
        { model: 'interview', label: 'Interview' },
        { model: 'recruitmentProcess', label: 'Recruitment' },
        { model: 'lead', label: 'Lead' },
        { model: 'position', label: 'Position' },
      ],
      chartistOptions: { plugins: [ChartistTooltip()], horizontalBars: false },
      modelItemList: [],
      currentEventType: '',
      currentModelType: '',
      currentModelItem: '',
      currentMember: '',
      stats: {},
    };
  },
  mounted () {
    this.$store.dispatch('statistics/getCommentsGroupedByEvent');
    this.getComments();
    this.getEventTypeList();
  },

  watch: {
    userId () {
      this.$store.dispatch('statistics/getCommentsGroupedByEvent', {
        createdBy: this.userId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      });
      this.getComments();
    },
    dateRange: {
      handler (newValue, oldValue) {
        this.getComments();
      },
      deep: true,
    },
    currentEventType () {
      if (this.currentEventType === '--') {
        this.currentEventType = '';
        return;
      }
      this.commentPage = 1;
      this.getComments();
    },
    currentModelType (newValue, oldValue) {
      this.currentModelItem = '';
      if (this.currentModelType === '--') {
        this.modelItemList = [];
        this.currentModelType = '';
        return;
      }

      this.commentPage = 1;
      this.getComments();

      if (this.currentModelType === '') {
        return;
      }
      this.getItemList();
    },

    currentModelItem () {
      if (this.currentModelItem === '--') {
        this.currentModelItem = '';
        return;
      }

      if (this.currentModelItem === '') {
        this.getComments();
        return;
      }
      this.commentPage = 1;
      this.getComments();
    },
  },
  methods: {
    hasModelData (item) {
      if (!item.model || !item.modelId || !item.modelData) {
        return false;
      }
      return true;
    },

    replaceImage (error) {
      error.target.src = defaultImage;
    },
    // getOpenPositionsCount() {
    //   return this.$http
    //     .get('/api/position', {
    //       params: {
    //         filter: {
    //           status: 'open'
    //         }
    //       },
    //       paramsSerializer(params) {
    //         return qs.stringify(params, {
    //           arrayFormat: 'repeat',
    //         });
    //       },
    //     });
    // },
    isUserModelRelation (item) {
      if (item.model === 'user') {
        return true;
      }
      return false;
    },
    marked (content) {
      const linkedContent = content.replace(/@\[(.+):(.+):(.*)\]/g, '<span class="text-info">$3</span>');
      return marked(linkedContent);
    },
    formatDate (date) {
      return dayjs(date).fromNow();
    },
    clearDateRangePicker () {
      this.showDateRangePicker = false;
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.getComments();
    },

    goToPage (page = 0) {
      if (page >= 0) {
        this.commentPage = page;
        this.getComments();
      }
    },

    formatData (modelData) {
      let formattedData = [];

      if (!Array.isArray(modelData) && !modelData.length > 0) {
        return formattedData;
      }
      // eslint-disable-next-line no-return-assign
      formattedData = modelData.map((item) => {
        if (item.model === 'user' || item.model === 'candidate') {
          return {
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
        }

        if (item.model === 'position' || item.model === 'request') {
          return {
            id: item.id,
            label: item.title,
          };
        }
        if (item.model === 'client') {
          return {
            id: item.id,
            label: item.name,
          };
        }

        if (item.model === 'recruitmentProcess') {
          return {
            id: item.id,
            label: item.id,
          };
        }
        return {
          id: item.id,
          label: item.id,
        };
      });

      return formattedData;
    },

    goToClientDetail (client) {
      this.$router.push(`/app/client/${client.id || client}`);
    },

    getComments () {
      const requestFilters = {
        includeModelData: true,
        startDate: this.dateRange.startDate && new Date(this.dateRange.startDate.setHours(0, 0, 0, 0)),
        endDate: this.dateRange.endDate && new Date(this.dateRange.endDate.setHours(23, 59, 0, 0)),
      };

      if (this.currentEventType !== '') {
        requestFilters.eventTypeCode = { $eq: this.currentEventType };
      } else {
        requestFilters.eventTypeCode = { $ne: 'history' };
      }

      if (this.currentModelType !== '') {
        requestFilters.model = this.currentModelType;
      }

      if (this.currentModelItem !== '') {
        requestFilters.modelId = this.currentModelItem;
      }
      if (this.userId !== '') {
        requestFilters.createdBy = this.userId;
      }
      this.isLoading = true;
      this.$http
        .get('/api/comment-stats', {
          params: {
            filters: requestFilters,
          },
          paramsSerializer (params) {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
            });
          },
        })
        .then(({ data }) => {
          this.stats = data.body;
        });
      return this.$http
        .get('/api/comment', {
          params: {
            page: this.commentPage > 1 ? this.commentPage : 0,
            perPage: this.commentPerPage,
            filters: requestFilters,
          },
          paramsSerializer (params) {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
            });
          },
        })
        .then(({ data }) => {
          if (!data.body) {
            return;
          }

          if (data.body && data.totalCount > 0) {
            this.myComments = data.body;
          } else {
            this.myComments = [];
          }

          this.totalCommentCount = data.totalCount;
        })
        .catch((err) => console.warn(err.message))
        .finally(() => {
          this.isLoading = false;
        });
    },

    getItemList () {
      this.$http
        .get(`/api/${this.currentModelType === '' ? 'all' : this.currentModelType}/all/comment/get-item-list`)
        .then(({ data }) => {
          let formattedItemList = [];

          formattedItemList = this.formatData(
            data.body.map((item) => {
              item.ModelData.model = item.model;
              return item.ModelData;
            })
          );
          this.modelItemList = formattedItemList;
          return data;
        })
        .catch((err) => console.warn(err));
    },

    getEventTypeList () {
      const p1 = this.$http.get(`/api/event-type?filters[organisationId]=${this.organisation.id}`);
      const p2 = this.$http.get(`api/recruitment-stage?filters[organisationId]=${this.organisation.id}`);
      Promise.all([p1, p2]).then(([response1, response2]) => {
        this.eventTypeList = uniqBy([...this.eventTypeList, ...response1.data.body, ...response2.data.body], 'code');
      });
    },

    isMobile () {
      return window.outerWidth < 992;
    },
  },
};
</script>

<style lang="scss">
#clear-date-range-picker {
  height: 30px;
}

.page-dashboard-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(78, 76, 93, 0.8);
}
.vue-daterange-picker {
  vertical-align: top;
}
.vue-daterange-picker .reportrange-text {
  height: calc(1.5em + 0.75rem - 5px);
}

.comment-content {
  padding-left: 10px;
}
</style>
