<template>
  <div>
    <Breadcrumbs main="Dashboard" :title="$t('app.taskboard.my-board')">
      <template #left>
        <MemberSelector v-if="isAdmin" v-model="userId" :currentUserId="user.id"></MemberSelector>
      </template>
    </Breadcrumbs>

    <div class="col-6 m-0 pb-3"></div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 col-xl-3 col-lg-6">
          <px-card class="bg-info static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center"><feather type="users" class="middle"></feather></div>
                <div class="media-body">
                  <span class="m-0">{{ $t('app.taskboard.my-tasks') }}</span>
                  <h4 class="mb-0 counter">{{ ($store.state.task.items && $store.state.task.items.length) || 0 }}</h4>
                  <feather type="check" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>

        <div class="col-sm-6 col-xl-3 col-lg-6">
          <px-card class="bg-secondary static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center"><feather type="box" class="middle"></feather></div>
                <div class="media-body">
                  <span class="m-0">{{ $t('app.taskboard.upcoming-interviews') }}</span>
                  <h4 class="mb-0 counter">
                    {{ ($store.state.interview.items && $store.state.interview.items.length) || 0 }}
                  </h4>
                  <feather type="box" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-sm-6 col-xl-3 col-lg-6">
          <px-card class="bg-success static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center">
                  <feather type="message-square" class="middle"></feather>
                </div>
                <div class="media-body">
                  <span class="m-0">{{ $t('app.taskboard.in-process-candidates') }}</span>
                  <h4 class="mb-0 counter">
                    {{
                      ($store.state.recruitmentProcess.recruitmentProcesses &&
                        $store.state.recruitmentProcess.recruitmentProcesses.length) ||
                      0
                    }}
                  </h4>
                  <feather type="message-square" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-sm-6 col-xl-3 col-lg-6">
          <px-card class="bg-danger static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center"><feather type="watch" class="middle"></feather></div>
                <div class="media-body">
                  <span class="m-0">{{ $t('app.taskboard.blocked-recruitments') }}</span>
                  <h4 class="mb-0 counter">
                    {{ (blockedRecruitmentProcesses && blockedRecruitmentProcesses.length) || 0 }}
                  </h4>
                  <feather type="watch" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
      <div class="row second-chart-list third-news-update">
        <div class="col-xl-6 xl-50 news box-col-6">
          <DashboardTaskWidget :title="$t('app.taskboard.my-tasks')" :per-page="10" :filters="filters">
          </DashboardTaskWidget>
        </div>
        <div class="col-xl-6 xl-50 news box-col-6">
          <px-card v-if="organisation.featuresClientConversations" :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.taskboard.my-conversations') }}</h5>
                <div class="card-header-right-icon d-none">
                  <select class="button btn btn-primary">
                    <option>{{ $t('stats.today') }}</option>
                    <option>{{ $t('stats.this_week') }}</option>
                    <option>{{ $t('stats.this_month') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="myRequests"
                :perRow="1"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template v-slot:list-item="{ item }">
                  <div class="news-update w-100 py-2">
                    <router-link :to="`/app/request/${item.id}`">
                      <small v-if="item.status" class="muted badge badge-primary f-w-300 pull-right">
                        {{ $t(`request.statuses.${item.status}`) }}</small
                      >
                      <p class="text-truncate pr-2">
                        {{ item.title }}
                      </p>
                      <small v-if="item.description" class="text-muted font-italic"
                        >{{ item.description ? striptags(item.description).substr(0, 100) : '' }}...
                      </small>
                    </router-link>
                  </div>
                </template>
              </awesome-list>
            </div>
            <div slot="footer">
              <div class="bottom-btn">
                <router-link to="/app/request">{{ $t('common.buttons.plus') }}</router-link>
              </div>
            </div>
          </px-card>
          <px-card v-if="organisation.featuresHumanResources" :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.taskboard.my-recruitments') }}</h5>
                <div class="card-header-right-icon d-none">
                  <select class="button btn btn-primary">
                    <option>{{ $t('stats.today') }}</option>
                    <option>{{ $t('stats.this_week') }}</option>
                    <option>{{ $t('stats.this_month') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :perRow="1"
                :useGridSystem="false"
                :showHeader="false"
                :apiUrl="`/api/recruitment-process`"
                :apiQueryParams="{ filters: { stageLastUpdatedBy: userId } }"
              >
                <template v-slot:list-item="{ item }">
                  <div class="news-update w-100 py-2 mb-3">
                    <router-link :to="`/app/recruitment-process/${item.id}`">
                      <p class="text-dark text-truncate">
                        {{ item.Candidate.firstName }} {{ item.Candidate.lastName }} ✨
                        {{ item.Position ? item.Position.title : '' }}
                        <small v-if="item.stage" class="muted badge badge-primary pull-right f-w-300 my-1">
                          {{ displayRecruitmentStageLabel(item.stage) || item.stage }}</small
                        >
                      </p>
                      <p
                        v-if="item.Client && item.Client.name"
                        class="m-0 text-left font-primary"
                        style="font-size: 12px; line-height: 0.5"
                      >
                        {{ item.Client.name }}
                      </p>
                    </router-link>
                  </div>
                </template>
              </awesome-list>
            </div>
            <div slot="footer">
              <div class="bottom-btn">
                <router-link to="/app/recruitment-process">{{ $t('common.buttons.plus') }}</router-link>
              </div>
            </div>
          </px-card>
          <px-card v-if="organisation.featuresHumanResources" :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.taskboard.my-upcoming-interviews') }}</h5>
                <div class="card-header-right-icon">
                  <select class="button btn btn-primary d-none" disabled>
                    <option>{{ $t('stats.this_week') }}</option>
                    <option>{{ $t('stats.today') }}</option>
                    <option>{{ $t('stats.this_month') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :perRow="1"
                :useGridSystem="false"
                :showHeader="false"
                :url="`/api/interview?${
                  userId ? 'filters[interviewerId]=' + userId : ''
                }&filters[date][$gte]=${todayDate}`"
              >
                <template v-slot:list-item="{ item }">
                  <div class="news-update w-100 pb-2">
                    <router-link :to="`/app/interview/${item.id}`">
                      <small class="muted badge badge-primary f-w-300 pull-right"> {{ item.date | formatDate }}</small>
                      <div class="text-dark">
                        {{ item.Candidate ? item.Candidate.firstName : '-' }}
                        {{ item.Candidate ? item.Candidate.lastName : '-' }} 🔗
                        {{ item.Position ? item.Position.title : '-' }}
                      </div>
                      <small class="text-muted text-primary">{{ item.Client ? item.Client.name : '-' }} </small>
                    </router-link>
                  </div>
                </template>
              </awesome-list>
            </div>
            <div slot="footer">
              <div class="bottom-btn">
                <router-link to="/app/interview">{{ $t('common.buttons.plus') }}</router-link>
              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import marked from 'marked';
import striptags from 'striptags';
import DashboardTaskWidget from '../../components/dashboard/DashboardTaskWidget.vue';
import MemberSelector from '../../components/user/MemberSelector.vue';
import recruitmentStagesMixin from '../../mixins/recruitmentStagesMixin';
import store from '../../store/index';

const primary = localStorage.getItem('primary_color') || '#7366ff';
const secondary = localStorage.getItem('secondary_color') || '#f73164';

export default {
  name: 'MyTaskboard',
  components: {
    DashboardTaskWidget,
    MemberSelector,
  },
  mixins: [recruitmentStagesMixin],
  computed: {
    ...mapState('user', ['user', 'organisation']),
    isAdmin () {
      if (
        this.user &&
        this.user.organisationRoles &&
        this.user.organisationRoles.find((r) => r.toLowerCase() === 'admin')
      ) {
        return true;
      }
      return false;
    },
    blockedRecruitmentProcesses () {
      let blockedRecruitmentProcesses = [];
      if (this.$store.state.recruitmentProcess.recruitmentProcesses) {
        blockedRecruitmentProcesses = this.$store.state.recruitmentProcess.recruitmentProcesses.filter(
          (rp) =>
            !(rp.stage === 'hired' || rp.stage === 'rejected') &&
            dayjs(rp.stageLastUpdatedOn) < dayjs(this.todayDate).subtract(7, 'days')
          // && (rp.stageLastUpdatedBy === this.userId)
        );
      }
      return blockedRecruitmentProcesses;
    },
  },

  watch: {
    userId () {
      this.loadData();
    },
  },
  data () {
    return {
      filters: {},
      myComments: [],
      myRequests: [],
      myRequestsWaiting: [],
      myRequestsWaitingForClient: [],
      now: new Date(),
      primary,
      secondary,
      todayDate: new Date().toISOString().substr(0, 10),
      totalRequests: 0,
      totalTasks: 0,
      totalWaitingForClientRequests: 0,
      totalWaitingRequests: 0,
      userId: store.state.user.user.id || null,
    };
  },
  mounted () {
    this.loadData();
  },

  methods: {
    striptags,
    marked (content) {
      const linkedContent = content.replace(
        /@\[(user|candidate|contact|client):(.+):(.*)\]/g,
        '<a href="/app/$1/$2">@$3</a>'
      );
      return marked(linkedContent);
    },
    loadData () {
      const { userId } = this;
      this.filters = { affectedTouserId: userId, status: { $in: ['todo', 'doing'] } };
      this.$store
        .dispatch('request/getDashboardRequests', {
          save: false,
          params: {
            perPage: 20,
            sort: { lastModifiedOn: 'desc' },
            filters: { affectedTouserId: userId, status: { $notIn: ['done', 'archived'] } },
          },
        })
        .then((data) => {
          const { body, totalCount } = data;
          this.myRequests = body;
          this.totalRequests = totalCount;
        });

      // this.$store
      //   .dispatch('request/getDashboardRequests', {
      //     save: false,
      //     params: {
      //       perPage: 20,
      //       sort: { lastModifiedOn: 'desc' },
      //       filters: { affectedTouserId: userId, status: ['waiting-for-client'] },
      //     },
      //   })
      //   .then((data) => {
      //     const { body, totalCount } = data;
      //     this.myRequestsWaitingForClient = body;
      //     this.totalWaitingForClientRequests = totalCount;
      //   });

      // this.$store
      //   .dispatch('request/getDashboardRequests', {
      //     save: false,
      //     params: {
      //       perPage: 1,
      //       sort: { lastModifiedOn: 'desc' },
      //       filters: { affectedTouserId: userId, status: ['waiting'] },
      //     },
      //   })
      //   .then((data) => {
      //     const { body, totalCount } = data;
      //     this.myRequestsWaiting = body;
      //     this.totalWaitingRequests = totalCount;
      //   });

      this.$store.dispatch('recruitmentProcess/getRecruitmentProcesses', {
        filters: { stageLastUpdatedBy: userId, stage: { $notIn: ['hired', 'rejected'] } },
      });

      this.$store.dispatch('interview/getItems', {
        save: true,
        params: { filters: { date: { $gte: this.todayDate }, interviewerId: this.userId } },
      });

      this.getComments();
    },

    getComments () {
      this.$http
        .get(`/api/comment?${this.userId ? `filters[createdBy]=${this.userId}` : ''}`)
        .then(({ data }) => {
          // eslint-disable-next-line arrow-body-style
          this.myComments = data.body;
        })
        .catch((err) => console.warn(err.message));
    },

    goToClientDetail (client) {
      this.$router.push(`/app/client/${client.id || client}`);
    },
    onTaskWidgetChange (data) {
      const { totalCount } = data;
      this.totalTasks = totalCount;
    },
    hasModelData (item) {
      if (!item.model || !item.modelId) {
        return false;
      }
      return true;
    },
  },
};
</script>
