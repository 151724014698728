<template>
  <div>
    <div
      class="page-wrapper compact-wrapper"
      :class="{
        [`page-${$route.name}`]: true,
      }"
    >
      <div id="site-header" class="page-header dashboard-tour-show-step-1" :class="{ close_icon: sidebarIsOpen }">
        <Header ref="header" @clicked="toggleSidebar" />
      </div>
      <div class="page-body-wrapper sidebar-icon">
        <div
          class="sidebar-wrapper"
          :class="[{ close_icon: sidebarIsOpen }, layout.settings.sidebar_backround]"
          :sidebar-layout="layout.settings.sidebar_setting"
        >
          <Sidebar @clicked="toggleSidebar" />
        </div>
        <div class="page-body">
          <transition name="fadeIn" enter-active-class="animated fadeIn">
            <router-view class="view"></router-view>
          </transition>
        </div>
        <Footer />
      </div>
    </div>
    <v-tour name="firstVisitTour" :steps="computedSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>
    <div v-if="isTutorialOverlayDisplayed" class="tour-overlay" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import swal from 'sweetalert2';
import config from '../config';
import Header from './header.vue';
import Sidebar from './sidebar.vue';
import Footer from './footer.vue';
// import Customizer from './customizer.vue';

export default {
  name: 'mainpage',
  // props:['sidebar_toggle_var'],
  components: {
    Header,
    Sidebar,
    Footer,
    // Customizer,
  },
  computed: {
    ...mapState('client', ['recentClients']),
    ...mapState('user', ['user', 'organisation']),
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      sidebarIsOpen: (state) => state.menu.sidebarIsOpen,
    }),
    // visit steps
    computedSteps() {
      return this.steps.filter((step) => {
        if (step?.features && !this.organisation?.[step.features]) {
          return false;
        }
        return true;
      });
    },
    isMobile() {
      return window.outerWidth < 992;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    // this.handleResize();
    // this.resized = this.sidebar_toggle_var;
    this.$store.dispatch('layout/set');
    this.$awEventBus.$on('api-network-error', this.onUserNotConnected);
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Meta') {
        this.ctrlPressed = true;
        if (this.ctrlLastHandle) {
          clearTimeout(this.ctrlLastHandle);
        }
        this.ctrlLastHandle = setTimeout(() => {
          this.ctrlPressed = false;
        }, 15000);
      }
    });
    window.addEventListener('blur', () => {
      this.ctrlPressed = false;
    });
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Meta') {
        this.ctrlPressed = false;

        if (this.ctrlLastHandle) {
          clearTimeout(this.ctrlLastHandle);
        }
      }
    });
  },
  async mounted() {
    // for some reason _.has is not working
    if (_.get(this.$store, 'state.user.user.id') === undefined) {
      this.onUserNotConnected();
    }
    // start tour of the app.
    if (this.user && !this.user.hasCompletedTutorial && !this.isMobile && !config.modeAts) {
      const result = await this.$awConfirm({
        type: 'success',
        title: this.$t('app.welcome-tour.introTitle'),
        html: this.$t('app.welcome-tour.introText'),
      });
      if (result) {
        this.sidebar_toggle_var = false;
        this.$tours.firstVisitTour.start();
      } else {
        this.finishTour();
      }
    }
    if (!config.modeAts) {
      this.startCrisp();
    }
  },

  beforeRouteUpdate(to, from, next) {
    // if (this.ctrlPressed) {
    //   window.open(to.fullPath, '_blank');
    //   return next(false);
    // }
    next();
  },

  beforeDestroy() {
    this.$awEventBus.$off('api-network-error', this.onUserNotConnected);
  },
  watch: {
    $route() {
      this.$store.commit('menu/closeSidebar');
      document.body.classList.remove('modal-open');
    },
  },
  data() {
    return {
      sidebar_toggle_var: true,
      horizontal_Sidebar: true,
      resized: false,
      tourCallbacks: {
        onStart: this.onTourStarted,
        onFinish: this.onTourFinished,
        onSkip: this.onTourFinished,
        onNextStep: this.onNextTourStep,
      },
      currentTourStep: -1,
      isTutorialOverlayDisplayed: false,
      tourOptions: {
        labels: {
          buttonSkip: this.$t('app.welcome-tour.skip-tour'),
          buttonPrevious: this.$t('app.welcome-tour.button-previous'),
          buttonNext: this.$t('app.welcome-tour.button-next'),
          buttonStop: this.$t('app.welcome-tour.button-stop'),
        },
      },
      steps: [
        {
          target: this.isMobile ? '.nav-dock' : '.dashboard-tour-show-step-0',
          header: {
            title: this.$t('app.welcome-tour.main-menu'),
          },
          content: this.$t('app.welcome-tour.main-menu-content'),
          params: {
            placement: this.isMobile ? 'top' : 'right',
          },
        },
        {
          target: '.horizontal-menu',
          header: {
            title: this.$t('app.welcome-tour.main-header'),
          },
          content: this.$t('app.welcome-tour.main-header-content'),
          params: {
            placement: 'bottom',
          },
          features: 'featuresClientConversations',
        },
        {
          target: '.search-box',
          header: {
            title: this.$t('app.welcome-tour.search'),
          },
          content: this.$t('app.welcome-tour.search-content'),
          params: {
            placement: 'bottom',
          },
          before() {
            document.querySelector('.search-box').click();
          },
        },
        {
          target: '#breadcrumb-dopdown-button',
          header: {
            title: this.$t('app.welcome-tour.display-setting'),
          },
          content: this.$t('app.welcome-tour.display-setting-content'),
          params: {
            placement: this.isMobile ? 'top' : 'bottom',
          },
          before() {
            this.isTutorialOverlayDisplayed = false;
            document.querySelector('.close-search').click(); // closes the search
          },
        },
        {
          target: '.sidebar-menu-item-support',
          header: {
            title: this.$t('app.welcome-tour.dashboard'),
          },
          params: {
            placement: this.isMobile ? 'top' : 'right',
          },
          content: this.$t('app.welcome-tour.dashboard-content'),
          features: 'featuresClientConversations',
        },
        {
          target: '.sidebar-menu-item-mytasks',
          header: {
            title: this.$t('app.welcome-tour.tasks'),
          },
          content: this.$t('app.welcome-tour.tasks-content'),
          params: {
            placement: 'bottom',
          },
          before() {
            document.querySelector('.sidebar-menu-item-mytasks a').click();
          },
        },
        {
          target: '.sidebar-menu-item-clients',
          header: {
            title: this.$t('app.welcome-tour.clients'),
          },
          content: this.$t('app.welcome-tour.clients-content'),
          params: {
            placement: 'bottom',
          },
          before() {
            document.querySelector('.sidebar-menu-item-clients a').click();
          },
        },
        {
          target: '.sidebar-menu-item-crm',
          header: {
            title: this.$t('app.welcome-tour.leads'),
          },
          content: this.$t('app.welcome-tour.leads-content'),
          params: {
            placement: 'bottom',
          },
          before() {
            document.querySelector('.sidebar-menu-item-crm a').click();
          },
          features: 'featuresCrm',
        },
        {
          target: '.sidebar-menu-item-support',
          header: {
            title: this.$t('app.welcome-tour.requests'),
          },
          content: this.$t('app.welcome-tour.requests-content'),
          params: {
            placement: 'bottom',
          },
          before() {
            document.querySelector('.sidebar-menu-item-support a').click();
          },
          features: 'featuresClientConversations',
        },
        {
          target: '.sidebar-menu-item-human-resources',
          header: {
            title: this.$t('app.welcome-tour.hr'),
          },
          content: this.$t('app.welcome-tour.hr-content'),
          params: {
            placement: 'bottom',
          },
          features: 'featuresHumanResources',
          before() {
            document.querySelector('.sidebar-menu-item-human-resources a').click();
          },
        },
        {
          target: '.sidebar-menu-item-mailboxes',
          header: {
            title: this.$t('app.welcome-tour.mailboxes'),
          },
          content: this.$t('app.welcome-tour.mailboxes-content'),
          params: {
            placement: 'bottom',
          },
          features: 'featuresUnifiedInbox',
          before() {
            document.querySelector('.sidebar-menu-item-mailboxes a').click();
          },
        },
        {
          target: '.sidebar-menu-item-settings-settings',
          header: {
            title: this.$t('app.welcome-tour.settings'),
          },
          content: this.$t('app.welcome-tour.settings-content'),
          params: {
            placement: 'top',
          },
          async before() {
            const menuItem = document.querySelector('.sidebar-menu-item-settings-settings a');
            menuItem.click();
            const submenuItem = document.querySelector('.sidebar-submenu-item-settings-advanced a');
            submenuItem.click();
          },
        },
      ],
    };
  },
  methods: {
    toggleSidebar(value) {},
    handleResize() {
      this.$store.dispatch('menu/resizetoggle');
    },
    openSidebar() {
      this.$store.dispatch('menu/openSidebar');
    },
    onUserNotConnected() {
      swal.fire({
        title: 'user not connected',
        text: 'Please refresh your page',
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        stopKeydownPropagation: true,
        keydownListenerCapture: true,
        toast: false,
        // position: 'bottom-end',
      });
    },

    highlightTourArea(stepIndex) {
      for (let i = 0; i < this.steps.length; i++) {
        if (i !== stepIndex && document.querySelector(`.dashboard-tour-show-step-${i}`)) {
          document.querySelector(`.dashboard-tour-show-step-${i}`).style = 'z-index: 1000';
        }
      }

      if (this.steps[stepIndex] && document.querySelector(`.dashboard-tour-show-step-${stepIndex}`)) {
        document.querySelector(`.dashboard-tour-show-step-${stepIndex}`).style = 'z-index: 10000';
      }
    },
    onTourStarted() {
      this.isTutorialOverlayDisplayed = true;
      this.currentTourStep = 0;
      if (!this.sidebarIsOpen) {
        this.openSidebar();
      }

      // document.body.style.position = 'fixed';
      // document.body.style.top = `-${window.scrollY}px`;

      this.highlightTourArea(0);
      this.$forceUpdate();
    },
    onTourFinished() {
      this.isTutorialOverlayDisplayed = false;
      this.currentTourStep = -1;
      this.finishTour();
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);

      this.highlightTourArea('all');
      this.$forceUpdate();
    },
    onNextTourStep(currentStep) {
      this.highlightTourArea(currentStep + 1);
      this.currentTourStep = currentStep + 1;
      if (currentStep === 2) {
        this.isTutorialOverlayDisplayed = false;
      }
    },
    async finishTour(tour, force) {
      const { user } = this.$store.state.user;
      if (user) {
        await this.$http.put(`/api/user/${user.id}`, {
          hasCompletedTutorial: true,
        });
        this.$store.dispatch('refreshUser');
      }
    },

    startCrisp() {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = '9cc0c9f5-a10c-463c-84c9-50cd2bafd61c';
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);

      setTimeout(() => {
        window.$crisp.push(['config', 'position:reverse', [true]]);
        window.$crisp.push(['set', 'session:segments', [['customer']]]);
        if (this.user) {
          window.$crisp.push(['set', 'user:email', [this.user.email]]);
          window.$crisp.push(['set', 'user:avatar', [this.user.avatarUrl]]);
          window.$crisp.push(['set', 'user:company', [this.organisation.name]]);
        }
        window.$crisp.push(['do', 'chat:hide']);
        // if (this.isMobile) {
        //   setTimeout(() => {
        //     const $crispElement = document.querySelector('.crisp-client');
        //     if (!$crispElement) {
        //       return;
        //     }
        //     $crispElement.style.opacity = 0;
        //     $crispElement.style.visibility = 'hidden';
        //   }, 200);
        // }
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped></style>
