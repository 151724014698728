var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"custom-fields-crud","size":"lg","visible":_vm.showCustomFieldTableModal},on:{"cancel":_vm.handleCloseCustomFieldTableModal,"closeRequested":_vm.handleCloseCustomFieldTableModal,"hidden":_vm.handleCloseCustomFieldTableModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',{staticClass:"mt-3 mb-0"},[_vm._v(_vm._s(_vm.$t('common.labels.customFields')))]),_c('hr',{staticClass:"text-primary mt-1 bg-info"})]},proxy:true}])},[_c('AwesomeTable',{ref:"customFieldsTable",attrs:{"actions":{
        edit: true,
        delete: true,
        refresh: true,
        create: true,
      },"url":("/api/custom-field?filters[model]=" + _vm.identity),"perRow":1,"perPage":20,"showHeader":false,"columns":['name']},on:{"edit":_vm.handleEditCustomField,"delete":_vm.handleDeleteCustomFieldForm,"create":function($event){_vm.showCreateCustomFieldForm = true;}},scopedSlots:_vm._u([{key:"table-header-right",fn:function(){return [_c('button',{staticClass:"btn btn-outline-primary mr-1",on:{"click":function($event){_vm.showCreateCustomFieldForm = true;}}},[_vm._v(" + "+_vm._s(_vm.$t('common.buttons.addField'))+" ")])]},proxy:true}])}),(_vm.showUpdateCustomFieldForm)?_c('awesomeForm',{attrs:{"identity":"customField","mode":"edit","item":_vm.customFieldToUpdate,"displayMode":"modal","url":"/api/custom-field","actions":{
        delete: true,
        close: true,
        cancel: true,
      },"useRouterMode":false},on:{"delete":_vm.handleDeleteCustomFieldForm,"cancel":_vm.closeUpdateCustomFieldForm,"save":_vm.closeUpdateCustomFieldForm,"closeRequested":_vm.closeUpdateCustomFieldForm}}):_vm._e(),(_vm.showCreateCustomFieldForm)?_c('awesomeForm',{attrs:{"identity":"customField","mode":"create","item":{ model: _vm.identity },"displayMode":"modal","url":"/api/custom-field","useRouterMode":false},on:{"cancel":_vm.closeCreateCustomFieldForm,"save":_vm.closeCreateCustomFieldForm,"closeRequested":_vm.closeCreateCustomFieldForm}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }