<template>
  <div>
    <px-card
      v-if="model && modelId"
      :title="$t('common.widget.tasks-title') + ` [${tasks ? tasks.length : 0}]`"
      :actions="false"
      class="task-widget task-section"
    >
      <div slot="with-padding">
        <div class="todo">
          <div class="todo-list-wrapper">
            <div class="todo-list-container">
              <div class="mark-all-tasks">
                <div class="mark-all-tasks-container d-none">
                  <span
                    class="mark-all-btn"
                    :class="{ 'move-down': !markAllAsRead }"
                    id="mark-all-finished"
                    role="button"
                  >
                    <small class="btn-label">Tout compléter</small>
                    <span class="action-box completed">
                      <i class="icon">
                        <feather type="check" class="m-0" />
                      </i>
                    </span>
                  </span>
                </div>
              </div>
              <div class="todo-list-body">
                <ul id="todo-list" v-if="!(selectedTask && selectedTask.id)">
                  <li
                    v-for="(todo, index) in tasks"
                    :key="index"
                    class="task"
                    :class="{
                      'task-todo': todo.status !== 'done',
                      'task-completed': todo.status === 'done',
                      'task-overdue': todo.isOverdue,
                    }"
                  >
                    <div class="task-container">
                      <h4 class="task-label">
                        <img
                          class="rounded-circle img-20 pull-left mr-2"
                          :alt="todo.AffectedTo && todo.AffectedTo.firstName"
                          :src="todo.AffectedTo && todo.AffectedTo.avatarUrl"
                        />
                        {{ todo.content }}
                        <br />
                        <small v-if="!isRecurring(todo) && todo.dueDate" class="font-roboto">
                          Due le {{ formatDate(todo.dueDate, todo.dueTime) }}
                        </small>
                        <small v-if="isRecurring(todo)" class="text-info">
                          | Next due on {{ todo.nextDate | formatDate('DD.MMM.YYYY HH:mm') }}
                        </small>
                      </h4>
                      <span class="task-action-btn" v-if="todo.status !== 'done' && !disabled">
                        <button
                          v-if="userCan('update', 'task')"
                          @click="editTask(todo)"
                          class="btn btn-icon p-0"
                          title="Delete Task"
                        >
                          <feather class="text-info" type="edit-3" size="1.1rem" />
                        </button>
                        <button
                          @click="isRecurring(todo) ? completeRecurringTask(todo) : completeTask(todo)"
                          class="btn btn-icon p-0"
                          title="Mark Complete"
                        >
                          <feather class="text-primary" type="check" />
                        </button>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="todo-list-footer">
                <hr />
                <div class="new-task-wrapper" :class="{ visible: !disabled }" style="overflow: visible !important">
                  <input
                    v-on:keyup.enter="createTask"
                    v-model="selectedTask.content"
                    id="new-task"
                    :placeholder="$t('app.task-widget.tasks-input-placeholder')"
                    v-if="!disabled"
                    class="mb-1 form-control"
                  />
                  <div class="row">
                    <template v-if="selectedTask && selectedTask.content">
                      <MemberSelectorDropdown
                        class="mt-1 col-2"
                        avatar-only
                        v-model="selectedTask.affectedToUserId"
                      ></MemberSelectorDropdown>
                      <div class="mt-1 col-7">
                        <div class="row">
                          <DateRangePicker
                            :auto-apply="true"
                            :locale-data="dateRangePicker.locale"
                            :min-date="dateRangePicker.startDate"
                            :date-range="{ startDate: new Date(selectedTask.dueDate) }"
                            :single-date-picker="true"
                            :time-picker="false"
                            :open="'left'"
                            :time-picker-increment="5"
                            :show-week-numbers="false"
                            :show-dropdowns="false"
                            :ranges="false"
                            opens="center"
                            class="date-range-picker col-8"
                            @update="onDateSelected"
                          >
                            <template slot="input">
                              <div class="date-picker-input-slot text-truncate small">
                                <feather type="calendar" size="1rem"></feather>
                                {{ formatDate(selectedTask.dueDate, selectedTask.dueTime) }}
                              </div>
                            </template>
                          </DateRangePicker>
                          <select v-model="hour" id="" class="button btn btn-outline-primary form-control col-2">
                            <option value="day">{{ $t('common.labels.fullday') }}</option>
                            <option value="00">00</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                          </select>
                          <select
                            v-if="hour !== 'day'"
                            v-model="minutes"
                            class="button btn btn-outline-primary form-control col-2"
                          >
                            <option value="00">00</option>
                            <option value="05">05</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                            <option value="35">35</option>
                            <option value="40">40</option>
                            <option value="45">45</option>
                            <option value="50">50</option>
                            <option value="55">55</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group text-left mt-1 col-3">
                        <select
                          name=""
                          v-model="selectedTask.recurrence"
                          class="button btn btn-outline-primary btn-block form-control"
                          id="recurrence-select"
                          @change="selectedTask.cronInstruction = null"
                        >
                          <option selected disabled>Recurrence</option>
                          <option
                            :key="recurrence.value"
                            :value="recurrence.value"
                            v-for="recurrence in recurrenceOptions"
                          >
                            {{ recurrence.label }}
                          </option>
                        </select>
                      </div>
                    </template>
                  </div>
                  <button
                    v-if="selectedTask.content && !selectedTask.id"
                    @click="isRecurring() ? createRecurringTask() : createTask()"
                    class="btn btn-primary btn-block add-new-task-btn"
                    id="add-task"
                  >
                    <feather type="save"></feather>
                  </button>
                  <button
                    v-if="canShowTaskEditButtons"
                    @click="isRecurring() ? updateRecurringTask() : updateTask()"
                    class="ml-1 btn btn-primary btn-block add-new-task-btn"
                    id="add-task"
                  >
                    {{ $t('common.buttons.save') }}
                  </button>
                  <button
                    v-if="canShowTaskEditButtons && userCan('delete', 'task')"
                    @click="isRecurring() ? deleteRecurringTask() : deleteTask()"
                    class="btn btn-outline-danger btn-block"
                    title="Delete Task"
                  >
                    <feather class="text-secondary" type="trash" size="1rem" /> {{ $t('common.buttons.delete') }}
                  </button>
                  <button
                    v-if="canShowTaskEditButtons"
                    @click="resetTaskForm()"
                    class="btn btn-outline-default btn-block"
                    title="Delete Task"
                  >
                    <feather class="text-default" type="x" size="1rem" /> {{ $t('common.buttons.close') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </px-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import marked from 'marked';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import DateRangePicker from 'vue2-daterange-picker';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import rolesMixin from '@/mixins/rolesMixin';
import MemberSelectorDropdown from '../user/MemberSelectorDropdown.vue';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);

export default {
  name: 'TasksWidget',
  components: {
    MemberSelectorDropdown,
    DateRangePicker,
  },
  mixins: [rolesMixin],
  props: {
    model: String,
    modelId: [String, Number],
    disabled: Boolean,
  },
  data () {
    return {
      isSending: false,
      showEditForm: false,
      markAllAsRead: true,
      isAddingTask: false,
      hour: '00',
      minutes: '00',
      tasks: [],
      selectedTask: {
        content: '',
        affectedToUserId: null,
        dueDate: dayjs().add(1, 'day'),
        dueTime: '',
        recurrence: undefined,
        cronInstruction: null,
      },
      dateRangePicker: {
        startDate: `${new Date().toISOString().substring(0, 10)} 00:00`,
        locale: {
          direction: 'ltr', // direction of text
          format: 'DD-MM-YYYY HH:mm', // fomart of the dates displayed
          separator: ' - ', // separator between the two ranges
          applyLabel: 'Appliquer',
          cancelLabel: 'Annuler',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: dayjs.weekdaysMin(),
          monthNames: dayjs.monthsShort(),
          firstDay: 1,
        },
      },
      recurrenceOptions: [
        { value: 'no-recurrence', label: this.$t('common.labels.recurrenceNoRecurrence') },
        { value: 'daily', label: this.$t('common.labels.recurrenceDaily') },
        { value: 'weekly', label: this.$t('common.labels.recurrenceWeekly') },
        { value: 'monthly', label: this.$t('common.labels.recurrenceMonthly') },
        { value: 'quarterly', label: this.$t('common.labels.recurrenceQuarterly') },
        { value: 'semiannual', label: this.$t('common.labels.recurrenceSemiannual') },
        { value: 'yearly', label: this.$t('common.labels.recurrenceYearly') },
      ],
    };
  },
  mounted () {
    this.getTasksDebounced = debounce(this.getTasks, 200);
    this.getRecurringTasksDebounced = debounce(this.getRecurringTasks, 200);
    // Get both recurring and ordinary tasks
    this.getAllTasksDebounced = debounce(async () => {
      await this.getTasks();
      const tasks = [...this.tasks];
      await this.getRecurringTasks();
      this.tasks.push(...tasks);
    }, 200);
    this.resetTaskForm();
    if (this.modelId) {
      this.getAllTasksDebounced();
    }
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    apiRoot () {
      return `/api/${this.model}/${this.modelId}/task`;
    },
    recurringTaskApiRoot () {
      return `/api/${this.model}/${this.modelId}/recurring-task`;
    },
    canShowTaskForm () {
      return this.selectedTask && this.selectedTask.id;
    },
    canShowTaskEditButtons () {
      return this.selectedTask && this.selectedTask.id;
    },
  },
  watch: {
    modelId () {
      this.getAllTasksDebounced();
    },
    model () {
      this.getAllTasksDebounced();
    },
  },
  methods: {
    marked,
    resetTaskForm () {
      this.selectedTask = {
        id: null,
        content: '',
        affectedToUserId: this.user && this.user.id,
        dueDate: dayjs().add(1, 'day'),
        dueTime: '',
        recurrence: 'no-recurrence',
        cronInstruction: null,
      };
    },
    getImgUrl (path) {
      // eslint-disable-next-line
      return require(`@/assets/images/${path}`);
    },

    getTasks () {
      return this.$http.get(`${this.apiRoot}`).then(({ data }) => {
        this.tasks = data.body;
      });
    },

    getRecurringTasks () {
      return this.$http.get(`${this.recurringTaskApiRoot}`).then(({ data }) => {
        this.tasks = data.body;
      });
    },

    editTask (task) {
      this.selectedTask = task;
      if (this.selectedTask.dueTime) {
        this.hour = this.selectedTask.dueTime.split(':')?.[0] || '00';
        this.minutes = this.selectedTask.dueTime.split(':')?.[1] || '00';
      }
    },

    createTask () {
      if (this.selectedTask && this.selectedTask.content) {
        if (this.hour && this.hour === 'day') {
          this.selectedTask.dueDate = dayjs(this.selectedTask.dueDate).format('YYYY-MM-DD');
          this.selectedTask.dueTime = '';
        } else {
          this.selectedTask.dueDate = dayjs(this.selectedTask.dueDate).format('YYYY-MM-DD');
          this.selectedTask.dueTime = `${this.hour || '08'}:${this.minutes || '00'}`;
        }
        this.$http.post(`${this.apiRoot}`, this.selectedTask).then(() => {
          this.resetTaskForm();
          this.getAllTasksDebounced();
        });
      }
    },

    createRecurringTask () {
      if (this.selectedTask && this.selectedTask.content) {
        const { recurrence } = this.selectedTask;
        const date = new Date(this.selectedTask.dueDate);
        const day = date.getDate();
        const weekday = date.getDay();
        const month = date.getMonth();
        const cron = this.getCronInstruction({
          day,
          month,
          recurrence,
          weekday,
        });
        this.selectedTask.cronInstruction = cron;

        this.$http.post(`${this.recurringTaskApiRoot}`, this.selectedTask).then(() => {
          this.resetTaskForm();
          this.getAllTasksDebounced();
        });
      }
    },

    updateTask () {
      if (this.selectedTask && this.selectedTask.id) {
        if (this.hour && this.hour !== 'day') {
          this.selectedTask.dueTime = `${this.hour || '08'}:${this.minutes || '00'}:00`;
        } else {
          this.selectedTask.dueTime = null;
        }
        this.$http.put(`${this.apiRoot}/${this.selectedTask.id}`, this.selectedTask).then(() => {
          this.resetTaskForm();
          this.getAllTasksDebounced();
        });
      }
    },

    updateRecurringTask () {
      if (this.selectedTask && this.selectedTask.id) {
        const { recurrence } = this.selectedTask;
        const date = new Date(this.selectedTask.dueDate);
        const day = date.getDate();
        const weekday = date.getDay();
        const month = date.getMonth();
        const cron = this.getCronInstruction({
          day,
          month,
          recurrence,
          weekday,
        });
        this.selectedTask.cronInstruction = cron;

        this.$http.put(`${this.recurringTaskApiRoot}/${this.selectedTask.id}`, this.selectedTask).then(() => {
          this.resetTaskForm();
          this.getAllTasksDebounced();
        });
      }
    },

    completeTask (task) {
      if (task && task.id) {
        this.$http
          .put(`${this.apiRoot}/${task.id}`, {
            status: 'done',
          })
          .then(() => {
            this.resetTaskForm();
            this.getAllTasksDebounced();
          });
      }
    },

    completeRecurringTask (task) {
      if (task && task.id) {
        this.$http
          .post(`${this.recurringTaskApiRoot}/${task.id}/complete`, {
            status: 'done',
            dueDate: task.nextDate,
          })
          .then(() => {
            this.resetTaskForm();
            this.getAllTasksDebounced();
          })
          .catch(this.apiErrorCallback);
      }
    },

    deleteTask () {
      if (this.selectedTask && this.selectedTask.id) {
        this.$awConfirm('Etes vous sur ?').then(
          (confirmation) =>
            confirmation &&
            this.$http.delete(`${this.apiRoot}/${this.selectedTask.id}`).then(() => {
              this.resetTaskForm();
              this.getAllTasksDebounced();
            })
        );
      }
    },

    deleteRecurringTask () {
      if (this.selectedTask && this.selectedTask.id) {
        this.$awConfirm('Etes vous sur ?').then(
          (confirmation) =>
            confirmation &&
            this.$http.delete(`${this.recurringTaskApiRoot}/${this.selectedTask.id}`).then(() => {
              this.resetTaskForm();
              this.getAllTasksDebounced();
            })
        );
      }
    },

    onDateSelected ($event) {
      if ($event.startDate > new Date()) {
        this.selectedTask.dueDate = dayjs($event.startDate).format('YYYY-MM-DD');
      }
    },

    getCronInstruction ({ minute = 0, hour = 0, day, month, weekday, recurrence }) {
      let cronMonth = month;
      let cronDay = day;
      let cronWeekday = weekday;

      if (recurrence === 'daily') {
        cronDay = '*';
        cronMonth = '*';
        cronWeekday = '*';
      } else if (recurrence === 'weekly') {
        cronMonth = '*';
        cronDay = '*';
      } else if (recurrence === 'monthly') {
        cronMonth = '*';
        cronWeekday = '*';
      } else if (recurrence === 'quarterly') {
        cronMonth = '*/3';
        cronWeekday = '*';
      } else if (recurrence === 'semiannual') {
        cronMonth = '*/6';
        cronWeekday = '*';
      } else if (recurrence === 'yearly') {
        cronWeekday = '*';
      }

      const instruction = `${minute} ${hour} ${cronDay} ${cronMonth} ${cronWeekday}`;

      return instruction;
    },

    isRecurring (task) {
      let { selectedTask } = this;
      if (task) {
        selectedTask = task;
      }

      return (selectedTask.recurrence && selectedTask.recurrence !== 'no-recurrence') || selectedTask.cronInstruction;
    },

    formatDate (date, time) {
      if (!date) {
        return '';
      }
      const d = dayjs(date);
      if (!time || time === '00:00' || time === '00:00:00') {
        return d.format('DD.MM.YYYY');
      }
      return `${dayjs(date).format('DD.MM.YYYY')} ${time.substring(0, 5)}`;
    },
  },
};
</script>

<style lang="scss">
.task-widget {
  .card {
    .card-header {
      padding: 15px;
      h5 {
        font-size: 1em;
        font-weight: 600;
        color: var(--primary);
      }
    }
  }
}
.reverse-message {
  text-align: right;
  flex-direction: row-reverse;
}

.task-todo {
  color: var(--primary);
  span,
  div > h4,
  .task-label {
    color: var(--primary) !important;
  }
}

.task-completed {
  *,
  span {
    text-decoration: line-through;
    color: #999;
  }
}

.comment-section .list-group,
.task-section .list-group {
  img {
    width: 20px;
    height: 20px;
  }

  .comment-content {
    white-space: break-spaces;
  }
}

.vdatetime.form-control {
  .vdatetime-input {
    border: none;
    width: 100%;
  }
}

.daterangepicker.ltr {
  z-index: 9999 !important;
  box-shadow: 1px 1px 15px;
}
.vue-daterange-picker .date-picker-input-slot {
  span {
    display: none;
  }

  &:hover {
    span {
      display: inline;
    }
  }
}

#recurrence-select {
  margin-bottom: 24px !important;
  font-size: 12px;
  font-weight: normal;
  option {
    text-align: left;
  }
}
</style>
