<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card mt-3">
            <div class="card-header card-no-border">
              <b-modal
                id="modal-1"
                :title="$t('common.labels.addMailbox')"
                v-model="showAddMailboxModal"
                :hideFooter="true"
                @cancel="showAddMailboxModal = true"
                @closeRequested="showAddMailboxModal = true"
              >
                <ValidationObserver ref="formStep">
                  <form @submit.prevent="">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex">
                        <input type="checkbox" 
                        class="mr-2" v-model="isPrivate" >
                        {{ $t('common.labels.privateMailbox') }}
                        <span class="slider"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <label class="d-inline-flex">
                        <input type="checkbox" 
                        class="mr-2" v-model="isFetchEnabled" >
                        {{ $t('common.labels.isFetchEnabled') }}
                        <span class="slider"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <button
                        @click="requestGmailEmailAccess()"
                        name=""
                        type="button"
                        class="btn btn-outline-danger btn-block"
                        autocomplete="off"
                      >
                        <i class="fa fa-google"></i>
                        Gmail
                      </button>
                    </div>

                    <div class="form-group d-none">
                      <button
                        @click="mailbox.type = 'outlook'"
                        name=""
                        type="button"
                        class="btn btn-outline-info btn-block"
                        autocomplete="off"
                      >
                        <i class="fa fa-microsoft"></i>
                        Outlook
                      </button>
                    </div>

                    <div class="form-group">
                      <button
                        @click="openImapModal"
                        name=""
                        type="button"
                        class="btn btn-outline-secondary btn-block"
                        autocomplete="off"
                      >
                        Autres
                      </button>
                    </div>
                    <hr />
                  </form>
                </ValidationObserver>
              </b-modal>
              <imap-modal 
                :shouldDisplayModal="shouldDisplayEmailCredentialsModal"
                :isPrivate="isPrivate"
                :isFetchEnabled="isFetchEnabled"
                @closeModal="imapModalClose"
              />
              <h4 class="d-inline-block text-info">
                {{ $t('app.settings.mailbox.your-mailboxes') }}
              </h4>
              <div class="pull-right">
                <button
                  class="btn bg-transparent text-primary p-1"
                  @click.prevent="addMailbox"
                  data-tooltip="Editer le client"
                >
                  <feather type="plus"></feather>
                </button>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="our-product">
                <div class="table-responsive">
                  <div
                    v-if="mailboxAddresses && !mailboxAddresses.length"
                    class="pointer"
                    @click.prevent="addMailbox()"
                  >
                    <noResult :message="$t('common.labels.noMailboxes')" />
                  </div>
                  <table v-else class="table table-bordernone">
                    <tbody class="f-w-500">
                      <tr class="pointer" v-for="address in mailboxAddresses" :key="address.id">
                        <td class="align-middle">
                          <div>
                            <span>{{ address.email }}</span>
                          </div>
                        </td>
                        <td class="align-middle">
                          <div>
                            <span>{{ address.type }}</span>
                          </div>
                        </td>
                        <td>
                          <button
                            class="btn btn-icon"
                            data-tooltip="Update the mailbox"
                            v-if="address.createdBy === user.id || isAdmin"
                            @click="editMailbox(address)"
                            >
                            <feather type="settings" class="text-success"></feather>
                          </button>
                          <button 
                            v-if="address.createdBy === user.id || isAdmin"
                            class="btn btn-icon"
                            data-tooltip="Delete the mailbox"
                            @click="deleteMailBox(address)"
                          >
                            <feather type="trash-2" class="text-danger"></feather>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <AwesomeForm
                    v-if="showMailboxEditionForm"
                    identity="mailbox"
                    :actions="{
                      delete: true,
                      edit: true,
                      close: true
                    }"
                    :item="{ id: selectedMailbox.id }"
                    :mode="'edit'"
                    :url="`/api/mailbox`"
                    :displayedFields="['email','type','isFetchEnabled','isPrivate']"
                    @closeRequested="showMailboxEditionForm = false"
                    @cancel="showMailboxEditionForm = false"
                    :useRouterMode="false"
                    displayMode="modal"
                    :displayHeader="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BModal } from 'bootstrap-vue';
import { ValidationObserver } from 'vee-validate';
import { AwesomeForm } from 'vue-aw-components';
import ImapModal from '@/components/mailbox/ImapModal.vue';
// import swal from 'sweetalert2/dist/sweetalert2';

export default {
  name: 'MailBoxSettings',
  components: {
    // ValidationProvider,
    BModal,
    ValidationObserver,
    ImapModal,
    AwesomeForm,
  },

  data() {
    return {
      selectedUser: {},
      showAddMailboxModal: false,
      mode: 'create',
      mailboxAddresses: [],
      newMailboxes: {},
      shouldDisplayEmailCredentialsModal: false,
      selectedMailbox: {},
      showMailboxEditionForm: false,
      isPrivate: false,
      isFetchEnabled: true,
    };
  },

  computed: {
    ...mapState('user', ['user', 'organisation']),
    isAdmin() {
      if (
        this.user &&
        this.user.organisationRoles &&
        this.user.organisationRoles.find((r) => r.toLowerCase() === 'admin')
      ) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.getMailboxes();

    if (this.$route.query.action && this.$route.query.action === 'create') {
      this.showAddMailboxModal = true;
    }
    // prettier-ignore
    if (
      this.$route.query.code
      && this.$route.query.scope
      && this.$route.query.scope.includes('gmail')
    ) {
      this.sendGmailEmailAccess().then(orgEmail => {
        this.$awNotify(`Email ajouté ! ${orgEmail.data.body.email}`);
      });
    }
  },

  beforeRouteEnter(to, from, next) {
    next();
  },

  methods: {
    addMailbox() {
      this.showAddMailboxModal = true;
    },

    editMailbox(address) {
      this.selectedMailbox = address;
      this.showMailboxEditionForm = true;
    },

    async deleteMailBox(address) {
      if (address && address.id && address.organisationId) {
        const confirmed = await this.$awConfirm('Êtes-vous sur ?');
        if (confirmed) {
          const url = `/api/mailbox/${address.id}`;
          this.$http.delete(url).then(() => {
            this.getMailboxes();
          });
        }
      }
    },
    requestGmailEmailAccess() {
      const organisationId = this.organisation && this.organisation.id;
      if (!organisationId) {
        // eslint-disable-next-line no-alert
        alert('Mssing organisationId');
        return;
      }
      localStorage.setItem('mailbox_isprivate', this.isPrivate);
      localStorage.setItem('mailbox_isfetchenabled', this.isFetchEnabled);
      this.$http.get(`/api/auth/gmail?redirectUrl=${window.location.origin}${window.location.pathname}`).then((res) => {
        window.location.href = res.data.body.replace('onboarding', 'app/settings/mailbox');
      });
      this.newMailboxes.type = 'gmail';
    },

    async requestImapEmailAccess() {
      this.shouldDisplayEmailCredentialsModal = true;
    },

    sendGmailEmailAccess() {
      this.newMailboxes.code = this.$route.query.code;
      this.newMailboxes.type = 'gmail';
      return this.$http
        .post('/api/mailbox', {
          code: this.$route.query.code,
          type: 'gmail',
          isPrivate: localStorage.getItem('mailbox_isprivate') === "true" || false,
          isFetchEnabled: localStorage.getItem('mailbox_isfetchenabled') === "true" || false,
          redirectUrl: `${window.location.origin}${window.location.pathname}`,
        })
        .then((mailbox) => {
          this.getMailboxes();
          this.newMailboxes = {};
          localStorage.removeItem('mailbox_isprivate');
          localStorage.removeItem('mailbox_isfetchenabled');
          return mailbox;
        });
    },
    getMailboxes() {
      if (!(this.organisation && this.organisation.id)) {
        return;
      }
      this.newMailboxes.code = this.$route.query.code;
      return this.$http.get('/api/mailbox').then(({ data }) => {
        this.mailboxAddresses = data.body;
      });
    },

    async imapModalClose() {
      this.shouldDisplayEmailCredentialsModal = false;
      await this.getMailboxes();
    },

    async openImapModal() {
      this.newMailboxes.type = 'imap';
      this.showAddMailboxModal = false;
      await this.requestImapEmailAccess();
    },
  },
};
</script>

<style lang="scss">
.aw-form .form-element {
  margin-bottom: 0px;
}
</style>
