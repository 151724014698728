<template>
  <div class="content" style="height: 100vh; background: #eee">
    <div class="container-fluid">
      <div class="sidebox row">
        <enyo-card
          class="card-user card-user col-xl-4 offset-xl-4 col-md-8 offset-md-2 col-lg-8
            offset-lg-2 col-sm-8 offset-sm-2 col-xs-8 offset-xs-2"
          style="margin-top: 2rem !important"
        >
          <div class="info">
            <h4 class="title text-primary text-bold text-center">
              {{ title }}
              <br />
              <small></small>
            </h4>
            <div class="description text-center">
              <p>{{ message }}</p>
            </div>
          </div>
        </enyo-card>
      </div>
    </div>
  </div>
</template>

<script>
import { apiErrorsMixin } from 'vue-aw-components';

export default {
  name: 'OptOut',
  components: {},
  mixins: [apiErrorsMixin],
  data() {
    return {
      title: null,
      message: null
    };
  },
  mounted() {
    this.setMessageAndTitle();
    this.submitForm();
  },
  methods: {
    submitForm() {
      this.message = this.$t('common.messages.optoutLoadingMessage');
      const { userId, organisationId } = this.$route.query;
      const { type } = this.$route.params;

      this.$http
        .post('/api/optout-of-email', {
          userId,
          organisationId,
          emailType: type,
        })
        .then(() => {
          this.message = this.$t('common.messages.optoutSuccessMessage');
        })
        .catch((err) => {
          this.message = this.$t('common.messages.optoutErrorMessage');
          this.apiErrorCallback(err);
        });
    },
    setMessageAndTitle() {
      const { type } = this.$route.params;

      if (type === 'blocked-recruitments-alerts') {
        this.title = this.$t('common.labels.optoutBlockedRecruitmentTitle');
      }
      if (type === 'weekly-digest-alerts') {
        this.title = this.$t('common.labels.optoutWeeklyDigestTitle');
      }
    },
  },
};
</script>
<style scoped>
  .info {
    margin: 1rem;
  }
</style>
