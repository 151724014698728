export default {
  methods: {
    apiErrorCallback(err) {
      if (err && !err.response && !err.message) {
        this.$awNotify({
          title: this.$t('common.messages.network_error'),
          type: 'warning',
        });
        return;
      }

      const messageOriginal = err.response || err.data || err;
      const message = this.parseErrorResponse(messageOriginal);
      const errorBody = (err.response && err.response.data) || err.data || err;
      const subErrors = errorBody && errorBody.errors;
      const subErrorsHtml
        = subErrors && Array.isArray(subErrors)
          ? subErrors
            .filter((e) => e !== messageOriginal?.message
              && e?.message !== messageOriginal?.message
              && e?.message !== message
              && e !== message)
            .map((e) => this.translateErrorMessage(e.message || e))
            .join('\n')
          : '';
      this.$awNotify({
        title: message,
        message: `${subErrorsHtml}`,
        type: 'warning',
        timer: 3000,
      });
    },
    parseErrorResponse(err) {
      if (!err) {
        return '';
      }
      const error = err.response || err.data || err;
      if (error) {
        let message = '';
        if (error.message) {
          return this.translateErrorMessage(error.message);
        }
        if (error.errors) {
          if (error.errors && error.errors.length) {
            message += `\n${error.errors.map((e) => this.translateErrorMessage(e.message || e)).join('<br/>')}`;
          } else {
            message += JSON.stringify(error.errors);
          }
          return message;
        }
        return error instanceof String ? error : JSON.stringify(error);
      }
      return `Error status: ${err.status}`;
    },

    translateErrorMessage(message) {

      if (typeof message === 'string') {
        if (this.$te(`aw.messages.${message}`)) {
          message = this.$t(`aw.messages.${message}`);
        } else if (this.$te(message)) {
          message = this.$t(message);
        }
      }
      return message;
    }
  },
};
