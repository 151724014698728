/* eslint-disable import/prefer-default-export */
import Swal from 'sweetalert2/dist/sweetalert2';

export const request = {
  identity: 'request',
  icon: '',
  actions: {
    create: true, edit: true, delete: false, refresh: true
  },
  options: {
    initialDisplayMode: 'kanban'
  },
  customFormTopActions: [
    {
      name: 'complete-request',
      label: 'Archiver la conversation',
      class: 'btn-outline-success btn-sm bg-transparent',
      title: 'Fermer la conversation',
      icon: 'fa fa-lock',
      action: async ({
        item, action, location, props, id,
      }, context) => {
        const { value: targetComment } = await Swal.fire({
          title: 'Commentaires',
          input: 'textarea',
          //  inputLabel: 'ex: demandée traitée | doublon.',
          showCancelButton: true,
        });
        if (targetComment) {
          await context.$http.post('/api/model-comment', {
            model: 'request',
            modelId: item.id,
            content: `Fermé par ${context.$store.state.user.firstName} ${context.$store.state.user.firstName}`
              + ` - le ${new Date()} \n ${targetComment}`
          });
        }

        context.$http.put(`/api/request/${item.id}`, {
          title: item.title,
          archived: true,
        })
          .then(() => {
            context.$awNotify('Conversation fermée');
            context.refreshComponent();
          });
      },
    },
  ],
  customTopRightActions: [
    {
      name: 'display-archived-items',
      label(state, vm) {
        return vm.$store.state.layout.requestArchiveButtonState
          ? vm.$t('request.actions.hide-archived')
          : vm.$t('request.actions.display-archived');
      },
      classes: 'btn-outline-info btn-sm mb-1 mr-1',
      icon: 'fa fa-eye mr-1',
      disabled: false,
      action: ({ items, action, location, props, id }, context) => {
        let includeArchived;
        if (!context.$store.state.layout.requestArchiveButtonState) {
          includeArchived = 1;
        } else {
          includeArchived = 0;
        }
        delete this.apiQueryParams.includeArchived;
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.apiQueryParams,
            includeArchived,
            filters: {
              ...this.apiQueryParams.filters,
            },
          },
        });
        context.$store.commit(
          'layout/setRequestArchiveButtonState',
          !context.$store.state.layout.requestArchiveButtonState
        );
      },
    },
  ],
  schema: {
    $id: 'http://acme.com/schemas/request.json',
    type: 'object',
    properties: {
      id: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        }
      },
      organisationId: {
        field: {
          readonly: true,
          visible: false,
        },
        column: {
          visible: false
        }
      },
      createdOn: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        }
      },
      receivedOn: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        }
      },
      closedOn: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        }
      },
      logs: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        }
      },
      lastModifiedOn: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        }
      },
    }
  },

  nestedModels: [

  ]
};
