import * as Msal from '@azure/msal-browser';
import config from '@/config';

export default class MicrosoftAuthService {
  constructor() {
    this.myMsal = new Msal.PublicClientApplication(config.msalConfig);
    this.request = {
      scopes: ['user.read', 'group.read.all']
    };

    this.idToken = '';
    this.accessToken = '';
  }

  async login() {
    try {
      this.idToken = await this.myMsal.loginPopup(this.request);
      const loggedInAccountName = this.idToken.idTokenClaims.preferred_username;
      this.request.account = await this.myMsal.getAccountByUsername(loggedInAccountName);
      const userData = await this.getMe();

      return {
        account: this.request.account,
        accessToken: this.accessToken,
        userData,
      };
    } catch (error) {
      console.warn(error);
    }
  }

  async getAccessToken() {
    let tokenResponse = '';
    try {
      tokenResponse = await this.myMsal.acquireTokenSilent(this.request);
      this.accessToken = tokenResponse.accessToken;
    } catch (error) {
      console.warn(error);
      if (this.requiresInteraction(error)) {
        try {
          tokenResponse = await this.myMsal.acquireTokenPopup(this.request);
          this.accessToken = tokenResponse.accessToken;
        } catch (err) {
          console.warn(err);
        }
      }
    }
  }

  async getMe() {
    await this.getAccessToken();
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${this.accessToken}`
    };
    const graphUrl = config.microsoftGraphMeEndpoint;
    const response = await fetch(graphUrl, {
      method: 'GET',
      headers
    });

    const responseData = await response.json();

    return responseData;
  }

  // eslint-disable-next-line class-methods-use-this
  requiresInteraction(errorCode) {
    if (!errorCode || !errorCode.length) {
      return false;
    }
    return errorCode === 'consent_required'
            || errorCode === 'interaction_required'
            || errorCode === 'login_required';
  }
}
