<template>
  <div>
    <px-card
      v-if="computedClient"
      :title="$t('app.client-detail.client')"
      :actions="false"
      class="client-widget client-section draggable pb-0"
      bodyClasses="pb-0"
    >
      <template #headerCustom>
        <div class="" style="position: absolute; top: 10px; right: 10px">
          <button
            class="btn bg-transparent text-black p-1"
            @click="() => openEmailComposerModal(computedClient)"
            data-tooltip="Contacter le client"
          >
            <feather type="mail" size="1rem"></feather>
          </button>
          <button
            v-if="showViewButton"
            class="btn bg-transparent text-primary p-1"
            @click.prevent="$emit('view', computedClient)"
            data-tooltip="Voir le client"
          >
            <feather type="eye" size="1rem"></feather>
          </button>
          <button
            v-if="showEditButton"
            class="btn bg-transparent text-info p-1"
            @click.prevent="$emit('edit', computedClient)"
            data-tooltip="Editer le client"
          >
            <feather class="" type="edit-3" size="1rem"></feather>
          </button>
          <button
            v-if="showDeleteButton"
            @click.prevent="$emit('delete', computedClient)"
            class="btn text-danger bd-transparent p-1"
            data-tooltip="Supprimer le client"
          >
            <feather type="trash-2" size="1rem"></feather>
          </button>
        </div>
        <hr class="mb-0" />
      </template>
      <div slot="with-padding">
        <div class="draggable">
          <div class="email-app-sidebar">
            <div class="pb-0">
              <div>
                <div class="">
                  <div class="">
                    <div class="media">
                      <div class="media-size-email">
                        <UserAvatar
                          v-if="computedClient"
                          class="mr-3 rounded-circle"
                          :user="computedClient"
                          imageField="avatarUrl"
                          :styles="{ width: '45px', height: '45px', fontSize: '1.4rem' }"
                          alt=""
                        />
                      </div>
                      <div class="media-body">
                        <router-link :to="`/app/client/${computedClient.id}`">
                          <h6 class="f-w-600">{{ computedClient.company || computedClient.name }}</h6>
                          <p>{{ computedClient.name }}</p>
                        </router-link>
                      </div>
                    </div>
                    <div
                      class="col-6 mb-1 d-inline-flex justify-content-start align-items-baseline"
                      v-if="computedClient"
                    >
                      <feather type="phone" size="10" class="display-on-hover text-dark mr-1"></feather>
                      <label>
                        <EditableText
                          :disabled="editionIsDisabled"
                          v-model="computedClient.phonenumber"
                          placeholder="-"
                          showSaveButton
                          class=""
                          style="min-width: 50px"
                          @change="saveClient"
                          type="text"
                        >
                          <template #default="{ value, onClick }">
                            <div class="text-primary text-truncate display-on-hover-parent pointer">
                              <div class="d-inline-flex" v-if="!value" @click="onClick">
                                {{ ' - ' }}
                              </div>
                              <a v-if="value" :href="'tel:' + value" class="text-primary">{{ value }}</a>
                              <feather
                                @click="onClick"
                                type="edit-3"
                                size="10"
                                class="display-on-hover text-info pull-right"
                              ></feather>
                              <a
                                v-if="canUseWhatsapp"
                                class="text-muted"
                                :href="`https://wa.me/${value.replace(/[ +-\.]/g, '')}?text=hello`"
                                ><i class="fa fa-whatsapp mr-1 text-primary"></i
                              ></a>
                            </div>
                          </template>
                        </EditableText>
                      </label>
                    </div>
                    <div
                      class="col-6 mb-1 d-inline-flex justify-content-end align-items-baseline"
                      v-if="computedClient"
                    >
                      <feather type="at-sign" size="10" class="display-on-hover text-dark mr-1"></feather>
                      <label>
                        <EditableText
                          :disabled="editionIsDisabled"
                          v-model="computedClient.email"
                          :value="computedClient.email"
                          placeholder="-"
                          showSaveButton
                          class=""
                          style="min-width: 50px"
                          @change="saveClient"
                          type="text"
                        >
                          <template #default="{ value, onClick }">
                            <div class="text-primary text-truncate display-on-hover-parent pointer">
                              <span @click.prevent.stop="openEmailComposerModal(computedClient)">{{ value }}</span>
                              <feather
                                @click="onClick"
                                type="edit-3"
                                size="10"
                                class="display-on-hover text-info ml-1"
                              ></feather>
                            </div>
                          </template>
                        </EditableText>
                      </label>
                    </div>
                    <hr class="" />
                    <div class="mb-2 d-flex justify-content-between align-items-baseline" v-if="computedClient">
                      <feather
                        type="linkedin"
                        size="15"
                        :class="computedClient.linkedin ? 'text-info' : 'text-muted'"
                      ></feather>
                      <label>
                        <EditableText
                          :disabled="editionIsDisabled"
                          v-model="computedClient.linkedin"
                          :value="computedClient.linkedin"
                          placeholder="-"
                          showSaveButton
                          class=""
                          style="min-width: 50px"
                          @change="saveClient"
                          type="url"
                        >
                          <template #default="{ value, onClick }">
                            <a
                              target="_blank"
                              :href="computedClient.linkedin"
                              :data-tooltip="computedClient.linkedin"
                              class="text-info text-truncate display-on-hover-parent"
                              v-if="value"
                            >
                              <feather
                                type="edit"
                                size="10"
                                class="ml-1 display-on-hover pointer"
                                @click.prevent.stop="onClick"
                              ></feather>
                              {{ computedClient.linkedin }}
                            </a>
                          </template>
                        </EditableText>
                      </label>
                    </div>
                    <div class="d-flex justify-content-between align-items-baseline" v-if="computedClient">
                      <feather
                        type="globe"
                        size="15"
                        :class="computedClient.websiteUrl ? 'text-info' : 'text-muted'"
                      ></feather>
                      <label>
                        <EditableText
                          :disabled="editionIsDisabled"
                          v-model="computedClient.websiteUrl"
                          :value="computedClient.websiteUrl"
                          placeholder="-"
                          showSaveButton
                          class=""
                          style="min-width: 50px"
                          @change="saveClient"
                          type="url"
                        >
                          <template #default="{ value, onClick }">
                            <a
                              target="_blank"
                              :href="computedClient.websiteUrl"
                              :data-tooltip="computedClient.websiteUrl"
                              class="text-info display-on-hover-parent text-right"
                              v-if="value"
                            >
                              <feather
                                type="edit"
                                size="10"
                                class="text-muted display-on-hover p-0 mr-1"
                                @click.prevent.stop="onClick"
                              ></feather>
                              {{ computedClient.websiteUrl }}
                            </a>
                          </template>
                        </EditableText>
                      </label>
                    </div>

                    <div
                      class="d-flex justify-content-between align-items-baseline"
                      v-if="computedClient && clientAddress"
                    >
                      <feather type="map-pin" size="0.8rem" />
                      <label>
                        <div class="" style="min-width: 50px">
                          {{ clientAddress }}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div v-if="organisation.featuresClientConversations" class="font-italic">
                <small class="title">
                  {{ selectedClientStats ? selectedClientStats.requestsPerMonth : '0' }} demandes ce mois-ci</small
                >
              </div>
              <div v-if="organisation.featuresClientConversations" class="font-italic">
                <small class="title">
                  {{ computedClient ? computedClient.totalOpenRequests : '0' }} demandes en cours</small
                >
                <small class="badge badge-danger pull-right d-none">Quota dépassé</small>
              </div>
              <div class="client-details" v-if="computedClient.description">
                <hr class="mt-1 mb-1" />
                <b-button
                  v-b-toggle.collapse-description
                  variant="link"
                  class="p-0 btn-sm text-primary"
                  style="display: flex; align-items: center"
                >
                  <p class="text-primary">Description</p>
                  <feather type="chevron-right" size="12" class="text-primary"
                /></b-button>
                <b-collapse id="collapse-description" class="mt-2 p-0">
                  <small
                    class="description-details"
                    v-html="striptags(computedClient.description, ['br', 'p'])"
                  ></small>
                </b-collapse>
              </div>
              <div class="client-details" v-if="computedClient.notes">
                <hr class="mt-1 mb-1" />
                <b-button v-b-toggle.collapse-description variant="link" class="p-0 btn-sm text-primary"
                  >Notes <feather type="chevron-right" size="12" class="text-primary pt-1"
                /></b-button>
                <b-collapse id="collapse-description" class="mt-2 p-0">
                  <small class="description-details" v-html="striptags(computedClient.notes, ['br', 'p'])"></small>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </px-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import striptags from 'striptags';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import { BButton, BCollapse } from 'bootstrap-vue';
import UserAvatar from '../user/UserAvatar.vue';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);

export default {
  name: 'ClientInfoWidget',
  props: {
    disabled: Boolean,
    showViewButton: Boolean,
    showEditButton: Boolean,
    showDeleteButton: Boolean,
    client: Object,
    selectedClientStats: Object,
    clientId: [String, Number],
  },
  components: {
    UserAvatar,
    BButton,
    BCollapse,
  },
  data() {
    return {
      shouldDisplayModal: false,
      isSending: false,
      showEditForm: false,
      markAllAsRead: true,
      isAddingTask: false,
      tasks: [],
      selectedTask: {
        content: '',
        affectedToUserId: null,
        dueDate: dayjs().add(1, 'day'),
      },
      dateRangePicker: {
        startDate: new Date().toISOString(),
        locale: {
          direction: 'ltr', // direction of text
          format: 'DD-MM-YYYY HH:mm', // fomart of the dates displayed
          separator: ' - ', // separator between the two ranges
          applyLabel: 'Appliquer',
          cancelLabel: 'Annuler',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: dayjs.weekdaysMin(),
          monthNames: dayjs.monthsShort(),
          firstDay: 1,
        },
      },
      internalClient: null,
    };
  },
  async mounted() {
    if (this.clientId && !this.client) {
      this.internalClient = await this.getClient(this.clientId);
    }
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    ...mapState('email', ['showEmailComposerModal']),
    clientAddress() {
      const address =
        this.computedClient &&
        (this.computedClient.address ||
          this.computedClient.postalCode ||
          this.computedClient.city ||
          this.computedClient.country)
          ? `${this.computedClient.address || ''}, ${this.computedClient.postalCode || ''} ${
              this.computedClient.city || ''
            } ${this.computedClient.country || ''}`
          : '';

      return address.trim();
    },
    canUseWhatsapp() {
      return (
        this.computedClient &&
        this.computedClient.phonenumber &&
        (this.computedClient.phonenumber.startsWith('+') || this.computedClient.phonenumber.startsWith('00'))
      );
    },
    computedClient() {
      return this.client || this.internalClient;
    },
    editionIsDisabled() {
      return !!(this.client || this.internalClient.archived);
    },
  },
  watch: {},
  methods: {
    openEmailComposerModal(item) {
      this.$store.dispatch('email/setShowEmailComposerModal', {
        show: true,
        recipientAddress: item.email,
        recipientObject: item,
      });
    },
    striptags,
    getClient() {
      return this.$store.dispatch('client/getClient', this.clientId);
    },

    saveClient() {
      if (typeof this.computedClient?.dealValue === 'string') {
        this.computedClient.dealValue = parseFloat(this.computedClient.dealValue);
      }
      return this.$store.dispatch('client/updateClient', this.computedClient);
    },
  },
};
</script>

<style lang="scss">
.client-details {
  font-size: 11.2px;
  color: #868e96;
  a {
    font-size: 11.2px;
  }
  a,
  i {
    color: #868e96;
    display: inline-block;
    padding: 0;
  }
  a:hover {
    cursor: pointer;
  }
  .description-details {
    font-size: 11.2px;
    * {
      font-size: 11.2px;
    }
  }
  .feather.feather--chevron-right {
    vertical-align: middle;
  }
}
</style>
