<template>
  <div class="col-xl-9 col-md-6 col-sm-12">
    <div class="email-right-aside">
      <div class="card email-body radius-left">
        <div class="pl-0">
          <div class="tab-content">
            <div
              class="tab-pane fade active show"
              v-if="!selectedEmail"
              id="pills-darkhome"
              role="tabpanel"
              aria-labelledby="pills-darkhome-tab"
            >
              <div class="email-compose">
                <div class="email-top compose-border">
                  <div class="row">
                    <div class="col-sm-8 xl-50">
                      <h6 class="mb-0">{{ $t('app.mailbox.new-message') }}</h6>
                    </div>
                    <div class="col-sm-4 btn-middle xl-50 text-right">
                      <button
                        @click="sendMail(selectedEmail, mailContent, mailSendMode, recipient)"
                        class="btn btn-primary btn-sm text-center mb-0 mt-0"
                        type="button"
                        :disabled="sendButtonIsDisabled"
                      >
                        <i class="fa fa-paper-plane mr-2"></i> {{ $t('app.mailbox.send') }}
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
                <div v-if="modal">
                  <p>
                    <label> {{ $t('app.mailbox.from') }}</label>
                    <select name="" v-model="selectedMailbox" class="form-control" id="">
                      <option>--</option>
                      <option :key="mailbox.id" :value="mailbox.id" v-for="mailbox in mailboxes">
                        {{ mailbox.email }}
                      </option>
                    </select>
                  </p>
                </div>

                <div class="email-wrapper">
                  <form class="theme-form">
                    <div>
                      <p>
                        <label> {{ $t('app.mailbox.template') }}</label>
                        <select
                          name=""
                          v-model="selectedTemplate"
                          class="form-control"
                          id=""
                          @change="onTemplateChanged"
                        >
                          <option>--</option>
                          <option :key="template.id" :value="template" v-for="template in templates">
                            {{ template.name }} ({{ template.section }})
                          </option>
                        </select>
                      </p>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label pt-0" for="exampleInputEmail1">{{ $t('app.mailbox.to') }}</label>
                      <v-select
                        v-model="recipient"
                        multiple
                        taggable
                        push-tags
                        :options="emailSuggestions"
                        class="form-control p-1"
                        :reduce="(option) => (option ? option.email || option.label || option : option)"
                        @search="completeEmail"
                      >
                      </v-select>
                      <div v-if="!showCcInput" class="pt-1" @click="showCcInput = true">
                        <u> {{ $t('app.mailbox.addCc') }} </u>
                      </div>
                      <!-- <vue-typeahead-bootstrap
                        :data="emailSuggestions"
                        :serializer="(item) => (item.name ? `${item.name} <${item.email}>` : item.email)"
                        :screen-reader-text-serializer="
                          (item) => (item.name ? `${item.name} <${item.email}>` : item.email)
                        "
                        :maxMatches="10"
                        :disableSort="true"
                        :minMatchingChars="0"
                        :showAllResults="true"
                        v-model="recipientQuery"
                        inputClass="form-control"
                        id="exampleInputEmail1"
                        type="email"
                        @input="completeEmail"
                        @hit="recipient = $event"
                      /> -->
                    </div>
                    <div v-if="showCcInput" class="form-group mb-1">
                      <label class="col-form-label pt-0" for="exampleInputEmail1">{{ $t('app.mailbox.cc') }}</label>
                      <v-select
                        v-model="ccRecipients"
                        :multiple="true"
                        :taggable="true"
                        :options="emailSuggestions"
                        class="form-control p-1"
                        @search="completeEmail"
                      >
                      </v-select>
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1"> {{ $t('app.mailbox.subject') }} </label>
                      <input v-model="mailSubject" class="form-control" id="" type="text" />
                    </div>
                    <div class="form-group mb-0">
                      <label class="text-muted">{{ $t('app.mailbox.message') }}</label>
                      <editor ref="tinymce" api-key="no-api-key" :init="editorConfig" v-model="mailContent" />
                    </div>
                  </form>
                </div>
                <div class="email-footer text-right">
                  <hr class="m-0" />
                  <ul class="actions pl-3 pr-0 pb-2 pt-2 mb-4">
                    <li :class="{ 'font-weight-bold': mailSendMode === 'send' }">
                      <button
                        @click="sendMail(selectedEmail, mailContent, mailSendMode, recipient)"
                        class="btn btn-primary btn-sm text-center mb-0 mt-0"
                        type="button"
                        :disabled="sendButtonIsDisabled"
                      >
                        <i class="fa fa-paper-plane mr-2"></i> {{ $t('app.mailbox.send') }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="email-wrap">
              <div
                class="tab-pane fade active show"
                v-if="selectedEmail && selectedEmail.id"
                id="pills-darkprofile"
                role="tabpanel"
                aria-labelledby="pills-darkprofile-tab"
              >
                <div class="email-content">
                  <div class="email-top">
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <div class="media">
                          <img
                            v-if="selectedEmail && selectedEmail.image"
                            class="mr-3 rounded-circle img-fluid img-50"
                            :src="getImgUrl(selectedEmail && selectedEmail.image)"
                            alt=""
                          />
                          <div class="media-body" v-if="selectedEmail">
                            <h3 class="font-12 text-primary">
                              {{ selectedEmail.subject || selectedEmail.parsedContent.subject }}
                              <span class="text-right text-black"
                                ><span class="digits"
                                  >[{{ formatDate(selectedEmail.parsedContent.date, 'D MMM YYYY - HH:mm') }}]</span
                                >
                              </span>
                            </h3>
                            <small>
                              From: {{ selectedEmail.from || selectedEmail.parsedContent.from }}
                              {{ selectedEmail.fromEmail || selectedEmail.parsedContent.fromEmail }}
                            </small>
                            <p class="text-info">
                              Mailbox:
                              {{ getRecipientEmail(selectedEmail.mailboxId) }}
                            </p>
                            <p class="user-emailid">
                              To:
                              {{ displayRecipients(selectedEmail) }}
                            </p>
                            <div class="badge badge-info p-1" v-if="selectedEmail.leadId">
                              <router-link
                                class="btn btn-icon p-0 d-flex text-white"
                                data-tooltip="Lead créé | Voir le client"
                                :to="`/app/lead/${selectedEmail.leadId}`"
                              >
                                <small>{{ $t('app.models.lead') }}</small>
                              </router-link>
                            </div>
                            <div class="badge badge-info p-1" v-if="selectedEmail.clientId">
                              <button
                                class="btn btn-icon p-0 d-flex text-white"
                                data-tooltip="Client créé | Voir le client"
                                @click="$router.push(`/app/client/${selectedEmail.clientId}`)"
                              >
                                <small>{{ $t('app.models.client') }}</small>
                              </button>
                            </div>
                            <div class="badge badge-info p-1" v-if="selectedEmail.requestId">
                              <button
                                class="btn btn-icon p-0 d-flex text-white"
                                title="Demande associée"
                                data-tooltip="Voir la demande associée"
                                @click="$router.push(`/app/request/${selectedEmail.requestId}`)"
                              >
                                <small>{{ $t('app.models.request') }}</small>
                              </button>
                            </div>
                            <div class="badge badge-info p-1" v-if="selectedEmail.candidateId">
                              <button
                                class="btn btn-icon p-0 text-white d-flex"
                                title="Candidat associé à cet email"
                                data-tooltip="Voir le candidat associé à cet email"
                                @click="$router.push(`/app/candidate/${selectedEmail.candidateId}`)"
                              >
                                <small>{{ $t('app.models.candidate') }}</small>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="float-right d-flex">
                          <div class="email-actions">
                            <template v-if="canDisplayEmailActions">
                              <div class="dropdown d-inline-block">
                                <button
                                  class="btn bg-transparent p-0 text-primary"
                                  id="dropdownMenuButton"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  v-on:click="toggleFolderDropdown()"
                                  data-tooltip="Créer à partir de cet email..."
                                >
                                  <feather class="text-primary" type="folder-plus"></feather>
                                </button>
                                <div
                                  class="dropdown-menu dropdown-menu-right"
                                  aria-labelledby="dropdownMenuButton"
                                  :class="{ show: folderDropdownIsOpen }"
                                >
                                  <button
                                    v-if="!selectedEmail.leadId"
                                    class="dropdown-item"
                                    @click="createLead(selectedEmail)"
                                  >
                                    <feather class="text-primary" type="user-plus" size="15"></feather>
                                    {{ $t('app.mailbox.create-lead') }}
                                  </button>
                                  <template v-if="!selectedEmail.clientId">
                                    <button class="dropdown-item" @click="createClient(selectedEmail)">
                                      <feather class="text-primary" type="user-plus" size="15"></feather>
                                      {{ $t('app.mailbox.create-client') }}
                                    </button>
                                    <button
                                      class="dropdown-item pl-2"
                                      :title="$t('app.mailbox.create-client-and-request')"
                                      :data-tooltip="$t('app.mailbox.create-client-and-request')"
                                      @click="createClientAndRequest(selectedEmail)"
                                    >
                                      <samp>
                                        <feather class="text-primary" type="user-plus" size="15"></feather>
                                        <feather class="text-primary" type="mail" size="15"></feather>
                                      </samp>
                                      {{ $t('app.mailbox.create-client-and-request') }}
                                    </button>
                                  </template>
                                  <button
                                    class="dropdown-item"
                                    @click="createRequest(selectedEmail)"
                                    title="Créer une nouvelle demande"
                                    data-tooltip="Créer une nouvelle demande"
                                    v-if="
                                      organisation.featuresClientConversations &&
                                      !selectedEmail.requestId &&
                                      selectedEmail.clientId
                                    "
                                  >
                                    <samp>
                                      <feather class="text-primary" type="mail" size="15"></feather>
                                      <feather
                                        class="text-primary"
                                        type="plus"
                                        size="10"
                                        style="position: absolute; top: 3px; left: 15px"
                                      ></feather>
                                    </samp>
                                    {{ $t('app.mailbox.create-request') }}
                                  </button>
                                  <button
                                    class="dropdown-item"
                                    @click="createCandidate(selectedEmail)"
                                    title="Créer une candidat (rh)"
                                    data-tooltip="Créer une candidat (rh)"
                                    v-if="organisation.featuresHumanResources && !selectedEmail.candidateId"
                                  >
                                    <feather class="text-primary" type="plus-square" size="15"></feather>
                                    {{ $t('app.mailbox.create-candidate') }}
                                  </button>
                                  <button class="dropdown-item" @click="toggleFolderDropdown(false)">
                                    <feather class="text-primary" type="x" size="15"></feather>
                                    {{ $t('common.buttons.cancel') }}
                                  </button>
                                </div>
                              </div>
                              <span class="mx-3">|</span>
                            </template>
                            <button
                              class="btn btn-icon p-0"
                              data-tooltip="Archiver le mail"
                              v-if="selectedEmail.id"
                              @click="archiveEmail(selectedEmail)"
                            >
                              <feather class="text-info" type="archive"></feather>
                            </button>
                            <span class="mx-3">|</span>

                            <button
                              class="btn btn-icon p-0"
                              data-tooltip="close"
                              v-if="selectedEmail.id"
                              @click="unselectEmail()"
                            >
                              <feather class="text-info" type="x"></feather>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ul class="actions pl-3 pr-3 pb-2 pt-2 text-right">
                      <li :class="{ 'font-weight-bold': mailSendMode === 'reply' }">
                        <a @click="openMailEditor('reply')" class="text-muted"
                          ><i class="fa fa-reply mr-2"></i>{{ $t('app.mailbox.reply') }}</a
                        >
                      </li>
                      <li :class="{ 'font-weight-bold': mailSendMode === 'replyAll' }">
                        <a @click="openMailEditor('replyAll')" class="text-muted"
                          ><i class="fa fa-reply-all mr-2"></i>{{ $t('app.mailbox.reply-all') }}</a
                        >
                      </li>
                      <li :class="{ 'font-weight-bold': mailSendMode === 'forward' }">
                        <a @click="openMailEditor('forward')" class="text-muted"
                          ><i class="fa fa-share mr-2"></i>{{ $t('app.mailbox.forward') }}</a
                        >
                      </li>
                    </ul>
                    <hr class="m-0" />
                  </div>
                  <div class="email-wrapper">
                    <template v-if="selectedEmail.parsedContent && selectedEmail.parsedContent.attachments">
                      <MailBoxAttachments
                        :attachments="selectedEmail.parsedContent && selectedEmail.parsedContent.attachments"
                      ></MailBoxAttachments>
                      <hr />
                    </template>
                    <div v-if="currentEmailContent" v-html="currentEmailContent"></div>
                    <!-- email content editor -->
                    <div v-if="showEditor" class="email-editor">
                      <hr v-if="mailSendMode !== 'new'" />
                      <form class="theme-form mt-1">
                        <div class="form-group">
                          <p>
                            <label> {{ $t('app.mailbox.template') }}</label>
                            <select
                              name=""
                              v-model="selectedTemplate"
                              class="form-control"
                              id=""
                              @change="onTemplateChanged"
                            >
                              <option>--</option>
                              <option :key="template.id" :value="template" v-for="template in templates">
                                {{ template.name }} ({{ template.section }})
                              </option>
                            </select>
                          </p>
                        </div>
                        <div class="form-group">
                          <label class="col-form-label pt-0" for="exampleInputEmail1">{{
                            $t('app.mailbox.to')
                          }}</label>
                          <v-select
                            v-model="recipient"
                            multiple
                            taggable
                            push-tags
                            :options="emailSuggestions"
                            class="form-control p-1"
                            @search="completeEmail"
                          >
                          </v-select>
                          <div v-if="!showCcInput" class="pt-1 mb-3" @click="showCcInput = true">
                            <u> {{ $t('app.mailbox.addCc') }} </u>
                          </div>
                        </div>
                        <div v-if="showCcInput" class="form-group">
                          <label class="col-form-label pt-0" for="exampleInputEmail1">
                            {{ $t('app.mailbox.cc') }}
                          </label>
                          <v-select
                            v-model="ccRecipients"
                            :multiple="true"
                            :taggable="true"
                            :options="emailSuggestions"
                            class="form-control p-1"
                            @search="completeEmail"
                          >
                          </v-select>
                        </div>
                        <div class="form-group">
                          <!-- <vue-typeahead-bootstrap
                            v-if="mailSendMode === 'forward'"
                            :data="emailSuggestions"
                            :serializer="(item) => item.email"
                            :screen-reader-text-serializer="
                              (item) => (item.name ? `${item.name} <${item.email}>` : item.email)
                            "
                            :maxMatches="10"
                            v-model="recipientQuery"
                            inputClass="form-control mt-2 mb-2 w-50"
                            id="exampleInputEmail1"
                            type="email"
                            placeholder="example@example.com"
                            @input="completeEmail"
                            @hit="recipient = $event"
                          />
                          <input
                            v-if="mailSendMode !== 'forward' && recipient"
                            type="text"
                            class="form-control"
                            disabled="disabled"
                            :value="recipient"
                          /> -->
                          <editor ref="tinymce" api-key="no-api-key" :init="editorConfig" v-model="mailContent" />
                          <div>
                            <button
                              @click="sendMail(selectedEmail, mailContent, mailSendMode, recipient)"
                              class="btn btn-primary btn-block btn-mail btn-sm btn-sm text-center mb-0 mt-1"
                              type="button"
                              :disabled="sendButtonIsDisabled"
                            >
                              <i
                                class="fa mr-2"
                                :class="{ 'fa-spinner fa-spin': isSending, 'fa-paper-plane': !isSending }"
                              ></i>
                              {{ $t('app.mailbox.send') }}
                            </button>
                            <div class="w-100 pt-1 text-secondary" v-if="!this.mailboxIsSelected">
                              {{ $t('app.mailbox.select-mailbox') }}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <!-- email content editor end -->
                    <template v-if="selectedEmail.parsedContent && selectedEmail.parsedContent.attachments">
                      <hr />
                      <MailBoxAttachments
                        :attachments="selectedEmail.parsedContent && selectedEmail.parsedContent.attachments"
                      ></MailBoxAttachments>
                    </template>
                  </div>
                  <div class="email-footer text-right">
                    <hr class="m-0" />
                    <ul class="actions pl-3 pr-3 pb-2 pt-2 mb-4">
                      <li :class="{ 'font-weight-bold': mailSendMode === 'reply' }">
                        <a @click="openMailEditor('reply')" class="text-muted"
                          ><i class="fa fa-reply mr-2"></i>{{ $t('app.mailbox.reply') }}</a
                        >
                      </li>
                      <li :class="{ 'font-weight-bold': mailSendMode === 'replyAll' }">
                        <a @click="openMailEditor('replyAll')" class="text-muted"
                          ><i class="fa fa-reply-all mr-2"></i>{{ $t('app.mailbox.reply-all') }}</a
                        >
                      </li>
                      <li :class="{ 'font-weight-bold': mailSendMode === 'forward' }">
                        <a @click="openMailEditor('forward')" class="text-muted"
                          ><i class="fa fa-share mr-2"></i>{{ $t('app.mailbox.forward') }}</a
                        >
                      </li>
                      <li :class="{ 'font-weight-bold': mailSendMode === 'forward' }">
                        <button
                          @click="sendMail(selectedEmail, mailContent, mailSendMode, recipient)"
                          class="btn btn-primary btn-sm text-center mb-0 mt-0"
                          type="button"
                          :disabled="sendButtonIsDisabled"
                        >
                          <i class="fa fa-paper-plane mr-2"></i> {{ $t('app.mailbox.send') }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import striptags from 'striptags';
import VSelect from 'vue-select';
import dayjs from 'dayjs';
import marked from 'marked';
import Editor from '@tinymce/tinymce-vue';
// import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import { mapState } from 'vuex';
import MailBoxAttachments from '@/components/mailbox/Attachments.vue';

import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/code';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
// import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/image';

import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/icons/default/';

import config from '../../config';

export default {
  name: 'EmailComposer',
  components: {
    MailBoxAttachments,
    editor: Editor,
    // VueTypeaheadBootstrap,
    'v-select': VSelect,
  },
  props: {
    mailboxAddresses: {
      type: Array,
      default: () => [],
    },
    client: {
      type: Object,
      default: null,
    },
    recipientObject: {
      type: Object,
      default: null,
    },
    currentMailBoxConfig: {
      default: null,
    },
    selectedEmail: {
      type: Object,
      default: null,
    },
    toEmails: {
      type: [Array, String],
      default: null,
    },
    modal: {
      type: Boolean,
      default: null,
    },
    closeModalAfterSend: {
      type: Boolean,
      default: false,
    },
    subject: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.modal) {
      this.getMailboxes();
    }
    this.getTemplates();
    if (this.$route.query.to) {
      this.recipient = this.$route.query.to;
    }
    if (this.toEmails) {
      this.recipientQuery = typeof this.toEmails === 'string' ? this.toEmails : this.toEmails.join(', ');
      this.recipient = this.recipientQuery;
    }
    if (this.subject) {
      this.mailSubject = this.subject;
    }
    if (this.body) {
      this.mailContent = this.body;
    }
    if (this.$route.query.cc) {
      this.recipient += this.$route.query.cc;
    }
    this.focusTinymceInput();
  },
  data() {
    return {
      // internal Mailbox selector
      showCcInput: false,
      selectedMailbox: this.currentMailBoxConfig && this.currentMailBoxConfig.id,
      selectedTemplate: null,
      mailboxes: this.mailboxAddresses,
      recipient: [],
      ccRecipients: [],
      mailContent: '',
      mailSendMode: 'new', // new, reply, replyAll, forward
      mailSubject: '',
      completeEmailTimeout: null,
      emailSuggestions: [],
      recipientQuery: null,
      editorConfig: {
        height: 500,
        width: '100%',
        menubar: false,
        images_upload_url: `${config.apiUrl || 'http://localhost:1335'}api/media/upload`,
        color_map: [
          '#47776e',
          'Primary Green',
          '#656278',
          'Brown',
          '#F9EEE5',
          'Beige',
          '#ECCAFA',
          'Light Purple',
          '#C2E0F4',
          'Light Blue',
          '#2DC26B',
          'Green',
          '#F1C40F',
          'Yellow',
          '#E03E2D',
          'Red',
          '#B96AD9',
          'Purple',
          '#3598DB',
          'Blue',
          '#169179',
          '#000000',
          'Black',
          '#ffffff',
          'White',
        ],

        plugins: [
          'code',
          'advlist autolink lists link image charmap preview anchor',
          'media table',
          'image',
          'textpattern',
        ],
        toolbar: 'bold italic underline | formatgroup | paragraphgroup | insertgroup',
        toolbar_location: 'bottom',
        skin: 'outside',
        default_link_target: '_blank',
        textpattern_patterns: [
          { start: '*', end: '*', format: 'italic' },
          { start: '**', end: '**', format: 'bold' },
          { start: '#', format: 'h1' },
          { start: '##', format: 'h2' },
          { start: '###', format: 'h3' },
          { start: '####', format: 'h4' },
          { start: '#####', format: 'h5' },
          { start: '######', format: 'h6' },
          // The following text patterns require the `lists` plugin
          { start: '1. ', cmd: 'InsertOrderedList' },
          { start: '* ', cmd: 'InsertUnorderedList' },
          { start: '- ', cmd: 'InsertUnorderedList' },
          { start: '---', replacement: '<hr/>' },
          { start: '--', replacement: '—' },
        ],
        toolbar_groups: {
          formatgroup: {
            icon: 'format',
            tooltip: 'Formatting',
            items: 'strikethrough | formatselect fontsizeselect | forecolor backcolor | removeformat | code',
          },
          paragraphgroup: {
            icon: 'paragraph',
            tooltip: 'Paragraph format',
            items: 'h1 h2 h3 | bullist numlist | alignleft aligncenter alignright alignjustify | indent outdent',
          },
          insertgroup: {
            icon: 'plus',
            tooltip: 'Insert',
            items: 'image media link',
          },
        },
        setup: this.tinymceAutocomplete,
      },
      showEditor: false,
      isSending: false,
      folderDropdownIsOpen: false,
      templates: [],
      lead: null,
    };
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    ...mapState('email', ['selectedEmail']),
    mailboxAddressesMap() {
      return this.mailboxAddresses
        ? this.mailboxAddresses.reduce((acc, current) => {
          acc[current.id] = current;
          return acc;
        }, {})
        : {};
    },
    selectedMailboxId() {
      return (
        (this.currentMailBoxConfig && this.currentMailBoxConfig.id) ||
        (this.selectedEmail && this.selectedEmail.mailboxId) ||
        this.selectedMailbox
      );
    },
    mailboxIsSelected() {
      return this.selectedMailboxId && typeof this.selectedMailboxId === 'number';
    },
    sendButtonIsDisabled() {
      if (this.mailSendMode === 'reply' || this.mailSendMode === 'replyAll') {
        // we check the fact that it's a number because the default mailbox is '--'
        return this.isSending || !this.mailboxIsSelected || !this.mailContent;
      }
      if (this.mailSendMode === 'forward') {
        return this.isSending || !this.recipient || !this.isEmailValid(this.recipient) || !this.mailboxIsSelected;
      }
      return (
        this.isSending ||
        !this.recipient ||
        !(
          (Array.isArray(this.recipient) &&
            this.recipient.length &&
            this.recipient.every((m) => this.isEmailValid(m))) ||
          this.isEmailValid(this.recipient)
        ) ||
        !this.mailSubject ||
        !this.mailContent ||
        !this.mailboxIsSelected
      );
    },
    currentEmailContent() {
      if (!this.selectedEmail) {
        return '';
      }
      if (!this.selectedEmail.parsedContent && !this.selectedEmail.content) {
        return '';
      }
      let html =
        this.selectedEmail.content ||
        this.selectedEmail.parsedContent.text ||
        this.selectedEmail.parsedContent.html ||
        this.selectedEmail.parsedContent.snippet ||
        '';
      html = html.replace(/\r\n?|\n/g, '<br />');
      html = striptags(marked(html), [
        'strong',
        'a',
        'p',
        'br',
        'img',
        'table',
        'tr',
        'td',
        'th',
        'tbody',
        'ul',
        'li',
        'img',
      ])
        .replace(/<a /gi, '<a target="_blank"') // open links in new tab
        .replace(/<img \/api\/follow\-up\/email/gi, ''); // remove follow-up pixel

      return html;
      // return 'Missing body';
    },
    canDisplayEmailActions() {
      return this.selectedEmail && !this.selectedEmail.isOutbound;
    },
  },
  watch: {
    email(newValue, old) {
      this.mailSendMode = null;
    },
    selectedEmail(newValue, old) {
      this.showEditor = false;
      this.mailSendMode = 'new';
    },
  },
  methods: {
    dayjs,
    striptags,
    marked,
    async getMailboxes() {
      let mailboxes = [];
      mailboxes = await this.$http.get('/api/mailbox').then(({ data }) => data.body);
      this.mailboxes = mailboxes;
      const myMailbox = mailboxes.find((m) => m.email === this.user?.email);
      this.selectedMailbox = myMailbox?.id || (mailboxes[0] ? mailboxes[0].id : null);
    },
    async getTemplates() {
      let templates = [];
      templates = await this.$http.get('/api/template?filters[type]=email').then(({ data }) => data.body);
      this.templates = templates;
    },

    isEmailValid(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/.test(email)) {
        return true;
      }
      return false;
    },

    getImgUrl(path) {
      return path;
      // return require('../../assets/images/' + path);
    },

    formatDate(str, format) {
      return dayjs(str).format(format);
    },
    displayRecipients(email) {
      return (
        (email.to && email.to.length && email.to.map((m) => `${m.name} <${m.address}>`).join('; ')) ||
        email.parsedContent.email
      );
    },
    getRecipientEmail(mailboxId) {
      return this.mailboxAddressesMap[mailboxId] ? this.mailboxAddressesMap[mailboxId].email : '';
    },

    sendMail(inReplyToEmail, message, mailSendMode, recipient) {
      this.isSending = true;
      this.$store
        .dispatch('email/sendEmail', {
          inReplyToEmail,
          message,
          mailSendMode,
          recipient,
          cc: this.ccRecipients,
          mailboxId: this.selectedMailboxId,
          subject: this.mailSubject,
        })
        .then(async (result) => {
          this.$emit('email-sent', result);
          await this.getEmails();
          this.$awNotify({ title: 'Votre réponse a bien été envoyée.', type: 'success' });
          this.setSelectedEmail(inReplyToEmail);
          this.showEditor = false;
          this.mailSendMode = 'new';
          this.resetEmailComposer();

          if (this.closeModalAfterSend) {
            this.$store.dispatch('email/setShowEmailComposerModal', { show: false, recipientObject: {} });
          }
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.showCcInput = false;
          this.ccRecipients = [];
          setTimeout(() => {
            this.isSending = false;
          }, 2000);
        });
    },

    createClientAndRequest(email) {
      this.createClient(email).then(() => this.createRequest(email));
    },

    createClient(email) {
      const { fromEmail, from, attachments } = email.parsedContent;
      // const clientId = email.clientId || (this.client ? this.client.id : null);
      return this.$store
        .dispatch('client/createClient', {
          name: from,
          email: fromEmail.toLowerCase(),
          company: fromEmail ? fromEmail.toLowerCase().replace(/.*@/, '') : '',
          messageId: email.id,
        })
        .then((client) => {
          this.$awNotify({ type: 'success', title: 'Le nouveau client à bien été créé !' });
          this.client = client;
          if (attachments && attachments.length) {
            attachments.forEach((attachment) => {
              this.$http.post(`/api/client/${client.id}/document`, attachment);
            });
          }
          this.$store.dispatch('email/updateEmail', { id: email.id, clientId: client.id });
        })
        .catch((err) => this.$awNotify({ type: 'danger', title: err.message }));
    },
    createLead(email) {
      const { fromEmail, from, attachments } = email.parsedContent;
      return this.$store
        .dispatch('lead/createItem', {
          name: from,
          email: fromEmail.toLowerCase(),
          company: fromEmail ? fromEmail.toLowerCase().replace(/.*@/, '') : '',
          fromEmail: fromEmail.toLowerCase(),
          messageId: email.id,
          mailboxId: email.mailboxId,
          description: email.content,
        })
        .then((lead) => {
          this.$awNotify({ type: 'success', title: 'Le nouveau lead à bien été créé !' });
          this.lead = lead;
          if (attachments && attachments.length) {
            attachments.forEach((attachment) => {
              this.$http.post(`/api/lead/${lead.id}/document`, attachment);
            });
          }
          this.$store.dispatch('email/updateEmail', { id: email.id, leadId: this.lead.id });
        })
        .catch((err) => this.$awNotify({ type: 'danger', title: err.message }));
    },

    createRequest(email) {
      const clientId = email.clientId || (this.client ? this.client.id : null);
      if (!clientId) {
        return;
      }
      const { snippet, text, fromEmail, subject, attachments } = email.parsedContent;
      return this.$store
        .dispatch('request/createRequest', {
          clientId,
          title: subject,
          description: text || snippet,
          fromEmail: fromEmail.toLowerCase(),
          mailboxId: email.mailboxId,
          messageId: email.id,
          externalId: email.externalId,
          source: email.source,
        })
        .then((request) => {
          this.$awNotify({ type: 'success', title: 'La nouvelle demande client à bien été créée !' });
          this.$store.dispatch('email/updateEmail', {
            id: email.id,
            requestId: request.id,
            clientId: clientId || undefined,
          });
          if (attachments && attachments.length) {
            attachments.forEach((attachment) => {
              this.$http.post(`/api/request/${request.id}/document`, attachment);
            });
          }
          this.selectedEmail.requestId = request.id;
        })
        .catch((err) => this.$awNotify({ type: 'danger', title: err.message }));
    },

    /** create a new candidate for the hr sections */
    createCandidate(email) {
      const { snippet, text, fromEmail, subject, from, attachments } = email.parsedContent;

      return this.$store
        .dispatch('candidate/createItem', {
          firstName: from.split(' ')[0],
          lastName: from.split(' ')[1] + (from.split(' ')[2] ? ` ${from.split(' ')[2]}` : ''),
          description: `${subject} \n\n${text || snippet}`,
          email: fromEmail.toLowerCase(),
          source: email.source || 'email',
        })
        .then((candidate) => {
          this.$awNotify({ type: 'success', title: 'La nouvelle demande client à bien été créée !' });
          if (attachments && attachments.length) {
            attachments.forEach((attachment) => {
              this.$http.post(`/api/candidate/${candidate.id}/document`, attachment);
            });
          }
          this.$store.dispatch('email/updateEmail', { id: email.id, candidateId: candidate.id });
          this.selectedEmail.candidateId = candidate.id;
        })
        .catch(this.apiErrorCallback);
    },

    archiveEmail(email) {
      email.isArchived = true;
      this.$store
        .dispatch('email/archiveEmail', email)
        .then(() => this.$awNotify({ title: 'La conversation a bien été archivée.', type: 'success' }))
        .then(() => this.getEmails())
        .catch(this.apiErrorCallback);
    },

    unselectEmail() {
      this.setSelectedEmail(false);
      this.recipients = [];
      this.mailSendMode = 'new';
    },

    scrollToTop() {
      if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
        window.scrollBy(0, -50);
        requestAnimationFrame(this.scrollToTop);
      }
    },

    async setSelectedEmail(email) {
      this.$emit('toggleClient');
      this.client = null;
      this.scrollToTop();
      const { parsedContent } = email;
      if (parsedContent && parsedContent.html === 'undefined') {
        email.parsedContent.html = '';
      }
      if (parsedContent && parsedContent.text === 'undefined') {
        email.parsedContent.text = '';
      }
      await this.$store.dispatch('email/setSelectedEmail', email);

      // Do not continue if any email was passed
      if (!email) {
        return;
      }

      email.isRead = true;
      this.$store.dispatch('email/readEmail', {
        id: email.id,
        isRead: true,
      });
      const [client] = await this.$store.dispatch('client/getClients', {
        save: false,
        params: { filters: { email: email.parsedContent && email.parsedContent.fromEmail } },
      });
      if (client && client.id) {
        this.client = client;
      }
    },

    getEmails(params) {
      const isArchived = this.$route.url && this.$route.url.startsWith('/app/mailbox') ? { $ne: 1 } : undefined;
      const isOutbound = this.$route.url && this.$route.url.startsWith('/app/mailbox') ? { $ne: 1 } : undefined;
      return this.$store
        .dispatch('email/getEmails', {
          params: {
            perPage: this.perPage,
            sort: { sentOrReceivedOn: 'desc' },
            filters: {
              isArchived,
              isOutbound,
            },
            ...params,
          },
        })
        .then((emails) => {
          this.setSelectedEmail(emails && emails[0]);
        });
    },

    completeEmail(searchedEmail) {
      if (this.completeEmailTimeout) {
        clearTimeout(this.completeEmailTimeout);
      }
      // this.recipient = this.recipientQuery;

      if (searchedEmail) {
        this.completeEmailTimeout = setTimeout(async () => {
          const query = `${searchedEmail}`;
          const res = await this.$http.get('/api/autocomplete-email', {
            params: {
              q: query,
              perPage: 10,
            },
          });
          this.emailSuggestions = res.data.body;
        }, 500);
      }

      // if (this.recipientQuery) {
      // this.completeEmailTimeout = setTimeout(async () => {
      //   const query = `${this.recipientQuery}`;
      //   const res = await this.$http.get('/api/autocomplete-email', {
      //     params: {
      //       q: query,
      //       perPage: 10,
      //     },
      //   });
      //   this.emailSuggestions = res.data.body;
      // }, 500);
      // }
    },

    tinymceAutocomplete(editor) {
      const onAction = function (autocompleteApi, rng, value) {
        editor.selection.setRng(rng);
        editor.insertContent(value);
        autocompleteApi.hide();
      };

      const specialChars = [
        { text: 'firstName', value: '{{firstName}}' },
        { text: 'lastName', value: '{{lastName}}' },
        { text: 'currentPosition', value: '{{currentPosition}}' },
        { text: 'company', value: '{{company}}' },
      ];

      const getMatchedChars = function (pattern) {
        if (!pattern) {
          return specialChars;
        }
        return specialChars.filter((char) => char.text.toLowerCase().includes(pattern));
      };

      /* An autocompleter that allows you to insert special characters */
      editor.ui.registry.addAutocompleter('specialchars', {
        ch: '{',
        minChars: 0,
        columns: 'auto',
        onAction,
        fetch(pattern) {
          return new Promise((resolve) => {
            const results = getMatchedChars(pattern).map((char) => ({
              type: 'cardmenuitem',
              value: char.value,
              items: [
                {
                  type: 'cardcontainer',
                  direction: 'vertical',
                  items: [
                    {
                      type: 'cardtext',
                      text: char.text,
                      name: 'char_name',
                    },
                  ],
                },
              ],
            }));
            resolve(results);
          });
        },
      });
    },

    toggleFolderDropdown(status) {
      if (status !== undefined) {
        this.folderDropdownIsOpen = !!status;
        return;
      }
      this.folderDropdownIsOpen = !this.folderDropdownIsOpen;
    },
    openMailEditor(sendMode = 'new') {
      this.resetEmailComposer();
      if (
        (sendMode === 'reply' || sendMode === 'replyAll') &&
        !this.recipient.includes(this.selectedEmail.fromEmail)
      ) {
        this.recipient.push(this.selectedEmail.fromEmail);
        this.mailSubject = `Re: ${this.selectedEmail.subject}`;
        this.mailContent = '';
      }

      if (sendMode === 'forward') {
        //   this.recipient = [];
      }

      this.showEditor = true;
      this.mailSendMode = sendMode;
      window.scrollTo({
        top: Math.max(document.body.scrollHeight, document.documentElement.scrollHeight),
        behavior: 'smooth',
      });
      this.focusTinymceInput();
    },

    focusTinymceInput() {
      setTimeout(() => {
        if (this.$refs.tinymce) {
          // eslint-disable-next-line prefer-template
          let iframe = document.getElementById(this.$refs.tinymce.elementId + '_ifr');
          if (iframe) {
            iframe = iframe.contentWindow.document;
          }
          iframe.getElementById('tinymce').focus();
        }
      }, 10);
    },

    onTemplateChanged() {
      if (!this.selectedTemplate) {
        return;
      }
      this.mailContent = this.selectedTemplate.content;
      this.mailSubject = this.selectedTemplate.title;
      if (this.recipientObject) {
        Object.entries(this.recipientObject).forEach(([key, value]) => {
          this.mailContent = this.mailContent.replace(new RegExp(`{{${key}}}`, 'g'), value);
          this.mailSubject = this.mailSubject.replace(new RegExp(`{{${key}}}`, 'g'), value);
        });
      }
    },

    resetEmailComposer() {
      this.mailSubject = '';
      this.mailContent = '';
      this.recipient = [];
      this.recipientQuery = null;
    },
  },
};
</script>

<style>
@import '~tinymce/skins/ui/oxide/skin.css';
@import '~tinymce/skins/ui/oxide/content.min.css';

.vue-form-generator .field-wrap .tox-editor-header button,
.vue-form-generator .field-wrap .tox-editor-header input[type='submit'] {
  border-radius: 0;
  border: initial;
  display: inherit;
  padding: initial;
}

.ck-editor__editable {
  min-height: 80px;
}

.theme-form .form-group input[type='search'] {
  border: none !important;
}

.inbox-list-item {
  border-bottom: 1px solid #ddd;
}

.email-wrap .email-app-sidebar .main-menu > li {
  width: auto !important;
}

.btn:disabled:hover {
  opacity: 0.5 !important;
  background-color: gray !important;
  border-color: gray !important;
}
</style>
