<template>
  <div :class="widget ? 'email-wrap' : 'col-xl-3 col-md-6 col-sm-12'">
    <div class="email-right-aside">
      <div class="card email-body">
        <div class="pr-0 b-r-light">
          <div class="email-top">
            <h5 v-if="widget" class="widget-title">{{ $t('common.labels.messages') }} [{{ totalCount }}]</h5>
            <div class="row">
              <div class="col">
                <div class="btn-group btn-sm pl-0">
                  <button
                    @click="type = 'inbox'"
                    class="btn btn-sm"
                    :class="type === 'inbox' ? 'btn-primary' : 'btn-outline-primary'"
                  >
                    <span class=""> Inbox</span>
                  </button>
                  <button
                    @click="type = 'sent'"
                    class="btn btn-sm"
                    :class="type === 'sent' ? 'btn-primary' : 'btn-outline-primary'"
                  >
                    <span class="digits"> Sent</span>
                  </button>
                  <button
                    class="btn btn-sm"
                    @click="type = 'archived'"
                    :class="type === 'archived' ? 'btn-primary' : 'btn-outline-primary'"
                  >
                    <span class="title"><i class="icon-folder"></i> {{ $t('app.mailbox.archived') }} </span>
                  </button>
                </div>
              </div>
              <div class="col text-right d-flex pt-1 pb-1">
                <button class="btn bg-transparent text-muted p-0" id="" type="button" @click="goToPage(0)">
                  <feather type="refresh-cw" :class="{ 'text-primary': isSearching }"></feather>
                </button>
                <div class="dropdown">
                  <button
                    class="btn bg-transparent p-1 text-muted"
                    id="dropdownMenuButton"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    v-on:click="menutoogle = !menutoogle"
                  >
                    <feather type="more-vertical"></feather>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" :class="{ show: menutoogle }">
                    <a
                      class="dropdown-item"
                      @click.prevent="
                        goToPage(0);
                        menutoogle = false;
                      "
                      href="#"
                      >{{ $t('app.mailbox.refresh') }}</a
                    >
                    <a class="dropdown-item" @click.prevent="displaySnippets = !displaySnippets" href="#">{{
                      displaySnippets ? $t('app.mailbox.hide-snippets') : $t('app.mailbox.show-snippets')
                    }}</a>
                    <router-link class="dropdown-item" to="/app/settings/rule">
                      {{ $t('app.mailbox.filtering-rules') }}
                    </router-link>
                    <a class="dropdown-item" @click.prevent="window.confirm('are you sure')" href="#">
                      {{ $t('app.mailbox.apply-filtering-rules') }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <input type="text" class="form-control" v-model="search" />
              </div>
            </div>
          </div>
          <div class="inbox custom-scrollbar-scrollbar" :style="!embedded ? 'height: calc(100vh - 80px)' : ''">
            <transition-group name="list" tag="div" class="list-items">
              <template v-if="emails && emails.length">
                <div
                  class="media inbox-list-item py-2"
                  :class="{
                    active: selectedEmail && email.id === selectedEmail.id,
                    treated: email && (email.isRead || email.isArchived),
                  }"
                  :style="{
                    'background-color': email.isRead ? 'rgba(0,188,212, 0.06)' : '#fff',
                  }"
                  v-for="email in emails"
                  :key="email.id"
                  @click="setSelectedEmail(email)"
                >
                  <div class="media-size-email">
                    <img
                      v-if="email && email.image"
                      class="mr-3 rounded-circle img-fluid img-50"
                      :src="getImgUrl(email)"
                      alt=""
                    />
                  </div>

                  <div class="media-body">
                    <div v-if="email && email.parsedContent" :class="'fw-300'">
                      <div
                        class=""
                        :style="{
                          color: email.isRead ? '#222' : '#222',
                          'font-weight': email.isRead ? '300' : 700,
                        }"
                      >
                        <feather
                          v-if="!email.isRead"
                          type="circle"
                          size="10"
                          class="mr-2 text-primary"
                          fill="var(--primary)"
                        ></feather>
                        <template v-if="email.isOutbound">
                          {{ displayRecipients(email, true) }}
                          <feather
                            v-if="email.isOpened"
                            type="circle"
                            size="10"
                            class="mr-2 text-primary"
                            fill="var(--primary)"
                            data-tooltip="opened by the recipient"
                          ></feather>
                        </template>
                        <template v-if="!email.isOutbound">{{
                          email.from || email.fromEmail || email.parsedContent.from || email.parsedContent.fromEmail
                        }}</template>
                        <small>
                          <feather
                            v-if="email.candidateId || email.clientId || email.requestId || email.leadId"
                            type="check"
                            size="10"
                            class="mr-2 text-success pull-right d-none"
                          ></feather>
                          <span class="digits pull-right">{{
                            formatDate(email.sentOrReceivedOn || email.createdOn, 'DD/MMM/YY - HH:mm')
                          }}</span>
                        </small>
                      </div>
                    </div>
                    <div class="text-italic text-truncate small">
                      {{ email.subject || email.parsedContent.subject }}
                    </div>
                    <div class="text-italic text-truncate small text-muted" v-if="displaySnippets">
                      {{ email.snippet || email.parsedContent.snippet }}
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="!(emails && emails.length) && !isSearching">
                <div key="noresult" class="text-center">
                  <noResult v-if="!widget"></noResult>
                </div>
              </template>
            </transition-group>
          </div>
          <nav aria-label="Page navigation example text-center w-100">
            <ul class="pagination w-100 justify-content-center">
              <li class="page-item">
                <a class="page-link" @click="goToPage(page - 1)" href="#" :disabled="page > 1">Précédent</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">{{ page }}</a>
              </li>
              <li class="page-item"><a class="page-link" href="#" @click="goToPage(page + 1)">Suivant</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import noResult from '@/components/basic/NoResult.vue';

export default {
  name: 'InboxWidget',
  components: {
    noResult,
  },

  props: {
    model: {
      type: String,
    },
    modelId: {
      type: Number,
    },
    widget: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
    emailAddresses: {
      type: Array,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
    mailboxId: {
      type: [String, Number],
    },
  },
  mounted() {
    const filters = {};
    if (this.modelId && this.model) {
      filters[`${this.model}Id`] = this.modelId;
      this.modelFilters = { ...filters };
    }
    this.getEmails({
      ...filters,
      emails: this.emailAddresses?.filter((e) => e),
    });
  },
  beforeRouteEnter(to, from, next) {
    const params = {};
    if (to.params.id) {
      params.filters = { id: to.params.id };
    }
    next((vm) => {
      if (vm.widget) {
        return;
      }
      if (to.query.to) {
        return;
      }
      vm.getEmails(params);
    });
  },

  beforeRouteUpdate(to) {
    const params = {};
    if (this.widget) {
      return;
    }
    if (to.params.id) {
      params.filters = { id: to.params.id };
    }
    if (to.query.to) {
      return;
    }
    this.getEmails(params);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('email/setSelectedEmail', null);
    return next();
  },
  watch: {
    search() {
      this.page = 1;
      this.isSearching = true;
      this.getEmails({ page: 0 }).finally(() => {
        this.isSearching = false;
      });
    },
    type(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.$emit('type', newVal);
      this.isOutbound = false;
      this.isArchived = false;
      switch (newVal) {
      case 'archived':
        this.isArchived = true;
        break;
      case 'sent':
        this.isOutbound = true;
        break;
      case 'inbox':
      default:
        this.isArchived = false;
        this.isOutbound = false;
        break;
      }
      this.getEmails();
    },

    mailboxId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.goToPage(0);
      }
    },
  },
  computed: {
    ...mapState('email', ['selectedEmail', 'emails']),
    ...mapGetters({
      index: 'email/index',
      send: 'email/send',
      draft: 'email/draft',
      trash: 'email/trash',
      starred: 'email/starred',
    }),
  },
  data() {
    return {
      modelFilters: {},
      search: '',
      page: 1,
      menutoogle: false,
      totalPages: null,
      perPage: 20,
      mailboxAddresses: [],
      isArchived: false,
      isSearching: false,
      displaySnippets: false,
      showEditor: false,
      mailSendMode: 'reply',
      type: 'inbox', // inbox, sent, archived
      totalCount: 0,
    };
  },
  methods: {
    dayjs,
    getCount(params) {
      //       mailboxId: this.currentMailBoxConfig ? this.currentMailBoxConfig.id : undefined,
      // ...this.modelFilters,
      const filters = {
        ...this.emailFilters,
        mailboxId: this.mailboxId || undefined,
      };
      if (params && params.filters) {
        Object.assign(filters, params.filters);
      }
      return this.$store
        .dispatch('email/getCount', {
          params: {
            search: this.search,
            ...params,
            filters,
            ...this.modelFilters,
          },
        })
        .then((res) => {
          this.totalCount = res;
          this.$emit('count-changed', res);
        })
        .catch(console.warn);
    },

    getEmails(params) {
      this.getCount(params);
      //       mailboxId: this.currentMailBoxConfig ? this.currentMailBoxConfig.id : undefined,
      // ...this.modelFilters,
      const filters = {
        isArchived: this.isArchived ? 1 : { $ne: 1 },
        isOutbound: this.isOutbound ? 1 : { $ne: 1 },
        ...this.emailFilters,
        mailboxId: this.mailboxId || undefined,
      };
      if (params && params.filters) {
        Object.assign(filters, params.filters);
      }
      this.isSearching = true;
      return this.$store
        .dispatch('email/getEmails', {
          params: {
            sort: { sentOrReceivedOn: 'desc' },
            search: this.search,
            perPage: this.perPage || 10,
            ...params,
            filters,
            ...this.modelFilters,
          },
        })
        .finally(() => {
          this.isSearching = false;
        });
      // .then((emails) => {
      //   //   this.setSelectedEmail(emails && emails[0]); // not needed if the send mail works
      // });
    },
    displayRecipients(email, addressOnly = false) {
      return (
        (email.to &&
          email.to.length &&
          ((!addressOnly && email.to.map((m) => `${m.name} <${m.address}>`).join('; ')) ||
            (addressOnly && email.to.map((m) => `${m.address}`).join('; ')))) ||
        email.toEmail ||
        email.parsedContent.email
      );
    },
    formatDate(str, format) {
      return dayjs(str).format(format);
    },
    goToPage(page = 0) {
      if (page >= 0) {
        this.page = page;
        return this.getEmails({ page });
      }
    },
    getMailboxes() {
      return this.$http.get('/api/mailbox').then(({ data }) => {
        this.mailboxAddresses = data.body;
      });
    },
    /**
     * closes all the open dropdown toggles
     */
    closeToggles() {
      this.menutoogle = false;
    },
    async setSelectedEmail(email) {
      this.closeToggles();
      if (this.widget) {
        this.openEmailComposerModal(email);
      }
      const { parsedContent } = email;
      if (parsedContent && parsedContent.html === 'undefined') {
        email.parsedContent.html = '';
      }
      if (parsedContent && parsedContent.text === 'undefined') {
        email.parsedContent.text = '';
      }
      await this.$store.dispatch('email/setSelectedEmail', email);
      email.isRead = true;
      this.$store.dispatch('email/readEmail', {
        id: email.id,
        isRead: true,
      });

      if (!this.widget) {
        const [client] = await this.$store.dispatch('client/getClients', {
          save: false,
          params: { filters: { email: email.parsedContent && email.parsedContent.fromEmail } },
        });
        if (client && client.id) {
          this.client = client;
        }
      }
    },
    openEmailComposerModal(email) {
      this.$store.dispatch('email/setShowEmailComposerModal', { show: true, selectedEmail: email });
    },

    getImgUrl(obj) {
      return (
        (obj.Client && obj.Client.avatarUrl) ||
        (obj.Lead && obj.Lead.avatarUrl) ||
        (obj.CreatedBy && obj.CreatedBy.avatarUrl)
      );
      // return require('../../assets/images/' + path);
    },
  },
};
</script>

<style scoped>
.email-wrap .list-items {
  max-height: 400px;
}
.widget-title {
  font-size: 14px !important;
  font-weight: 500 !important;
  border-bottom: solid 1px var(--primary);
  margin-bottom: 0;
  padding: 0 !important;
  text-transform: none;
  display: inline-block;
}
</style>
