<template>
  <div :key="computedIdentity" style="position: relative; display: flex; padding-left: 0">
    <transition name="slide-fade">
      <div class="segment-section col-12 col-sm-2" v-if="showSegmentSidebar">
        <SegmentSidebar
          v-model="currentSegment"
          :model="modelComputed"
          :query="apiQueryComputed"
          :uiState="uiStateComputed"
          :value="selectedSegmentIdComputed"
          :selectedSegmentId="selectedSegmentIdComputed"
          @segmentSelected="onSegmentSelected"
          @close="showSegmentSidebar = false"
        ></SegmentSidebar>
      </div>
    </transition>
    <div
      class="segment-target col-12 animated fadeIn"
      style="flex: 1; padding-left: 10px"
      :class="showSegmentSidebar ? 'col-sm-10' : 'col-sm-12'"
    >
      <Breadcrumbs
        v-if="breadcrumbsComputed"
        :title="breadcrumbsTitle"
        pageClass="pb-0 pt-1"
        :dropdownItems="breadcrumbsDropdownItems"
      >
      </Breadcrumbs>
      <ImportModal :model="identity" :visible="showImportModal" @closeModal="showImportModal = false"></ImportModal>
      <div v-if="computedIdentity" class="crud-wrapper pt-3 pb-5">
        <div class="layout">
          <div class="row m-0">
            <div v-if="canShowSidebarItems" class="col-12 order-2 col-md-3 order-md-1 col-xl-3 p-0">
              <template v-if="canShowSidebarItems">
                <CommentsWidget
                  :model="modelComputed.identity"
                  :modelId="$route.params.id"
                  :enable-events="eventsEnabledComputed"
                />
                <TasksWidget :model="modelComputed.identity" :modelId="$route.params.id" />
                <DocumentsWidget :model="modelComputed.identity" :modelId="$route.params.id" />
                <InboxWidget
                  v-if="canShowInboxWidget"
                  :model="modelComputed.identity"
                  :modelId="parseInt($route.params.id)"
                  embedded
                  widget
                  style="max-height: 400px"
                />
                <MetadataWidget :model="modelComputed.identity" :modelId="$route.params.id" :item="currentItem" />
                <CustomFieldValuesWidget
                  v-if="(modelComputed.identity && $route.params.id) || currentItem"
                  :model="modelComputed.identity"
                  :modelId="$route.params.id"
                  :item="currentItem"
                />
              </template>
            </div>
            <div class="order-1 order-md-2" :class="canShowSidebarItems ? 'col-xl-9 col-md-9 pt-0' : 'col-12 pl-md-0'">
              <AwesomeCrud
                ref="awesomeCrud"
                v-if="modelComputed"
                v-bind="modelComputed"
                :key="refreshKey"
                :detailPageMode="detailPageModeComputed"
                v-on="$listeners"
                :useRouterMode="useRouterModeComputed"
                :apiQueryParams="apiQueryParams"
                :apiRequestPermanentBodyParams="apiRequestPermanentBodyParams"
                @after-refresh="swapMapIcon"
              >
                <template #top-left-buttons>
                  <button
                    v-if="canUseSegments"
                    class="btn btn-sm float-left mr-2"
                    :class="showSegmentSidebar ? 'btn-info' : 'btn-outline-info'"
                    @click="toggleSegmentSidebar()"
                  >
                    <feather type="menu" size="14" class="mr-1 pt-0"></feather> {{ $t('common.labels.views') }}
                  </button>
                  <button
                    class="btn btn-sm btn-info mr-2"
                    v-if="computedIdentity === 'company'"
                    @click="getPublicCompanies"
                  >
                    <i class="fa fa-bank"></i> {{ companySourceButtonLabelComputed }}
                  </button>
                </template>
                <template #top-more-actions>
                  <button class="btn text-info p-2" @click="showCustomFieldTableModal = true">
                    <i class="fa fa-cog mr-1"></i>{{ $t('common.buttons.manageField') }}
                  </button>
                  <router-link v-if="computedIdentity === 'lead'" to="/app/settings/lead-stage" class="p-3"
                    ><i class="fa fa-cog mr-1"></i> Edit pipeline statuses</router-link
                  >
                  <router-link
                    class="btn text-info"
                    v-if="computedIdentity === 'card' && parentBoard"
                    :to="'/app/board?id=' + parentBoard.id"
                    >Edit board</router-link
                  >
                </template>
                <template #top-right-buttons>
                  <button class="btn btn-outline-success" @click="showImportModal = true">
                    {{ $t('common.buttons.importCsv') }}
                  </button>
                  <MemberSelectorDropdown
                    v-if="canFilterByUser"
                    :value="affectedToUserIdComputed"
                    @change="onAffectedToChanged"
                    class="d-inline-flex mr-2"
                  />
                  <div class="d-inline-flex mr-2">
                    <field-v-select
                      v-if="canFilterByUser"
                      :schema="{
                        name: 'status',
                        label: $t('common.labels.filterByClient'),
                        relation: 'client',
                        relationUrl: '/api/client',
                        relationKey: 'id',
                        relationLabel: 'name',
                        fieldOptions: {
                          relationUrl: '/api/client', // to delete soon
                          preload: true,
                          disableRelationActions: true,
                        },
                        search: true,
                        clearable: true,
                        placeholder: $t('common.labels.filterByClient'),
                      }"
                      :model="{}"
                      class="input-group-sm"
                      @itemSelected="onClientChanged"
                    />
                  </div>
                </template>
                <template
                  v-if="
                    computedIdentity === 'candidate' ||
                    computedIdentity === 'lead' ||
                    computedIdentity === 'client' ||
                    computedIdentity === 'card' ||
                    computedIdentity === 'contact'
                  "
                  v-slot:kanban-list-items="{ items }"
                >
                  <div v-for="item in items" :key="item.id" class="card-kanban-candidate">
                    <KanbanCard
                      v-if="computedIdentity === 'candidate'"
                      :item="item"
                      :model="modelComputed && modelComputed.identity"
                    >
                      <template #title> {{ item.Candidate.firstName }} {{ item.Candidate.lastName }} </template>
                      <template #description>
                        {{ item.Client && item.Client.name ? item.Client.name : '-' }}
                      </template>
                      <template #subtitle>
                        {{ item.Position ? item.Position.title : '-' }}
                      </template>
                    </KanbanCard>
                    <KanbanCard
                      v-if="computedIdentity === 'lead'"
                      :item="item"
                      :model="modelComputed && modelComputed.identity"
                    >
                      <template #title> {{ item.name }} </template>
                      >
                      <template #subtitle>
                        {{ item.Client && item.Client.name ? item.Client.name : item.company || '-' }}
                      </template>
                    </KanbanCard>
                    <KanbanCard
                      v-if="computedIdentity === 'card'"
                      :item="item"
                      :model="modelComputed && modelComputed.identity"
                    >
                      <template #title> {{ item.title }} </template>
                      >
                      <template #subtitle>
                        {{ item.Client && item.Client.name ? item.Client.name : '' }}
                      </template>
                    </KanbanCard>
                  </div>
                </template>
                <template
                  v-if="
                    computedIdentity === 'candidate' ||
                    computedIdentity === 'client' ||
                    computedIdentity === 'contact' ||
                    supportsMapModeComputed
                  "
                  v-slot:list-items="{ items, itemsPerRow }"
                >
                  <PeopleList
                    v-if="computedIdentity === 'candidate' || computedIdentity === 'contact'"
                    :items="items"
                    :itemsPerRow="itemsPerRow"
                    :isMobile="isMobile"
                    :model="computedIdentity"
                  />
                  <ClientList v-if="computedIdentity === 'client'" :items="items" :itemsPerRow="itemsPerRow" />
                  <div class="card p-2" v-if="supportsMapModeComputed">
                    <div class="card-body">
                      <GmapMap
                        :center="getMapCenter(items)"
                        :zoom="12"
                        map-type-id="terrain"
                        style="width: 100%; height: 80vh"
                      >
                        <GmapMarker
                          :key="index"
                          v-for="(m, index) in items"
                          :position="getItemPosition(m)"
                          :clickable="true"
                          :title="m.name || m.company"
                          :icon="getMapIcon(m)"
                          @click="toggleMapInfoWindow(m)"
                        />
                        <gmap-info-window
                          v-if="selectedPin && selectedPin.id"
                          :options="{
                            pixelOffset: {
                              width: 0,
                              height: -35,
                            },
                          }"
                          :position="getItemPosition(selectedPin)"
                          :opened="showMapInfoWindow"
                          @closeclick="showMapInfoWindow = false"
                        >
                          <h5>{{ selectedPin.name || selectedPin.company }}</h5>
                          <br />
                          <div>
                            {{ selectedPin.address }}, {{ selectedPin.postalCode }} {{ selectedPin.city }} -
                            {{ selectedPin.country }}
                          </div>
                          <div>{{ selectedPin.phonenumber }}</div>
                          <div v-if="selectedPin.website">
                            <a :href="selectedPin.website">{{ selectedPin.website }}</a>
                          </div>
                          <span class="badge badge-info mb-3 mt-1">{{ selectedPin.stage }}</span
                          ><br />
                          <router-link
                            class="btn btn-primary btn-xs btn-block w-100"
                            :to="'/app/lead/' + selectedPin.id"
                            >View</router-link
                          >
                        </gmap-info-window>
                      </GmapMap>
                    </div>
                  </div>
                </template>
                <template v-slot:kanban-list-title="{ title, items }">
                  {{ title }}
                  <small v-if="modelComputed && modelComputed.identity === 'lead'" class="badge badge-dark"
                    >{{ computeLeadsTotalDealValue(items) }}
                  </small>
                  <small class="badge badge-info">{{ items.length }}</small>
                </template>
              </AwesomeCrud>
              <CustomFieldsCrud
                :showCustomFieldTableModal="showCustomFieldTableModal"
                :identity="identity"
                :handleCloseCustomFieldTableModal="handleCloseCustomFieldTableModal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AwesomeCrud } from 'vue-aw-components';
import _ from 'lodash';
import { mapState } from 'vuex';

import TasksWidget from '../shared/TasksWidget.vue';
import DocumentsWidget from '../shared/DocumentsWidget.vue';
import CommentsWidget from '../shared/CommentsWidget.vue';
import InboxWidget from '../shared/InboxWidget.vue';
import KanbanCard from '../shared/KanbanCard.vue';
import MetadataWidget from '../shared/MetadataWidget.vue';
import CustomFieldValuesWidget from '../shared/CustomFieldValuesWidget.vue';
import MemberSelectorDropdown from '../user/MemberSelectorDropdown.vue';
import defaultImage from '../../assets/images/user/user.png';
import SegmentSidebar from './SegmentSidebar.vue';
import ImportModal from '../import/ImportModal.vue';
import PeopleList from '../candidate/PeopleList.vue';
import CustomFieldsCrud from '../shared/CustomFieldsCrud.vue';
import ClientList from '../client/ClientList.vue';

export default {
  name: 'ModelCrudWrapper',
  props: {
    identity: String,
    model: Object,
    useRouterMode: { type: Boolean, default: true },
    enableSidebar: Boolean,
    breadcrumbs: Boolean,
    breadcrumbsTitle: String,
    breadcrumbsDropdownItems: { type: Array, default: () => [] },
    // events: Object,
    title: String,
    storePath: { type: String, default: 'state.models' },
  },
  components: {
    AwesomeCrud,
    PeopleList,
    CommentsWidget,
    CustomFieldValuesWidget,
    DocumentsWidget,
    ImportModal,
    InboxWidget,
    KanbanCard,
    MemberSelectorDropdown,
    MetadataWidget,
    SegmentSidebar,
    TasksWidget,
    CustomFieldsCrud,
    ClientList,
  },
  data () {
    return {
      apiQueryParams: { filters: {} },
      apiRequestPermanentBodyParams: {},
      modalFooters: [],
      actionButtonsShouldFloat: true,
      currentSegment: null,
      showSegmentSidebar: false,
      refreshTimestamp: Date.now(),
      showCustomFieldTableModal: false,
      customFieldToUpdate: null,
      currentItem: null,
      showImportModal: false,
      companyDisplaySource: '',
      parentBoard: null,
      myPosition: {
        lat: 38.71667,
        lng: -9.13333,
      },
      selectedPin: null,
      showMapInfoWindow: false,
    };
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    affectedToUserIdComputed () {
      if (this.$route.query?.filters?.[this.userFilterKey]) {
        return this.$route.query?.filters?.[this.userFilterKey];
      }
      if (this.$store.state.layout.selectedUserFilter?.[this.computedIdentity] !== undefined) {
        return this.$store.state.layout?.selectedUserFilter?.[this.computedIdentity];
      }
      return undefined;
    },
    modelComputed () {
      const models = _.get(this.$store, this.storePath);
      if (!models) {
        return;
      }
      const model = this.model || models.find((m) => m.identity === this.identity);
      const modelComputed = { ...model, ...this.$props, ...this.$attrs };
      if (['Board', 'BoardView', 'CardView', 'CardEdit'].includes(this.$route.name)) {
        // if it's a kanban board we need to get the parent board
        if (!this.parentBoard || !this.parentBoard.id) {
          return null;
        }
        modelComputed.title = this.parentBoard.title;
        modelComputed.name = this.parentBoard.name;
        modelComputed.namePlural = this.parentBoard.namePlural;
        modelComputed.schema.properties.status.enum = this.parentBoard.statuses;
        modelComputed.apiUrl = `/api/board/${this.parentBoard.id}/card`;
        modelComputed.options.viewPath = `/app/board-${this.parentBoard.id}/card/:id`;
        modelComputed.options.editPath = `/app/board-${this.parentBoard.id}/card/:id/edit`;
        //  modelComputed.identity = `board-${this.parentBoard.id}-card`;
      }
      return modelComputed;
    },

    canShowSidebarItems () {
      return !!(
        (this.enableSidebar ||
          (['contact', 'company', 'card'].includes(this.modelComputed?.identity) &&
            this.$route.query.detailPageMode === 'page')) &&
        this.$route?.params?.id &&
        this.$route?.params?.id !== 'new'
      );
    },
    useRouterModeComputed () {
      if (this.useRouterMode !== undefined) {
        return this.useRouterMode;
      }
      if (this.modelComputed && this.modelComputed.useRouterMode !== undefined) {
        return this.modelComputed.useRouterMode;
      }
      return true;
    },

    canFilterByUser () {
      return (
        this.modelComputed &&
        ['request', 'lead', 'recruitmentProcess', 'candidate', 'card'].includes(this.modelComputed.identity)
      );
    },

    canShowInboxWidget () {
      return (
        this.modelComputed?.identity === 'candidate' && this.organisation && this.organisation.featuresUnifiedInbox
      );
    },

    currencyComputed () {
      return (this.organisation && this.organisation.currency) || '€';
    },

    breadcrumbsComputed () {
      return (
        this.breadcrumbs || ['leadStage', 'requestStatus', 'recruitmentStatus'].includes(this.modelComputed?.identity)
      );
    },
    computedIdentity () {
      return this.modelComputed ? this.modelComputed.identity : '';
    },

    refreshKey () {
      // + this.$route.params.id
      return this.computedIdentity + this.refreshTimestamp + (this.parentBoard?.id || '');
    },

    isMobile () {
      return window.outerWidth < 992;
    },
    // The key to use to filter by user. it changes for some models.
    userFilterKey () {
      switch (this.computedIdentity) {
      case 'recruitmentProcess':
        return 'recruiterId';
      case 'candidate':
        return 'createdBy';
      case 'lead':
      case 'request':
      case 'client':
      case 'card':
        return 'affectedToUserId';
      default:
        return 'createdBy';
      }
    },

    apiQueryComputed () {
      return {
        ...this.apiQueryParams,
        ...this.apiRequestPermanentBodyParams,
        ...this.$refs?.awesomeCrud?.$children?.[0]?.serverParams,
      };
    },

    selectedSegmentIdComputed () {
      // return this.$store.state.layout.selectedSegment?.[this.computedIdentity];
      return this.$route.query?.segmentId;
    },

    uiStateComputed () {
      return {
        displayMode: this.$refs?.awesomeCrud?.displayMode,
      };
    },

    canUseSegments () {
      return ['lead', 'request', 'client', 'contact', 'company', 'contact', 'card'].includes(this.computedIdentity);
    },

    detailPageModeComputed () {
      if (this.enableSidebar) {
        return 'page';
      }
      if (this.$route.query.detailPageMode && this.useRouterModeComputed) {
        return this.$route.query.detailPageMode;
      }
      return this.modelComputed.detailPageMode;
    },

    companySourceButtonLabelComputed () {
      if (this.computedIdentity !== 'company') {
        return '';
      }
      return this.companyDisplaySource === 'public'
        ? this.$t('common.buttons.showMyCompanies')
        : this.$t('common.buttons.exploreCompanies');
    },

    eventsEnabledComputed () {
      return ['candidate', 'position', 'interview', 'recruitmentProcess', 'client', 'lead', 'request'].includes(
        this.computedIdentity
      );
    },

    supportsMapModeComputed () {
      return (
        this.computedIdentity === 'lead' &&
        this.organisation.Settings &&
        this.organisation.Settings.supportForGeocoding
      );
    },
  },
  watch: {
    actionButtonsShouldFloat (newValue, old) {
      this.setActionButtonsFloat(newValue);
    },
    $route () {
      this.scrollToActiveColumn();
      if (this.$route.query?.displayMode && this.useRouterModeComputed) {
        this.changeDisplayMode(this.$route.query.displayMode);
      }
    },

    '$route.params.id': function () {
      this.currentItem = null;
    },
    computedIdentity () {
      this.apiQueryParams = { filters: {} };
      this.updateSectionParams();
      this.updateAffectedToParams();
    },

    currentSegment (newValue) {
      // if the ui stage has changed we update the component
      if (
        newValue?.uiState?.displayMode &&
        this.$refs.awesomeCrud?.supportedListingDisplayModes?.includes(newValue.uiState.displayMode)
      ) {
        this.$refs.awesomeCrud.displayMode = newValue.uiState.displayMode;
      }
    },
  },

  async mounted () {
    this.apiQueryParams = { filters: {} };
    if (this.$awEventBus) {
      this.$awEventBus.$on('itemEdited', this.clearLabelsCache);
      this.$awEventBus.$on('itemEdited', this.updateOrganisation);
      this.$awEventBus.$on('itemFetched', this.setCurrentItem);
      this.$awEventBus.$on('aw-kanban-list-added', this.createNewStage);
    }

    if (this.$route.params.boardId) {
      this.loadBoardModel(this.$route.params.boardId);
    }
    // if a section is selected inject it in the query params
    // this is for the template sections of the template model
    this.updateSectionParams();
    this.updateAffectedToParams();
    this.adjustScrollClasses();
    //   this.registerscrolllistener();
    this.scrollToActiveColumn();
    setTimeout(() => {
      this.setActionButtonsFloat(true);
    }, 400);
    if (this.supportsMapModeComputed) {
      this.getLocation();
      setTimeout(() => {
        this.swapMapIcon();
      }, 2000);
    }
  },
  updated () {
    this.setActionButtonsFloat(true);
  },
  // beforeRouteEnter(to, from, next) {
  //   // eslint-disable-next-line
  //   next((vm) => {});
  // },
  // beforeRouteLeave(to, from, next) {
  //   next((vm) => {});
  // },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (to.query?.segmentId) {
        vm.onSegmentRequest(to.query?.segmentId);
      }
      // restore state only if it's not a reset segment id
      /*
      if (vm.useRouterMode && !to.params.id && !to.params?.restoredState && !to.query?.segmentId) {
        const modifiedTo = vm.loadRouteState(to);
        if (modifiedTo) {
          return vm.$router.replace(modifiedTo);
        }
      }
      */

      if (vm.useRouterMode && to.query.filters) {
        vm.apiQueryParams.filters = to.query.filters;
      }
      if (vm.useRouterMode && to.query.segmentId) {
        vm.apiQueryParams.segmentId = to.query.segmentId;
      }
    });
  },

  beforeRouteUpdate (to, from, next) {
    // if we are on the same component and coming from a detail list
    if (this.useRouterMode && from.params.id && !to.params.id) {
      // this.onViewDisplayCancelled();
    }
    this.onScrollEvent();
    const beforeRouteUpdate = _.get(this, '$children.0.AwesomeCrud.beforeRouteUpdate[0]');
    this.adjustScrollClasses(to.query.detailPageMode); // model tends to lock the scroll
    this.loadBoardModel(to.params.boardId);
    if (beforeRouteUpdate) {
      return beforeRouteUpdate(to, from, next);
    }

    if (this.useRouterMode) {
      if (to.query.filters) {
        this.$set(this.apiQueryParams, 'filters', to.query.filters);
      } else {
        this.$set(this.apiQueryParams, 'filters', {});
      }
      if (to.query.includeArchived) {
        this.apiQueryParams.filters.archived = 1;
        this.$set(this.apiQueryParams.filters, 'archived', 1);
      }
      this.$set(this.apiQueryParams, 'includeArchived', to.query.includeArchived);

      if (to.query.segmentId) {
        this.apiQueryParams.segmentId = to.query.segmentId;
        this.$set(this.apiQueryParams, 'segmentId', to.query.segmentId);
      }
    }
    if (`${to.query?.segmentId}` === '-1') {
      this.clearRouteState();
    }
    // if the changes are not related to a detail page, we save the route state
    // if (this.useRouterModeComputed) {
    //   if (!from.params.id && to.params.id && `${to.query?.segmentId}` !== '-1') {
    //     this.saveRouteState(from);
    //   }

    //   if (from.params.id && !to.params.id && !to.params?.restoredState) {
    //     // const modifiedTo = this.loadRouteState(to);
    //     // if (modifiedTo) {
    //     //   return this.$router.replace(modifiedTo);
    //     // }
    //   }
    // }

    if (to.query.filters) {
      this.apiQueryParams.filters = to.query.filters;
    }
    if (to.query.includeArchived) {
      this.apiQueryParams.filters.archived = 1;
    }
    this.$set(this.apiQueryParams, 'includeArchived', to.query.includeArchived);

    this.onSegmentRequest(to.query?.segmentId);

    next();
  },
  beforeDestroy () {
    this.$awEventBus.$off('itemEdited', this.clearLabelsCache);
    this.$awEventBus.$off('aw-kanban-list-added', this.createNewStage);
    this.$awEventBus.$off('itemFetched', this.setCurrentItem);
    window.removeEventListener('scroll', this.onScrollEvent);
  },
  destroyed () {},

  methods: {
    clearLabelsCache (data, context, identity) {
      if (
        this.awComponentsConfig.displayLabelsCache &&
        identity.identity &&
        identity.identity === 'recruitmentProcess'
      ) {
        if (this.awComponentsConfig.displayLabelsCache[`/api/recruitment-process/${identity.item.id}?`]) {
          delete this.awComponentsConfig.displayLabelsCache[`/api/recruitment-process/${identity.item.id}?`];
        }
      }
    },

    handleCloseCustomFieldTableModal () {
      this.showCustomFieldTableModal = false;
      this.$store.dispatch('getModels');
    },

    computeLeadsTotalDealValue (items) {
      if (!items) {
        return `- ${this.currencyComputed}`;
      }
      const dealValue = items.reduce((acc, curr) => acc + (curr.dealValue || 0), 0);
      if (!dealValue) {
        return `- ${this.currencyComputed}`;
      }
      return `${dealValue} ${this.currencyComputed}`;
    },

    async createNewStage (data, context, identity) {
      await this.$http.post('/api/lead-stage', { code: data.id.replace(/ /g, '-'), label: data.id });
      this.$forceUpdate();
    },

    getCompany (item) {
      if (item.company) {
        return item.company;
      }
      if (item.Client) {
        return item.Client.company || item.Client.name;
      }
      return '';
    },

    /**
     * push to the route user in params
     * @param {Number} userId
     */
    onAffectedToChanged (affectedToUserId) {
      this.$router.push({
        path: this.$route.path,
        query: {
          filters: {
            ...this.$route.query.filters,
            [this.userFilterKey]: affectedToUserId || null,
          },
        },
      });
      this.$refs.awesomeCrud.refreshComponent(true);
      this.$store.dispatch('layout/setSelectedUserFilter', {
        model: this.computedIdentity,
        value: affectedToUserId || null,
      });
    },

    onClientChanged (client) {
      delete this.apiQueryParams.filters.clientId;
      setTimeout(() => {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.apiQueryParams,
            includeArchived: this.$store.state.layout.leadArchiveButtonState ? 1 : undefined,
            filters: {
              ...this.apiQueryParams.filters,
              clientId: client || null,
            },
          },
        });
        this.$refs.awesomeCrud.refreshComponent(true);
      }, 500);
    },

    onScrollEvent () {
      const bottomOfWindow =
        Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) +
          window.innerHeight >
        0.95 * document.documentElement.offsetHeight;
      if (bottomOfWindow && window.innerWidth > 900) {
        this.actionButtonsShouldFloat = false;
      } else {
        this.actionButtonsShouldFloat = true;
      }
    },

    refreshResults () {
      this.$refs.awesomeCrud.refreshLocalData();
    },

    registerscrolllistener () {
      window.addEventListener('scroll', this.onScrollEvent);
    },

    setActionButtonsFloat (option = true) {
      this.modalFooters = this.$el.querySelectorAll('div:not(.modal-dialog) .modal-footer');
      if (window.innerHeight === document.documentElement.scrollHeight) {
        option = false;
      }
      if (option) {
        this.modalFooters.forEach((element) => {
          element.classList.add('fixed-actions');
        });
      } else {
        this.modalFooters.forEach((element) => {
          element.classList.remove('fixed-actions');
        });
      }
    },

    scrollToActiveColumn () {
      // if there are kanban pages scroll to the first no
      setTimeout(() => {
        const listWithItem = document.querySelector('.draggable-list .list-has-items');
        if (listWithItem) {
          listWithItem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 600);
    },

    updateOrganisation (data, context, identity) {
      if (identity.identity && identity.identity !== 'roleBasedAccess') {
        return;
      }
      this.$store.dispatch('user/getOrganisation', identity.item.organisationId);
    },

    /** @descrition this function sets the correct section field for the templates */
    updateSectionParams () {
      if (this.computedIdentity === 'template' && this.$route.params.section) {
        this.apiQueryParams.filters.section = this.$route.params.section;
        this.apiRequestPermanentBodyParams.section = this.$route.params.section;
      } else {
        delete this.apiQueryParams.filters.section;
        delete this.apiRequestPermanentBodyParams.section;
      }
    },

    updateAffectedToParams () {
      if (this.computedIdentity === 'lead') {
        // if (this.organisation?.Settings?.leadUsersCanSeeAllLeads) {
        //   return;
        // }
        // if (!this.userIsAnyOf(['ADMIN', 'MANAGER'])) {
        //   return;
        // }
        // if (this.$route.query?.filters?.[this.userFilterKey] === undefined) {
        //   // affectedToUserId = this.$route.query?.filters?.[this.userFilterKey];
        //   this.$router.replace({
        //     path: this.$route.path,
        //     query: {
        //       ...this.apiQueryParams,
        //       filters: {
        //         ...this.apiQueryParams.filters,
        //         [this.userFilterKey]: this.user.id,
        //       },
        //     },
        //   });
        // }
      } else if (
        !this.$route.query?.filters?.[this.userFilterKey] &&
        this.apiQueryParams?.filters?.[this.userFilterKey]
      ) {
        delete this.apiQueryParams.filters[this.userFilterKey];
      }
      //  this.$store.dispatch('layout/setSelectedUserFilter',
      // { model: this.computedIdentity, value: affectedToUserId });
    },

    replaceImage (error) {
      error.target.src = defaultImage;
    },

    getColClasses (itemsPerRow) {
      switch (itemsPerRow) {
      case 0:
      case 1:
      default:
        return 'col-12';
      case 2:
        return 'col-6';
      case 3:
        return 'col-4';
      case 4:
        return 'col-3';
      case 5:
      case 6:
        return 'col-2';
      case 12:
        return 'col-1';
      }
    },

    onSegmentSelected (segment) {
      this.apiQueryParams = {
        ...this.apiQueryParams,
        filters: {
          ...this.apiQueryParams.filters,
        },
      };
      this.refreshResults();
    },

    toggleSegmentSidebar () {
      this.showSegmentSidebar = !this.showSegmentSidebar;
    },

    /* saves the current route state in the store when going to the detail page
    /* so that we always have the correct filters stage when we come back ?
    */
    saveRouteState ($route) {
      // console.log('saveRouteState called', $route, document.location.href);
      // this.$store.commit('layout/saveRouteState', {
      //   model: this.computedIdentity,
      //   query: $route.query,
      // });
    },
    /*
    clears the saved state
    */
    clearRouteState ($route) {
      // console.log('clearRouteState called', $route, document.location.href);
      this.$store.commit('layout/clearRouteState', this.computedIdentity);
      this.refreshTimestamp = Date.now();
    },
    /* reload the current route state from the store when going to the detail page
     so that we always have the correct filters stage when we come back
    */
    loadRouteState ($route) {
      // console.log('loadRouteState called', $route, document.location.href);
      if (this.$store.state.layout?.routeState?.[this.computedIdentity]) {
        return {
          name: $route.name,
          query: {
            ...this.$store.state.layout?.routeState?.[this.computedIdentity]?.query,
          },
          meta: {
            ...$route.meta,
            restoredState: true,
          },
          params: {
            restoredState: true,
          },
        };
      }
    },

    onSegmentRequest (segmentId) {
      if (!segmentId) {
        return;
      }
      if (`${segmentId}` === '-1') {
        this.clearRouteState();
      }
      if (this.useRouterMode && segmentId) {
        this.apiQueryParams.segmentId = segmentId;
        this.$set(this.apiQueryParams, 'segmentId', segmentId);
      }
      console.warn('onSegmentRequest', segmentId);
      this.forceRefresh();
    },
    /** Force the refresh of the awCrud component */
    forceRefresh () {
      this.refreshTimestamp = Date.now();
    },

    /** Force the displaymode of the awCrud component */
    changeDisplayMode (mode) {
      if (this.$refs?.awesomeCrud) {
        this.$refs.awesomeCrud.detailPageMode = mode;
      }
    },

    setCurrentItem (a, event) {
      const { identity, item } = event;
      if (
        identity === this.computedIdentity &&
        // eslint-disable-next-line eqeqeq
        this.$route.params.id == item?.id &&
        this.currentItem?.id !== item?.id
      ) {
        this.currentItem = item;
      }
    },

    getPublicCompanies () {
      this.companyDisplaySource = this.companyDisplaySource !== 'public' ? 'public' : 'private';
      this.$set(this.apiQueryParams, 'companySource', this.companyDisplaySource);
      this.forceRefresh();
    },

    /**
     * When changing detial page mode from modal to page,
     *  the scroll does not work because of a class.
     *  This function adjust the require behavior
     * @param {string} detailPageMode the mode received from the router
     */
    adjustScrollClasses (detailPageMode) {
      if (detailPageMode) {
        // remove the modal-open classe if not needed
        if (detailPageMode === 'page' && document.body.classList.contains('modal-open')) {
          document.body.classList.remove('modal-open');
        } else if (
          // add the modal-open classe if needed
          detailPageMode === 'modal' &&
          this.$route.params.id &&
          !document.body.classList.contains('modal-open')
        ) {
          setTimeout(() => {
            document.body.classList.add('modal-open');
          }, 2000);
        }
      }
    },

    async loadBoardModel (boardId) {
      if (boardId) {
        if (!this.$store.state.board?.selectedItem?.id || boardId !== this.$store.state.board?.selectedItem?.id) {
          await this.$store.dispatch('board/getItem', boardId);
        }
        if (this.$store.state.board.selectedItem) {
          this.parentBoard = this.$store.state.board.selectedItem;
        }
      }
    },
    async getLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (position.coords) {
            this.myPosition = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          }
        });
      }
    },

    getItemPosition (item) {
      if (item?.latitude) {
        return {
          lat: this.parseFloatIfNeeded(item.latitude),
          lng: this.parseFloatIfNeeded(item.longitude),
        };
      }
      if (item?.customFields?.latitude) {
        return {
          lat: this.parseFloatIfNeeded(item.customFields.latitude),
          lng: this.parseFloatIfNeeded(item.customFields.longitude),
        };
      }
    },

    parseFloatIfNeeded (value) {
      if (typeof value === 'string') {
        return parseFloat(value);
      }
      return value;
    },

    toggleMapInfoWindow (m) {
      this.selectedPin = m;
      this.myPosition = this.getItemPosition(m);
      this.showMapInfoWindow = true;
    },

    getMapCenter (items) {
      return items?.length ? this.getItemPosition(items[0]) : this.myPosition;
    },

    swapMapIcon () {
      const listButton = document.querySelector('.aw-list-mode-selector button i.fa.fa-list');
      if (listButton) {
        listButton.classList.add('fa-map-o');
        listButton.classList.remove('fa-list');
      }
    },

    getMapIcon (str) {
      const color = str?.LeadStage?.color;
      if (color && ['green', 'red', 'yellow', 'blue', 'pink', 'orange', 'purple', 'lightblue'].includes(color)) {
        return `https://maps.google.com/mapfiles/ms/icons/${color}-dot.png`;
        // http://maps.gstatic.com/mapfiles/ms2/micons/lightblue.png
      }
      // eslint-disable-next-line max-len
      return 'http://maps.gstatic.com/mapfiles/ms2/micons/red-pushpin.png';
    },
  },
};
</script>

<style lang="scss">
.awesomecrud-detail-section > .aw-form .modal-content {
  box-shadow: 0 0 20px rgba(8, 21, 66, 0.1);

  .modal-content,
  .card {
    box-shadow: none;
  }
}

#send-mail-0 > span {
  display: none;
}

.display-mode-page .fixed-actions {
  position: fixed;
  bottom: 32px;
  right: 50px;
  background: white;
  border-radius: 15px;
  z-index: 5;
  box-shadow: 0 0 20px rgba(8, 21, 66, 0.4);
  border: none !important;
}
@media (max-width: 900px) {
  .display-mode-page .fixed-actions {
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 0;
    right: 0;
    background: white;
    z-index: 5;
    box-shadow: 0 0 5px rgba(8, 21, 66, 0.3);
    border: none !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    .btn {
      font-size: 11px !important;
      margin: 2px !important;
      padding-right: 10px;
      padding-left: 10px;
      &#send-mail-0 > span {
        display: none;
      }
    }
  }

  .crud-wrapper.view {
    .layout > .row > .p-3 {
      padding: 0 !important;
    }
  }
}

.modal-footer .btn-awtable-inline-action {
  background-color: #1446a0 !important;
  border-color: #1446a0 !important;
  padding: 0.15rem 0.5rem;
  display: inline-block;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 1.6;
  border-radius: 0.25rem;
  margin-left: 0 !important;
  margin-bottom: 0 !important;

  > span {
    display: inline-block;
    margin-left: 0.5rem;
  }

  span i {
    color: white;
  }

  &:hover {
    color: white !important;
    border-color: var(--theme-default) !important;
    background-color: var(--theme-default) !important;
  }
}

@media (max-width: 1440px) {
  .modal-footer .btn-awtable-inline-action {
    font-weight: 300;
    font-size: 11px;
  }
}

.page-body {
  padding: 0 !important;
}

.segment-section {
  background: white;
  z-index: 5;
}
</style>
