import swal from 'sweetalert2/dist/sweetalert2';

export default (title, options = {}) => swal
  .fire({
    reverseButtons: true,
    input: 'text',
    title,
    // confirmButtonText: this.$t('common.buttons.submit'),
    // cancelButtonText: this.$t('common.buttons.cancel'),
    showCancelButton: true,
    ...options,
  })
  .then((result) => {
    if (result.isConfirmed && result.value) {
      return result;
    }
    return '';
  });
