import config from '../config';
import roleMixin from '../mixins/rolesMixin';
// eslint-disable-next-line max-len
const isAdmin = (state) =>
  state.user
  && state.user.user
  && state.user.user.organisationRoles
  && state.user.user.organisationRoles.includes('ADMIN');


export default {
  data: [
    {
      title: 'mytasks',
      icon: 'layers',
      type: 'link',
      badgeType: 'primary',
      badgeValue: '',
      path: '/app/taskboard',
      class: 'dashboard-tour-show-step-1',
      active: false,
    },
    {
      title: 'history',
      icon: 'list',
      type: 'link',
      badgeType: 'primary',
      badgeValue: '',
      path: '/app/history',
      class: '',
      active: false,
    },

    {
      title: 'clients',
      icon: 'dollar-sign',
      type: 'link',
      badgeType: 'primary',
      // path: ({ state }) => (state.user.user && state.user.user.organisationId
      // ? `/app/client?filters[organisationId]=${state.user.user.organisationId}`
      path: ({ state }) => '/app/client',
      active: false,
      //  badgeValue: ({ state }) => state.client.clients.length,
    },

    {
      title: 'crm',
      icon: 'target',
      type: 'sub',
      badgeType: 'primary',
      path: '/app/lead',
      active: false,
      displayed: (state) => state.user && state.user.organisation && state.user.organisation.featuresCrm,
      showInHeader: true,
      children: [
        {
          title: 'dashboard',
          icon: 'bar-chart-2',
          type: 'link',
          badgeType: 'primary',
          path: '/app/dashboard/leads',
          active: false,
          displayed: false,
        },
        {
          title: 'leads',
          icon: 'target',
          type: 'link',
          badgeType: 'primary',
          // path: ({ state }) => (state.user.user && state.user.user.organisationId
          // ? `/app/client?filters[organisationId]=${state.user.user.organisationId}`
          path: ({ state }) => '/app/lead',
          active: false,
          //  badgeValue: ({ state }) => state.client.clients.length,
        },
        {
          title: 'contacts',
          icon: 'users',
          type: 'link',
          badgeType: 'primary',
          // path: ({ state }) => (state.user.user && state.user.user.organisationId
          // ? `/app/client?filters[organisationId]=${state.user.user.organisationId}`
          path: ({ state }) => '/app/contact',
          active: false,
          //  badgeValue: ({ state }) => state.client.clients.length,
        },
        {
          title: 'companies',
          icon: 'briefcase',
          type: 'link',
          badgeType: 'primary',
          // path: ({ state }) => (state.user.user && state.user.user.organisationId
          // ? `/app/client?filters[organisationId]=${state.user.user.organisationId}`
          path: ({ state }) => '/app/company',
          active: false,
          displayed: (state) => state?.user?.user?.email.includes('enyosolutions.com'),
          //  badgeValue: ({ state }) => state.client.clients.length,
        },
        {
          title: 'notesAndTemplates',
          icon: 'file-text',
          type: 'link',
          badgeType: 'primary',
          path: ({ state }) => '/app/template/for/lead',
          active: false,
        },
        {
          path: ({ state }) => ({ path: '/app/settings/details/settings.crm' }),
          title: 'settings.crm',
          type: 'link',
          icon: 'target',
          displayed: (state) => state.user && state.user.organisation && state.user.organisation.featuresCrm,
          children: [
            {
              path: '/app/settings/lead-stage',
              title: 'settings.lead-stage',
              type: 'link',
              displayed: (state) => state.user && state.user.organisation && state.user.organisation.featuresCrm,
            },
            {
              path: '/app/settings/crm-settings',
              title: 'settings.other-settings',
              type: 'link',
              displayed: (state) => state.user && state.user.organisation && state.user.organisation.featuresCrm,
            },
          ],
        },
      ],
    },
    {
      title: 'support',
      icon: 'message-square',
      type: 'sub',
      badgeType: 'primary',
      active: false,
      path: '/app/request',
      displayed: (state) =>
        state.user && state.user.organisation && state.user.organisation.featuresClientConversations
        && roleMixin.methods.userCan('read', 'request', state),
      showInHeader: true,
      children: [
        {
          title: 'dashboard',
          icon: 'bar-chart-2',
          type: 'link',
          badgeType: 'primary',
          path: '/app/dashboard/requests',
          active: false,
          displayed: false,
        },
        {
          title: 'conversations',
          icon: 'message-square',
          type: 'link',
          badgeType: 'primary',
          path: '/app/request',
          active: false,
        },
        {
          title: 'notesAndTemplates',
          icon: 'file-text',
          type: 'link',
          badgeType: 'primary',
          path: ({ state }) => '/app/template/for/request',
          active: false,
        },
        {
          badgeType: 'primary',
          active: false,
          path: '/app/settings/details/settings.conversation',
          title: 'settings.conversation',
          menuTitle: 'settings.settings',
          type: 'link',
          icon: 'settings',
        },

      ],
    },

    /*
    {
      title: 'Agenda',
      icon: 'calendar',
      type: 'link',
      badgeType: 'primary',
      path: '/app/agenda',
      active: false,
    },
    */

    /*
    {
      title: 'Rapports',
      icon: 'pie-chart',
      type: 'sub',
      badgeType: 'primary',
      path: '/app/dashboard',
      active: false,
      children: [
        //   { path: '/app/default', title: 'Demandes par clients', type: 'link' },
        //  { path: '/app/ecommerce', title: 'Demandes par statut', type: 'link' }
      ]
    },
    */
    {
      title: 'human-resources',
      icon: 'user-check',
      type: 'sub',
      badgeType: 'primary',
      active: false,
      displayed: (state) => state.user && state.user.organisation && state.user.organisation.featuresHumanResources
        && roleMixin.methods.userCan('read', 'hr', state),
      alwaysOpen: config.features.modeAts,
      showInHeader: !config.features.modeAts,
      path: '',
      children: [
        {
          title: 'dashboard',
          icon: 'home',
          type: 'link',
          badgeType: 'primary',
          badgeValue: '',
          path: '/app/dashboard/ats',
          active: false,
        },
        {
          title: 'candidates',
          icon: 'users',
          type: 'link',
          badgeType: 'primary',
          path: ({ state }) => '/app/candidate',
          active: false,
        },
        {
          title: 'ongoing-recruitments',
          icon: 'shuffle',
          type: 'link',
          badgeType: 'primary',
          path: ({ state }) => '/app/recruitment-process',
          active: false,
        },
        {
          title: 'positions',
          icon: 'list',
          type: 'link',
          badgeType: 'primary',
          path: ({ state }) => '/app/position',
          active: false,
        },
        {
          title: 'interviews',
          icon: 'calendar',
          type: 'link',
          badgeType: 'primary',
          path: ({ state }) => '/app/interview',
          active: false,
        },
        {
          title: 'notesAndTemplates',
          icon: 'file-text',
          type: 'link',
          badgeType: 'primary',
          path: ({ state }) => '/app/template/for/human-resources',
          active: false,
        },
        {
          path: ({ state }) => ({ path: '/app/settings/details/settings.hr' }),
          title: 'settings.hr',
          menutTitle: 'settings.settings',
          type: 'link',
          icon: 'users',
          displayed: (state) => state.user && state.user.organisation && state.user.organisation.featuresHumanResources,
          children: [
            {
              path: '/app/settings/recruitment-stage',
              title: 'settings.recruitment-stage',
              type: 'link',
              displayed: (state) =>
                state.user && state.user.organisation && state.user.organisation.featuresHumanResources,
            },
            {
              path: '/app/settings/event-type',
              title: 'settings.events',
              type: 'link',
            },
            {
              path: '/app/settings/hr-settings',
              title: 'settings.other-settings',
              type: 'link',
            },
          ],
        },
      ],
    },
    {
      title: 'mailboxes',
      icon: 'mail',
      type: 'sub',
      badgeType: 'primary',
      active: false,
      showInHeader: false,
      path: '/app/mailbox',

      displayed: (state) => state.user && state.user.organisation && state.user.organisation.featuresUnifiedInbox,
      children: [
        {
          path: '/app/mailbox',
          title: 'mailboxes',
          icon: 'mail',
          type: 'link',
          showInHeader: false,
          displayed: (state) => state.user && state.user.organisation && state.user.organisation.featuresUnifiedInbox,
        },
        {
          path: '/app/settings/mailbox?action=create',
          title: 'mailbox.add',
          type: 'link',
          displayed: (state) =>
            state.user && state.user.organisation && state.user.organisation.featuresUnifiedInbox,
        },
        {
          path: '/app/settings/details/settings.mailboxes',
          title: 'settings.mailboxes',
          type: 'link',
          showInHeader: false,
          displayed: (state) => state.user && state.user.organisation && state.user.organisation.featuresUnifiedInbox,
          children: [
            {
              path: '/app/settings/mailbox',
              title: 'settings.mailboxes',
              type: 'link',
              displayed: (state) =>
                state.user && state.user.organisation && state.user.organisation.featuresUnifiedInbox,
            },
            {
              path: '/app/settings/rule',
              title: 'settings.mailbox-rules',
              type: 'link',
              displayed: (state) =>
                state.user && state.user.organisation && state.user.organisation.featuresUnifiedInbox,
            },
          ],
        },
      ]
    },

    {
      title: 'settings-settings',
      icon: 'settings',
      type: 'sub',
      badgeType: 'primary',
      active: false,
      path: '/app/settings',
      children: [
        {
          path: '/app/settings/member',
          title: 'settings.users',
          type: 'link',
          displayed: isAdmin,
        },

        {
          path: '/app/settings/details/settings.conversation',
          title: 'settings.conversation',
          type: 'link',
          icon: 'users',
          displayed: (state) =>
            state.user && state.user.organisation && state.user.organisation.featuresClientConversations,
          children: [
            {
              path: '/app/settings/request-status',
              title: 'settings.request-status',
              type: 'link',
              displayed: (state) =>
                state.user && state.user.organisation && state.user.organisation.featuresClientConversations,
            },
            {
              path: '/app/settings/conversation-settings',
              title: 'settings.other-settings',
              type: 'link',
              displayed: (state) =>
                state.user && state.user.organisation && state.user.organisation.featuresClientConversations,
            },
          ],
        },
        {
          path: '/app/settings/company',
          title: 'settings.companies',
          type: 'link',
        },

        {
          path: '/app/settings/details/settings-advanced',
          title: 'settings-advanced',
          type: 'link',
          icon: 'settings',
          displayed: false,
          children: [
            {
              path: '/app/settings/organisation',
              title: 'settings.features',
              type: 'link',
              displayed: isAdmin,
            },
            {
              path: '/app/board',
              title: 'settings.boards',
              type: 'link',
              displayed: isAdmin,
            },
            {
              path: '/app/settings/event-type',
              title: 'settings.events',
              type: 'link',
            },
            {
              path: '/app/settings/apikey',
              title: 'settings.api-key',
              type: 'link',
              displayed: isAdmin,
            },
            {
              path: '/app/settings/webhook',
              title: 'settings.webhooks',
              type: 'link',
              displayed: isAdmin,
            },
            {
              path: '/app/settings/incoming-webhook',
              title: 'settings.incoming-webhooks',
              type: 'link',
              displayed: isAdmin,
            },
            {
              path: '/app/settings/role-based-access',
              title: 'settings.role-based-access',
              type: 'link',
              displayed: isAdmin,
            },
            {
              path: '/app/settings/advanced-settings',
              title: 'settings.other-settings',
              type: 'link',
              // displayed: isAdmin,
            },

          ],
        },
        {
          title: 'settings.chatsupport',
          type: 'button',
          onClick: () => {
            window.$crisp.push(['do', 'chat:show']);
            window.$crisp.push(['do', 'chat:open']);
          }
        },
      ],
    },
  ],
};
