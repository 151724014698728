import qs from 'qs';

const initialState = {
  recruitmentProcesses: [],
  selectedItem: null,
  blockedRecruitmentProcesses: [],
  recruitmentProcessesCount: 0,
  blockedRecruitmentProcessesCount: 0,
  recruitmentStages: [],
};

// mutations
const mutations = {
  recruitmentProcesses(state, data) {
    state.recruitmentProcesses = data;
  },
  blockedRecruitmentProcesses(state, data) {
    state.blockedRecruitmentProcesses = data;
  },
  recruitmentProcessesCount(state, data) {
    state.recruitmentProcessesCount = data;
  },
  blockedRecruitmentProcessesCount(state, data) {
    state.blockedRecruitmentProcessesCount = data;
  },
  recruitmentStages(state, data) {
    state.recruitmentStages = data;
  },
  selectedItem(state, data) {
    state.selectedItem = data;
  },
};

const actions = {
  getRecruitmentProcesses(context, filters = {}) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      throw new Error('user_is_not_connected');
    }
    return this._vm.$http
      .get('/api/recruitment-process', {
        params: {
          ...filters,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat',
          });
        },
      })
      .then((res) => {
        if (res.data && res.data.body) {
          if (res.data.totalCount) {
            context.commit('recruitmentProcessesCount', res.data.totalCount);
          }
          context.commit('recruitmentProcesses', res.data.body);
          return res.data.body;
        }
        return [];
      });
  },

  getBlockedRecruitmentProcesses(context, options) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      throw new Error('user_is_not_connected');
    }
    return this._vm.$http
      .get('/api/recruitment-process', {
        params: {
          ...options,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat',
          });
        },
      })
      .then((res) => {
        if (res.data && res.data.body) {
          if (res.data.totalCount) {
            context.commit('blockedRecruitmentProcessesCount', res.data.totalCount);
          }
          context.commit('blockedRecruitmentProcesses', res.data.body);
          return res.data.body;
        }
        return [];
      });
  },

  getRecruitmentStages(context, filters = {}) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      throw new Error('user_is_not_connected');
    }
    return this._vm.$http
      .get('/api/recruitment-stage', {
        params: {
          ...filters,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat',
          });
        },
      })
      .then((res) => {
        if (res.data && res.data.body) {
          context.commit('recruitmentStages', res.data.body);
          return res.data.body;
        }
        return [];
      });
  },

  getItem(context, id) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve(null);
    }
    context.commit('selectedItem', null);
    return this._vm.$http
      .get(`/api/recruitment-process/${id}`)
      .then((res) => {
        if (res.data && res.data.body) {
          context.commit('selectedItem', res.data.body);
          return res.data.body;
        }
        return null;
      });
  },

  updateItem(context, item) {
    const { id } = item;
    if (!id) {
      throw new Error('error_missing_id_in_put_request');
    }
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }
    return this._vm.$http
      .put(`/api/recruitment-process/${id}`,
        item)
      .then((res) => {
        if (res.data && res.data.body) {
          return res.data.body;
        }
        return [];
      });
  },

  async updateManyItems(context, { items, modifications }) {
    const p = items.map((item) =>
      context.dispatch('updateItem', {
        id: item.id,
        ...item,
        ...modifications,
      }));
    return Promise.all(p);
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
