import _, { uniq } from 'lodash';
import config from '@/config';
import Sentry from '../../services/Sentry';

const token = localStorage.getItem(`${config.appKey}_token`);
let currentUser;
let roles;
try {
  roles = localStorage.getItem(`${config.appKey}_roles`)
    ? JSON.parse(localStorage.getItem(`${config.appKey}_roles`))
    : [];
} catch (err) {
  console.error(err);
}

/**
 * Generate an array of all possible roles and their dependencies
 *
 * @param {*} role
 * @param {*} state
 * @returns
 */
function getExtendedRoles(role, state) {
  let myRoles = [];
  if (state.roles && state.roles[role] && state.roles[role].inherits && Array.isArray(state.roles[role].inherits)) {
    myRoles = myRoles.concat(state.roles[role].inherits);
    state.roles[role].inherits.forEach((r) => {
      myRoles = myRoles.concat(getExtendedRoles(r, state));
    });
  }
  return uniq(myRoles);
}
function formatRoleBasedAccesses(organisation) {
  let formattedRoleBasedAccess = {};
  if (!organisation || !organisation.RoleBasedAccess) {
    return;
  }
  formattedRoleBasedAccess = organisation.RoleBasedAccess.reduce(
    (acc, access) => ({ ...acc, [access.feature]: access }),
    {}
  );
  organisation.RoleBasedAccess = formattedRoleBasedAccess;
  return organisation;
}

function getCurrentOrganisation(u) {
  let organisation;
  const savedOrganisationId = localStorage.getItem(`${config.appKey}_organisationId`);
  if (u.MainOrganisation) {
    return u.MainOrganisation;
  }
  if (u.Organisations && u.Organisations.length) {
    if (savedOrganisationId) {
      organisation = u.Organisations.find((org) => `${org.id}` === `${savedOrganisationId}`);
      if (!organisation) {
        return u.Organisations[0];
      }
    } else {
      return u.Organisations[0];
    }
  }
  return organisation;
}

function getCurrentRoles(u) {
  const organisation = getCurrentOrganisation(u);
  const currentRoles
    = organisation && organisation.OrganisationMember && organisation.OrganisationMember.roles
      ? organisation.OrganisationMember.roles
      : [];

  return currentRoles;
}

export default {
  namespaced: true,
  state: {
    all: {},
    token,
    user: currentUser,
    roles,
    organisation: null,
    organisationSettings: null,
    userExtendedRoles: [],
  },
  actions: {
    login({ commit, dispatch }, user) {
      return this._vm.$http.post('/api/auth/login', user).then((res) => {
        if (res.data && res.data.user) {
          commit('token', res.data.token);
          commit('roles', res.data.appRoles);
          dispatch('setUser', res.data.user);
          // commit('organisation', organisation);
          // commit('currentLocale', u.locale || config.defaultLocale);
          dispatch('refreshListOfValues');
        }
      });
    },
    async setUser({ commit, dispatch, state }, user) {
      const { username, email } = user;
      await commit('user', user);
      await commit('extendedRoles');
      const organisation = getCurrentOrganisation(user);
      if (organisation) {
        if (!state.organisation || (organisation && organisation.id !== state.organisation.id)) {
          await commit('organisation', formatRoleBasedAccesses(organisation));
          //  await dispatch('refreshUser');
        }
      }
      try {
        Sentry.setUser({ username, email });
      } catch (err) {
        console.warn('[setUser]', err.message);
      }
      return true;
    },

    async refreshUser({ commit, dispatch, state }) {
      const orgId
        = state.organisation && state.organisation.id
          ? state.organisation.id
          : localStorage.getItem(`${config.appKey}_organisationId`);
      const res = await this._vm.$http.get('/api/auth/user', {
        headers: {
          'x-cimple-organisation-id': orgId,
        },
      });
      try {
        const { user, appRoles } = res.data;
        commit('roles', appRoles);
        await dispatch('setUser', user);

        if (!this.organisation && !getCurrentOrganisation(user) && !document.location.href.includes('/onboarding')) {
          document.location.href = `/onboarding?t=${this._vm.$route.query.t}`;
        }
        return user;
      } catch (err) {
        this._vm.$awEventBus.$emit('api-network-error');

        if (this._vm.$awNotify) {
          this._vm.$awNotify({
            title: err.response ? err.response.data : err,
            type: 'warning',
          });
        }
        if (err.response && err.response.status === 404) {
          dispatch('logout');
        }
      }
    },

    getAll({ commit }) {
      // commit('getAllOrganisations');

      this._vm.$http.get('/api/organisation').then(
        (users) => commit('getAllSuccess', users),
        (error) => commit('getAllFailure', error)
      );
    },

    async getOrganisation({ commit, dispatch, state }, organisationId = null) {
      if (!organisationId) {
        // eslint-disable-next-line
        organisationId = state.organisationId || (state.organisation && state.organisation.id);
      }
      if (!organisationId) {
        throw new Error('error_missing_organisation_id');
      }
      try {
        const { data } = await this._vm.$http.get(`/api/organisation/${organisationId}`);
        let org = data.body;
        org = formatRoleBasedAccesses(org);
        commit('organisation', org);
        await dispatch('refreshUser');
        return org;
      } catch (err) {
        console.error(err);
      }
    },
    async createOrganisation({ commit, dispatch, state }, organisationName = null) {
      if (!organisationName) {
        throw new Error('error_missing_organisation_name');
      }
      try {
        const { data } = await this._vm.$http.post('/api/organisation', { name: organisationName });
        await dispatch('getOrganisation', data.body.id);
        document.location.reload();
      } catch (err) {
        console.error(err);
      }
    },

    getUser({ commit, state }, userId = null) {
      if (!userId) {
        throw new Error('error_missing_user_id');
      }
      return this._vm.$http
        .get(`/api/user/${userId}`)
        .then(({ data }) => {
          const org = data.body;
          return org;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    updateUserLocale({ commit, dispatch, state }, locale) {
      if (!locale) {
        throw new Error('error_missing_user_locale');
      }
      let org;
      return this._vm.$http
        .put(`/api/user/${state.user.id}`, {
          ...state.user.user,
          locale,
        })
        .then(({ data }) => {
          org = data.body;
          return dispatch('refreshUser');
        })
        .then(() => org)
        .catch((err) => {
          console.error(err);
        });
    },

    refreshListOfValues(context) {
      const { dispatch } = context;
      dispatch('getModels', '', { root: true });
      dispatch('member/getItems', '', { root: true });
      return true;
    },

    logout({ commit }) {
      commit('token', '');
      commit('user', '');
      commit('organisation', '');
      return true;
    },
  },
  mutations: {
    roles(state, appRoles) {
      state.roles = appRoles;
      localStorage.setItem(`${config.appKey}_roles`, JSON.stringify(roles));
    },

    extendedRoles(state) {
      let myRoles = getCurrentRoles(state.user) || [];

      if (!Array.isArray(myRoles)) {
        myRoles = JSON.parse(myRoles);
      }

      myRoles.forEach((role) => {
        myRoles = myRoles.concat(getExtendedRoles(role, state));
      });
      state.userExtendedRoles = _.uniq(myRoles);
    },

    user(state, user) {
      const myRoles = getCurrentRoles(user) || [];

      state.user = user;
      if (user) {
        state.user.roles = myRoles;
      }
      state.locale = user.locale || config.defaultLocale;
    },

    organisation(state, org) {
      state.organisation = org;
      if (this._vm && this._vm.$http) {
        this._vm.$http.defaults.headers.common['x-cimple-organisation-id'] = org ? org.id : null;
      }
      if (this._vm && this._vm.$awApi) {
        this._vm.$awApi.defaults.headers.common['x-cimple-organisation-id'] = org ? org.id : null;
      }
      localStorage.setItem(`${config.appKey}_organisationId`, org && org.id);
      // after setting the org id, we need to pull the user data again to get the roles
    },

    token(state, appToken) {
      state.token = appToken;
      localStorage.setItem(`${config.appKey}_token`, appToken);

      if (this._vm && this._vm.$awApi) {
        this._vm.$awApi.defaults.headers.common.Authorization = `Bearer ${appToken}`;
        this._vm.$awApi.defaults.headers.Authorization = `Bearer ${appToken}`;
      }
    },
  },
  getters: {
    activeFeaturesModels: (state) => {
      const activeFeatures = ['client'];
      if (state.organisation) {
        if (state.organisation.featuresHumanResources) {
          activeFeatures.push('candidate', 'position', 'recruitmentProcess');
        }
        if (state.organisation.featuresClientConversations) {
          activeFeatures.push('request');
        }
        if (state.organisation.featuresCrm) {
          activeFeatures.push('lead', 'contact', 'company');
        }
        /*
        if (state.organisation.featuresUnifiedInbox) {
          activeFeatures.push('message');
        }
        */
      }
      return activeFeatures;
    },
  },
};
