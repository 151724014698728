<template>
  <div>
    <Breadcrumbs main="Dashboard" :title="$t('app.dashboard.leads-dashboard')" class="">
      <template #left>
        <div class="d-flex">
          <MemberSelectorDropdown v-model="userId" avatar-only />
          <div class="d-flex ml-3 mb-3">
            <button
              v-if="!showDateRangePicker"
              @click="showDateRangePicker = true"
              class="btn btn-primary btn-sm mx-2 py-0 px-2"
              id="clear-date-range-picker"
            >
              {{ $t('app.dashboard.date-filter-button') }}
            </button>
            <button
              v-if="showClearDatesButton"
              @click="clearDateRangePicker()"
              :disabled="!dateRange.startDate && !dateRange.endDate"
              class="btn btn-primary btn-sm mx-2 py-0 px-2"
              id="clear-date-range-picker"
            >
              {{ $t('app.dashboard.clear-date-filter-button') }}
            </button>
          </div>
          <date-range-picker
            v-if="showDateRangePicker"
            class="form-group vgt-date-range pl-2"
            :placeholder="$t('common.field.start')"
            @update="loadStatistics()"
            v-model="dateRange"
            :opens="'right'"
            :singleDatePicker="'range'"
            :auto-apply="true"
            :timePicker="false"
            :ranges="dateRanges"
            :locale-data="{ format: 'dd-mm-yyyy' }"
          >
          </date-range-picker>
        </div>
      </template>
    </Breadcrumbs>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 col-xl-4 col-lg-6">
          <px-card class="bg-secondary static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center"><feather type="users" class="middle"></feather></div>
                <div class="media-body">
                  <span class="m-0"> {{ $t('app.dashboard.clients') }} </span>
                  <h4 class="mb-0 counter">
                    {{ ($store.state.client.clients && $store.state.client.clientsCount) || 0 }}
                  </h4>
                  <feather type="users" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-sm-6 col-xl-4 col-lg-6">
          <px-card class="bg-success static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center"><feather type="box" class="middle"></feather></div>
                <div class="media-body">
                  <span class="m-0">{{ $t('app.dashboard.leads') }}</span>
                  <h4 class="mb-0 counter">
                    {{ totalLeadsCount || '0' }}
                  </h4>
                  <feather type="box" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-sm-6 col-xl-4 col-lg-6">
          <px-card class="bg-info static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center">
                  <feather type="message-square" class="middle"></feather>
                </div>
                <div class="media-body">
                  <span class="m-0"> {{ $t('app.dashboard.ongoing-leads') }} </span>
                  <h4 class="mb-0 counter">
                    {{ ongoingLeadsCount || 0 }}
                  </h4>
                  <feather type="message-square" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div>
        <!-- <div class="col-sm-6 col-xl-3 col-lg-6">
          <px-card class="bg-danger static-top-widget-card pt-3">
            <div slot="with-padding">
              <div class="media static-top-widget">
                <div class="align-self-center text-center">
                  <feather type="message-square" class="middle"></feather>
                </div>
                <div class="media-body">
                  <span class="m-0"> {{ $t('app.dashboard.late-leads') }} </span>
                  <h4 class="mb-0 counter">
                    {{ lateRequestsCount || 0 }}
                  </h4>
                  <feather type="message-square" class="icon-bg"></feather>
                </div>
              </div>
            </div>
          </px-card>
        </div> -->
      </div>
      <!-- Charts starts-->
      <div class="row">
        <div class="col-xl-4">
          <div class="card card-primary bg-primary" style="height: calc(100% - 15px)">
            <div class="card-header bg-primary">
              <h4 class="m-0 text-white">{{ $t('app.dashboard.leads') }}</h4>
            </div>

            <div class="card-body">
              <img src="../../assets/images/dashboard/folder1.png" alt="" class="pull-right" />
              Get more Leads using the new sourcing feature. We now add newly created companies to the sourcing
              section. Click on the explore companies button in the
              <router-link to="/app/company" class="text-info"> companies section</router-link> to find more leads.
              <a href="/app/company" class="text-primary">Click here</a>
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top mb-3">
                <h5 class="m-0">{{ $t('app.dashboard.leads-by-status') }}</h5>
                <div class="card-header-right-icon">
                  <select v-model="chartType" class="button btn btn-primary">
                    <option>Bar</option>
                    <option>Line</option>
                    <option>Pie</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="rp-graph text-dark">
              <chartist
                v-if="chartData && chartData.showChart"
                :type="chartType"
                ratio="VueChartList"
                :data="chartData"
                :options="chartistOptions"
              ></chartist>
              <no-result v-else />
            </div>
          </px-card>
        </div>
      </div>
      <!-- Charts ends-->
      <div class="row">
        <div class="col-xl-4 box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.leads-by-affected-to') }}</h5>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="leadsByAffectedTo"
                :perRow="1"
                :perPage="10"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template v-slot:list-items="{ items }">
                  <div class="news-update stat-block w-100 py-2 mb-0" v-for="item in items" :key="item.id">
                    <p class="text-dark mb-0">
                      {{ item.AffectedTo ? item.AffectedTo.firstName + ' ' + item.AffectedTo.lastName : '-' }}
                      <small class="muted badge badge-info f-w-700 pull-right" style="color: #fff">
                        {{ item.value }}</small
                      >
                    </p>
                  </div>
                </template>
              </awesome-list>
            </div>
          </px-card>
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.leads-by-source') }}</h5>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="leadsBySource"
                :perRow="1"
                :perPage="10"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template v-slot:list-items="{ items }">
                  <div class="news-update stat-block w-100 py-2 mb-0" v-for="item in items" :key="item.id">
                    <p v-if="item && item.source" class="text-dark mb-0">
                      {{ item.source }}
                      <small class="muted badge badge-info f-w-700 pull-right"> {{ item.value }}</small>
                    </p>
                  </div>
                </template>
              </awesome-list>
            </div>
          </px-card>
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.general-stats') }}</h5>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="generalStatistics"
                :perRow="1"
                :perPage="10"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template v-slot:list-items="{ items }">
                  <div class="news-update stat-block w-100 py-2 mb-0" v-for="item in items" :key="item.id">
                    <p class="text-dark mb-0">
                      {{ $t(`app.models.${item.model}`) }}
                      <small class="muted badge badge-primary f-w-700 pull-right"> {{ item.count }}</small>
                    </p>
                  </div>
                </template>
              </awesome-list>
            </div>
          </px-card>
          <!-- <div class="col-xl-4 xl-50 news box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.leads-by-source') }}</h5>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="requestsBySource"
                :perRow="1"
                :perPage="10"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template v-slot:list-items="{ items }">
                  <div class="news-update stat-block w-100 py-2 mb-0" v-for="item in items" :key="item.id">
                    <p class="text-dark mb-0">
                      {{ item.source }}
                      <small class="muted badge badge-info f-w-700 pull-right" style="color: #fff">
                        {{ item.value }}</small
                      >
                    </p>
                  </div>
                </template>
              </awesome-list>
            </div>
          </px-card>
        </div> -->
        </div>
        <div class="col-xl-8 box-col-6">
          <px-card v-if="organisation.featuresClientConversations" :actions="false" class="draggable" key="emails">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 id="#" class="m-0">{{ $t('app.client-detail.emails') }}</h5>
              </div>
            </div>
            <div class="recruitment-aws-list">
              <awesome-list
                :actions="{
                  refresh: true,
                  pagination: true,
                  search: false,
                  filter: true,
                  advancedFiltering: true,
                }"
                url="api/message"
                sortField="order"
                :perRow="1"
                :useGridSystem="true"
                :showHeader="false"
                :apiQueryParams="{
                  sort: { sentOrReceivedOn: 'desc' },
                  filters: {
                    leadId: { $notEq: null },
                  },
                }"
                :columns="[]"
                mode="remote"
              >
                <template v-slot:list-item="{ item }">
                  <div class="news-update w-100 py-2 mb-3">
                    <router-link :to="`/app/mailbox/${item.id}`">
                      <h6 class="mb-2">
                        <!-- {{ item.content }} -->

                        <small class="text-info pull-right" style="font-size: 8px; line-height: 0.5">
                          {{ item.parsedContent.date | formatDate }}</small
                        >
                        <small class="text-muted">From:</small>
                        <small class="text-info">{{ item.fromEmail }}</small>
                        <span class="mx-1 text-muted">>></span>
                        <small class="text-muted">to:</small>
                        <small class="text-primary">{{ item.toEmail || item.parsedContent.toEmail }}</small>
                        <template v-if="item.parsedContent.cc">
                          <span class="mx-1 text-dark">|</span>
                          <small class="text-muted">cc:</small>
                          <small class="badge badge-primary">{{ item.toEmail || item.parsedContent.cc }}</small>
                        </template>
                        <br />
                        <div class="text-truncate">
                          <small class="text-muted">Subject:</small>
                          <small class="text-dark">{{ item.subject || item.parsedContent.subject }}</small>
                        </div>
                      </h6>
                      <div
                        class="m-0 text-left text-truncate text-muted d-none"
                        style="font-size: 12px; line-height: 1"
                      >
                        {{ item.snippet || item.parsedContent.snippet }}
                        <br />
                      </div>
                    </router-link>
                  </div>
                </template>
              </awesome-list>
            </div>
          </px-card>
        </div>
      </div>
      <div v-if="false" class="row second-chart-list third-news-update">
        <!-- <div class="col-xl-4 xl-50 news box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.requests-by-client') }}</h5>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="$store.state.statistics && $store.state.statistics.requestsByClient"
                :perRow="1"
                :perPage="10"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template
                  v-slot:list-item="{ item }"
                  v-if="$store.state.statistics && $store.state.statistics.requestsByClient.length > 0"
                >
                  <div class="d-flex align-item-center news-update stat-block w-100 py-2 mb-">
                    <div v-if="item.Client">
                      <img
                        v-if="item.Client && item.Client.avatarUrl"
                        class="img-fluid img-40 rounded-circle mr-3 pull-left"
                        :src="item.Client.avatarUrl"
                        alt="Image description"
                      />
                      <img
                        v-else
                        class="img-fluid img-40 rounded-circle mr-3 pull-left"
                        src="../../assets/images/user/user.png"
                        alt="Image description"
                      />
                    </div>
                    <p v-if="item.Client" class="text-dark my-auto">
                      {{ item.Client ? `${item.Client.name}` : '' }}
                    </p>
                    <small
                      v-if="item.value"
                      class="muted badge badge-primary f-w-700 ml-auto my-auto"
                    >
                      {{ item.value }}</small
                    >
                  </div>
                </template>
                <template v-else v-slot:list-empty-state></template>
              </awesome-list>
            </div>
          </px-card>
        </div> -->
        <!-- <div v-if="organisation.featuresClientConversations" class="col-xl-4 xl-50 news box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.latest-conversations') }}</h5>
                <div class="card-header-right-icon">
                  <select class="button btn btn-primary" disabled>
                    <option>{{ $t('stats.today') }}</option>
                    <option>{{ $t('stats.this_week') }}</option>
                    <option>{{ $t('stats.this_month') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="$store.state.request && $store.state.request.dashboardRequests"
                :perRow="1"
                :useGridSystem="false"
                :showHeader="false"
              >
                <template v-slot:list-item="{ item }">
                  <div class="news-update w-100 py-2 mb-4">
                    <router-link :to="`/app/request/${item.id}`">
                      <div class="w-80">
                        <small
                          class="muted badge badge-primary  float-right f-w-300 mt-1"
                        >
                          {{ $t(`request.statuses.${item.status}`) }}</small
                        >
                        <p class="text-dark text-truncate pr-2">
                          {{ item.title }}
                        </p>
                        <small v-if="item.description" class="text-muted font-italic"
                          >{{ item.description ? striptags(item.description).substr(0, 100) : '' }}...
                        </small>
                      </div>
                    </router-link>
                  </div>
                </template>
              </awesome-list>

              <div class="news-update d-none">
                <h6>Déclaration des bénéficiaires effectifs</h6>
                <span>Il semble que notre déclaration des bénéficiaires ...</span>
              </div>
            </div>
            <div slot="footer">
              <div class="bottom-btn">
                <router-link to="/app/request">{{ $t('common.buttons.plus') }}</router-link>
              </div>
            </div>
          </px-card>
        </div> -->
        <!-- <div v-if="organisation.featuresClientConversations" class="col-xl-4 xl-50 notification box-col-6">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 class="m-0">{{ $t('app.dashboard.latest-messages') }}</h5>
                <div class="card-header-right-icon">
                  <select class="button btn btn-primary">
                    <option>{{ $t('stats.today') }}</option>
                    <option>{{ $t('stats.this_week') }}</option>
                    <option>{{ $t('stats.this_month') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div slot="with-padding" class="d-none">
              <div class="media">
                <div class="media-body">
                  <p>
                    20-04-2021
                    <span>10:10</span>
                  </p>
                  <small>
                    Attestation de vigilance
                    <span class="dot-notification"></span> </small
                  ><br />
                  <span>Nous attendons une réponse de l'urssaf, je vous la ferais parvenir dès que je la reçois</span>
                </div>
              </div>
              <div class="media">
                <div class="media-body">
                  <p>
                    20-04-2021
                    <span class="pl-1">Il y a quelques instants</span>
                    <span class="badge badge-secondary">New</span>
                  </p>
                  <small>
                    Mise en demeure
                    <span class="dot-notification"></span> </small
                  ><br />
                  <span>Voici mes retours sur le projet de courrier. Je vous laisse faire les modifications.</span>
                </div>
              </div>
            </div>
          </px-card>
        </div> -->
        <!-- <div class="col-xl-4 xl-50 appointment-sec box-col-6">
          <div class="row">
            <div class="col-xl-12">
              <px-card :actions="false">
                <div slot="headerCustom">
                  <div class="header-top mb-2">
                    <h5 class="m-0">{{ $t('app.dashboard.new-clients') }}</h5>
                    <div class="card-header-right-icon">
                      <select class="button btn btn-primary d-none">
                        <option>{{ $t('stats.today') }}</option>
                        <option>Tomorrow</option>
                        <option>Yesterday</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <awesome-list
                      :actions="{ refresh: false, pagination: false, search: false }"
                      :perRow="1"
                      :useGridSystem="false"
                      :showHeader="false"
                      :perPage="5"
                      :apiUrl="'/api/client?sort[createdOn]=desc'"
                    >
                      <template v-slot:list-item="{ item }">
                        <div class="w-100 py-2 mb-1 news-update">
                          <router-link :to="`/app/client/${item.id}`">
                            <small
                              v-if="item.status"
                              :class="{
                                'badge-primary': item.status === 'pending',
                                'badge-success': item.status === 'active',
                                'badge-default': item.status === 'inactive',
                              }"
                              class="muted badge pull-right  f-w-300 my-1"
                            >
                              {{ item.status }}</small
                            >
                            <div class="text-dark text-truncate d-flex align-item-center">
                              <img
                                v-if="item.avatarUrl"
                                class="img-fluid img-40 rounded-circle mr-2"
                                :src="item.avatarUrl"
                                alt="Image description"
                              />
                              <img
                                v-else
                                class="img-fluid img-40 rounded-circle mr-2"
                                src="../../assets/images/user/user.png"
                                alt="Image description"
                              />
                              <p class="d-inline-block" style="line-height: 1.2">
                                {{ item.name }}
                                <br /><small class="m-0 font-primary"> created {{ formatDate(item.createdOn) }}</small>
                              </p>
                            </div>
                          </router-link>
                        </div>
                      </template>
                    </awesome-list>
                  </div>
                </div>
                <div slot="footer">
                  <div class="bottom-btn">
                    <router-link to="/app/candidate">{{ $t('common.buttons.plus') }}</router-link>
                  </div>
                </div>
              </px-card>
            </div>
          </div>
        </div> -->
        <div class="col-xl-12 news box-col-12">
          <px-card :actions="false">
            <div slot="headerCustom">
              <div class="header-top">
                <h5 id="#" class="m-0">
                  {{ $t('app.dashboard.latest-comments') }}
                  <small>
                    {{ `(${totalCommentCount})` }}
                  </small>
                </h5>
                <div class="d-inline-flex justify-content-around">
                  <div class="card-header-right-icon pl-4">
                    <select name="" v-model="currentEventType" class="form-control" id="">
                      <!-- @change="setCurrentEventType" -->
                      <option value="" disabled>{{ $t('app.dashboard.event') }}</option>
                      <option value="--">--</option>
                      <option :key="eventType.code" :value="eventType.code" v-for="eventType in eventTypeList">
                        {{ eventType.code }}
                      </option>
                    </select>
                  </div>
                  <div class="card-header-right-icon pl-4 mw-30">
                    <select name="" v-model="currentModelType" class="form-control" id="">
                      <!-- @change="setCurrentEventType" -->
                      <option value="" disabled>{{ $t('app.dashboard.category') }}</option>
                      <option value="--">--</option>
                      <option
                        :key="commentModelType.model"
                        :value="commentModelType.model"
                        v-for="commentModelType in myCommentModelTypeList"
                      >
                        {{ $t(`app.models.${commentModelType.model}`) }}
                      </option>
                    </select>
                  </div>
                  <div v-if="checkIfModelIsSelectedAndHasItems" class="card-header-right-icon pl-4">
                    <select name="" v-model="currentModelItem" class="form-control">
                      <!-- @change="setCurrentEventType" -->
                      <option value="" disabled>Item</option>
                      <option value="--">--</option>
                      <option :key="modelItem.id" :value="modelItem.id" v-for="modelItem in modelItemList">
                        {{ modelItem.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <awesome-list
                :actions="{ refresh: false, pagination: false, search: false }"
                :rows="myComments"
                :perRow="1"
                :useGridSystem="true"
                :showHeader="false"
                :perPage="commentPerPage"
              >
                <template v-if="myComments && myComments.length > 0" v-slot:list-item="{ item }">
                  <div class="news-update w-100 py-2 mb-3">
                    <router-link
                      v-if="item.ModelRelations"
                      :to="`/app/${item.ModelRelations[0].model}/${item.ModelRelations[0].modelId}`"
                    >
                      <div>
                        <!-- {{ item.content }} -->
                        <small v-if="item.eventTypeCode" class="muted badge badge-primary pull-right f-w-300">{{
                          displayRecruitmentStageLabel(item.eventTypeCode) || item.eventTypeCode
                        }}</small>
                        <div class="comment-content" v-if="item.content" v-html="marked(item.content)"></div>
                        <div class="d-flex flex-wrap" v-if="item.ModelRelations">
                          <div v-for="model of item.ModelRelations" :key="model.model + model.modelId">
                            <div v-if="hasModelData(model) && !isUserModelRelation(model)">
                              <router-link
                                :to="`/app/${
                                  model.model === 'recruitmentProcess' ? 'recruitment-process' : model.model
                                }/${model.modelId}`"
                              >
                                <div
                                  v-if="model.model === 'recruitmentProcess'"
                                  class="badge badge-info fw-500 mr-2 mb-2 p-2"
                                >
                                  {{ $t('app.dashboard.go-to-recruitment-process') }}
                                </div>
                                <div v-else class="badge badge-info fw-500 mr-2 mb-2 p-2">
                                  {{ model.model }} :
                                  {{
                                    model.modelData.name ||
                                    model.modelData.title ||
                                    model.modelData.username ||
                                    (model.modelData.firstName &&
                                      model.modelData.firstName + ' ' + model.modelData.lastName) ||
                                    model.modelId
                                  }}
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-wrap" v-if="item.AllModelRelations">
                          <div v-for="model of item.AllModelRelations" :key="model.model + model.modelId">
                            <div v-if="hasModelData(model) && !isUserModelRelation(model)">
                              <router-link
                                :to="`/app/${
                                  model.model === 'recruitmentProcess' ? 'recruitment-process' : model.model
                                }/${model.modelId}`"
                              >
                                <div
                                  v-if="model.model === 'recruitmentProcess'"
                                  class="muted badge badge-primary fw-500 mr-2 mb-2 p-2"
                                ></div>
                                <div v-else class="muted badge badge-primary fw-500 mr-2 mb-2 p-2">
                                  {{ model.model }} :
                                  {{
                                    model.modelData.name ||
                                    model.modelData.title ||
                                    model.modelData.username ||
                                    (model.modelData.firstName &&
                                      model.modelData.firstName + ' ' + model.modelData.lastName) ||
                                    model.modelId
                                  }}
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="m-0 mt-2 text-left font-primary" style="font-size: 12px; line-height: 0.5">
                        {{ item.CreatedBy ? item.CreatedBy.firstName + ' ' + item.CreatedBy.lastName : '' }}
                        <br /><br />
                        <small class="m-0 font-primary">{{ item.createdOn | formatDate }}</small>
                      </p>
                    </router-link>
                  </div>
                </template>
                <template v-else v-slot:list-empty-state></template>
              </awesome-list>
            </div>
            <div aria-label="Page navigation example text-center w-100" class="d-flex justify-content-center">
              <ul class="pagination">
                <li v-if="!isFirstCommentPageDisplayed" class="page-item">
                  <a class="page-link" @click="goToPage(commentPage - 1)" href="#">{{
                    $t('common.buttons.previous')
                  }}</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">{{ commentPage }}</a>
                </li>
                <li v-if="hasNextCommentPageResults" class="page-item">
                  <a class="page-link" href="#" @click="goToPage(commentPage + 1)">{{ $t('common.buttons.next') }}</a>
                </li>
              </ul>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
    <div v-if="isTutorialOverlayDisplayed" class="page-dashboard-overlay" />

    <!-- <v-tour class="bg-light text-dark" name="firstVisitTour" :steps="steps" :callbacks="tourCallbacks">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            class="bg-light text-dark"
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template>
              <div slot="actions" class="v-step__actions">
                <button
                  v-if="tour.currentStep !== 3"
                  @click="() => finishTour(tour, true)"
                  class="btn btn-link text-secondary"
                >
                  Passer la visite
                </button>
                <button @click="() => finishTour(tour)" class="btn btn-primary">J'ai compris</button>
              </div>
            </template>
          </v-step>
        </transition>
      </template></v-tour
    > -->
  </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import qs from 'qs';
import { mapState } from 'vuex';
import marked from 'marked';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import striptags from 'striptags';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import MemberSelectorDropdown from '../../components/user/MemberSelectorDropdown.vue';
import NoResult from '../../components/basic/NoResult.vue';
import recruitmentStagesMixin from '../../mixins/recruitmentStagesMixin';

const primary = localStorage.getItem('primary_color') || '#7366ff';
const secondary = localStorage.getItem('secondary_color') || '#f73164';

export default {
  name: 'LeadDashboard',
  components: {
    MemberSelectorDropdown,
    DateRangePicker,
    NoResult,
  },
  mixins: [recruitmentStagesMixin],
  computed: {
    // ...mapState('request', ['dashboardRequests', 'requestsStatuses']),
    // ...mapState('client', ['recentClients']),
    ...mapState('user', ['user', 'organisation']),
    ...mapState('statistics', ['models', 'commentsByEvent', 'leadsByStage', 'leadsByAffectedTo', 'leadsBySource']),
    nonFinalLeadStages () {
      return this.leadStages ? this.leadStages.filter((stage) => !stage.isFinal).map((stage) => stage.code) : [];
    },
    generalStatistics () {
      let result;
      if (this.$store.state.statistics) {
        result = this.$store.state.statistics.models;
        result = result.filter((m) => !['candidate', 'position', 'interview', 'recruitmentProcess'].includes(m.model));
      }
      return result;
    },
    chartData () {
      let labels = [];
      let series = [];
      if (this.leadsByStage && this.leadsByStage.length > 0) {
        labels = this.leadsByStage.map((stage) => `${stage.stage || 'no stage'} (${stage.value})`);
        series = this.leadsByStage.map((stage) => {
          if (this.chartType === 'Pie') {
            return stage.value;
          }
          return {
            meta: stage.status,
            value: stage.value,
          };
        });
        return {
          labels,
          series: this.chartType === 'Pie' ? series : [series],
          showChart: labels.length > 0 && series.length > 0,
        };
      }
      return { labels, series: [series] };
    },
    hasNextCommentPageResults () {
      return !(this.commentPage > Math.floor(this.totalCommentCount / this.commentPerPage));
    },
    isFirstCommentPageDisplayed () {
      return this.commentPage === 1;
    },
    checkIfModelIsSelectedAndHasItems () {
      if (this.currentModelType !== '' && this.currentModelType !== '--' && this.modelItemList.length > 0) {
        return true;
      }
      return false;
    },
    showClearDatesButton () {
      if (this.dateRange.startDate && this.dateRange.endDate) {
        return true;
      }
      return false;
    },
    dateRanges () {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      const yesterdayStart = new Date();
      yesterdayStart.setDate(today.getDate() - 1);
      yesterdayStart.setHours(0, 0, 0, 0);
      const yesterdayEnd = new Date();
      yesterdayEnd.setDate(today.getDate() - 1);
      yesterdayEnd.setHours(23, 59, 59, 999);
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);
      return {
        Today: [today, todayEnd],
        Yesterday: [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999),
        ],
      };
    },

    // totalLeadsCount() {
    //   return this.generalStatistics && this.generalStatistics.find((stat) => stat.model === 'lead')
    //     ? this.generalStatistics.find((stat) => stat.model === 'lead').count
    //     : 0;
    // },
  },
  async mounted () {
    if (!this.leadStatuses) {
      this.leadStages = await this.$http
        .get(`/api/lead-stage?filters[organisationId]=${this.organisation.id}`)
        .then((result) => {
          if (result && result.data && result.data.body) {
            return result.data.body;
          }
        });
    }
    this.loadStatistics();

    this.$store.dispatch('client/getClients', { save: true });
    this.getComments();
    this.getEventTypeList();
  },

  watch: {
    userId () {
      this.loadStatistics();
    },
    dateRange: {
      handler (newValue, oldValue) {
        this.getComments();
      },
      deep: true,
    },
    currentEventType () {
      if (this.currentEventType === '--') {
        this.currentEventType = '';
        return;
      }
      this.commentPage = 1;
      this.getComments();
    },
    currentModelType (newValue, oldValue) {
      this.currentModelItem = '';
      if (this.currentModelType === '--') {
        this.modelItemList = [];
        this.currentModelType = '';
        return;
      }

      this.commentPage = 1;
      this.getComments();

      if (this.currentModelType === '') {
        return;
      }
      this.getItemList();
    },

    currentModelItem () {
      if (this.currentModelItem === '--') {
        this.currentModelItem = '';
        return;
      }

      if (this.currentModelItem === '') {
        this.getComments();
        return;
      }
      this.commentPage = 1;
      this.getComments();
    },
  },

  data () {
    return {
      primary,
      secondary,
      isTutorialOverlayDisplayed: false,
      myComments: [],
      commentPage: 1,
      commentPerPage: 8,
      totalCommentCount: 0,
      eventTypeList: [],
      userId: null,
      showDateRangePicker: false,
      showFilterByDateButton: true,
      todayDate: new Date().toISOString().substr(0, 10),
      dateRange: {
        startDate: null,
        endDate: null,
      },
      myCommentModelTypeList: [
        { model: 'candidate', label: 'Candidate' },
        { model: 'client', label: 'Client' },
        { model: 'contact', label: 'Contact' },
        { model: 'request', label: 'Request' },
        { model: 'interview', label: 'Interview' },
        { model: 'recruitmentProcess', label: 'Recruitment' },
        { model: 'position', label: 'Position' },
      ],
      chartistOptions: { plugins: [ChartistTooltip()], donut: true },
      modelItemList: [],
      currentEventType: '',
      currentModelType: '',
      currentModelItem: '',
      currentMember: '',
      chartType: 'Bar',
      ongoingLeadsCount: 0,
      totalLeadsCount: 0,
      lateRequestsCount: 0,
      leadStages: [],
    };
  },
  methods: {
    striptags,
    hasModelData (item) {
      if (!item.model || !item.modelId || !item.modelData) {
        return false;
      }
      return true;
    },

    isUserModelRelation (item) {
      if (item.model === 'user') {
        return true;
      }
      return false;
    },
    marked (content) {
      const linkedContent = content.replace(/@\[(.+):(.+):(.*)\]/g, '<span class="text-info">$3</span>');
      return marked(linkedContent);
    },
    formatDate (date) {
      return dayjs(date).fromNow();
    },
    clearDateRangePicker () {
      this.showDateRangePicker = false;
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.loadStatistics();
    },
    goToPage (page = 0) {
      if (page - 1 >= 0) {
        this.commentPage = page;
        this.getComments();
      }
    },
    loadStatistics (options = {}) {
      this.getItemsCount();
      this.$store.dispatch('statistics/getCountByModel', {
        startDate: this.dateRange.startDate && new Date(this.dateRange.startDate.setHours(0, 0, 0, 0)),
        endDate: this.dateRange.endDate && new Date(this.dateRange.endDate.setHours(23, 59, 0, 0)),
        ...options,
      });

      this.$store.dispatch('statistics/getCommentsGroupedByEvent', {
        createdBy: this.userId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        ...options,
      });
      ['Stage', 'AffectedTo', 'Source'].forEach((model) => {
        this.$store.dispatch(`statistics/getLeadsBy${model}`, {
          affectedToUserId: this.userId,
          startDate: this.dateRange.startDate,
          endDate: this.dateRange.endDate,
          archived: { $notEq: null },
          ...options,
        });
      });

      this.$store
        .dispatch('lead/getItems', {
          save: false,
          params: {
            perPage: 0,
            filters: {
              stage: { $in: this.nonFinalLeadStages },
              archived: false,
              affectedToUserId: this.userId,
            },
          },
        })
        .then((response) => {
          this.ongoingLeadsCount = response.totalCount;
        });
    },

    formatData (modelData) {
      let formattedData = [];

      if (!Array.isArray(modelData) && !modelData.length > 0) {
        return formattedData;
      }
      // eslint-disable-next-line no-return-assign
      formattedData = modelData.map((item) => {
        if (item.model === 'user' || item.model === 'candidate') {
          return {
            id: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
        }

        if (item.model === 'position' || item.model === 'request') {
          return {
            id: item.id,
            label: item.title,
          };
        }
        if (item.model === 'client') {
          return {
            id: item.id,
            label: item.name,
          };
        }

        if (item.model === 'recruitmentProcess') {
          return {
            id: item.id,
            label: item.id,
          };
        }
        return {
          id: item.id,
          label: item.id,
        };
      });

      return formattedData;
    },

    getComments () {
      const requestFilters = {
        includeModelData: true,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      };
      if (this.currentEventType !== '') {
        requestFilters.eventTypeCode = this.currentEventType;
      }

      if (this.currentModelType !== '') {
        requestFilters.model = this.currentModelType;
      }

      if (this.currentModelItem !== '') {
        requestFilters.modelId = this.currentModelItem;
      }
      return this.$http
        .get('/api/comment', {
          params: {
            page: this.commentPage - 1,
            perPage: this.commentPerPage,
            filters: requestFilters,
          },
          paramsSerializer (params) {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
            });
          },
        })
        .then(({ data }) => {
          if (!data.body) {
            return;
          }

          if (data.body && data.totalCount > 0) {
            this.myComments = data.body;
          } else {
            this.myComments = [];
          }

          this.totalCommentCount = data.totalCount;
        })
        .catch((err) => console.warn(err.message));
    },

    getItemList () {
      this.$http
        .get(`/api/${this.currentModelType === '' ? 'all' : this.currentModelType}/all/comment/get-item-list`)
        .then(({ data }) => {
          let formattedItemList = [];

          formattedItemList = this.formatData(
            data.body.map((item) => {
              item.ModelData.model = item.model;
              return item.ModelData;
            })
          );
          this.modelItemList = formattedItemList;
          return data;
        })
        .catch((err) => console.warn(err));
    },

    getItemsCount () {
      this.$http
        .get(`/api/lead?perPage=10${this.userId ? `&filters[affectedToUserId]=${this.userId}` : ''}`, {})
        .then(({ data }) => {
          this.totalLeadsCount = data.totalCount;
        })
        .catch((err) => console.warn(err));
    },

    getEventTypeList () {
      const p1 = this.$http.get(`/api/event-type?filters[organisationId]=${this.organisation.id}`);
      const p2 = this.$http.get(`api/recruitment-stage?filters[organisationId]=${this.organisation.id}`);
      Promise.all([p1, p2]).then(([response1, response2]) => {
        this.eventTypeList = uniqBy([...this.eventTypeList, ...response1.data.body, ...response2.data.body], 'code');
      });
    },

    isMobile () {
      return window.outerWidth < 992;
    },
  },
};
</script>

<style lang="scss">
#clear-date-range-picker {
  height: 30px;
}

.page-dashboard-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(78, 76, 93, 0.8);
}
</style>
