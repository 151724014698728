import qs from 'qs';
import Menu from '../../data/menu';

const initialState = {
  data: Menu.data,
  searchData: [],
  sidebarIsOpen: (document.body.clientWidth > 990)
};

// getters
const getters = {

};

// mutations
const mutations = {
  openSidebar: (state) => {
    state.sidebarIsOpen = true;
  },
  toggleSidebar: (state) => {
    state.sidebarIsOpen = !state.sidebarIsOpen;
  },
  closeSidebar: (state) => {
    state.sidebarIsOpen = false;
  },
  setMenu: (state, data) => {
    state.data = data;
  },
  resizetoggle: (state) => {
    if (window.innerWidth < 1199) {
      state.sidebarIsOpen = false;
    } else {
      state.sidebarIsOpen = true;
    }
  },
  searchTerm: (state, term) => {
    const items = [];
    const searchval = term.toLowerCase();
    state.data.forEach(menuItem => {
      if (menuItem.title) {
        if (menuItem.title.toLowerCase().includes(searchval) && menuItem.type === 'link') {
          if (menuItem.path) {
            items.push(menuItem);
          } else {
            console.warn('[menu] menu item without path', menuItem);
          }
        }
        if (!menuItem.children) return false;
        menuItem.children.forEach(subItems => {
          if (subItems.title.toLowerCase().includes(searchval) && subItems.type === 'link') {
            subItems.icon = menuItem.icon;
            if (subItems.path) {
              items.push(subItems);
            } else {
              console.warn('[menu] subItem item without path', subItems);
            }
            items.push(subItems);
          }
          if (!subItems.children) return false;
          subItems.children.forEach(suSubItems => {
            if (suSubItems.title.toLowerCase().includes(searchval)) {
              suSubItems.icon = menuItem.icon;
              if (suSubItems.path) {
                items.push(suSubItems);
              } else {
                console.warn('[menu] menu suSubItem without path', subItems);
              }
            }
          });
        });
        const found = [];
        state.searchData = items.filter(item => {
          if (!found[item.title]) {
            found[item.title] = true;
            return true;
          }
          return false;
        });
      }
    });
  },
  filterNavByFeature: (state, rootState) => {
    const navData = [];

    const filteredChildren = (item) => ({
      ...item,
      children: !item.children ? [] : item.children.filter((subItem) => {
        if (!subItem.displayed) {
          return true;
        }
        if (subItem.displayed(rootState)) {
          return true;
        }
        return false;
      })
    });

    Menu.data.forEach((item) => {
      if (!item.displayed || item.displayed(rootState)) {
        navData.push(filteredChildren(item));
      }
    });

    state.data = navData;
  },

  setNavActive: (state, item) => {
    if (!item.active) {
      state.data.forEach(a => {
        if (state.data.includes(item)) {
          a.active = false;
        }
        if (!a.children) return false;
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  },

  setActiveRoute: (state, item) => {
    state.data.forEach(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.forEach(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }
};

// actions
const actions = {
  openSidebar: (context, term) => {
    context.commit('openSidebar', term);
  },
  resizetoggle: (context, term) => {
    context.commit('resizetoggle', term);
  },
  searchTerm: (context, term) => {
    context.commit('searchTerm', term);
  },
  setNavActive: (context, item) => {
    context.commit('setNavActive', item);
  },
  setActiveRoute: (context, item) => {
    context.commit('setActiveRoute', item);
  },
  filterNavByFeature: (context, item) => {
    context.commit('filterNavByFeature', context.rootState);
  },
  searchItems(context, { query, type }) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      console.warn('[STORE][CLIENT] user or organisation not defined');
      return Promise.resolve([]);
    }
    return this._vm.$http
      .get(`/api/search?q=${query}`, {
        params: {
          type
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat',
          });
        },
      })
      .then((res) => {
        if (res.data && res.data.body) {
          return res.data;
        }
        return [];
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
