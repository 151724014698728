<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div>
              <a class="logo text-left">
                <img class="img-fluid for-light" src="../../assets/images/logo/logo.png" alt="loginpage" />
              </a>
            </div>
            <div class="login-main login-form-card">
              <form class="theme-form" @submit.prevent="submitForm()">
                <h4>{{ $t('common.labels.register') }}</h4>
                <p class="d-none">Tous les champs sont obligatoires</p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ $t('common.labels.firstName') }}</label>
                      <input
                        v-model="newUser.firstName"
                        name="firstName"
                        type="text"
                        class="form-control"
                        :placeholder="$t('common.placeholders.firstName')"
                        required
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ $t('common.labels.lastName') }}</label>
                      <input
                        v-model="newUser.lastName"
                        name="lastName"
                        type="text"
                        class="form-control"
                        :placeholder="$t('common.placeholders.lastName')"
                        required
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>{{ $t('common.labels.email') }}</label>
                      <input
                        v-model="newUser.email"
                        name="email"
                        type="email"
                        class="form-control"
                        :placeholder="$t('common.placeholders.email')"
                        :disabled="disableEmailField"
                        required
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ $t('common.labels.password') }}</label>
                      <input
                        v-model="newUser.password"
                        name="password"
                        type="password"
                        class="form-control"
                        :placeholder="$t('common.placeholders.password')"
                        required
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ $t('common.labels.confirmPassword') }}</label>
                      <input
                        v-model="newUser.cPassword"
                        name="cPassword"
                        type="password"
                        class="form-control"
                        :placeholder="$t('common.placeholders.confirmPassword')"
                        required
                        autocomplete="off"
                      />
                    </div>
                  </div>

                  <div class="col-md-12 mt-4">
                    <div class="form-group">
                      <button class="btn btn-primary btn-block" type="submit">
                        <i class="fa fa-circle-o-notch fa-spin" v-if="isRequestInProgress"></i>
                        <i class="fa fa-paper-plane" v-if="!isRequestInProgress"></i>
                        S'inscrire
                      </button>
                    </div>
                  </div>
                </div>
                <h6 class="text-muted mt-4 or" v-if="config.features.googleAuth">
                  {{ $t('common.labels.connectWith') }}
                </h6>
                <div class="social mt-4">
                  <div class="btn-showcase">
                    <a class="btn btn-light btn-block" v-if="config.features.googleAuth" @click="loginGoogle()">
                      <i class="fa fa-google text-danger"></i>
                      {{ !isRequestInProgress ? $t('common.buttons.google') : '' }}
                      <i class="fa fa-circle-o-notch fa-spin" v-if="isRequestInProgress"></i>
                    </a>
                    <!-- <a class="btn btn-light"> <feather type="facebook" class="txt-fb"></feather>facebook</a> -->
                  </div>
                </div>
                <div class="social mt-4">
                  <div class="btn-showcase">
                    <a class="btn btn-light btn-block" @click="loginMicrosoft()">
                      <i class="fa fa-windows text-info"></i>
                      {{ !isMicrosoftRequestInProgress ? $t('common.buttons.outlook') : '' }}
                      <i class="fa fa-circle-o-notch fa-spin" v-if="isMicrosoftRequestInProgress"></i>
                    </a>
                  </div>
                </div>
                <p class="mt-4 mb-0">
                  {{ $t('common.labels.alreadyHaveAnAccount') }}
                  <router-link to="/login" class="ml-2" tag="a">{{ $t('common.labels.login') }}</router-link>
                  <br />
                  <forgot-password tag="a" v-if="config && config.features && config.features.passwordReset" />
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-7 d-none d-lg-block p-0">
        <img
          class="bg-img-cover bg-center"
          src="../../assets/images/login/2.jpg"
          alt="loginpage"
          style="width: 100%; min-height: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { apiErrorsMixin } from 'vue-aw-components';
import { has, get } from 'lodash';
import swal from 'sweetalert2/dist/sweetalert2';
import config from '@/config';

import ForgotPassword from './ForgotPassword.vue';

export default {
  components: { ForgotPassword },
  mixins: [apiErrorsMixin],
  data() {
    return {
      newUser: {
        firstName: null,
        lastName: null,
        username: null,
        email: null,
        password: null,
        organisationName: '',
      },
      pageLoaded: false,
      disableEmailField: false,
      isRequestInProgress: false,
      isMicrosoftRequestInProgress: false,
      config,
      features: null,
    };
  },
  created() {},
  mounted() {
    if (this.$route.query.features) {
      this.features = this.$route.query.features;
      if (typeof this.$route.query.features === 'string') {
        this.features = [this.features];
        localStorage.setItem('registrationFeatures', JSON.stringify(this.features));
      }
    } else if (window.localStorage && localStorage.getItem('registrationFeatures')) {
      this.features = JSON.parse(localStorage.getItem('registrationFeatures'));
    }
    if (!window.gapi) {
      throw new Error('"https://apis.google.com/js/api:client.js" needs to be included as a <script>.');
    }
    if (this.$route.query.email) {
      this.newUser.email = this.$route.query.email;
      this.disableEmailField = true;
    }
    if (window.gapi && this.config.features.googleAuth) {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init({
          client_id:
            config.googleAuthClient || '735540248134-ggfesuvs015qf6f1fqs79aufslflp29s.apps.googleusercontent.com',
          scope: 'profile email openid',
        });
      });
    }

    setTimeout(() => {
      this.pageLoaded = true;
    }, 20);
  },
  methods: {
    showOrganisationForm() {
      return swal
        .fire({
          reverseButtons: true,
          input: 'text',
          title: this.$t('app.register.have-organisation'),
          text: `${this.$t('app.register.ask-invite')}
           ${this.$t('app.register.create-organization')}`,
          cancelButtonText: this.$t('app.register.company-already-exist'),
          confirmButtonText: this.$t('app.register.understood'),
          showCancelButton: true,
          inputValidator: (value) =>
            new Promise((resolve) => {
              if (!value) {
                resolve(this.$t('common.labels.enterCompanyName'));
              }
              resolve();
            }),

          allowOutsideClick: () => !swal.isLoading(),
        })
        .then((result) => {
          if (result.value) {
            this.newUser.organisationName = result.value;
            swal.fire(this.$t('common.messages.requestSuccess'), 'success');
          }
          return result;
        });
    },

    async submitForm() {
      // this.$notifications.clear();

      if (this.newUser.password !== this.newUser.cPassword) {
        this.$awNotify({
          title: this.$t('common.messages.password_not_match'),
          type: 'warning',
        });
        return;
      }
      this.$store.dispatch('user/logout');

      if (!this.disableEmailField) {
        const organisationShouldBeCreated = await this.showOrganisationForm();
        if (!organisationShouldBeCreated.isConfirmed) {
          return;
        }
      }
      this.$http
        .post('/api/user', {
          ...this.newUser,
          t: this.$route.query.t,
          features: this.features,
        })
        .then(async (res) => {
          const { user, token } = res.data;
          await this.$store.dispatch('user/setUser', user);
          await this.$store.commit('user/token', token);
          await this.$store.dispatch('refreshListOfValues');
          await this.$store.commit('user/organisation', user.Organisations[0]);
          const redirectUrl = this.$route.query.redirect ? this.$route.query.redirect : '/';
          window.location.href = redirectUrl;
        })
        .catch(this.apiErrorCallback);
    },

    async postLogin(res) {
      this.isRequestInProgress = false;
      if (!this.$store.state.user.user && has(res, 'data.user')) {
        await this.$store.dispatch('user/setUser', get(res, 'data.user'));
        await this.$store.commit('user/token', get(res, 'data.token'));
        this.$socket.connect();
      }
      if (this.$store.state.user.user) {
        const { user } = this.$store.state.user;
        if (!user.Organisations || !user.Organisations.length) {
          const { isConfirmed, value } = await this.showOrganisationForm();
          if (isConfirmed) {
            const organisationWasCreated = await this.createOrganisation(value);
            if (!organisationWasCreated.id) {
              return;
            }
          }
        } else {
          const redirectUrl = this.$route.query.redirect ? this.$route.query.redirect : '/';
          this.$router.push(redirectUrl);
        }
        setTimeout(() => {
          this.$awNotify({
            title: this.$t('common.messages.loginWelcome', {
              name: `${user.firstName}
                ${user.lastName}`,
            }),
            type: 'success',
          });
        }, 250);
        return;
      }
      this.$awNotify({
        title: this.$t('common.messages.no_access_account'),
        type: 'warning',
      });
    },

    loginGoogle() {
      return window.gapi.auth2.getAuthInstance().signIn().then(this.onSignIn);
      // return
      // this.$http.get('/api/auth/google').then(res => {
      //   window.location.href = res.data.body;
      // });
    },

    async onSignIn(googleUser) {
      // Useful data for your client-side scripts:
      this.isRequestInProgress = true;
      this.$http
        .post('/api/auth/google/callback', {
          ...googleUser.getAuthResponse(true),
          t: this.$route.query.t,
          features: this.features,
        })
        .then(this.postLogin)
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isRequestInProgress = false;
        });
    },

    async loginMicrosoft() {
      try {
        this.isMicrosoftRequestInProgress = true;
        const data = await this.microsoftAuthService.login();

        if (data && data.account && data.userData && data.accessToken) {
          this.loggedIn = true;
          const { account, userData, accessToken } = data;

          const res = await this.$http.post('/api/auth/microsoft/callback', {
            t: this.$route.query.t,
            account,
            userData,
            accessToken,
            features: this.features,
          });
          await this.postLogin(res);
        }
      } catch (error) {
        this.apiErrorCallback(error);
      } finally {
        this.isMicrosoftRequestInProgress = false;
      }
    },

    async createOrganisation(name) {
      this.isRequestInProgress = true;
      const response = await this.$http.post('/api/organisation', {
        name,
        features: this.features,
      });
      if (!response) {
        return null;
      }
      const { body } = response.data;
      this.$store.commit('user/organisation', body);
      await this.$store.dispatch('user/refreshUser');
      return body;
    },
  },
};
</script>
<style lang="scss">
.swal2-input {
  margin-top: 1em !important;
}
</style>
