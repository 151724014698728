import qs from 'qs';
import _ from 'lodash';

const initialState = {
  models: [],
  commentsByEvent: [],
  positionsByClient: [],
  recruitmentProcessesByStage: [],
  requestsByStatus: [],
  leadsByStage: [],
  requestsByClient: [],
  requestsByAffectedTo: [],
  leadsByAffectedTo: [],
  requestsBySource: [],
  leadsBySource: []
};

const mutations = {
  models(state, data) {
    state.models = data;
  },
  commentsByEvent(state, data) {
    state.commentsByEvent = data;
  },
  recruitmentProcessesByStage(state, data) {
    state.recruitmentProcessesByStage = data;
  },
  positionsByClient(state, data) {
    state.positionsByClient = data;
  },
  requestsByStatus(state, data) {
    state.requestsByStatus = data;
  },
  leadsByStage(state, data) {
    state.leadsByStage = data;
  },
  requestsByAffectedTo(state, data) {
    state.requestsByAffectedTo = data;
  },
  leadsByAffectedTo(state, data) {
    state.leadsByAffectedTo = data;
  },
  requestsBySource(state, data) {
    state.requestsBySource = data;
  },
  leadsBySource(state, data) {
    state.leadsBySource = data;
  },
  requestsByClient(state, data) {
    state.requestsByClient = data;
  },
};

const actions = {
  getCountByModel(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/models', {
        params: {
          ...filters,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat',
          });
        },
      })
      .then((res) => {
        context.commit('models', res.data.body);
      });
  },

  getCommentsGroupedByEvent(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/comment/eventTypeCode', {
        params: {
          ...filters,
        },
      })
      .then((res) => {
        if (res.data.body && res.data.body.length > 0) {
          context.commit('commentsByEvent', res.data.body.filter((item) => item.eventTypeCode && item.value));
        } else {
          context.commit('commentsByEvent', []);
        }
      });
  },

  getRecruitmentProcessesGroupedByStage(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/recruitmentProcess/stage', {
        params: {
          ...filters,
        },
      })
      .then(async (res) => {
        if (res && res.data && res.data.body) {
          const stages = await this._vm.$http('/api/recruitment-stage')
            .then(({ data }) => {
              if (data && data.body) {
                return data.body;
              }
            })
            .catch((err) => console.warn(err));
          if (stages && stages.length) {
            stages.forEach((stage) => {
              stage.count = res.data.body.find((s) => s.stage === stage.code);
              if (stage.count) {
                stage.count = stage.count.value;
              } else {
                stage.count = 0;
              }
            });
            return stages;
          }
        }
      })
      .then((res) => {
        context.commit('recruitmentProcessesByStage', res);
      });
  },

  getPositionsGroupedByClient(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/position/clientId', {
        params: {
          status: 'open',
          ...filters,
          include: ['Client']
        },
      })
      .then((res) => {
        if (res.data.body && res.data.body.length > 0) {
          context.commit('positionsByClient', res.data.body.filter((item) => item.Client !== null));
        } else {
          context.commit('positionsByClient', []);
        }
      });
  },

  getRequestsByClient(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/request/clientId', {
        params: {
          ...filters,
          include: ['Client']
        },
      })
      .then((res) => {
        if (res.data.body && res.data.body.length > 0) {
          context.commit('requestsByClient', res.data.body.filter((item) => item.Client !== null));
        } else {
          context.commit('requestsByClient', []);
        }
      });
  },

  getRequestsByAffectedTo(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/request/affectedToUserId', {
        params: {
          ...filters,
          include: ['AffectedTo']
        },
      })
      .then((res) => {
        if (res.data.body && res.data.body.length > 0) {
          context.commit('requestsByAffectedTo', res.data.body.filter((item) => item.Client !== null));
        } else {
          context.commit('requestsByAffectedTo', []);
        }
      });
  },
  getLeadsByAffectedTo(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/lead/affectedToUserId', {
        params: {
          ...filters,
          include: ['AffectedTo']
        },
      })
      .then((res) => {
        if (res.data.body && res.data.body.length > 0) {
          context.commit('leadsByAffectedTo', res.data.body);
        } else {
          context.commit('leadsByAffectedTo', []);
        }
      });
  },
  getLeadsBySource(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/lead/source', {
        params: {
          ...filters
        },
      })
      .then((res) => {
        if (res.data.body && res.data.body.length > 0) {
          context.commit('leadsBySource', res.data.body);
        } else {
          context.commit('leadsBySource', []);
        }
      });
  },
  getRequestsBySource(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/request/source', {
        params: {
          ...filters,
        },
      })
      .then((res) => {
        if (res.data.body && res.data.body.length > 0) {
          context.commit('requestsBySource', res.data.body.filter((item) => item.Client !== null));
        } else {
          context.commit('requestsBySource', []);
        }
      });
  },
  getRequestsByStatus(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/request/status', {
        params: {
          ...filters,
        },
      })
      .then((res) => {
        if (res.data.body && res.data.body.length > 0) {
          // if we have a status list, we use it to reorder the list of statuses.
          if (context.rootState.request.requestsStatuses) {
            res.data.body.forEach((item) => {
              item.Status = context.rootState.request.requestsStatuses.find(status => status.code === item.status);
            });
          }
          res.data.body = _.sortBy(res.data.body, 'Status.order');
          context.commit('requestsByStatus', res.data.body.filter((item) => item.Client !== null));
        } else {
          context.commit('requestsByStatus', []);
        }
      });
  },
  getLeadsByStage(context, filters = {}) {
    return this._vm.$http
      .get('/api/statistics/lead/stage', {
        params: {
          ...filters,
        },
      })
      .then((res) => {
        if (res.data.body && res.data.body.length > 0) {
          res.data.body = _.sortBy(res.data.body, 'Stage.order');
          context.commit('leadsByStage', res.data.body.filter((item) => item.Client !== null));
        } else {
          context.commit('leadsByStage', []);
        }
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
