/* eslint-disable import/prefer-default-export */

export const client = {
  identity: 'client',
  icon: '',

  schema: {
    properties: {
      id: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        }
      },
      organisationId: {
        field: {
          readonly: true,
          visible: false,
        },
        column: {
          visible: false
        }
      },
      createdOn: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        }
      },
      createdBy: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        }
      },
      lastModifiedOn: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}'
        }
      },
    },
  },
  actions: { create: true, edit: true, delete: true },
  options: {
    mode: 'local',
    customInlineActions: [],
  },

  nestedSchemas: [],
};
