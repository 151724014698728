<template>
  <div
    v-if="items && items.length > 0"
    class="card-body people-list"
    :class="{
      'table-responsive': isMobile,
      'row p-0 m-0': itemsPerRow > 1,
    }"
  >
    <router-link :to="`/app/${model}/${item.id}`" v-for="item in items" :key="item.id" class="">
      <div
        class="pointer parent p-1 pl-0 bg-white d-flex mt-2 mb-2 people-list-item"
        :class="{
          'col-6': itemsPerRow === 2,
          'col-4': itemsPerRow === 3,
          'col-3': itemsPerRow === 4,
          'col-2': itemsPerRow === 6,
        }"
      >
        <div class="col-12 col-md-4">
          <div class="media m-r-15">
            <img
              v-if="item.picture"
              style="width: 40px; height: 40px"
              class="img-fluid rounded-circle"
              :src="item.picture"
              alt
              @error="replaceImage"
            />
            <UserAvatar
              v-if="!item.picture"
              :user="item"
              :styles="{
                fontSize: '1rem',
                width: '40px',
                height: '40px',
                lineHeight: 1.8,
                fontWeight: '300',
              }"
            ></UserAvatar>
            <div class="media-body">
              <span>{{ item.firstName }} {{ item.lastName }}</span>
              <div class="font-roboto font-weight-light">{{ item.email }}</div>
              <div class="font-roboto font-weight-light">{{ item.phonenumber }}</div>
              <div class="d-flex">
                <a
                  target="_blank"
                  :href="item.linkedin"
                  :data-tooltip="item.linkedin"
                  data-tooltip-position="left"
                  class="text-info"
                  v-if="item.linkedin"
                >
                  <feather type="linkedin" size="15"></feather>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <span class="fw-400">{{ item.currentPosition || item.position }}</span>
          <template v-if="item.currentPosition && item.currentCompany"> @ </template>
          <span class="font-weight-light">{{ item.currentCompany }}</span>
        </div>
        <div class="col-12 col-md-3">
          <CompanyDisplay v-if="item.Company" :item="item.Company" />
          <div data-tooltip="In process for the position of " v-if="item.RecruitmentProcesses">
            <div v-for="process in item.RecruitmentProcesses" :key="process.id">
              <div v-if="process.Position">
                <feather class="text-bold" type="package" size="15"></feather>
                <span class="ml-1 font-weight-light">{{ process.Position && process.Position.title }}</span>
              </div>
              <div v-if="process.Client">
                <feather class="text-bold" type="map-pin" size="15"></feather>
                <span class="ml-1 font-weight-light">{{ process.Client && process.Client.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-none col-2">
          <button class="btn child-edit btn-transparent btn-simple mr-1 p-0">
            <feather type="edit-3" class="text-primary"></feather>
          </button>
          <button class="btn child-delete btn-transparent btn-simple p-0">
            <feather type="trash-2" class="text-secondary"></feather>
          </button>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import defaultImage from '../../assets/images/user/user.png';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemsPerRow: {
      type: [Number, String],
      default: 1,
    },
    model: {
      type: String,
      default: 'candidate',
    },
    isMobile: Boolean,
  },
  data () {
    return {};
  },
  mounted () {},
  methods: {
    replaceImage (error) {
      error.target.src = defaultImage;
    },
  },
};
</script>

<style>
.people-list a {
  color: unset;
}
</style>
