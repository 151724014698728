<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card" v-for="position in positions" :key="position.id">
            <div class="card-header">
              <h4 class="text-primary">{{ position.title }}</h4>
              <span>{{ position.createdOn | formatDate }}</span>
              <div v-if="position.contractType" class="badge badge-info">{{ position.contractType }}</div>
              <div v-if="position.location" class="badge badge-info">{{ position.location }}</div>
              <template v-if="position.tags"
                ><div class="badge badge-info" v-for="tag in position.tags" :key="tag">{{ tag }}</div></template
              >
              <div class="badge badge-success" v-if="position.location">{{ position.location }}</div>
              <div class="badge badge-secondary" v-if="position.workMode">{{ position.workMode }}</div>
              <template v-if="position.description">
                <hr />
                <b-button v-b-toggle.collapse-description variant="link" class="p-0 btn-sm text-primary"
                  >Description <feather type="chevron-right" size="12" class="text-primary pt-1"
                /></b-button>
                <b-collapse id="collapse-description" class="mt-2 p-0">
                  <small class="description-details" v-html="striptags(position.description, ['br', 'p'])"></small>
                </b-collapse>
              </template>
            </div>
            <div class="card-body">
              <button class="btn btn-primary btn-sm pull-right" @click="openCandidateModal(position)">
                {{ $t('common.buttons.apply') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <p class="mb-0 text-center">
        Copyright 2022 © <a href="https://so.cimple.to" target="_blank">{{ config.appName }}</a> by
        <a href="https://www.enyosolutions.com" target="_blank">Enyosolutions</a>
      </p>
    </div>
    <!-- Container-fluid Ends-->
    <!-- Modal -->
    <public-candidate
      :shouldDisplayModal="shouldDisplayModal"
      :organisationId="organisationId"
      :position="currentPosition"
      @closeModal="handleCloseModal"
    />
  </div>
</template>

<script>
import striptags from 'striptags';
import { BCollapse, BButton } from 'bootstrap-vue';

import PublicCandidate from '../components/candidate/PublicCandidate.vue';
import config from '../config';

export default {
  name: 'PublicPositionPage',
  components: {
    BButton,
    BCollapse,
    PublicCandidate,
  },
  mounted() {
    this.getPositions();
  },

  data() {
    return {
      positions: [],
      isCreating: true,
      shouldDisplayModal: false,
      currentPosition: null,
      organisationId: null,
      config,
    };
  },

  methods: {
    striptags,
    async getPositions() {
      const { organisationId } = this.$route.params;
      this.organisationId = organisationId;
      const { data } = await this.$http.get('/api/public-position?perPage=1000', {
        headers: {
          'x-cimple-organisation-id': organisationId,
        },
      });
      this.positions = data.body;
    },
    openCandidateModal(position) {
      this.currentPosition = position;
      this.shouldDisplayModal = true;
    },
    handleCloseModal() {
      this.shouldDisplayModal = false;
    },
  },
};
</script>
