/* eslint-disable import/prefer-default-export */

export const company = {
  identity: 'company',
  icon: '',
  schema: {
    properties: {
    },
  },
  // actions: { create: true, edit: true, delete: true },
  options: {
  },
  customInlineActions: [
    {
      name: 'create-lead',
      label: 'common.buttons.createLead',
      class: 'btn btn-sm ml-2 bg-info text-white',
      icon: 'fa fa-dollar-sign',
      // visible: ({ item }) => item.email,
      action: async ({
        item, action, location, props, id
      }, context) => {

        if (!item || !item.id) {
          return;
        }
        const lead = await context.$store.dispatch('company/convertToLead', item);
        context.$awNotify(
          context.$t('common.messages.contactConverted', {
            contactId: item.id,
            leadId: lead.id
          }),
        );
      },
    }
  ],
  customBulkActions: [],
  nestedSchemas: [],
};
