var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"segment-sidebar"},[_c('h4',{staticClass:"list-item text-dark pt-2 fw-300"},[_vm._v(" "+_vm._s(_vm.$t('common.labels.segmentViews'))+" "),_c('button',{staticClass:"btn-close btn btn-transparent",attrs:{"data-tooltip":"Close"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('feather',{attrs:{"type":"x","size":"1.2em"}})],1),_c('button',{staticClass:"btn btn-transparent p-0 pt-0 mr-4 float-right",attrs:{"data-tooltip":"Save"},on:{"click":_vm.createSegment}},[_c('feather',{staticClass:"text-primary",attrs:{"type":"plus-square","size":"1.2em"}})],1)]),_c('hr'),_c('ul',{staticClass:"sidebar-links"},[_c('li',{key:-1,staticClass:"btn p-0 fw-300 btn-transparent w-100 text-left mb-2 display-on-hover-parent",class:_vm.selectedSegmentId == -1 ? 'text-dark bg-light-primary' : 'text-info',on:{"click":function($event){return _vm.onSegmentClick(-1)}}},[_c('router-link',{staticClass:"px-1 pt-3 pb-4",class:_vm.selectedSegmentId == -1 ? 'text-dark' : 'text-dark',attrs:{"to":{
          name: _vm.$route.name,
          query: { segmentId: -1 },
        },"router-link-exact-active":""}},[_c('i',{staticClass:"fa fa-home",class:{
            'text-light-primary': _vm.selectedSegmentId == -1,
          }}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.buttons.reset')))])])],1),_vm._l((_vm.items),function(segment){return _c('li',{key:segment.id,staticClass:"btn p-0 fw-300 btn-transparent w-100 text-left mb-2 display-on-hover-parent",class:_vm.selectedSegmentId == segment.id ? 'text-dark bg-light-primary' : 'text-info',on:{"click":function($event){return _vm.onSegmentClick(segment.id)}}},[_c('router-link',{staticClass:"px-1 pt-3 pb-4",class:_vm.selectedSegmentId == segment.id ? 'text-dark' : 'text-dark',attrs:{"to":{
          name: _vm.$route.name,
          query: Object.assign({}, segment.query, {segmentId: segment.id}),
        },"router-link-exact-active":""}},[_c('i',{staticClass:"fa",class:{
            'text-light-primary': _vm.selectedSegmentId == segment.id,
            'fa-list': segment.uiState.displayMode === 'list',
            'fa-columns': segment.uiState.displayMode === 'kanban',
            'fa-table': segment.uiState.displayMode === 'table',
            'fa-angle-right': !segment.uiState.displayMode,
          }}),_c('span',[_vm._v(" "+_vm._s(segment.name))]),_c('feather',{staticClass:"ml-2 pull-right text-danger display-on-hover",attrs:{"type":"trash-2","size":"16"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteSegment(segment.id)}}}),_c('feather',{staticClass:"ml-2 pull-right text-info display-on-hover",attrs:{"type":"save","size":"16"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateSegment(segment.id)}}})],1)],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }