<template>
  <div>
    <div class="d-inline-block">
      <h6 class="text-muted"><i class="icofont icofont-clip"></i> Pièces jointes</h6>
      <a class="text-muted text-right right-download d-none" href="#"
        ><i class="fa fa-long-arrow-down mr-2"></i>Download All</a
      >
      <div class="clearfix"></div>
    </div>
    <div class="attachment">
      <ul class="list-inline" v-if="attachments">
        <li
          class="list-inline-item"
          v-for="(attachment, index) in attachments"
          :key="index"
          :data-type="attachment.contentType"
        >
          <a :href="attachment.url" target="_blank">
            <i
              class="fa"
              :class="{
                'fa-file-pdf-o': attachment.contentType === 'application/pdf',
                'fa-file-word-o': attachment.contentType === 'application/word',
                'fa-file-excel-o': attachment.contentType === 'application/excel',
                'fa-file-image-o':
                  attachment.contentType === 'application/jpeg' ||
                  attachment.contentType === 'application/png' ||
                  attachment.contentType === 'application/gif',
              }"
            ></i>
            {{ attachment.fileName || attachment.filename }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MailBoxAttachments',
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
