export default {
  methods: {

    userCan(action, feature, state = null) {
      const $state = (this.$store && this.$store.state) || state;
      if (!$state?.user?.organisation?.RoleBasedAccess || !$state.user.organisation.RoleBasedAccess[feature]) {
        return false;
      }
      return !!$state.user.organisation.RoleBasedAccess[feature][action].find((role) =>
        $state.user.userExtendedRoles.includes(role));
    },

    userIs(role, state = null) {
      const $state = (this.$store && this.$store.state) || state;
      return !!(
        $state.user
        && $state.user.user
        && $state.user.user.organisationRoles
        && $state.user.user.organisationRoles.indexOf(role) > -1
      );
    },

    userIsAnyOf(roles, $store = null) {
      return roles.some((role) => this.userIs(role, $store));
    },

    userHasRole(role, $store = null) {
      return this.userIs(role, $store);
      /*
      $store = this.$store || $store;
      return $state.user
        && $state.user.userExtendedRoles
        && $state.user.userExtendedRoles.indexOf(role) > -1;
        */
    },
  },
};
