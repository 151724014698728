export default {
  methods: {
    displayRecruitmentStageLabel(stage, $store = null) {
      $store = this.$store || $store;
      if (!$store.state.recruitmentProcess.recruitmentStages) {
        return false;
      }
      if ($store.state.recruitmentProcess.recruitmentStages.length) {
        const { recruitmentStages } = $store.state.recruitmentProcess;
        if (recruitmentStages.find((s) => s.code === stage)) {
          return recruitmentStages.find((s) => s.code === stage).label || false;
        }
      }
      return false;
    }
  }
};
