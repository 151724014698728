<template>
  <px-card :actions="false">
    <div slot="headerCustom">
      <div class="header-top">
        <h5 class="m-0">{{ title || 'Mes taches' }}</h5>
        <div class="card-header-right-icon">
          <feather type="plus" @click="openCreateForm" size="1.3em" class="text-primary pointer"></feather>
          <select disabled class="button btn btn-primary d-none">
            <option></option>
            <option>{{ $t('stats.today') }}</option>
            <option>{{ $t('stats.this_week') }}</option>
            <option>{{ $t('stats.this_month') }}</option>
          </select>
        </div>
      </div>
    </div>
    <div slot="with-padding">
      <p class="ml-3" v-if="totalTasks === 0">
        <no-result />
      </p>
      <div class="media d-block" v-for="task in tasks" :key="task.id">
        <!-- <router-link :to="`/app/${task.model}/${task.modelId}`"> -->
        <div class="media-body task mb-2">
          <div class="task-action-btn pull-right" v-if="task.status !== 'done'">
            <button
              v-if="userCan('update', 'task')"
              @click="editTask(task)"
              class="btn btn-icon p-0 d-none"
              title="Delete Task"
            >
              <feather class="text-info" type="edit-3" size="1.1rem" />
            </button>
            <button
              v-if="userCan('update', 'task') && !isRecurring(task)"
              @click="deleteTask(task)"
              class="btn btn-icon p-0"
              title="Delete Task"
            >
              <feather class="text-danger" type="trash-2" size="1.1rem" />
            </button>
            <button
              v-if="!isRecurring(task)"
              @click="completeTask(task)"
              class="btn btn-icon p-0"
              title="Mark Complete"
            >
              <feather class="text-primary" type="check" />
            </button>
          </div>
          <div class="mb-0 text-dark">
            {{ task.content }}
            <br />

            <ul style="border-left: 1px solid var(--primary); margin-left: 5px; padding-left: 5px; font-szie: 12pt">
              <li>
                <router-link
                  v-if="task.model && task.modelId"
                  :to="`/app/${task.model === 'recruitmentProcess' ? 'recruitment-process' : task.model}/${
                    task.modelId
                  }`"
                >
                  <div class="text-info btn-sm fw-500 p-1">
                    <i class="fa fa-angle-double-right text-muted"></i>
                    <span class="text-muted">{{ $t(`app.models.${task.model}`) }}:</span> {{ getTaskTitle(task) }}
                  </div>
                </router-link>
              </li>
              <li>
                <i class="fa fa-angle-double-right text-muted"></i>
                <StatusLabel class="" :status="task.status ? task.status : ''" />
                <StatusLabel
                  v-if="task.dueDate && new Date(task.dueDate) < now"
                  class=""
                  :status="'overdue'"
                  :color="task.status !== 'done' && task.dueDate && new Date(task.dueDate) < now ? 'danger' : ''"
                />
              </li>
              <li
                v-if="task.dueDate"
                :class="{
                  'text-danger': new Date(task.dueDate) < now,
                  'text-dark': new Date(task.dueDate) > now,
                }"
              >
                <i class="fa fa-angle-double-right text-muted"></i>
                <span class="font-italic text-muted">{{ $t('common.deadline') }}:</span>
                {{ task.dueDate | formatDate }}
              </li>
              <li class="m-0 text-left text-muted" style="font-size: 10px; line-height: 0.5">
                <UserAvatar
                  :user="task.AffectedTo"
                  :style="{
                    width: '20px',
                    height: '20px',
                    lineHeight: '1.5',
                  }"
                ></UserAvatar>
                <br />
              </li>
            </ul>
          </div>
        </div>
        <!-- </router-link> -->
      </div>
      <awesomeForm
        v-if="showCreateForm"
        identity="modelTask"
        :item="{}"
        mode="create"
        displayMode="modal"
        @cancel="closeCreateForm"
        @save="closeCreateForm"
        @closeRequested="closeCreateForm"
      ></awesomeForm>
    </div>
  </px-card>
</template>
<script>
import { mapState } from 'vuex';
import { debounce, upperFirst } from 'lodash';
import StatusLabel from '../basic/StatusLabel.vue';
import NoResult from '../basic/NoResult.vue';

export default {
  name: 'DashboardTaskWidget',
  components: { StatusLabel, NoResult },
  computed: {
    ...mapState('user', ['user', 'organisation']),
  },
  props: {
    title: String,
    perPage: { type: [String, Number], default: 10 },
    filters: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      tasks: [],
      totalTasks: 0,
      now: new Date(),
      showCreateForm: false,
    };
  },
  mounted() {
    this.getTasksDebounced = debounce(this.getTasks, 200);
    this.getTasksDebounced();
  },
  watch: {
    filters() {
      this.getTasksDebounced();
    },
  },
  methods: {
    getTasks() {
      this.$store
        .dispatch('task/getItems', {
          save: false,
          perPage: this.perPage,
          sort: { createdOn: 'desc' },
          params: {
            filters: { ...this.filters, status: { $in: ['todo', 'doing'] } },
            shouldFetchRecurringTasks: true,
          },
        })
        .then((data) => {
          const { body, totalCount } = data;
          this.totalTasks = totalCount;
          this.tasks = body.filter((t) => t);
          this.$emit('onChange', data);
        });
    },

    closeCreateForm() {
      this.showCreateForm = false;
      this.getTasksDebounced();
    },

    openCreateForm() {
      this.showCreateForm = true;
    },

    isRecurring(task) {
      let { selectedTask } = this;
      if (task) {
        selectedTask = task;
      }

      return (selectedTask.recurrence && selectedTask.recurrence !== 'no-recurrence') || selectedTask.cronInstruction;
    },

    completeTask(task) {
      if (task && task.id) {
        this.$http
          .put(`/api/model-task/${task.id}`, {
            content: task.content,
            status: 'done',
          })
          .then(() => {
            this.getTasks();
          });
      }
    },

    deleteTask(selectedTask) {
      if (selectedTask && selectedTask.id) {
        this.$awConfirm('Etes vous sur ?').then(
          (confirmation) =>
            confirmation &&
            this.$http.delete(`/api/model-task/${selectedTask.id}`).then(() => {
              this.getTasks();
            })
        );
      }
    },

    getTaskTitle(task) {
      const model = upperFirst(task.model);
      if (!task[model]) {
        if (task.modelData) {
          task[model] = task.modelData;
        } else {
          return '';
        }
      }
      if (task.model === 'recruitmentProcess') {
        return task.RecruitmentProcess ? task.RecruitmentProcess.title : '';
      }
      switch (task.model) {
      case 'candidate':
      case 'contact':
        return `${task[model].firstName} ${task[model].lastName}`;
      case 'client':
      case 'lead':
        return `${task[model].name}`;
      case 'request':
        return `${task[model].title.substr(0, 30)}...`;
      default:
        break;
      }
      return task[task.model] ? task[task.model].title : '';
    },
  },
};
</script>
