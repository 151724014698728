<template>
  <div>
    <awesomeForm
      v-if="showContactForm"
      identity="contact"
      :item="newContact"
      :mode="formMode"
      displayMode="modal"
      :url="`/api/contact`"
      @cancel="showContactForm = false"
      @delete="deleteContact"
      @closeRequested="closeContactCreateForm()"
      :standalone="false"
      :useRouterMode="false"
    ></awesomeForm>

    <px-card
      :title="$t('app.client-detail.contacts') + ` [${tasks ? tasks.length : 0}]`"
      :actions="false"
      class="client-widget client-section draggable pb-0"
      bodyClasses="pb-0"
    >
      <template #headerCustom>
        <div class="" style="position: absolute; top: 10px; right: 10px">
          <button class="btn bg-transparent text-primary p-1" @click.prevent="goToContactCreate" data-tooltip="Create">
            <feather type="user-plus" size="1rem"></feather>
          </button>
        </div>
        <hr class="mb-0" />
      </template>
      <div slot="with-padding">
        <div class="draggable">
          <div class="appointment-table table-responsive" v-if="_contacts">
            <table class="table table-bordernone">
              <tbody>
                <tr v-for="contact in _contacts" :key="contact.id" class="mb-3">
                  <td class="mb-3">
                    <user-avatar :user="contact" size="22px"></user-avatar>
                  </td>
                  <td class="img-content-box mb-3">
                    <span class="d-block">{{ contact.firstName }} {{ contact.lastName }}</span>
                    <small class="font-roboto">{{ contact.position }}</small>
                  </td>
                  <td>
                    <div class="m-0">
                      <template v-for="(phonenumber, i) in formatFieldToArray(contact.phonenumber)">
                        <template v-if="i > 0">|</template>
                        <a :key="phonenumber" :href="`tel:${phonenumber.replace(/[ -\.]/g, '')}`">{{ phonenumber }}</a>
                      </template>
                    </div>
                    <div class="m-0">
                      <template v-for="(email, i) in formatFieldToArray(contact.email)">
                        <template v-if="i > 0">|</template>
                        <a
                          class="text-primary pointer"
                          :key="email"
                          @click.prevent="() => openEmailComposerModal(contact)"
                          >{{ email }}</a
                        >
                      </template>
                    </div>
                  </td>
                  <td class="text-right">
                    <button
                      target="_blank"
                      class="button btn btn-icon p0 pr-1"
                      @click="enrichContact(contact)"
                      :title="$t('common.buttons.enrich')"
                    >
                      <feather type="zap" size="1rem" class="text-warning" />
                    </button>
                    <a
                      v-if="!contact.linkedin"
                      target="_blank"
                      class="button btn btn-icon p-0 pr-1"
                      :href="getLinkedInSearchUrl(contact)"
                      :title="$t('common.buttons.searchOnGoogle')"
                    >
                      <feather type="search" size="1rem" class="text-info" />
                    </a>
                    <a
                      v-if="contact.linkedin"
                      target="_blank"
                      class="button btn btn-icon p-0 pr-1"
                      :href="contact.linkedin"
                      :title="contact.linkedin"
                    >
                      <feather type="linkedin" size="1rem" class="text-info" />
                    </a>
                    <button v-if="contact.email" class="button btn btn-icon p-0 pr-1">
                      <feather
                        @click="() => openEmailComposerModal(contact)"
                        type="mail"
                        size="1rem"
                        class="text-info"
                      />
                    </button>
                    <button class="button btn btn-icon p-0" @click="goToContactView(contact)">
                      <feather type="eye" size="1rem" class="text-info" />
                    </button>
                    <button class="button btn btn-icon p-0" @click="goToContactEdit(contact)">
                      <feather type="edit-3" size="1rem" class="text-info" />
                    </button>
                    <button class="button btn btn-icon pr-1 d-none" @click="goToContactEdit(contact)">
                      <feather type="trash-2" size="1rem" class="text-danger" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </px-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import striptags from 'striptags';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);

export default {
  name: 'ContactsWidget',
  props: {
    disabled: Boolean,
    showViewButton: Boolean,
    showEditButton: Boolean,
    showDeleteButton: Boolean,
    client: Object,
    selectedClientStats: Object,
    clientId: [String, Number],
    leadId: [String, Number],
    contacts: [Array],
  },
  data() {
    return {
      shouldDisplayModal: false,
      isSending: false,
      showEditForm: false,
      markAllAsRead: true,
      isAddingTask: false,
      tasks: [],
      apiContacts: [],
      showContactForm: false,
      formMode: 'create',
      newContact: {
        firstName: '',
        lastName: '',
        email: '',
        phonenumber: '',
        role: '',
      },
    };
  },
  mounted() {
    this.resetContactObject();
    if (this.clientId) {
      this.newContact.clientId = this.clientId;
    } else if (this.leadId) {
      this.newContact.leadId = this.leadId;
    }

    if (!this.contacts) {
      this.refreshContacts();
    }
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    ...mapState('email', ['showEmailComposerModal']),
    _contacts() {
      return this.contacts || this.apiContacts;
    },
  },
  watch: {},
  methods: {
    openEmailComposerModal(contact) {
      this.$store.dispatch('email/setShowEmailComposerModal', {
        show: true,
        recipientAddress: contact.email,
        recipientObject: contact,
      });
    },
    striptags,
    async getContacts(params) {
      const { data } = this.$http.get('/api/contact', { params });
      this.apiContacts = data.body;
    },

    goToContactCreate() {
      this.formMode = 'create';
      this.resetContactObject();
      this.showContactForm = true;
    },

    goToContactView(contact) {
      this.formMode = 'view';
      this.showContactForm = true;
      this.newContact = contact;
    },

    goToContactEdit(contact) {
      this.formMode = 'edit';
      this.showContactForm = true;
      this.newContact = contact;
    },

    async deleteContact(contact) {
      const confirmation = await this.$awConfirm(this.$t('common.messages.are_you_sure'));
      if (confirmation) {
        try {
          await this.$http.delete(`/api/contact/${contact.id}` || contact);
          this.$emit('change');
          this.showContactForm = false;
          if (!this.contacts) {
            this.refreshContacts();
          }
        } catch (err) {
          this.apiErrorCallback(err);
        }
      }
    },
    async closeContactCreateForm() {
      this.showContactForm = false;
      this.resetContactObject();
      this.$emit('change');
    },

    resetContactObject() {
      this.newContact = {
        clientId: this.clientId || undefined,
        leadId: this.leadId || undefined,
        organisationId: this.organisation.id,
      };
    },

    formatFieldToArray(fieldValue) {
      if (!fieldValue) {
        return [];
      }
      const list = fieldValue.includes('|') ? fieldValue.split('|') : fieldValue.split(',');
      return list.map((item) => item.trim());
    },

    getLinkedInSearchUrl(contact) {
      let url = `https://www.google.com/search?q=site:linkedin.com%20${contact.firstName}%20${contact.lastName}`;
      if (contact.company || contact?.Company?.name) {
        url += `%20${contact.company || contact?.Company?.name}`;
      }

      return url;
    },

    refreshContacts() {
      if (this.contacts) {
        this.$emit('change');
        return;
      }
      this.getContacts({
        filters: {
          clientId: this.clientId || undefined,
          leadId: this.leadId || undefined,
        },
      });
    },

    enrichContact(contact) {
      return this.$http
        .put(`/api/contact/${contact.id}/enrich`)
        .then((response) => {
          const result = response.data.body;
          if (result?.[0]) {
            this.refreshContacts();
            this.$awToast({
              title: this.$t('common.messages.success'),
              message: this.$t('common.messages.item_enriched'),
              type: 'success',
            });
          }
        })
        .catch(console.warn);
    },
  },
};
</script>

<style lang="scss">
.client-details {
  font-size: 11.2px;
  color: #868e96;
  a {
    font-size: 11.2px;
  }
  a,
  i {
    color: #868e96;
    display: inline-block;
    padding: 0;
  }
  a:hover {
    cursor: pointer;
  }
  .description-details {
    font-size: 11.2px;
    * {
      font-size: 11.2px;
    }
  }
  .feather.feather--chevron-right {
    vertical-align: middle;
  }
}
</style>
