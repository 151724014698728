/* eslint-disable import/prefer-default-export */
const dayjs = require('dayjs');

export const interview = {
  identity: 'interview',
  icon: '',

  schema: {
    properties: {
      id: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}',
        },
      },
      organisationId: {
        field: {
          readonly: true,
          visible: false,
        },
        column: {
          visible: false,
        },
      },
      createdOn: {
        nullable: true,
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}',
        },
      },
      createdBy: {
        nullable: true,
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}',
        },
      },
      lastModifiedOn: {
        nullable: true,
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}',
        },
      },
      lastModifiedBy: {
        nullable: true,
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}',
        },
      },
    },
  },
  actions: { create: true, edit: true, delete: true },
  options: {
    mode: 'local',
    customInlineActions: [],
  },

  customInlineActions: [
    {
      name: 'complete-request',
      label: 'Ajouter à google calendar',
      class: 'btn btn-outline-info p-1',
      title: '',
      icon: 'fa fa-calendar mr-1',
      canDisplay({ item }, context) {
        return context.mode === 'view';
      },
      action: async ({
        item, action, location, props, id
      }, context) => {
        let startDate = '';
        let endDate = '';
        if (item.date) {
          startDate = dayjs(item.date);
          endDate = startDate.add(30, 'minutes');
          endDate = endDate.format('YYYYMMDD[T]HHmm00[z]');
          startDate = startDate.format('YYYYMMDD[T]HHmm00[z]');
        }
        window.open(
          `https://calendar.google.com/calendar/render?action=TEMPLATE&text=Interview ${
            item.Candidate ? `avec ${item.Candidate.firstName} ${item.Candidate.lastName}` : ''
          } X ${item.Position ? item.Position.title : ''}&dates=${startDate}/${endDate}&details=${
            item.Client ? `Client: ${item.Client.name}` : ''
          }`,
          '_blank'
        );
      },
    },
  ],
  nestedSchemas: [],
};
