import qs from 'qs';

const initialState = {
  showEmailComposerModal: {
    show: false,
    recipientAddress: '',
    selectedEmail: null,
    subject: '',
    body: '',
    recipientObject: {},
  },
  emails: [],
  emaillist: [],
  selectedEmail: null

};

// getters
const getters = {
  index: (state) => state.emails.length,
  send: (state) => state.emails.filter(data => (data.type === 'sent')).length,
  draft: (state) => state.emails.filter(data => (data.type === 'draft')).length,
  trash: (state) => state.emails.filter(data => (data.type === 'trash')).length,
  starred: (state) => state.emails.filter(data => data.favourite === true).length
};

// mutations
const mutations = {

  emails: (state, payload) => {
    state.emails = payload;
  },
  setSelectedEmail: (state, payload) => {
    state.selectedEmail = payload;
  },
  showEmailComposerModal: (state, payload) => {
    state.showEmailComposerModal = payload;

  }
};

// actions
const actions = {
  setShowEmailComposerModal: (context, payload) => {
    context.commit('showEmailComposerModal', payload);
  },
  setSelectedEmail: (context, payload) => {
    context.commit('setSelectedEmail', payload);
  },
  addFavorites: (context, payload) => {
    context.commit('addFavorites', payload);
  },
  getCount(context, options) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      console.warn('[STORE][CLIENT] user or organisation not defined');
      return Promise.resolve([]);
    }
    return this._vm.$http
      .get('/api/message?perPage=1&page=0',
        {
          ...options,
          paramsSerializer(params) {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
            });
          }
        })
      .then((res) => res.data?.totalCount || 0);
  },
  getEmails(context, options) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      console.warn('[STORE][CLIENT] user or organisation not defined');
      return Promise.resolve([]);
    }
    return this._vm.$http
      .get('/api/message',
        {
          ...options,
          paramsSerializer(params) {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
            });
          }
        })
      .then((res) => {
        if (res.data && res.data.body) {
          context.commit('emails', res.data.body);
          return res.data.body;
        }
        return [];
      });
  },

  getEmail(context, id, options = {}) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }
    const { selectedEmail } = context.state;
    if (selectedEmail && selectedEmail.id && selectedEmail.id === id && !options.force) {
      return Promise.resolve(selectedEmail);
    }
    context.commit('selectedClient', null);
    return this._vm.$http
      .get(`/api/message/${id}`, options)
      .then((res) => {
        if (res.data && res.data.body) {
          context.commit('setSelectedEmail', res.data.body);
          return res.data.body;
        }
        return [];
      });
  },

  sendEmail(context, data) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }
    const { inReplyToEmail, mailSendMode } = data;

    if ((mailSendMode !== 'new') && (!inReplyToEmail || !inReplyToEmail.id)) {
      return Promise.reject(new Error('missing_email_to_reply_to'));
    }
    // if (mailSendMode === 'new' && (!data.mailbox || data.mailbox === '--')) {
    //   return Promise.reject(new Error('common.messages.error_no_mailbox'));
    // }

    return this._vm.$http
      .post('/api/message/send', data)
      .then((res) => {
        if (res.data && res.data.body) {
          context.commit('setSelectedEmail', res.data.body);
          return res.data.body;
        }
        return null;
      });
  },

  updateEmail(context, email) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }

    if (!email || !email.id) {
      throw new Error('missing_email_id');
    }
    return this._vm.$http
      .put(`/api/message/${email.id}`, email)
      .then((res) => {
        if (res.data && res.data.body) {
          context.commit('setSelectedEmail', res.data.body);
          return res.data.body;
        }
        return null;
      });
  },

  archiveEmail(context, email) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }

    if (!email || !email.id) {
      throw new Error('missing_email_id');
    }
    return this._vm.$http
      .put(`/api/message/${email.id}/archive`, email)
      .then((res) => {
        if (res.data && res.data.body) {
          context.commit('setSelectedEmail', res.data.body);
          return res.data.body;
        }
        return null;
      });
  },

  readEmail(context, { id, isRead }) {
    return context.dispatch('updateEmail', { id, isRead });
  },

};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
