<template>
  <div id="page-board-settings">
    <Breadcrumbs :title="$t(`app.sidebar.settings.boards`)" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <AwesomeCrud
            v-if="model"
            v-bind="model"
            identity="board"
            :useRouterMode="false"
            :enabledListingModes="['list']"
            :initialListingMode="'list'"
          />
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { AwesomeCrud } from 'vue-aw-components';
import config from '@/config';

export default {
  components: {
    AwesomeCrud,
  },
  props: {
    feature: {
      type: String,
    },
  },
  data() {
    return {
      selectedUser: {},
      isCreating: false,
      mode: 'edit',
      config,
    };
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    model() {
      return this.$store.state.models.find((m) => m.identity === 'board');
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
#page-board-settings {
  .aw-crud-listing-mode-list .aw-list-card {
    background: white;
  }
  .aw-crud-listing-mode-list .aw-list-card > .card-header,
  .aw-crud-listing-mode-list .aw-list-card > .card-body .aw-list-item,
  .aw-list-card {
    box-shadow: none !important;
  }
  .aw-crud-listing-mode-list .aw-list-card > .card-header:after {
    display: none;
  }
}
</style>
