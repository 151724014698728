<template>
  <small v-if="status" :is="tag" class="muted badge f-w-300 my-1" :class="colorComputed">
    {{ $t(`request.statuses.${status}`) }}</small
  >
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'DashboardTaskWidget',
  computed: {
    ...mapState('user', ['user', 'organisation']),
    colorComputed() {
      if (this.color) {
        return `badge-${this.color}`;
      }

      switch (this.status) {
      default:
      case 'todo':
        return 'badge-primary';
      case 'done':
        return 'bg-dark';
      case 'doing':
        return 'badge-info';
      case 'waiting':
      case 'waiting-for-client':
        return 'badge-warning';
      }
    },
  },
  props: {
    tag: { type: [String], default: 'small' },
    status: String,
    color: String,
    filters: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
