<template>
  <div>
    <b-modal
      id="email-composer-modal"
      ref="modal"
      size="lg"
      no-close-on-backdrop
      :visible="shouldDisplayModal"
      hide-footer
      @cancel="handleCloseModal"
      @closeRequested="handleCloseModal"
      @hidden="handleCloseModal"
      :noEnforceFocus="true"
    >
      <email-composer
        class="mw-100"
        :toEmails="toEmails"
        :subject="subject"
        :body="body"
        :closeModalAfterSend="true"
        :recipientObject="recipientObjectComputed"
        modal
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import EmailComposer from './EmailComposer.vue';

export default {
  name: 'EmailComposerModal',
  components: {
    EmailComposer,
    BModal,
  },
  props: {
    shouldDisplayModal: {
      type: Boolean,
      required: true,
    },
    organisationId: {
      type: String,
      default: null,
    },
    toEmails: {
      type: [String, Array],
      default: null,
    },
    subject: {
      type: [String],
      default: '',
    },
    body: {
      type: [String],
      default: '',
    },
  },
  mounted() {},
  data() {
    return {
      show: false,
      fileToUpload: null,
      fileName: '',
    };
  },
  computed: {
    recipientObjectComputed() {
      return this.$store.state?.email?.showEmailComposerModal?.recipientObject;
    },
  },
  methods: {
    handleCloseModal() {
      this.$store.dispatch('email/setShowEmailComposerModal', {
        show: false,
        recipient: '',
        subject: '',
        body: '',
        showEmailComposerModal: {},
      });
      this.$store.dispatch('email/setSelectedEmail', null);
    },
    async handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      const success = await this.submitForm();
      if (success) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<style scoped>
.modal .modal-content .modal-body {
  padding: auto !important;
}
#email-composer-modal___BV_modal_body_ > div {
  margin: auto !important;
}
</style>
