/* eslint-disable import/prefer-default-export */

export const message = {
  identity: 'message',
  icon: '',

  actions: { create: false, edit: true, delete: false },
  options: {

  },
  customInlineActions: [
    {
      name: 'new-request',
      label: 'Create client',
      class: 'btn-info',
      title: 'Create a new client from this expeditor',
      icon: 'fa fa-plus',
      action: ({
        item, action, location, props, id,
      }, context) => {
        const targetData = {};
        // eslint-disable-next-line
        targetData.email = item.parsedContent.fromEmail;
        targetData.name = item.parsedContent.from;
        context.$router.push({
          path: '/app/client/new',
          query: { item: targetData },
        });
      },
    },
    {
      name: 'new-request',
      label: 'Créer une conversation',
      class: 'btn-info',
      title: 'Créer une conversation',
      icon: 'fa fa-new',
      canDisplay({ item }, context) {
        return (
          !item.status || item.status !== 'treated'
        );
      },
      action: ({
        item, action, location, props, id,
      }, context) => {
        const targetData = {};
        // eslint-disable-next-line
        targetData.title = item.subject || item.parsedContent.subject;
        targetData.description = item.parsedContent.text || item.content;
        context.$router.push({
          path: '/app/request/new',
          query: { item: targetData },
        });
      },
    }
  ],
};
