<template>
  <div class="input-group tinymce-editor">
    <editor
      api-key="no-api-key"
      :props="fieldOptions"
      :init="editorConfig"
      v-model="value"
      :inline="fieldOptions.inline"
    />
  </div>
</template>

<script>
import { abstractField } from 'vue-aw-components';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';

import 'tinymce/plugins/code';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
// import 'tinymce/plugins/textcolor';
// import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/image';

import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';

import Editor from '@tinymce/tinymce-vue';
import config from '../../config';

//     'undo redo | formatselect fontsizeselect bold italic underline | forecolor colorpicker backcolor | \
//           alignleft aligncenter alignright alignjustify | \
//           bullist numlist outdent indent | image media link | removeformat code',
export default {
  mixins: [abstractField],
  components: { editor: Editor },
  computed: {
    fieldOptions() {
      return this.schema && this.schema.fieldOptions ? this.schema.fieldOptions : {};
    },
  },
  data() {
    return {
      config,
      editorConfig: {
        height: 500,
        width: '100%',
        menubar: false,
        images_upload_url: `${config.apiUrl || 'http://localhost:1335'}api/media/upload`,
        color_map: [
          '#47776e',
          'Primary Green',
          '#656278',
          'Brown',
          '#F9EEE5',
          'Beige',
          '#ECCAFA',
          'Light Purple',
          '#C2E0F4',
          'Light Blue',

          '#2DC26B',
          'Green',
          '#F1C40F',
          'Yellow',
          '#E03E2D',
          'Red',
          '#B96AD9',
          'Purple',
          '#3598DB',
          'Blue',
          '#169179',

          '#000000',
          'Black',
          '#ffffff',
          'White',
        ],

        plugins: [
          'code',
          'advlist lists link image charmap preview anchor',
          'media',
          'table',
          'image',
          'textpattern',
          'quickbars',
          'autolink',
        ],
        toolbar: 'bold italic underline link | formatgroup | paragraphgroup | table | variabbles | insertgroup',
        toolbar_location: 'top',
        toolbar_mode: 'floating',
        toolbar_persist: true,
        quickbars_insert_toolbar: false,
        quickbars_selection_toolbar: 'bold italic underline | blocks | bullist numlist | blockquote quicklink',
        contextmenu: 'link image media | table | variabbles | quicklink | copy cut paste',
        skin: 'outside',
        default_link_target: '_blank',
        textpattern_patterns: [
          { start: '*', end: '*', format: 'italic' },
          { start: '**', end: '**', format: 'bold' },
          { start: '#', format: 'h1' },
          { start: '##', format: 'h2' },
          { start: '###', format: 'h3' },
          { start: '####', format: 'h4' },
          { start: '#####', format: 'h5' },
          { start: '######', format: 'h6' },
          // The following text patterns require the `lists` plugin
          { start: '1. ', cmd: 'InsertOrderedList' },
          { start: '* ', cmd: 'InsertUnorderedList' },
          { start: '- ', cmd: 'InsertUnorderedList' },
          { start: '---', replacement: '<hr/>' },
          { start: '--', replacement: '—' },
        ],
        toolbar_groups: {
          formatgroup: {
            icon: 'format',
            tooltip: 'Formatting',
            items: 'strikethrough | formatselect fontsizeselect | forecolor backcolor | removeformat | code',
          },
          paragraphgroup: {
            icon: 'paragraph',
            tooltip: 'Paragraph format',
            items: 'h1 h2 h3 | bullist numlist | alignleft aligncenter alignright alignjustify | indent outdent',
          },
          insertgroup: {
            icon: 'plus',
            tooltip: 'Insert',
            items: 'image media link attachment',
          },
        },
        ...this.fieldOptions,
        setup: this.tinymceAutocomplete,
      },
    };
  },

  methods: {
    tinymceAutocomplete(editor) {
      // const onAction = function (autocompleteApi, rng, value) {
      //   editor.selection.setRng(rng);
      //   editor.insertContent(value);
      //   autocompleteApi.hide();
      // };

      const specialChars = [
        { text: 'firstName', value: '{{firstName}}' },
        { text: 'lastName', value: '{{lastName}}' },
        { text: 'position', value: '{{position}}' },
        { text: 'name', value: '{{name}}' },
        { text: 'currentPosition', value: '{{currentPosition}}' },
        { text: 'company', value: '{{company}}' },
      ];

      // const getMatchedChars = function (pattern) {
      //   if (!pattern) {
      //     return specialChars;
      //   }
      //   return specialChars.filter((char) => char.text.toLowerCase().includes(pattern));
      // };

      editor.ui.registry.addMenuButton('variabbles', {
        text: 'Variables',
        fetch(callback) {
          const items = specialChars.map((item) => ({
            type: 'menuitem',
            text: item.text,
            onAction() {
              editor.insertContent(item.value);
            },
          }));
          // {
          //   type: 'nestedmenuitem',
          //   text: 'Menu item 2',
          //   icon: 'user',
          //   getSubmenuItems() {
          //     return [
          //       {
          //         type: 'menuitem',
          //         text: 'Sub menu item 1',
          //         icon: 'unlock',
          //         onAction() {
          //           editor.insertContent('&nbsp;<em>You clicked Sub menu item 1!</em>');
          //         },
          //       },
          //       {
          //         type: 'menuitem',
          //         text: 'Sub menu item 2',
          //         icon: 'lock',
          //         onAction() {
          //           editor.insertContent('&nbsp;<em>You clicked Sub menu item 2!</em>');
          //         },
          //       },
          //     ];
          //   },
          // },
          callback(items);
        },
      });

      /* An autocompleter that allows you to insert special characters */
      // editor.ui.registry.addAutocompleter('specialchars', {
      //   ch: '{',
      //   minChars: 0,
      //   columns: 'auto',
      //   onAction,
      //   fetch(pattern) {
      //     return new Promise((resolve) => {
      //       console.log('pattern', pattern);
      //       const results = getMatchedChars(pattern).map((char) => ({
      //         type: 'cardmenuitem',
      //         value: char.value,
      //         items: [
      //           {
      //             type: 'cardcontainer',
      //             direction: 'vertical',
      //             items: [
      //               {
      //                 type: 'cardtext',
      //                 text: char.text,
      //                 name: 'char_name',
      //               },
      //             ],
      //           },
      //         ],
      //       }));
      //       resolve(results);
      //     });
      //   },
      // });
    },
  },
};
</script>

<style>
@import '~tinymce/skins/ui/oxide/skin.css';
@import '~tinymce/skins/ui/oxide/content.min.css';

.vue-form-generator .field-wrap .tox-editor-header button,
.vue-form-generator .field-wrap .tox-editor-header input[type='submit'] {
  border-radius: 0;
  border: initial;
  display: inherit;
  padding: initial;
}
</style>
