<template>
  <awesome-kanban
    :lists="requestsComputedOld"
    titleField="title"
    descriptionField="title"
    usersField="AffectedTo"
    userImageField="avatarUrl"
    :actions="actions"
    @cardClicked="goToRequestDetail"
    @cardAdded="updateRequestStatus"
  />
</template>

<script>
import { mapState } from 'vuex';
import { uniq } from 'lodash';

export default {
  name: 'RequestsKanban',
  props: {
    requests: Array,
    actions: Object,
  },
  data() {
    return {
      isDetailPage: false,
    };
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    ...mapState('requestStatus', ['items']),
    requestModel() {
      return (
        this.$store.state.models
        && this.$store.state.models.find
        && this.$store.state.models.find((m) => m.identity === 'request')
      );
    },
    requestsComputed() {
      if (this.requests && this.requests.length) {
        return this.requests.map((r) => {
          const labels = r.tags || [];
          if (r.Client && r.Client.company) {
            labels.push(r.Client.company);
          }
          if (r.Contact && r.Contact.id) {
            labels.push(`${r.Contact.firstName} ${r.Contact.lastName}`);
          }

          return {
            ...r,
            id: r.id,
            status: r.status,
            title: r.title,
            date: r.dueDate,
            description: r.description ? this.stripContent(r.description) : '',
            users:
              r.AffectedTo && r.AffectedTo.avatarUrl
                ? [{ src: r.AffectedTo && r.AffectedTo.avatarUrl, label: '' }]
                : [],
            labels,
          };
        });
      }
      return [];
    },
    requestsComputedOld() {
      const statuses = this.items;
      const list = statuses.map((status) => ({
        title: status.label || this.$t(status.code || status),
        id: status.code || status,
        content: [],
      }));

      list.unshift({
        title: this.$t('request.statuses.unsorted'),
        id: 'unsorted',
      });

      if (this.requests && this.requests.length) {
        list.forEach((l) => {
          l.content = this.requests
            .filter((r) => (l.id === 'unsorted' ? (!r.status && r.status !== false) : (r.status === l.id)))
            .map((r) => {
              let labels = r.tags || [];
              if (r.Client && r.Client.company) {
                labels.push(r.Client.company);
              }
              if (r.Contact && r.Contact.id) {
                labels.push(`${r.Contact.firstName} ${r.Contact.lastName}`);
              }
              labels = uniq(labels);
              return {
                ...r,
                id: r.id,
                status: r.status,
                title: r.title,
                date: r.dueDate,
                description: r.description ? this.stripContent(r.description) : '',
                users:
                  r.AffectedTo && r.AffectedTo.avatarUrl
                    ? [{ src: r.AffectedTo && r.AffectedTo.avatarUrl, label: '' }]
                    : [],
                labels,
              };
            });
          l.title += `(${l.content.length})`;
        });
      }
      return list;
    },
  },
  mounted() {
    this.$store.dispatch('request/getRequests', { save: true });
    this.$store.dispatch('requestStatus/getItems', { save: true });
  },
  methods: {
    goToRequestDetail(data) {
      this.$emit('requestClicked', data);
    },
    async updateRequestStatus(payload, list) {
      this.$emit('requestAdded', payload, list);
    },
    stripContent(string, size = 100) {
      return `${string.replace(/<\/?[^>]+>/gi, ' ').substr(0, size || 100)}...`;
    },
  },
};
</script>

<style lang="scss">
.email-right-aside .request-details .aw-kanban {
  & > .float-left {
    display: none;
  }
  & > .float-right {
    display: none;
  }
}
.awesome-kanban {
  .list-actions {
    .dropdown > button {
      background: transparent !important;
      color: var(--primary);
    }
  }
  .card {
    .card-body {
      padding: 15px;

      .card-title {
        font-size: 15px;
        white-space: normal !important;
      }
      .card-text {
        font-size: 12px;
      }
    }
  }
}
</style>
