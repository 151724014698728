var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumbs',{attrs:{"title":"Organisation"}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.organisation && _vm.organisation.id)?_c('awesomeForm',{attrs:{"identity":"organisation","item":{ id: _vm.organisation.id },"mode":_vm.mode,"url":"/api/organisation","actions":{ delete: false, edit: true, autosave: true },"standalone":true,"useRouterMode":false,"title":'Organisation',"displayMode":"page","displayHeader":false,"displayedFields":[
            'name',
            _vm.config.features.customLogo ? 'logo' : null,
            _vm.config.features.customLogo ? 'logoSmall' : null,
            'featuresHumanResources',
            'featuresClientConversations',
            'featuresUnifiedInbox',
            'featuresCrm',
            'currency',
            'slaResponseTime',
            'leadUsersCanSeeAllLeads',
            'requestUsersCanSeeAllRequests',
            'supportForGeocoding' ]},on:{"closeRequested":_vm.updateOrganisation,"cancel":function($event){_vm.mode = 'view'},"edit":function($event){_vm.mode = 'edit'}}}):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }