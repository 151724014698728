/* eslint-disable import/prefer-default-export */

export const position = {
  identity: 'position',
  icon: '',

  schema: {
    properties: {
      id: {
        field: {
          readonly: true,
          visible: '{{ context.mode !== "create" && context.mode !== "edit" }}',
        },
        column: {
          visible: false,
        },
      },
      organisationId: {
        field: {
          readonly: true,
          visible: false,
        },
        column: {
          visible: false,
        },
      },
      minSalary: {
        column: {
          cols: 2,
        },
      },
      maxSalary: {
        column: {
          cols: 2,
        },
      },
      tags: {
        column: {
          cols: 6,
          visible: true,
          hidden: false,
        },
      },
      description: {
        title: 'Job Description',
        column: {
          type: 'html',
          cols: 12,
          visible: false,
        },
      },
      createdOn: {
        field: {
          readonly: true,
          visible: false,
        },
      },
      createdBy: {
        field: {
          readonly: true,
          visible: false,
        },
      },
      lastModifiedOn: {
        field: {
          readonly: true,
          visible: false,
        },
      },
      lastModifiedBy: {
        nullable: true,
        field: {
          readonly: true,
          visible: false,
        },
      },
    },
  },
  actions: { create: true, edit: true, delete: true },
  options: {
    mode: 'local',
    customInlineActions: [],
  },
  customTopRightActions: [
    {
      name: 'public-widget',
      label: 'Show public positions',
      classes: 'btn btn-simple text-info button-icon',
      title: 'Show public positions',
      icon: 'fa fa-share-alt-square',
      action: ({
        items, action, location, props, id
      }, context) => {
        if (!context.$store.state.user || !context.$store.state.user.organisation) {
          return;
        }
        /* eslint-disable */
        window.open(
          `${document.location.origin}/public/organisation/${context.$store.state.user.organisation.id}/widgets/position/`,
          '_blank'
        );
        /* eslint-anable */
      },
    },
  ],
  nestedSchemas: [],
};
