import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';

import Calendar from '@/pages/Calendar.vue';
import ClientDetail from '@/pages/ClientDetail.vue';
import DetailsPage from '@/pages/settings/DetailsPage.vue';
import Error404 from '@/pages/errors/Error404.vue';
import Error500 from '@/pages/errors/Error500.vue';
import HistoryDashboard from '@/pages/dashboard/HistoryDashboard.vue';
import HrDashboard from '@/pages/dashboard/HrDashboard.vue';
import LeadDashboard from '@/pages/dashboard/LeadDashboard.vue';
import LeadDetail from '@/pages/LeadDetail.vue';
import Login from '@/pages/general/Login.vue';
import MailBox from '@/pages/MailBox.vue';
import MailBoxSettings from '@/pages/settings/MailBox.vue';
import NotFound from '@/pages/general/NotFoundPage.vue';
import Onboarding from '@/pages/general/Onboarding.vue';
import OptOut from '@/pages/general/OptOut.vue';
import OrganisationPage from '@/pages/settings/Organisation.vue';
import OrganisationSettingsPage from '@/pages/settings/Settings.vue';
import PublicPositionPage from '@/pages/PublicPositionPage.vue';
import RefuseInvite from '@/pages/general/RefuseInvite.vue';
import Register from '@/pages/general/Register.vue';
import RequestDashboard from '@/pages/dashboard/RequestDashboard.vue';
import RecruitmentProcessDetail from '@/pages/RecruitmentProcessDetail.vue';
import RequestDetail from '@/pages/RequestDetail.vue';
import Reset from '@/pages/general/Reset.vue';
import SamplePage from '@/pages/SamplePage.vue';
import store from '@/store';
import Taskboard from '@/pages/dashboard/Taskboard.vue';
import UserPage from '@/pages/settings/User.vue';
import UsersPage from '@/pages/settings/Users.vue';
import BoardSettings from '@/pages/settings/BoardSettings.vue';
import ModelCrudWrapper from '../components/page/ModelCrudWrapper.vue';
import config from '../config';
import Body from '../components/body.vue';

export const authGuard = (to, from, next) => {
  if (store.state.user.token) {
    return next();
  }
  next(`/login?redirect=${to.path}`);
};

Vue.use(Router);

const routes = [
  { path: '', redirect: { name: config.features.modeAts ? 'HrDashboard' : 'Taskboard' } },
  {
    path: '/logout',
    beforeEnter(to, from, next) {
      store.dispatch('user/logout')
        .then(() => next());
    },
    component: Login,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/notfound',
    name: 'Error404',
    component: Error404,
    meta: {
      title: 'Page not found',
    }
  },
  {
    path: '/error-500',
    name: 'Error500',
    component: Error500,
    meta: {
      title: 'Server error not found',
    }
  },
  {
    path: '/user-refuse-invite',
    name: 'RefuseInvite',
    component: RefuseInvite,
    meta: {
      title: 'refuse invite',
    }
  },
  {
    path: '/auth/google/callback',
    name: 'Google',
    component: Login,
  },
  {
    path: '/api/auth/google/callback',
    name: 'Google api',
    component: Login,
  },
  {
    path: '/reset-password/:token',
    name: 'Reset',
    component: Reset,
    props: true,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
  },
  {
    name: 'OptOut',
    path: '/app/optout/:type',
    component: OptOut,
    meta: {
      title: 'Opt Out | Cimple',
    },
  },
  {
    name: 'widgets',
    path: '/public/organisation/:organisationId/widgets/position',
    component: PublicPositionPage,
    meta: {
      title: 'Widgets | Cimple',
    },
  },
  {
    name: 'app',
    path: '/app',
    component: Body,
    beforeEnter: authGuard,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        // component: config.features.modeAts ? Dashboard : RequestDashboard,
        redirect: { name: config.features.modeAts ? 'HrDashboard' : 'RequestDashboard' },
        beforeEnter: authGuard,
      },
      {
        path: 'history',
        name: 'History',
        component: HistoryDashboard,
        beforeEnter: authGuard,
      },
      {
        path: 'dashboard/ats',
        name: 'HrDashboard',
        component: HrDashboard,
        beforeEnter: authGuard,
        meta: {
          title: 'Dashboard',
        }
      },
      {
        path: 'dashboard/requests',
        name: 'RequestDashboard',
        component: RequestDashboard,
        beforeEnter: authGuard,
        meta: {
          title: 'Dashboard',
        }
      },
      {
        path: 'dashboard/leads',
        name: 'LeadDashboard',
        component: LeadDashboard,
        beforeEnter: authGuard,
        meta: {
          title: 'Dashboard',
        }
      },
      {
        path: 'taskboard',
        name: 'Taskboard',
        component: Taskboard,
        beforeEnter: authGuard,
        meta: {
          title: 'My tasks Dashboard',
        }
      },
      {
        path: 'agenda',
        name: 'Agenda',
        component: Calendar,
        beforeEnter: authGuard,
        meta: {
          title: 'Agenda Dashboard',
        },
        children: [
        ]
      },
      {
        path: 'request/:id',
        name: 'request-detail',
        component: RequestDetail,
        beforeEnter: authGuard,
        props: {
          useRouterMode: false,
        },
        meta: {
          title: 'Request detail',
        },
      },
      {
        path: 'lead/new',
        name: 'CreateLead',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          identity: 'lead',
          title: 'Lead detail',
        },
        props: {
          identity: 'lead',
        }
      },
      {
        path: 'lead/:id',
        name: 'LeadDetail',
        component: LeadDetail,
        beforeEnter: authGuard,
        meta: {
          title: 'Lead detail',
        },
      },
      {
        name: 'ClientDetail',
        path: 'client/:id',
        component: ClientDetail,
        beforeEnter: authGuard,
        meta: {
          title: 'Clients',
        },
      },
      {
        name: 'ClientDetailView',
        path: 'client/:id/view',
        component: ClientDetail,
        beforeEnter: authGuard,
        meta: {
          title: 'Clients',
        },
      },
      {
        name: 'ClientEdit',
        path: 'client/:id/edit',
        component: ClientDetail,
        beforeEnter: authGuard,
        meta: {
          title: 'Edit client',
        },
      },
      {
        path: 'mailbox',
        name: 'Mailbox',
        component: MailBox,
        beforeEnter: authGuard,
        meta: {
          title: 'Mailbox Dashboard',
        }
      },
      {
        path: 'message',
        name: 'Messages',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'Message Dashboard',
        },
        props: {
          identity: 'message',
          useRouterMode: false,
          options: {
            mode: 'remote',
          }
        }
      },
      {
        path: 'mailbox/new',
        name: 'Mailbox-new',
        component: MailBox,
        beforeEnter: authGuard,
        meta: {
          title: 'Mailbox Dashboard',
        }
      },
      {
        path: 'mailbox/:id',
        name: 'MailboxDetail',
        component: MailBox,
        beforeEnter: authGuard,
        meta: {
          title: 'Mailbox Dashboard',
        }
      },
      {
        redirect: 'mailbox/:id',
        path: 'message/:id',
      },
      {
        redirect: 'mailbox/:id',
        path: 'mailbox/:id',
      },
      {
        redirect: 'recruitment-process/:id/candidate',
        path: 'message/:id',
      },
      {
        path: 'recruitmentProcess/:id',
        redirect: 'recruitment-process/:id',
      },
      {
        path: 'recruitmentProcess',
        redirect: 'recruitment-process',
      },
      {
        path: 'recruitment-process/:id',
        name: 'RecruitmentProcessDetail',
        component: RecruitmentProcessDetail,
        beforeEnter: authGuard,
      },
      {
        path: 'board-:boardId',
        name: 'Board',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'boards',
        },
        props: () => ({
          identity: 'card',
          enableSidebar: true,
        }),
        children: [
          {
            path: 'card',
            redirect: '/app/board-:boardId',
          },
          {
            name: 'CardView',
            path: 'card/:id',
            component: ModelCrudWrapper,
            props: () => ({
              identity: 'card',
              enableSidebar: true,
            }),
          },
          {
            name: 'CardEdit',
            path: 'card/:id/edit',
            component: ModelCrudWrapper,
            props: () => ({
              identity: 'card',
              enableSidebar: true,
            }),
          },
        ],
      },
      {
        path: 'board',
        name: 'BoardList',
        component: BoardSettings,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => boards',
        },
        props: () => ({
          identity: 'board',
        }),
        children: [
          {
            path: ':id/edit',
            name: 'BoardEdit',
            component: ModelCrudWrapper,
            beforeEnter: authGuard,
            meta: {
              title: 'Settings => board',
            },
            props: () => ({
              identity: 'board',
            }),
          },

        ],
      },

      {
        path: 'template/for/:section',
        name: 'Templates',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'Templates',
        },
        props: () => ({
          identity: 'template',
        }),
        children: [
          {
            name: 'TemplateView',
            path: ':id',
            component: ModelCrudWrapper,
            meta: {
              title: 'template view',
            },
            props: {
              identity: 'template',
            },
          },
          {
            name: 'TemplateEdit',
            path: ':id/edit',
            component: ModelCrudWrapper,
            props: {
              identity: 'template edit',
            }
          }
        ],
      },
      {
        path: 'settings',
        name: 'DetailsPage',
        component: DetailsPage,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => DetailsPage',
          menu: 'settings-settings'
        },
        props: {
          menu: 'settings-settings',
        }
      },
      {
        path: 'settings/organisation',
        name: 'OrganisationFeatures',
        component: OrganisationPage,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Organisation',
        }
      },
      {
        path: 'settings/conversation-settings',
        name: 'ConversationFeatures',
        component: OrganisationSettingsPage,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Organisation',
        },
        props: {
          feature: 'conversation',
          breadcrumbs: true,
          breadcrumbsDropdownItems: [
            { title: 'app.sidebar.support', to: '/app/request', icon: '' },
            { title: 'app.sidebar.crm', to: '/app/lead', icon: '' }
          ]
        }
      },
      {
        path: 'settings/hr-settings',
        name: 'HRFeatures',
        component: OrganisationSettingsPage,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Organisation',
        },
        props: {
          feature: 'hr'
        }
      },
      {
        path: 'settings/advanced-settings',
        name: 'AdvancedFeatures',
        component: OrganisationSettingsPage,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Organisation',
        },
        props: {
          feature: 'advanced'
        }
      },
      {
        path: 'settings/member',
        name: 'Members',
        component: UsersPage,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Users',
        }
      },
      {
        path: 'settings/member/:id',
        name: 'user-detail',
        component: UserPage,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Users',
        }
      },
      {
        redirect: 'settings/member/:id',
        path: 'user/:id',
      },
      {
        redirect: 'settings/member/:id',
        path: 'organisation-member/:id',
      },
      {
        path: 'settings/member/:id/edit',
        name: 'user-edit',
        component: UserPage,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Users',
        }
      },
      {
        path: 'settings/mailbox',
        name: 'Mailboxes',
        component: MailBoxSettings,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Mailboxes',
          parent: 'mailboxes',
        }
      },
      {
        path: 'settings/details/:menu',
        name: 'DetailsPage',
        component: DetailsPage,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => DetailsPage',
        }
      },
      {
        path: 'settings/recruitment-stage',
        name: 'Statuts de Recrutements',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Statuts de Recrutements',
        },
        props: () => ({
          identity: 'recruitmentStage',

        }),
        children: [
          {
            name: 'recruitment-stage-view',
            path: ':id',
            ModelCrudWrapper,
            meta: {
              title: 'Settings => Statuts de Recrutements',
            },
            props: {
              identity: 'recruitmentStage',
            },
          },
          {
            name: 'recruitment-stage-edit',
            path: ':id/edit',
            ModelCrudWrapper,
            props: {
              identity: 'recruitmentStage',
            }
          }
        ],
      },
      {
        path: 'settings/request-status',
        name: 'request-status',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Requests stages',
        },
        props: () => ({
          identity: 'requestStatus',
          breadcrumbs: true,
          breadcrumbsDropdownItems: [
            { title: 'app.sidebar.support', to: '/app/request', icon: 'message-square' }
          ]
        }),
        children: [
          {
            name: 'request-status-view',
            path: ':id',
            ModelCrudWrapper,
            meta: {
              title: 'Settings => Statuts de Recrutements',
            },
            props: {
              identity: 'requestStatus',
              breadcrumbs: true,
              breadcrumbsDropdownItems: [
                { title: 'app.sidebar.support', to: '/app/request', icon: 'message-square' }
              ]
            },
          },
          {
            name: 'request-status-edit',
            path: ':id/edit',
            ModelCrudWrapper,
            props: {
              identity: 'requestStatus',
              breadcrumbs: true,
              breadcrumbsDropdownItems: [
                { title: 'app.sidebar.support', to: '/app/request', icon: 'message-square' }
              ]
            }
          }
        ],
      },
      {
        path: 'settings/lead-stage',
        name: 'lead-stage',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Leads stages',
        },
        props: () => ({
          identity: 'leadStage',
          breadcrumbs: true,
          breadcrumbsDropdownItems: [
            { title: 'app.sidebar.crm', to: '/app/lead', icon: '' }
          ]
        }),
        children: [
          {
            name: 'lead-stage-view',
            path: ':id',
            ModelCrudWrapper,
            props: {
              identity: 'leadStage',
              breadcrumbs: true,
              breadcrumbsDropdownItems: [
                { title: 'app.sidebar.crm', to: '/app/lead', icon: '' }
              ]
            },
          },
          {
            name: 'lead-stage-edit',
            path: ':id/edit',
            ModelCrudWrapper,
            props: () => ({
              identity: 'leadStage',
              breadcrumbs: true,
              breadcrumbsDropdownItems: [
                { title: 'app.sidebar.crm', to: '/app/lead', icon: '' }
              ]
            })
          }
        ],
      },
      {
        path: 'settings/crm-settings',
        name: 'CrmFeatures',
        component: OrganisationSettingsPage,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Organisation',
        },
        props: {
          feature: 'crm',
          breadcrumbs: true,
          breadcrumbsDropdownItems: [
            { title: 'app.sidebar.crm', to: '/app/lead', icon: '' }
          ]
        }
      },
      {
        path: 'settings/event-type',
        name: 'settings/event-type',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Types d\'évenement',
        },
        props: () => ({
          identity: 'eventType',

        }),
        children: [
          {
            name: 'event-type-view',
            path: ':id',
            ModelCrudWrapper,
            meta: {
              title: 'Settings => Types d\'évenement',
            },
            props: {
              identity: 'eventType',
            },
          },
          {
            name: 'event-type-edit',
            path: ':id/edit',
            ModelCrudWrapper,
            props: {
              identity: 'eventType',
            }
          }
        ],
      },
      {
        path: 'settings/apikey',
        name: 'api-key',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => Api key',
        },
        props: () => ({
          identity: 'apiKey',
          useRouterMode: false,
        }),
      },
      {
        path: 'settings/webhook',
        name: 'webhook',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => webhook',
        },
        props: () => ({
          identity: 'webhook',
          useRouterMode: false,
        }),
      },
      {
        path: 'settings/incoming-webhook',
        name: 'incoming-webhook',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => webhook',
        },
        props: () => ({
          identity: 'incomingWebhook',
          useRouterMode: false,
        }),
      },
      {
        path: 'settings/role-based-access',
        name: 'role-based-access',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        meta: {
          title: 'Settings => User permissions',
        },
        props: () => ({
          identity: 'roleBasedAccess',
          useRouterMode: false,
        }),
      },
      {
        path: 'settings/company',
        name: 'Companies',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        props: () => ({
          identity: 'company',

        }),
        meta: {
          title: 'Settings => Companies',
        },
        children: [
          {
            name: 'company-view',
            path: ':id',
            ModelCrudWrapper,
            meta: {
              title: 'Settings => Companies',
            },
            props: {
              identity: 'company',
            },
          },
          {
            name: 'company-edit',
            path: ':id/edit',
            ModelCrudWrapper,
            props: {
              identity: 'company',
            },
          },
        ],
      },
      {
        path: 'settings/rule',
        name: 'Smart Rules',
        component: ModelCrudWrapper,
        beforeEnter: authGuard,
        props: () => ({
          identity: 'rule',
        }),
        meta: {
          title: 'Settings => Smart Rules',
        },
        children: [
          {
            name: 'rule-view',
            path: ':id',
            ModelCrudWrapper,
            meta: {
              title: 'Settings => Smart Rule',
            },
            props: {
              identity: 'rule',
            },
          },
          {
            name: 'rule-edit',
            path: ':id/edit',
            ModelCrudWrapper,
            props: {
              identity: 'rule',
            },
          },
        ],
      },
      {
        path: 'ecommerce',
        name: 'ecommerce',
        component: SamplePage,
        beforeEnter: authGuard,
        meta: {
          title: 'Ecommerce Dashboard',
        }
      },
    ]
  },
  {
    path: '*',
    beforeEnter: authGuard,
    component: NotFound,
  }
];

/** a significant part of the routes are connected directly via the vuex store in  */
// 'stores/inde.js' => prepareModelToRoute

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result ? `?${result}` : '';
  },
  scrollBehavior(to, from, savedPosition) {
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition;
      // For anchors
    } if (to.hash) {
      return { selector: to.hash };
      // By changing queries we are still in the same component,
      // so "from.path" === "to.path"
      // (new query changes just "to.fullPath", but not "to.path").
    } if (from.path === to.path) {
      return {};
    }

    // Scroll to top
    return { x: 0, y: 0 };
  }
});

router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} | ${config.appName}`;
  }
});
export default router;
