<template>
  <div class="text-center pb-3">
    <template v-if="!noMessage"
      >{{ message || $t('common.messages.not_found', { field }) }}
      <br />
    </template>
    <img style="max-width: 60px" src=" https://cdn-icons-png.flaticon.com/512/6665/6665325.png" alt="" />
    <br />
  </div>
</template>

<script>
export default {
  name: 'NoResult',
  props: {
    noMessage: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
};
</script>
