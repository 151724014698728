<template>
  <div v-if="model && modelId" class="card comment-widget comment-section">
    <div class="card-header" role="tablist">
      <h5 class="d-inline-block">
        {{ $t('common.widget.comments-title') }}
        <template v-if="comments">[{{ comments.length }}]</template>
      </h5>
      <label style="" class="pull-right">
        {{ $t('common.labels.showHistory') }}
        <input
          type="checkbox"
          class="mt-1"
          @change="
            includeHistory = !includeHistory;
            getComments();
          "
          :value="includeHistory"
        />
      </label>
    </div>
    <div class="card-body">
      <div class="appointment-table table-responsive">
        <ul class="list-group list-group-flush" v-if="!canShowCommentUpdateButton">
          <li class="list-group-item p-2" v-for="(comment, index) in comments" :key="index">
            <div v-if="comment.eventTypeCode" class="comment-hrlabel badge badge-info">
              {{ displayRecruitmentStageLabel(comment.eventTypeCode) || comment.eventTypeCode }}
            </div>
            <p class="m-0 text-right font-primary" style="font-size: 12px; line-height: 0.5">
              <img
                class="img-fluid rounded-circle mr-0"
                v-if="comment.CreatedBy && comment.CreatedBy.avatarUrl"
                :src="comment.CreatedBy && comment.CreatedBy.avatarUrl"
                :alt="comment.CreatedBy.firstName + ' ' + comment.CreatedBy.lastName"
              />
              <img
                v-else
                class="img-fluid rounded-circle mr-0"
                src="@/assets/images/user/user.png"
                :alt="comment.CreatedBy ? comment.CreatedBy.firstName + ' ' + comment.CreatedBy.lastName : ''"
              />
              {{ comment.CreatedBy ? comment.CreatedBy.firstName + ' ' + comment.CreatedBy.lastName : '' }}
              <br />
              <br /><small class="text-muted" style="font-size: 8px; line-height: 0.5">{{
                comment.createdOn | formatDate
              }}</small>
            </p>
            <div class="comment-content d-block pt-2" v-if="comment.content" v-html="marked(comment.content)"></div>
            <span class="task-action-btn float-right" v-if="!disabled">
              <button
                @click="editComment(comment)"
                v-if="userCan('update', 'comment')"
                class="btn btn-icon p-0"
                title="Edit comment"
              >
                <feather class="text-info" type="edit-3" size="1.1rem" />
              </button>
            </span>
          </li>
        </ul>
        <table class="table table-bordernone">
          <tbody>
            <tr class="mt-4">
              <td colspan="4">
                <hr />
                <div id="comment-form" class="form-group text-left p-2">
                  <select
                    v-if="enableEvents"
                    name=""
                    v-model="currentEventType"
                    class="button btn btn-outline-primary btn-block"
                    id="event-select"
                  >
                    <option value="" disabled>Event</option>
                    <option value="--">--</option>
                    <option :key="eventType.code" :value="eventType.code" v-for="eventType in eventTypeList">
                      {{ eventType.label }}
                    </option>
                  </select>
                  <Mentionable
                    :keys="['@', '#']"
                    filtering-disabled
                    :limit="15"
                    :items="mentionData"
                    :groups="mentionGroups"
                    placement=""
                    offset="120"
                    @open="loadMentionData()"
                    @search="loadMentionData"
                    auto-size="max"
                    insert-space
                    allow-space
                  >
                    <textarea
                      type="text"
                      style="border-radius: 0"
                      class="form-control"
                      v-model="newCommentText"
                      :disabled="disabled"
                      :placeholder="$t('app.comment-widget.comment-input-placeholder')"
                    ></textarea>

                    <template #no-result>
                      <div class="dim">
                        {{ loadingMentionData ? 'Loading...' : mentionSearchTerm ? 'No result' : '...' }}
                      </div>
                    </template>
                    <template #item-@="{ item }">
                      <div class="dim text-truncate">
                        <span class="mr-5">{{ item.label }}</span>
                        <span class="badge badge-info pull-right">{{
                          item.mentionGroupLabel || item.mentionGroup
                        }}</span>
                      </div>
                    </template>
                    <template #item-#="{ item }">
                      <div class="dim text-truncate">
                        <span class="mr-5">{{ item.label }}</span>
                        <span class="badge badge-info pull-right">{{
                          item.mentionGroupLabel || item.mentionGroup
                        }}</span>
                      </div>
                    </template>
                  </Mentionable>
                  <div class="d-flex justify-content-between"></div>
                  <button
                    @click="createComment()"
                    v-if="(newCommentText || currentEventType) && canShowCommentUpdateButton === false"
                    class="btn btn-primary btn-block add-new-task-btn btn-sm"
                    id="add-comment"
                  >
                    {{ $t('common.buttons.add') }}
                  </button>
                  <button
                    @click="updateComment(selectedComment)"
                    v-if="canShowCommentUpdateButton"
                    class="btn btn-primary btn-block add-new-task-btn btn-sm"
                    id="edit-comment"
                  >
                    {{ $t('common.buttons.save') }}
                  </button>
                  <button
                    v-if="canShowCommentUpdateButton && userCan('delete', 'comment')"
                    @click="deleteComment(selectedComment)"
                    class="btn btn-outline-danger btn-block"
                    title="Delete Comment"
                  >
                    <feather class="text-secondary" type="trash" size="1rem" /> {{ $t('common.buttons.delete') }}
                  </button>
                  <button
                    v-if="canShowCommentUpdateButton"
                    @click="resetCommentForm()"
                    class="btn btn-outline-default btn-block"
                    title="Cancel"
                  >
                    <feather class="text-default" type="x" size="1rem" /> {{ $t('common.buttons.close') }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import marked from 'marked';
import rolesMixin from '@/mixins/rolesMixin';
import recruitmentStagesMixin from '@/mixins/recruitmentStagesMixin';
import Mentionable from '../basic/Mentionable.vue';

export default {
  name: 'CommentsWidget',
  components: {
    Mentionable,
  },
  mixins: [rolesMixin, recruitmentStagesMixin],
  props: {
    model: String,
    modelId: [String, Number],
    disabled: Boolean,
    enableEvents: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedComment: {},
      canShowCommentUpdateButton: false,
      comments: [],
      newCommentText: '',
      eventTypeList: [],
      currentEventType: '',
      mentionData: [],
      loadingMentionData: false,
      mentionSearchTerm: '',
      terms: '',
      apiSearchResults: {},
      searchLocations: ['client', 'position', 'request', 'candidate'],
      mentionGroups: ['user', 'client', 'position', 'request', 'candidate'],
      includeHistory: false,
    };
  },
  watch: {
    modelId() {
      this.getComments();
    },
    model() {
      this.getComments();
    },
    currentEventType() {
      if (this.currentEventType === '--') {
        this.currentEventType = '';
      }
    },
  },
  mounted() {
    if (this.modelId) {
      this.getComments();
      this.getEventTypeList();
    }
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    apiRoot() {
      return `/api/${this.model}/${this.modelId}/comment`;
    },
    mentionPlaceholder() {
      if (this.loadingMentionData) {
        return 'Loading...';
      }
      if (this.mentionSearchTerm) {
        return 'No result';
      }
      return '...';
    },
  },
  methods: {
    async loadMentionData(searchText = null, oldValue, key) {
      let result = null;
      let formattedData = null;
      this.mentionData = [];
      this.loadingMentionData = true;
      this.mentionSearchTerm = searchText;
      let users = null;
      result = {};
      if (key === '@') {
        users = await this.getUsersBySearchTerm(searchText);
      } else if (key === '#') {
        result = await this.searchTerm(searchText);
      } else {
        result = await this.searchTerm(searchText);
        users = await this.getUsersBySearchTerm(searchText);
      }

      if (users) {
        result.user = users;
      }
      if (!result) {
        this.loadingMentionData = false;
        return;
      }
      formattedData = this.formatResultsForMentionable(result);
      this.mentionData = formattedData;
      this.loadingMentionData = false;
    },

    editComment(comment) {
      this.canShowCommentUpdateButton = true;
      this.newCommentText = comment.content;
      if (comment.eventTypeCode) {
        this.currentEventType = comment.eventTypeCode;
      }
      this.selectedComment = comment;
    },

    updateComment(comment) {
      if (comment && comment.id) {
        this.$http
          .put(`${this.apiRoot}/${comment.id}`, {
            ...comment,
            content: this.newCommentText,
            eventTypeCode: this.currentEventType,
            // lastModifiedOn: new Date()
          })
          .then((data) => {
            this.resetCommentForm();
            this.getComments();
          });
      }
    },

    deleteComment(comment) {
      if (comment && comment.id) {
        this.$awConfirm('Etes vous sur ?').then(
          (confirmation) =>
            confirmation &&
            this.$http.delete(`${this.apiRoot}/${comment.id}`).then(() => {
              this.resetCommentForm();
              this.getComments();
            })
        );
      }
    },

    resetCommentForm() {
      this.newCommentText = '';
      this.currentEventType = '';
      this.canShowCommentUpdateButton = false;
    },

    async getUsersBySearchTerm(terms = null) {
      const users = await this.$store.state.member.items;
      const result = {};
      if (!users || users.length < 1 || !terms) {
        return null;
      }
      result.body = users.filter((user) =>
        (user.lastName + user.firstName + user.email).toLowerCase().includes(terms.toLowerCase())
      );
      result.count = result.body.length;
      return result;
    },

    async searchTerm(terms = null) {
      const res = {};

      if (!terms) {
        return;
      }
      const filterTerm = terms.includes(':') ? terms.split(':')[0] : false;
      const searchTerm = terms.includes(':') ? terms.split(':')[1] : terms;
      const promises = this.searchLocations
        .filter((type) => !filterTerm || filterTerm === type)
        .map((type) =>
          this.$store
            .dispatch('menu/searchItems', { query: `${searchTerm}*`, type, perPage: 8 })
            .then(async (data) => {
              if (data.body) {
                data.body.forEach((item) => {
                  item.mentionGroup = type;
                  item.mentionGroupLabel = this.$te(`app.models.${item.mentionGroup}`)
                    ? this.$t(`app.models.${item.mentionGroup}`)
                    : item.mentionGroup;
                });
              }

              res[type] = data;
            })
            .catch((err) => console.warn(err))
        );

      await Promise.all(promises);
      return res;
    },

    formatResultsForMentionable(result) {
      const formattedData = [];
      Object.keys(result).forEach((model) => {
        if (!(result[model].count > 0) || !result[model].body) {
          return;
        }

        switch (model) {
        case 'client':
          result[model].body.forEach((client) => {
            formattedData.push({
              ...client,
              label: `${client.name}`,
              value: `[client:${client.id}:${client.name}]`,
            });
          });
          break;

        case 'contact':
          result[model].body.forEach((contact) => {
            formattedData.push({
              ...contact,
              label: `${contact.firstName} ${contact.lastName} ${
                  contact['Client.name'] ? `- ${contact['Client.name']}` : ''
                }`,
              value: `[contact:${contact.id}:${contact.firstName} ${contact.lastName}]`,
            });
          });
          break;

        case 'request':
          result[model].body.forEach((request) => {
            formattedData.push({
              ...request,
              label: `${request.title}`,
              value: `[request:${request.id}:${request.title}]`,
            });
          });
          break;

        case 'position':
          result[model].body.forEach((position) => {
            formattedData.push({
              ...position,
              label: `${position.title} ${position['Client.name'] ? `- ${position['Client.name']}` : ''}`,
              value: `[position:${position.id}:${position.title}]`,
            });
          });
          break;

        case 'candidate':
          result[model].body.forEach((candidate) => {
            formattedData.push({
              ...candidate,
              label: `${candidate.firstName} ${candidate.lastName}`,
              value: `[candidate:${candidate.id}:${candidate.firstName} ${candidate.lastName}]`,
            });
          });
          break;

        case 'user':
          result[model].body.forEach((user) => {
            formattedData.unshift({
              mentionGroup: 'user',
              mentionGroupLabel: this.$te('app.models.user') ? this.$t('app.models.user') : 'User',
              ...user,
              label: `${user.firstName} ${user.lastName}`,
              value: `[user:${user.id}:${user.firstName} ${user.lastName}]`,
            });
          });
          break;

        default:
          break;
        }
      });
      return formattedData;
    },

    marked(content = '') {
      const linkedContent = marked(content);

      return (
        linkedContent
          // this must be done first to avoid opening cimple links in other tab
          .replace(/<a /, '<a class="text-info" target="_blank" rel="nofollow" ')
          .replace(/@\[user:(\d+):(.*?)\]/g, '<a class="text-info" href="/app/user/$1">@$2</a>')
          .replace(/[@,#]\[candidate:(\d+):(.*?)\]/g, '<a class="text-info" href="/app/candidate/$1">$2</a>')
          .replace(/[@,#]\[client:(\d+):(.*?)\]/g, '<a class="text-info" href="/app/client/$1">$2</a>')
          .replace(/[@,#]\[contact:(\d+):(.*?)\]/g, '<a class="text-info" href="#">$2</a>')
          .replace(/[@,#]\[position:(\d+):(.*?)\]/g, '<a class="text-info" href="/app/position/$1">$2</a>')
      );
    },

    getImgUrl(path) {
      // eslint-disable-next-line
      return require(`@/assets/images/${path}`);
    },

    getComments() {
      this.$http
        .get(
          `${this.apiRoot}?page=0&perPage=10&sort[createdOn]=desc${
            !this.includeHistory ? '&filters[eventTypeCode][$ne]=history' : ''
          }`
        )
        .then(({ data }) => {
          this.comments = data.body;
        });
    },

    getEventTypeList() {
      this.$http.get(`/api/event-type?filters[organisationId]=${this.organisation.id}`).then(({ data }) => {
        this.eventTypeList = data.body;
      });
    },

    createComment() {
      if (this.newCommentText || this.currentEventType) {
        this.$http
          .post(`${this.apiRoot}`, {
            content: this.newCommentText || '',
            eventTypeCode: this.currentEventType === '--' ? null : this.currentEventType,
            model: this.model,
            modelId: this.modelId,
          })
          .then(() => {
            this.newCommentText = '';
            this.currentEventType = '';
            this.getComments();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#event-select {
  margin-bottom: 24px !important;
  font-size: 12px;
  font-family: 'Rubik';
  font-weight: normal;
  option {
    text-align: left;
  }
}

#add-comment {
  font-size: 16px;
  // font-family: 'Rubik';
  font-weight: normal;
}
.comment-widget {
  * {
    font-size: 12px;
  }
  h5,
  h6 {
    font-size: 1em;
    font-weight: 600;
    color: var(--primary);
  }
  h2 {
    font-size: 1.5em;
  }
  .card {
    .card-header {
      padding: 15px;
      h5,
      h6 {
        font-size: 1em;
        font-weight: 600;
        color: var(--primary);
      }
    }
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #f4f4f4;
    height: 90px;
    padding: 10px 15px;
    margin-bottom: 24px;
    border-radius: 4px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms eas;
  }
}

.comment-content.d-block > p {
  font-size: 10px !important;
}
.reverse-message {
  text-align: right;
  flex-direction: row-reverse;
}

.task-completed {
  *,
  span {
    text-decoration: line-through;
    color: #999;
  }
}

.comment-section .list-group .comment-content,
.task-section .list-group .comment-content {
  clear: both !important;
}
.list-group,
.task-section .list-group {
  img {
    width: 20px;
    height: 20px;
  }

  .comment-content.d-block {
    white-space: break-spaces;
    font-size: 12px;
    p {
      font-size: 12px;
    }
  }
  .comment-hrlabel {
    clear: both;
    display: inline-flex;
    justify-content: flex-end;
    padding: 3px 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    white-space: break-spaces;
    font-size: 12px;
    float: left;
    p {
      font-size: 12px;
    }
  }
}

.vdatetime.form-control {
  .vdatetime-input {
    border: none;
    width: 100%;
  }
}
</style>
