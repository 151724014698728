import Swal from 'sweetalert2';
/* eslint-disable import/prefer-default-export */
export const lead = {
  identity: 'lead',
  icon: '',
  options: {
    mode: 'local',
    customInlineActions: [],
  },
  customInlineActions: [{
    name: 'send-mail',
    label: 'common.buttons.sendMail',
    class: 'btn btn-sm btn-simple btn-awtable-inline-action btn-icon ml-2 text-white',
    icon: 'fa fa-envelope',
    action: ({
      item, action, location, props, id,
    }, context) => {
      context.$store.dispatch('email/setShowEmailComposerModal', {
        show: true,
        recipientObject: item,
        recipientAddress: item.email
      });
    },
  }],
  kanbanOptions: {
    customListActions: [
      {
        name: 'close-all',
        label: 'common.buttons.changeStatus',
        classes: 'btn-success',
        title: 'Close all',
        icon: 'fa fa-disk',
        action: async ({
          items, action, location, props, id
        }, context) => {
          if (!context.$store.state.leadStage.items.length) {
            await context.$store.dispatch('leadStage/getItems');
          }
          const { value, isConfirmed } = await Swal.fire({
            title: '',
            input: 'select',
            reverseButtons: true,
            inputOptions: {
              ...context.$store.state.leadStage.items.reduce((acc, item) => {
                acc[item.code] = item.label;
                return acc;
              }, {})
            },
            inputPlaceholder: 'Select a status',
            showCancelButton: true,
            // inputValidator: (value) => new Promise((resolve) => {
            //   if (value === 'oranges') {
            //     resolve();
            //   } else {
            //     resolve('You need to select oranges :)');
            //   }
            // })
          });

          if (value && isConfirmed) {
            await context.$store.dispatch('lead/updateManyItems', { items, modifications: { stage: value } });
            if (context.$children && context.$children[0] && context.$children[0].refreshLocalData) {
              context.$children[0].refreshLocalData();
            }
          }

          //
        },
      },
      {
        name: 'archive-all',
        label: 'common.buttons.archiveAll',
        classes: 'btn-info',
        title: 'Archive all items',
        icon: 'fa fa-disk',
        action: async ({
          items, action, location, props, id
        }, context) => {
          // Do what you want
          await context.$store.dispatch('lead/updateManyItems', { items, modifications: { archived: true } });
          if (context.$children && context.$children[0] && context.$children[0].refreshLocalData) {
            context.$children[0].refreshLocalData();
          }
        },
      },
    ],
  },
  customTopRightActions: [
    // {
    //   name: 'AffectedTo',
    //   type: 'filter',
    //   field: 'affectedToUserId',
    //   label: 'Affected to',
    //   class: 'd-inline-flex',
    //   showLabel: false,
    //   showOperator: false,
    // },
    {
      name: 'display-archived-items',
      label(state, vm) {
        return vm.$store.state.layout.leadArchiveButtonState
          ? vm.$t('request.actions.hide-archived')
          : vm.$t('request.actions.display-archived');
      },
      classes: 'btn-outline-info btn-sm mb-1 mr-1',
      icon: 'fa fa-eye mr-1',
      disabled: false,
      action: ({
        items, action, location, props, id
      }, context) => {
        let includeArchived;
        if (!context.$store.state.layout.leadArchiveButtonState) {
          includeArchived = 1;
        } else {
          includeArchived = 0;
        }
        context.$router.push({
          path: context.$route.path,
          query: {
            page: 0,
            // includeArchived,
            filters: {
              archived: includeArchived,
            },
          },
        });
        context.$store.commit('layout/setLeadArchiveButtonState', !context.$store.state.layout.leadArchiveButtonState);
        context.refreshComponent(true);
      },
    },
    /*
    {
      name: 'display-archived-items',
      label: '',
      classes: 'mb-1 mr-1 pull-right',
      icon: 'fa fa-ellipsis-v mr-1',
      disabled: false,
      type: 'dropdown',
      children: [{ label: 'a' }, 'b', 'c'],
      action: ({
        items, action, location, props, id
      }, context) => {
        let includeArchived;
        if (!context.$store.state.layout.leadArchiveButtonState) {
          includeArchived = 1;
        } else {
          includeArchived = 0;
        }
        context.$router.push({
          path: context.$route.path,
          query: {
            page: 0,
            includeArchived,
            filters: {
              archived: includeArchived,
            },
          },
        });
        context.$store.commit('layout/setLeadArchiveButtonState', !context.$store.state.layout.leadArchiveButtonState);
        context.refreshComponent(true);
      },
    },
    */
  ],

  nestedSchemas: [],
};
