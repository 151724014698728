var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"attachment"},[(_vm.attachments)?_c('ul',{staticClass:"list-inline"},_vm._l((_vm.attachments),function(attachment,index){return _c('li',{key:index,staticClass:"list-inline-item",attrs:{"data-type":attachment.contentType}},[_c('a',{attrs:{"href":attachment.url,"target":"_blank"}},[_c('i',{staticClass:"fa",class:{
              'fa-file-pdf-o': attachment.contentType === 'application/pdf',
              'fa-file-word-o': attachment.contentType === 'application/word',
              'fa-file-excel-o': attachment.contentType === 'application/excel',
              'fa-file-image-o':
                attachment.contentType === 'application/jpeg' ||
                attachment.contentType === 'application/png' ||
                attachment.contentType === 'application/gif',
            }}),_vm._v(" "+_vm._s(attachment.fileName || attachment.filename)+" ")])])}),0):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-block"},[_c('h6',{staticClass:"text-muted"},[_c('i',{staticClass:"icofont icofont-clip"}),_vm._v(" Pièces jointes")]),_c('a',{staticClass:"text-muted text-right right-download d-none",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-long-arrow-down mr-2"}),_vm._v("Download All")]),_c('div',{staticClass:"clearfix"})])}]

export { render, staticRenderFns }