<template>
  <router-link :to="getRouteUrl(item)">
    <div class="card kanban-card" v-if="item">
      <div class="card-body">
        <template v-if="item.affectedToUserId">
          <div class="pull-right kanban-card-users-avatars">
            <slot name="avatars" :item="item">
              <template v-if="item.affectedToUserId">
                <UserAvatar :userId="item.affectedToUserId" shadow size="24px"></UserAvatar>
              </template>
            </slot>
          </div>
        </template>
        <h5 class="card-title text-truncate">
          <slot name="title">{{ item.title }}</slot>
        </h5>
        <h6 class="card-subtitle mb-2 text-muted text-truncate">
          <slot name="subtitle"
            ><router-link class="text-dark" :to="`/app/client/${item.clientId}`">{{
              item.Client ? item.Client.name : ''
            }}</router-link></slot
          >
        </h6>
        <p class="card-text" v-if="false">
          <slot name="description"> {{ '' }} </slot>
        </p>
        <div v-if="item.tags" class="text-right tags-field mb-1">
          <small v-for="(label, index) in item.tags" :key="index" class="badge badge-info kanban-label">{{
            label
          }}</small>
        </div>
        <hr v-if="hasTasks" class="mb-1 mt-1" />

        <div class="d-flex justify-content-between">
          <div
            v-if="hasTasks"
            @mouseover="toggleTasksHoverState(true)"
            @mouseleave="toggleTasksHoverState(false)"
            @click.prevent.stop="toggleTasksState($event)"
            :class="{
              'text-muted': !taskActiveState,
              'text-dark': taskActiveState || taskHoverState,
            }"
          >
            <feather type="check-square" size="1rem"></feather> {{ countActiveTasks(item.Tasks) }}/{{
              item.Tasks.length
            }}
            <ul
              class=""
              v-if="hasTasks && (taskActiveState || taskHoverState)"
              @mouseover="toggleTasksHoverState(true)"
              @mouseleave="toggleTasksHoverState(false)"
            >
              <li
                v-for="task of item.Tasks"
                class="text-truncate"
                :key="task.id"
                :class="{ 'text-danger': isDateOverdue(item.slaDueDate), 'text-muted': task.status === 'done' }"
              >
                <feather
                  :type="task.status === 'done' ? 'check-square' : 'square'"
                  size="0.8rem"
                  class="mr-1"
                ></feather>
                <small>{{ task.content }}</small>
              </li>
            </ul>
          </div>
          <div v-if="item.slaDueDate" :class="{ 'text-danger': isDateOverdue(item.slaDueDate) }">
            <feather type="clock" size="1rem" class=""></feather>
            {{ item.slaDueDate | formatDate('DD.MMM.YYYY') }}
          </div>
          <div v-if="item.dueDate" :class="{ 'text-danger': isDateOverdue(item.dueDate) }">
            <feather type="clock" size="1rem" class=""></feather>
            {{ item.dueDate | formatDate('DD.MMM.YYYY') }}
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import dayjs from 'dayjs';
import UserAvatar from '../user/UserAvatar.vue';

export default {
  name: 'KanbanCard',
  components: {
    UserAvatar,
  },
  mixins: [],
  props: {
    item: Object,
    model: String,
  },
  data() {
    return {
      taskHoverState: false,
      taskActiveState: false,
    };
  },
  computed: {
    hasTasks() {
      return this.item.Tasks && this.item.Tasks.length;
    },
  },
  methods: {
    toggleTasksHoverState(state) {
      this.taskHoverState = state;
    },
    toggleTasksState($event) {
      this.taskActiveState = !this.taskActiveState;
      $event.preventDefault();
    },
    isDateOverdue(date) {
      return dayjs(date).isBefore(dayjs());
    },
    countActiveTasks(tasks) {
      return tasks ? tasks.filter((t) => t.status === 'done').length : 0;
    },
    getRouteUrl() {
      switch (this.model) {
      case 'recruitmentProcess':
        return `/app/recruitment-process/${this.item.id}`;
      case 'card':
        return `/app/board-${this.item.boardId}/card/${this.item.id}`;
      default:
        return `/app/${this.model}/${this.item.id}`;
      }
    },
  },
};
</script>
