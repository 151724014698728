<template>
  <div>
    <Breadcrumbs :title="$t(`app.sidebar.settings.${feature}`)" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <awesomeForm
            v-if="organisation && organisation.id"
            identity="organisationSetting"
            :itemId="organisation.id"
            :mode="mode"
            :url="`/api/organisation-setting`"
            @closeRequested="updateOrganisation"
            @cancel="mode = 'edit'"
            @edit="mode = 'edit'"
            :actions="{ delete: false, edit: true }"
            :standalone="true"
            :useRouterMode="false"
            :title="'Organisation'"
            displayMode="page"
            :displayHeader="false"
            :displayedFields="computedDisplayFields"
          >
          </awesomeForm>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '@/config';

export default {
  props: {
    feature: {
      type: String,
    },
  },
  data() {
    return {
      selectedUser: {},
      isCreating: false,
      mode: 'edit',
      config,
    };
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    computedDisplayFields() {
      if (this.feature) {
        switch (this.feature) {
        case 'conversation':
          return ['requestUsersCanSeeAllRequests', 'requestSlaTimeToReply', 'requestSlaTimeToClose'];
        case 'crm':
          return ['leadUsersCanSeeAllLeads', 'leadSlaTimeToReply', 'leadSlaTimeToClose', 'supportForGeocoding'];
        case 'hr':
          return [
            'jobBoardPageTitle',
            'jobBoardPageDescription',
            'jobBoardPageSeoTitle',
            'jobBoardPageSeoDescription',
          ];
        case 'advanced':
          return ['slackWebHook'];
        default:
          break;
        }
      }
      return [
        'requestUsersCanSeeAllRequests',
        'requestSlaTimeToReply',
        'requestSlaTimeToClose',
        'leadUsersCanSeeAllLeads',
        'leadSlaTimeToReply',
        'leadSlaTimeToClose',
        'jobBoardPageTitle',
        'jobBoardPageDescription',
        'jobBoardPageSeoTitle',
        'jobBoardPageSeoDescription',
        'slackWebHook',
      ];
    },
  },
  mounted() {},
  methods: {
    updateOrganisation() {
      this.$store.dispatch('user/getOrganisation', this.organisation.id).then(() => {
        this.$store.dispatch('menu/filterNavByFeature');
      });
      this.mode = 'edit';
    },
  },
};
</script>

<style lang="scss">
.aw-form .form-element {
  margin-bottom: 0px;
}
</style>
