<template>
  <div class="content" style="height: 100vh; background: #eee">
    <div class="container-fluid">
      <div class="sidebox row">
        <enyo-card
          class="
            card-user card-user
            col-xl-2
            offset-xl-5
            col-md-4
            offset-md-4
            col-lg-4
            offset-lg-4
            col-xs-10
            offset-xs-1
          "
          style="margin-top: 20% !important"
        >
          <div class="author">
            <a href="#">
              <h4 class="title text-primary text-bold">
                {{ $t('common.labels.reset') }}<br />
                <small></small>
              </h4>
            </a>
          </div>
          <div class="description text-center">
            <form @submit.prevent="submitForm()">
              <div class="form-group">
                <label for="password">{{ $t('common.field.new.password') }}</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  :placeholder="$t('common.field.new.password')"
                  autocomplete="off"
                  required
                />
              </div>
              <div class="form-group">
                <label for="confirmPassword">{{ $t('common.field.confirmPassword') }}</label>
                <input
                  v-model="confirmPassword"
                  type="password"
                  class="form-control"
                  :placeholder="$t('common.field.confirmPassword')"
                  autocomplete="off"
                  required
                />
              </div>
              <div class="notice">{{ $t('common.labels.passwordPolicy') }}</div>
              <button type="submit" class="btn btn-primary btn-block">
                {{ $t('common.buttons.submit') }}
              </button>
            </form>
          </div>
        </enyo-card>
      </div>
    </div>
  </div>
</template>

<script>
import { apiErrorsMixin } from 'vue-aw-components';

export default {
  components: {},
  props: {
    token: String,
  },
  mixins: [apiErrorsMixin],
  data() {
    return {
      password: null,
      confirmPassword: null,
    };
  },
  methods: {
    submitForm() {
      if (this.password !== this.confirmPassword) {
        this.$awNotify({
          title: this.$t('common.messages.passwordsDoNotMatch'),
          type: 'warning',
        });
        return;
      }

      if (
        !this.password
        || this.password.length < 8
        || !this.password.match(/[a-z]/)
        || !this.password.match(/[A-Z]/)
        || !this.password.match(/[0-9]/)
      ) {
        this.$awNotify({
          title: this.$t('common.messages.incorrectPasswordStrength'),
          type: 'warning',
        });
        return;
      }

      this.$http
        .post(`/api/user-reset/${this.token}`, {
          password: this.password,
        })
        .then(() => {
          this.$awNotify({
            title: this.$t('common.messages.successfullyReset'),
            type: 'success',
          });
          this.$router.push('/');
        })
        .catch(this.apiErrorCallback);
    },
  },
};
</script>
