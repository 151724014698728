import qs from 'qs';

const initialState = {
  clients: [],
  clientsCount: 0,
  recentClients: [],
  selectedClient: null,
  selectedClientStats: null,
};

// getters
const getters = {
  clientTitle: state => (state.selectedClient ? (state.selectedClient.company || state.selectedClient.name) : ''),
  clientAddress: state => (state.selectedClient
    && (state.selectedClient.address
      || state.selectedClient.postalCode
      || state.selectedClient.city
      || state.selectedClient.country)
    ? `${state.selectedClient.address || ''}, ${state.selectedClient.postalCode
    || ''} ${state.selectedClient.city
    || ''} ${state.selectedClient.country
    || ''}`
    : ''),
};

// mutations
const mutations = {
  clients(state, data) {
    state.clients = data;
  },
  clientsCount(state, data) {
    state.clientsCount = data;
  },
  recentClients(state, data) {
    state.recentClients = data;
  },
  selectedClient(state, data) {
    state.selectedClient = data;
  },
  selectedClientStats(state, data) {
    state.selectedClientStats = data;
  },
};

// actions
const actions = {
  createClient(context, payload) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      console.warn('[STORE][CLIENT] user or organisation not defined');
      return Promise.resolve([]);
    }
    return this._vm.$http
      .post('/api/client', payload)
      .then((res) => {
        if (res.data && res.data.body) {
          context.dispatch('getClients', { save: true });
          return res.data.body;
        }
        return [];
      });
  },
  getClients(context, options) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }
    return this._vm.$http
      .get('/api/client', {
        ...options,
        paramsSerializer(params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat',
          });
        },
      })
      .then((res) => {
        if (res.data && res.data.body) {
          if (res.data.totalCount) {
            context.commit('clientsCount', res.data.totalCount);
          }

          if (options.save !== false) {
            context.commit('clients', res.data.body);
          }
          return res.data.body;
        }
        return [];
      });
  },
  getAndSelectClient(context, id) {
    return context.dispatch('getClient', id)
      .then((client) => context.commit('selectedClient', client));
  },

  getClient(context, id) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve(null);
    }
    context.commit('selectedClient', null);
    return this._vm.$http
      .get(`/api/client/${id}`)
      .then((res) => {
        if (res.data && res.data.body) {
          context.commit('selectedClient', res.data.body);
          return res.data.body;
        }
        return null;
      });
  },

  deleteClient(context, id) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve(null);
    }
    return this._vm.$http
      .delete(`/api/client/${id}`)
      .then(({ data }) => data);
  },

  getClientStats(context, id, options = {}) {
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }
    const { selectedClientStats } = context.state;
    if (selectedClientStats && selectedClientStats.id && selectedClientStats.id === id && !options.force) {
      return Promise.resolve(selectedClientStats);
    }
    context.commit('selectedClientStats', null);
    return this._vm.$http
      .get(`/api/client/${id}/stats`, options)
      .then((res) => {
        if (res.data && res.data.body) {
          context.commit('selectedClientStats', res.data.body);
          return res.data.body;
        }
        return [];
      });
  },

  updateClient(context, request) {
    const { id } = request;
    if (!id) {
      throw new Error('error_missing_id_in_put_request');
    }
    if (!context.rootState.user.user || !context.rootState.user.organisation) {
      return Promise.resolve([]);
    }
    return this._vm.$http.put(`/api/client/${id}`, request).then((res) => {
      if (res.data && res.data.body) {
        return res.data.body;
      }
      return [];
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
