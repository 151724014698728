<template>
  <div class="tag-selector" :class="classes ? classes.toString() : ''">
    <!-- <img class="rounded-circle img-20 mr-2" :src="(selectedItems && selectedItems.avatarUrl) || '/user.png'" /> -->
    <v-select
      v-model="selectedItems"
      :options="items"
      :placeholder="placeholder || $t('app.member-selector.select-user')"
      :value="selectedItems"
      :multiple="multiple"
      :taggable="taggable"
      :searchable="searchable"
      label="firstName"
      class="user-tag-picker"
      :class="classes + ' ' + (avatarOnly ? 'avatar-only' : '')"
      :reduce="(item) => item.id"
      @search="onSearch"
      @input="onInput"
      @change="onChange"
    >
      <template #selected-option="option">
        <img v-if="option && option.avatarUrl" class="rounded-circle img-20 mr-2" :src="option.avatarUrl" />
        <img v-else class="rounded-circle img-20 mr-2" src="../../assets/images/user/user.png" />
        <small>{{ option.firstName }} {{ option.lastName }}</small>
      </template>
      <template v-slot:option="option">
        <div class="my-1">
          <img v-if="option && option.avatarUrl" class="rounded-circle img-20 mr-2" :src="option.avatarUrl" />
          <img v-else class="rounded-circle img-20 mr-2" src="../../assets/images/user/user.png" />
          <small>{{ option.firstName }} {{ option.lastName }}</small>
        </div>
      </template>
      <span slot="no-options" @click="tagOptionsWithId === false">Pas de résultats</span>
    </v-select>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import VSelect from 'vue-select';

export default {
  components: {
    'v-select': VSelect,
  },
  props: {
    value: {
      type: [Array, String, Number, Object],
      default: () => null,
    },
    classes: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    preload: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    currentUserId: {
      type: Number,
      default: null,
    },
    avatarOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return this.$store.state.member.items.filter((item) => item.isActiveInOrganisation);
    },
    ...mapState('user', ['user']),
  },
  data() {
    return {
      selectedItems: this.currentUserId,
      searchInput: '',
    };
  },
  watch: {
    value(newVal) {
      this.selectedItems = newVal;
    },
    selectedItems(newVal) {
      this.$emit('input', this.selectedItems);
    },
  },
  mounted() {
    if (!this.items.length) {
      this.$store.dispatch('member/getItems');
    }
    if (this.value && (!Array.isArray(this.value) || this.value.length)) {
      this.selectedItems = this.value;
    }
    if (this.preload) {
      this.loadItems('');
    }
  },
  methods: {
    onInput(e) {
      // clearTagOptionsDropdownList
      this.$emit('change', e);
    },
    onChange(e) {
      this.$emit('change', e);
    },

    onSearch(e) {
      this.$emit('searchInput', e);
      if (!e) {
        return;
      }
      if (e) {
        this.loadItems(e);
      }
      this.searchInput = e;
      this.$emit('searchInput', e);
    },

    async loadItems(tag) {
      try {
        this.$store.dispatch('member/getItems');
      } catch (err) {
        console.warn(err);
      }
    },
  },
};
</script>
<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: var(--primary-color, teal);
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.vs__actions {
  padding: 0 4px 0 0 !important;
}
.user-tag-picker {
  min-width: 200px;

  &.avatar-only {
    min-width: unset;
  }
}
</style>
