<template>
  <div class="tag-selector" :class="classes ? classes.toString() : ''">
    <!-- <img class="rounded-circle img-20 mr-2" :src="(selectedIds && selectedIds.avatarUrl) || '/user.png'" /> -->
    <div class="dropdown" v-click-outside="closeDropdown" :class="dropDirection">
      <button
        class="btn dropdown-toggle btn-sm text-uppercase"
        :class="selectedIds ? 'btn-transparent pr-0 pl-0' : ''"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        v-on:click="isOpened = !isOpened"
        :disabled="disabled"
      >
        <template v-if="!selectedItems.length && placeholder">{{ placeholder }} </template>
        <template v-if="!selectedItems.length && !placeholder"><feather type="user-check" size="16" /></template>
        <template v-if="selectedItems.length">
          <template v-for="selectedItem in selectedItems">
            <div :key="selectedItem.id" class="d-inline">
              <img
                v-if="selectedItem.avatarUrl"
                :src="selectedItem.avatarUrl"
                class="mr-1 rounded-circle img-20"
                alt=""
              />
              <span v-if="!selectedItem.avatarUrl" class="mr-1 rounded-circle img-20" alt="">{{
                prepareInitials(selectedItem)
              }}</span>
              <template v-if="!avatarOnly && !multiple">
                <span>{{ selectedItem.firstName }} {{ selectedItem.lastName }}</span>
              </template>
            </div>
          </template>
        </template>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" :class="{ show: isOpened }">
        <a v-for="item in activeItems" :key="item.id" class="dropdown-item" @click.prevent="setItem(item)" href="#">
          <img v-if="item.avatarUrl" :src="item.avatarUrl" class="mr-1 rounded-circle img-20" alt="" />
          {{ item.firstName }} {{ item.lastName }}
        </a>
        <div :key="-1" class="dropdown-divider"></div>
        <a v-for="item in inactiveItems" :key="item.id" class="dropdown-item" @click.prevent="setItem(item)" href="#">
          <img v-if="item.avatarUrl" :src="item.avatarUrl" class="mr-1 rounded-circle img-20" alt="" />
          {{ item.firstName }} {{ item.lastName }}
        </a>
        <div :key="-2" class="dropdown-divider"></div>
        <a :key="-3" class="dropdown-item d-flex justify-content-start" @click.prevent="setItem()" href="#">
          <feather class="mr-1" type="x-circle"></feather>
          {{ $t('common.buttons.reset') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'MemberSelectorDropdown',
  components: {},
  directives: { ClickOutside },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Array, Number, String, Object],
      default: () => null,
    },
    direction: {
      type: String,
      default: 'down',
    },
    classes: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    preload: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    avatarOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return this.$store.state.member.items;
    },
    activeItems() {
      return this.$store.state.member.items.filter((item) => item.isActiveInOrganisation);
    },
    inactiveItems() {
      return this.$store.state.member.items.filter((item) => !item.isActiveInOrganisation);
    },
    selectedItems() {
      if (!this.selectedIds) {
        return [];
      }

      if (this.multiple) {
        return this.$store.state.member.items.filter((item) => this.selectedIds.includes(item.id));
      }
      return this.$store.state.member.items.filter((item) => `${item.id}` === `${this.selectedIds}`);
    },
    ...mapState('user', ['user']),
    dropDirection() {
      return this.direction === 'up' ? 'dropup' : 'dropdown';
    },
  },
  data() {
    return {
      selectedIds: this.value,
      backupIds: '',
      searchInput: '',
      isOpened: false,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (this.multiple && (JSON.stringify(newVal) !== JSON.stringify(oldVal) || !this.selectedIds)) {
        this.selectedIds = newVal;
      } else if (!this.multiple && (newVal !== oldVal || this.selectedIds === undefined)) {
        this.selectedIds = newVal;
      }
    },
    selectedIds(newVal, oldVal) {
      if (`${newVal}` !== `${oldVal}`) {
        this.$emit('change', newVal);
      }
    },
  },
  mounted() {
    if (this.multiple) {
      this.selectedIds = [];
    }
    if (!this.items.length) {
      this.$store.dispatch('member/getItems');
    }
    if (this.value && (!Array.isArray(this.value) || this.value.length)) {
      this.selectedIds = this.value;
    }
    if (this.preload) {
      this.loadItems('');
    }
  },
  methods: {
    prepareInitials(user) {
      if (!user) {
        return '';
      }
      let text = `${user.firstName ? user.firstName.substring(0, 1) : ''}`;
      text += `${user.lastName ? user.lastName.substring(0, 1) : ''}`;

      return text;
    },

    onSearch(e) {
      this.$emit('searchInput', e);
      if (!e) {
        return;
      }
      if (e) {
        this.loadItems(e);
      }
      this.searchInput = e;
      this.$emit('searchInput', e);
    },

    async loadItems(tag) {
      try {
        this.$store.dispatch('member/getItems');
      } catch (err) {
        console.warn(err);
      }
    },
    setItem(item) {
      this.$forceUpdate();
      this.isOpened = false;
      if (this.multiple) {
        if (!this.selectedIds) {
          this.selectedIds = [];
        }
        if (item === undefined) {
          this.selectedIds = [];
          return;
        }
        if (this.selectedIds.includes(item.id)) {
          this.selectedIds = this.selectedIds.filter((id) => id !== item.id);
        } else {
          this.selectedIds = [...this.selectedIds, item.id];
        }
      } else {
        if (item === undefined) {
          this.selectedIds = undefined;
          return;
        }
        this.$set(this, 'selectedIds', item.id);
        this.backupIds = item.id;
      }
    },

    closeDropdown() {
      this.isOpened = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: var(--primary-color, teal);
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
.user-tag-picker {
  min-width: 200px;

  &.avatar-only {
    min-width: unset;
    .vs__search {
      //      display: none;
    }
  }
}
</style>
