var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.model && _vm.modelId)?_c('px-card',{staticClass:"task-widget task-section",attrs:{"title":("Documents [" + (_vm.documents ? _vm.documents.length : 0) + "]"),"actions":false},scopedSlots:_vm._u([{key:"headerCustom",fn:function(){return [_c('div',{staticStyle:{"position":"absolute","top":"10px","right":"10px"}},[_c('button',{staticClass:"btn bg-transparent text-info p-1",attrs:{"data-tooltip":"Url"},on:{"click":function($event){$event.preventDefault();return _vm.promptUrl.apply(null, arguments)}}},[_c('feather',{attrs:{"type":"link","size":"1rem"}})],1),_c('button',{staticClass:"btn bg-transparent text-primary p-1",attrs:{"data-tooltip":"Upload"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.fileInput.click()}}},[_c('feather',{attrs:{"type":"file-plus","size":"1rem"}})],1)]),_c('hr',{staticClass:"mb-0"})]},proxy:true}],null,false,2424435873)},[_c('div',{attrs:{"slot":"with-padding"},slot:"with-padding"},[_c('div',{staticClass:"todo"},[_c('div',{staticClass:"todo-list-wrapper"},[_c('div',{staticClass:"todo-list-container"},[_c('div',{staticClass:"todo-list-body"},[_c('ul',{attrs:{"id":"todo-list"}},_vm._l((_vm.documents),function(document,index){return _c('li',{key:index,staticClass:"task"},[_c('div',{staticClass:"task-container"},[_c('a',{staticClass:"btn btn-icon p-0",attrs:{"href":document.url,"title":"Mark Complete","target":"_blank"}},[_c('h4',{staticClass:"task-label"},[(document.mimeType)?[(document.mimeType.startsWith('image'))?_c('img',{staticClass:"img-20 pull-left mr-2",attrs:{"alt":document.CreatedBy && document.CreatedBy.firstName,"src":document.CreatedBy && document.url}}):_c('span',{staticClass:"img-40"},[_c('i',{staticClass:"fa mr-1",class:{
                                'fa-file-pdf-o text-danger': document.mimeType === 'application/pdf',
                                'fa-file-word-o': document.mimeType === 'application/word',
                                'fa-file-powerpoint': document.mimeType === 'application/powerpoint',
                                'fa-file-zip': document.mimeType === 'application/zip',
                                'fa-file-excel-o text-success':
                                  document.mimeType === 'application/excel' || document.mimeType.includes('sheet'),
                                'fa-file-audio-o text-info':
                                  document.mimeType.includes('audio') || document.mimeType.includes('mp3'),
                                'fa-file-video-o text-warning':
                                  document.mimeType.includes('video') || document.mimeType.includes('mp4'),
                                'fa-file-image-o': document.mimeType.includes('image'),
                              }})])]:_c('span',{staticClass:"img-40"},[_c('i',{staticClass:"fa mr-1 fa-file-o"})]),_c('small',[_vm._v(_vm._s(document.fileName))])],2)]),_c('span',{staticClass:"task-action-btn"},[(_vm.canDisplayDocument(document))?_c('span',{staticClass:"btn btn-icon p-0",attrs:{"title":"Delete Task"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openDocument(document)}}},[_c('feather',{staticClass:"text-info",attrs:{"type":"eye","size":"15"}})],1):_vm._e(),_c('span',{staticClass:"btn btn-icon p-0",attrs:{"title":"Delete Task"},on:{"click":function($event){return _vm.deleteDocument(document)}}},[_c('feather',{staticClass:"text-secondary",attrs:{"type":"trash-2","size":"15"}})],1)])])])}),0)]),_c('div',{staticClass:"todo-list-footer"},[_c('hr'),_c('div',{staticClass:"new-task-wrapper",class:{ visible: !_vm.disabled },on:{"dragenter":function($event){_vm.fileZoneHover = true},"dragleave":function($event){_vm.fileZoneHover = false},"dragend":function($event){_vm.fileZoneHover = false},"drop":function($event){_vm.fileZoneHover = false}}},[_c('div',{staticClass:"base64-upload pointer",class:{ 'bg-primary': _vm.fileZoneHover }},[_c('p',{staticStyle:{"min-height":"70px","font-size":"12px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.fileName)+" "),(!_vm.fileName)?_c('i',{staticClass:"fa fa-upload fa-2x"}):_vm._e()]),_c('input',{ref:"fileInput",staticClass:"pointer dropzone",attrs:{"multiple":"","type":"file","disabled":_vm.disabled,"accept":'*'},on:{"change":_vm.onFileInputChange}})])])])])]),_c('div')])])]):_vm._e(),_c('b-modal',{ref:"documentModal",attrs:{"id":"document-modal","title":_vm.openedDocument.fileName,"size":"xl","hide-footer":""}},[(_vm.openedDocument && _vm.openedDocument.url)?_c('div',{staticStyle:{"background":"transparent","width":"100%","text-align":"center","height":"100%"}},[_c('iframe',{staticStyle:{"background":"transparent","height":"100%"},style:({
          'min-height': _vm.openedDocument.mimeType == 'application/pdf' ? '85vh' : '0',
        }),attrs:{"src":_vm.openedDocument.url,"frameborder":"0","onmousewheel":"","width":"100%","height":"100%"}})]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }