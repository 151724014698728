/* eslint-disable import/prefer-default-export */

export const user = {
  identity: 'user',
  icon: '',

  actions: { create: false, edit: true, delete: true },
  options: {
    mode: 'local',
    customInlineActions: [],
  },

  nestedSchemas: [],
};
