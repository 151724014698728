<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div>
              <a class="logo text-left">
                <img class="img-fluid for-light" src="../../assets/images/logo/logo.png" alt="loginpage" />
              </a>
            </div>
            <div class="login-main login-form-card">
              <form class="theme-form" @submit.prevent="submitForm()">
                <h4>{{ $t('common.labels.login') }}</h4>
                <div class="form-group">
                  <label class="col-form-label">{{ $t('common.labels.email') }}</label>
                  <input
                    v-model="email"
                    name="email"
                    type="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    :placeholder="$t('common.placeholders.email')"
                    required
                    autocomplete="off"
                  />
                </div>
                <div class="form-group">
                  <label class="col-form-label">{{ $t('common.labels.password') }}</label>
                  <input
                    v-model="password"
                    name="password"
                    type="password"
                    class="form-control"
                    :placeholder="$t('common.placeholders.password')"
                    required
                    autocomplete="off"
                  />
                </div>
                <div class="form-group mb-0">
                  <button class="btn btn-primary btn-block" type="submit">
                    <i class="fa fa-circle-o-notch fa-spin" v-if="isRequestInProgress"></i>
                    <i class="fa fa-paper-plane" v-if="!isRequestInProgress"></i>
                    {{ $t('common.buttons.connect') }}
                  </button>
                </div>
                <h6 class="text-muted mt-4 or" v-if="config.features.googleAuth">
                  {{ $t('common.labels.connectWith') }}
                </h6>
                <div class="social mt-4">
                  <div class="btn-showcase">
                    <a class="btn btn-light btn-block" v-if="config.features.googleAuth" @click="loginGoogle()">
                      <i class="fa fa-google text-danger"></i>
                      {{ !isRequestInProgress ? $t('common.buttons.google') : '' }}
                      <i class="fa fa-circle-o-notch fa-spin" v-if="isRequestInProgress"></i>
                    </a>
                  </div>
                  <div id="googleButtonDiv"></div>
                </div>
                <div class="social mt-4">
                  <div class="btn-showcase">
                    <a class="btn btn-light btn-block" @click="loginMicrosoft()">
                      <i class="fa fa-windows text-info"></i>
                      {{ !isMicrosoftRequestInProgress ? $t('common.buttons.outlook') : '' }}
                      <i class="fa fa-circle-o-notch fa-spin" v-if="isMicrosoftRequestInProgress"></i>
                    </a>
                  </div>
                </div>
                <p class="mt-4 mb-0">
                  {{ $t('common.labels.noAccountYet') }}
                  <router-link
                    v-if="config && config.features && config.features.register"
                    to="/register"
                    class="ml-2"
                    tag="a"
                    >{{ $t('common.labels.register') }}</router-link
                  >
                  <br />
                  <forgot-password tag="a" v-if="config && config.features && config.features.passwordReset" />
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-7 d-none d-lg-block p-0">
        <img
          class="bg-img-cover bg-center"
          src="../../assets/images/login/2.jpg"
          alt="loginpage"
          style="width: 100%; min-height: 100%"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import { apiErrorsMixin } from 'vue-aw-components';
import { has, get } from 'lodash';
import swal from 'sweetalert2/dist/sweetalert2';
import apiErrorsMixin from '@/mixins/apiErrorsMixin';
import config from '@/config';
import MicrosoftAuthService from '../../services/msal2-authService';
import ForgotPassword from './ForgotPassword.vue';

export default {
  components: {
    ForgotPassword,
  },
  mixins: [apiErrorsMixin],
  data () {
    return {
      email: null,
      password: null,
      pageLoaded: false,
      isRequestInProgress: false,
      isMicrosoftRequestInProgress: false,
      config,
      microsoftAuthService: null,
      features: null,
    };
  },
  created () {},
  mounted () {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 20);

    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
    if (this.$route.query.features) {
      this.features = this.$route.query.features;
      if (typeof this.$route.query.features === 'string') {
        this.features = [this.features];
        localStorage.setItem('registrationFeatures', JSON.stringify(this.features));
      }
    } else if (window.localStorage && localStorage.getItem('registrationFeatures')) {
      this.features = JSON.parse(localStorage.getItem('registrationFeatures'));
    }

    if (this.$route.path.includes('/auth/google/callback') && (this.$route.query.code || this.$route.hash)) {
      this.isRequestInProgress = true;
      this.getGoogleToken();
    }
    if (window.gapi && this.config.features.googleAuth) {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init({
          client_id:
            config.googleAuthClient || '735540248134-ggfesuvs015qf6f1fqs79aufslflp29s.apps.googleusercontent.com',
          scope: 'profile email openid',
          // ux_mode: 'redirect',
          redirect_uri: `${window.location.origin}/auth/google/callback`,
        });
      });
    }

    this.microsoftAuthService = new MicrosoftAuthService();
  },
  methods: {
    submitForm () {
      this.$store.dispatch('user/logout');
      this.$store
        .dispatch('user/login', {
          email: this.email,
          password: this.password,
          t: this.$route.query.t,
        })
        .then(this.postLogin)
        .catch((err) => {
          this.apiErrorCallback(err);
        });
    },

    async postLogin (res) {
      this.isRequestInProgress = false;
      if (has(res, 'data.user')) {
        const user = get(res, 'data.user');
        // if we are connected
        if (!this.$store.state.user.user || this.$store.state.user.user.id !== user.id) {
          await this.$store.dispatch('user/setUser', user);
          await this.$store.commit('user/token', get(res, 'data.token'));
          await this.$store.dispatch('refreshListOfValues');
          this.$socket.connect();
        }
      }
      if (this.$store.state.user.user) {
        const { user } = this.$store.state.user;
        const redirectUrl = this.$route.query.redirect ? this.$route.query.redirect : '/';
        if (!user.Organisations || !user.Organisations.length) {
          // this.$router.push('/onboarding');
          const organisationWasCreated = await this.showOrganisationForm();
          if (!organisationWasCreated.id) {
            return;
          }
          window.location.href = redirectUrl;
        } else {
          window.location.href = redirectUrl;
        }
        setTimeout(() => {
          this.$awNotify({
            title: this.$t('common.messages.loginWelcome', {
              name: `${user.firstName}
                ${user.lastName}`,
            }),
            type: 'success',
          });
        }, 250);
        return;
      }
      this.$awNotify({
        title: this.$t('common.messages.no_access_account'),
        type: 'warning',
      });
    },

    loginGoogle () {
      // return google.accounts.id.prompt();
      return window.gapi.auth2.getAuthInstance().signIn().then(this.onSignIn);
      // return
      // this.$http.get('/api/auth/google').then(res => {
      //   window.location.href = res.data.body;
      // });
    },

    async onSignIn (googleUser) {
      // Useful data for your client-side scripts:
      this.isRequestInProgress = true;
      this.$http
        .post('/api/auth/google/callback', {
          ...googleUser.getAuthResponse(true),
          t: this.$route.query.t,
          features: this.features,
        })
        .then(this.postLogin)
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isRequestInProgress = false;
        });
    },

    getGoogleToken () {
      let token = this.$route.query;
      if (window.location.hash && !this.$route.query.code) {
        token = this.unserializeHash(window.location.hash);
      }
      this.$http.post('/api/auth/google/callback', token).then(this.postLogin).catch(this.apiErrorCallback);
    },
    unserializeHash (str) {
      const obj = {};
      str
        .substring(1, str.length)
        .split('&')
        .forEach((elm) => {
          const [key, value] = elm.split('=');
          obj[key] = value;
        });
      return obj;
    },

    async loginMicrosoft () {
      try {
        this.isMicrosoftRequestInProgress = true;
        const data = await this.microsoftAuthService.login();

        if (data && data.account && data.userData && data.accessToken) {
          this.loggedIn = true;
          const { account, userData, accessToken } = data;

          const res = await this.$http.post('/api/auth/microsoft/callback', {
            t: this.$route.query.t,
            account,
            userData,
            accessToken,
            features: this.features,
          });
          await this.postLogin(res);
        }
      } catch (error) {
        this.apiErrorCallback(error);
      } finally {
        this.isMicrosoftRequestInProgress = false;
      }
    },

    async showOrganisationForm () {
      const confirmation = await swal.fire({
        reverseButtons: true,
        input: 'text',
        title: this.$t('app.register.have-organisation'),
        text: `${this.$t('app.register.ask-invite')}
           ${this.$t('app.register.create-organization')}`,
        cancelButtonText: this.$t('app.register.company-already-exist'),
        confirmButtonText: this.$t('app.register.understood'),
        showCancelButton: true,
        inputValidator: (value) =>
          new Promise((resolve) => {
            if (!value) {
              resolve(this.$t('common.labels.enterCompanyName'));
            }
            resolve();
          }),

        allowOutsideClick: () => !swal.isLoading(),
      });

      if (!confirmation || !confirmation.value) {
        return null;
      }
      this.isRequestInProgress = true;
      const response = await this.$http.post('/api/organisation', {
        name: confirmation.value,
        features: this.features,
      });
      if (!response) {
        return null;
      }
      const { body } = response.data;
      this.$store.commit('user/organisation', body);
      await this.$store.dispatch('user/refreshUser');
      return body;
    },
  },
};
</script>
