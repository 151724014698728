<template>
  <b-modal
    title="Imap configuration"
    :visible="shouldDisplayModal"
    @cancel="handleCloseModal"
    @closeRequested="handleCloseModal"
    @hidden="handleCloseModal"
    @ok="handleOk"
  >
    <b-form ref="form" @submit.stop.prevent="">
      <b-form-group
        :label="$t('common.field.email')"
        label-for="email"
        :invalid-feedback="$t('common.field.email') + ' ' + $t('common.messages.fieldRequired')"
      >
        <b-form-input
          type="email"
          required
          autocomplete="off"
          v-model="imapCredentials.email"
          id="email"
          ref="email"
          :state="imapCredentialsState.email"
        />
      </b-form-group>
      <b-form-group
        :label="$t('common.field.host')"
        label-for="host"
        :invalid-feedback="$t('common.field.host') + ' ' + $t('common.messages.fieldRequired')"
      >
        <b-form-input
          type="text"
          required
          autocomplete="off"
          v-model="imapCredentials.host"
          id="host"
          ref="host"
          @input="handleHostChange"
          :state="imapCredentialsState.host"
        />
      </b-form-group>
      <b-form-group
        :label="$t('common.field.smtpHost')"
        label-for="host"
        :invalid-feedback="$t('common.field.smtpHost') + ' ' + $t('common.messages.fieldRequired')"
      >
        <b-form-input
          type="text"
          required
          autocomplete="off"
          v-model="imapCredentials.smtpHost"
          id="host"
          ref="smtpHost"
          :state="imapCredentialsState.smtpHost"
        />
      </b-form-group>
      <b-form-group
        :label="$t('common.field.port')"
        label-for="port"
        :invalid-feedback="$t('common.field.port') + ' ' + $t('common.messages.fieldRequired')"
      >
        <b-form-input
          type="text"
          required
          autocomplete="off"
          v-model="imapCredentials.port"
          id="port"
          ref="port"
          :state="imapCredentialsState.port"
        />
      </b-form-group>
      <b-form-group
        :label="$t('common.field.smtpPort')"
        label-for="smtpPort"
        :invalid-feedback="$t('common.field.smtpPort') + ' ' + $t('common.messages.fieldRequired')"
      >
        <b-form-input
          type="text"
          required
          autocomplete="off"
          v-model="imapCredentials.smtpPort"
          id="smtpPort"
          ref="smtpPort"
          :state="imapCredentialsState.smtpPort"
        />
      </b-form-group>
      <b-form-group
        :label="$t('common.field.user')"
        label-for="user"
        :invalid-feedback="$t('common.field.user') + ' ' + $t('common.messages.fieldRequired')"
      >
        <b-form-input
          type="text"
          required
          autocomplete="off"
          v-model="imapCredentials.user"
          id="user"
          ref="user"
          :state="imapCredentialsState.user"
        />
      </b-form-group>
      <b-form-group
        :label="$t('common.field.password')"
        label-for="pass"
        :invalid-feedback="$t('common.field.password') + ' ' + $t('common.messages.fieldRequired')"
      >
        <b-form-input
          type="password"
          required
          autocomplete="off"
          v-model="imapCredentials.pass"
          id="pass"
          ref="pass"
          :state="imapCredentialsState.pass"
        />
      </b-form-group>
    </b-form>
    <feather v-if="isRefreshing" type="loader" animation="spin" class=""></feather>
    <button class="btn btn-info pull-right" @click="testConnection">
      {{ $t('common.buttons.testConnection') }}
    </button>
  </b-modal>
</template>

<script>
import { BModal, BFormInput, BFormGroup } from 'bootstrap-vue';

export default {
  name: 'ImapModal',
  components: {
    BModal,
    BFormInput,
    BFormGroup,
  },
  props: {
    shouldDisplayModal: {
      type: Boolean,
      required: true,
      isPrivate: Boolean,
      isFetchEnabled: Boolean,
    },
  },
  data() {
    return {
      imapCredentials: {
        email: null,
        host: null,
        port: 993,
        smtpPort: 465,
        user: null,
        pass: null,
      },
      imapCredentialsState: {
        email: null,
        host: null,
        port: null,
        smtpPort: null,
        user: null,
        pass: null,
      },
      isRefreshing: false,
    };
  },
  methods: {
    handleCloseModal() {
      this.$emit('closeModal');
    },

    async handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      const success = await this.submitForm();
      if (success) {
        this.$emit('closeModal');
      }
    },

    checkFormValidity() {
      // Check each individual field to display message
      const emailValid = this.$refs.email.checkValidity();
      const hostValid = this.$refs.host.checkValidity();
      const portValid = this.$refs.port.checkValidity();
      const smtpHostValid = this.$refs.smtpHost.checkValidity();
      const smtpPortValid = this.$refs.smtpPort.checkValidity();
      const userValid = this.$refs.user.checkValidity();
      const passValid = this.$refs.pass.checkValidity();
      const formValid = this.$refs.form.checkValidity();

      this.imapCredentialsState.email = emailValid;
      this.imapCredentialsState.host = hostValid;
      this.imapCredentialsState.port = portValid;
      this.imapCredentialsState.host = smtpHostValid;
      this.imapCredentialsState.smtpPort = smtpPortValid;
      this.imapCredentialsState.user = userValid;
      this.imapCredentialsState.pass = passValid;

      return formValid;
    },

    async submitForm() {
      try {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return false;
        }
        this.isRefreshing = true;
        const isConnectionWorking = await this.testConnection();
        if (!isConnectionWorking) {
          throw new Error(this.$t('common.messages.testConnectionFail'));
        }

        const credentials = this.imapCredentials;
        await this.$http.post('/api/mailbox', {
          email: this.imapCredentials.email,
          type: 'imap',
          credentials: JSON.stringify(credentials),
          isPrivate: this.isPrivate,
          isFetchEnabled: this.isFetchEnabled,
        });

        this.$awNotify({
          title: this.$t('common.messages.addMailbox'),
          type: 'success',
        });
        this.imapCredentials = {
          email: null,
          host: null,
          smtpHost: null,
          port: 993,
          smtpPort: 465,
          user: null,
          pass: null,
        };
        this.imapCredentialsState = {
          email: null,
          host: null,
          smtpHost: null,
          port: null,
          smtpPort: null,
          user: null,
          pass: null,
        };

        this.isRefreshing = false;
        return true;
      } catch (err) {
        this.apiErrorCallback(err);
        this.isRefreshing = false;
        return false;
      }
    },

    async testConnection() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return false;
      }

      try {
        this.isRefreshing = true;
        const credentials = this.imapCredentials;
        await this.$http.post(
          '/api/email/imap/test',
          {
            credentials: JSON.stringify(credentials),
          },
          { timeout: 30000 }
        );

        this.$awNotify({
          title: this.$t('common.messages.testConnection'),
          type: 'success',
        });
        return true;
      } catch (err) {
        this.apiErrorCallback(err);
        this.isRefreshing = false;
        return false;
      }
    },

    handleHostChange(value) {
      if (!this.imapCredentials.smtpHost) {
        this.imapCredentials.smtpHost = value;
      }
      if (value === 'imap.gmail.com') {
        if (!this.imapCredentials.port) this.imapCredentials.port = 993;
        if (!this.imapCredentials.smtpPort) this.imapCredentials.smtpPort = 465;
      } else if (value === 'imap.mail.yahoo.com') {
        if (!this.imapCredentials.port) this.imapCredentials.port = 993;
        if (!this.imapCredentials.smtpPort) this.imapCredentials.smtpPort = 465;
      } else if (value === 'imap-mail.outlook.com') {
        this.imapCredentials.port = 993;
        this.imapCredentials.smtpPort = 587;
      } else if (value === 'outlook.office365.com') {
        this.imapCredentials.smtpHost = 'smtp.office365.com';
        this.imapCredentials.port = 993;
        this.imapCredentials.smtpPort = 587;
      } else {
        this.imapCredentials.port = 993;
        this.imapCredentials.smtpPort = 465;
      }
    },
  },
};
</script>

<style></style>
