<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{ loderhide: !show }">
      <div class="loader-index">
        <span></span>
      </div>
    </div>
    <!-- Loader ends-->

    <!--<Main/>-->
    <router-view></router-view>
    <email-composer-modal
      :shouldDisplayModal="showEmailComposerModal.show"
      :toEmails="showEmailComposerModal.recipientAddress"
      :subject="showEmailComposerModal.subject"
      :body="showEmailComposerModal.body"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '@/config';
import EmailComposerModal from './components/mailbox/EmailComposerModal.vue';

export default {
  name: 'app',
  components: {
    EmailComposerModal,
  },

  watch: {
    $route() {
      if (this.$route.query.locale) {
        this.$i18n.locale = this.$route.query.locale;
        this.$store.dispatch('changeLocale', this.$route.query.locale);
      }

      // send traffic to google analytics
      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_title: document.title,
          page_location: this.$route.path,
          page_path: this.$route.fullPath,
        });
      }
    },
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    ...mapState('request', ['requests', 'selectedRequest']),
    ...mapState('email', ['showEmailComposerModal']),
  },
  mounted() {
    this.timeOut();
    if (this.$route.query.locale) {
      this.$i18n.locale = this.$route.query.locale;
      this.$store.dispatch('changeLocale', this.$route.query.locale);
    }
    if (this.organisation) {
      this.$store.dispatch('recruitmentProcess/getRecruitmentStages', {
        organisationId: this.organisation.id,
      });
    }
    this.$store.subscribe(async ({ type, payload }) => {
      if (type === 'models') {
        // if models list has changed then connect the models to the routes
        await this.$store.dispatch('connectRoutes', this.$router);
        this.show = false;
      }
    });
  },
  data() {
    return {
      show: true,
      ctrlPressed: false,
    };
  },
  methods: {
    timeOut() {
      const self = this;
      // If user is logged, wait for models to load and connectRoutes for max 60s,
      // otherwise charge 'unlogged' pages immediately
      const timeoutDuration = localStorage.getItem(`${config.appKey}_token`) ? 60000 : 200;
      setTimeout(() => {
        self.show = false;
      }, timeoutDuration);
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
