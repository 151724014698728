<template>
  <div>
    <Breadcrumbs
      :parents="[
        { title: 'Clients', url: `/app/clients` },
        { title: clientName, url: `/app/client/${this.selectedRequest && this.selectedRequest.clientId}` },
        {
          title: 'Requests',
          url: `/app/request?filters[clientId]=${
            this.selectedRequest && this.selectedRequest.clientId ? this.selectedRequest.clientId : ''
          }`,
        },
      ]"
      :main="this.selectedRequest && this.selectedRequest.id"
      :title="requestTitle"
      :user="this.selectedRequest && this.selectedRequest.AffectedTo"
      :isEditingLayout="isEditingLayout"
      :canEditLayout="true"
      @layout-edit-toggle="toggleLayoutEdit"
      @layout-reset="resetLayout"
    >
      <template #dropdown>
        <router-link to="/app/settings/details/settings.conversation" class="dropdown-item"
          ><feather type="settings" size="1.1em" class="mr-1" />{{ $t('app.sidebar.settings-settings') }}</router-link
        >
      </template>
    </Breadcrumbs>
    <div class="container-fluid">
      <div class="text-center" v-if="!selectedRequest">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" fill="var(--primary, ')">
          <circle transform="translate(8 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
          <circle transform="translate(16 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0.3"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
          <circle transform="translate(24 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0.6"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
        </svg>
      </div>
      <div class="email-wrap bookmark-wrap" v-if="selectedRequest">
        <DraggableLayout
          classes="row"
          dragoverBubble="true"
          @change="onLayoutChange"
          v-model="pageLayout"
          @close="toggleLayoutEdit"
          @reset="resetLayout"
          :isEditing="isEditingLayout"
          responsive
        >
          <template slot-scope="{ itemId }">
            <ClientInfoWidget
              v-if="itemId === 'client'"
              :data-key="itemId"
              :key="itemId"
              show-view-button
              show-edit-button
              :show-delete-button="false"
              :client="selectedRequest.Client"
              @view="$router.push({ path: `/app/client/${selectedRequest.clientId}` })"
              @edit="$router.push({ path: `/app/client/${selectedRequest.clientId}/edit` })"
            ></ClientInfoWidget>

            <div
              class="d-none card"
              v-if="selectedRequest.Client && !selectedRequest.Contact && selectedRequest.Client.name"
            >
              <div class="card-body">
                <div class="email-app-sidebar">
                  <div class="media">
                    <div class="media-size-email">
                      <img
                        v-if="selectedRequest.Client.avatarUrl"
                        class="mr-3 rounded-circle"
                        src="selectedRequest.Client.avatarUrl"
                        alt=""
                      />
                      <img v-else class="mr-3 rounded-circle" src="../assets/images/user/user.png" alt="" />
                    </div>
                    <div class="media-body">
                      <h6 class="f-w-600">{{ selectedRequest.Client && selectedRequest.Client.name }}</h6>
                      <p>{{ selectedRequest.Client.company }}</p>
                    </div>
                  </div>
                  <ul class="nav main-menu" role="tablist">
                    <li class="nav-item"></li>
                    <li class="nav-item"><span class="main-title"></span></li>
                    <li>
                      <a href="#pills-created"
                        ><span class="title"> {{ selectedRequest.Client.phonenumber }}</span></a
                      >
                    </li>
                    <li>
                      <a class="show" href="#pills-favourites"
                        ><small class="title"> {{ selectedRequest.Client.email }}</small></a
                      >
                    </li>
                    <li>
                      <a href="pills-shared" aria-selected="false"><small class="title"> 25 demandes</small></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              class="card draggable"
              v-if="selectedRequest.Contact && selectedRequest.Contact.id && itemId === 'requestContact'"
              :data-key="itemId"
              :key="itemId"
            >
              <div class="card-body">
                <div class="email-app-sidebar">
                  <div class="media">
                    <div class="media-size-email">
                      <img
                        v-if="selectedRequest.Contact && selectedRequest.Contact.avatarUrl"
                        class="mr-3 rounded-circle"
                        :src="selectedRequest.Contact.avatarUrl"
                        alt=""
                      />

                      <img v-else class="mr-3 rounded-circle" src="../assets/images/user/user.png" alt="" />
                    </div>
                    <div class="media-body">
                      <h6 class="f-w-600">
                        {{ selectedRequest.Contact.firstName }} {{ selectedRequest.Contact.lastName }}
                      </h6>
                      <p>{{ 'company' }}</p>
                    </div>
                  </div>
                  <ul class="nav main-menu" role="tablist">
                    <li class="nav-item"></li>
                    <li class="nav-item"><span class="main-title"></span></li>
                    <li>
                      <a href="#pills-created"
                        ><span class="title"> {{ selectedRequest.Contact.phonenumber }}</span></a
                      >
                    </li>
                    <li>
                      <a class="show" href="#pills-favourites"
                        ><small class="title"> {{ selectedRequest.Contact.email }}</small></a
                      >
                    </li>
                    <li>
                      <a href="pills-shared" aria-selected="false"><small class="title"> xx demandes</small></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <template v-if="itemId === 'requestSection'">
              <div class="email-body radius-left draggable" :key="'requestSection'">
                <div class="pl-0">
                  <div class="card mb-0">
                    <div class="card-header p-4">
                      <ul class="d-flex pull-right flex-row">
                        <li class="mr-1" v-if="selectedRequest">
                          <MemberSelectorDropdown
                            :value="selectedRequest.affectedToUserId"
                            @change="(id) => affectRequestToUser({ id })"
                            class="d-inline-flex mr-2"
                          />
                        </li>
                        <li class="mr-1">
                          <div class="dropdown">
                            <button
                              class="btn dropdown-toggle btn-sm text-uppercase"
                              :class="{
                                'btn-dark': !requestIsNotClosed,
                                'btn-primary': requestIsNotClosed,
                              }"
                              id="dropdownMenuButton"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              v-on:click="statusDropdownToggle = !statusDropdownToggle"
                            >
                              {{ selectedRequest.status || $t('common.buttons.changeStatus') }}
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                              v-if="requestStatuses && requestStatuses.length"
                              :class="{ show: statusDropdownToggle }"
                            >
                              <a
                                v-for="status in requestStatuses"
                                :key="status.code"
                                class="dropdown-item"
                                @click.prevent="
                                  changeRequestStatus(status.code);
                                  statusDropdownToggle = false;
                                "
                                href="#"
                                >{{ status.label || status.code }}
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <button
                            v-if="requestIsNotClosed && requestIsNotClosed"
                            @click="closeRequest"
                            class="btn btn-secondary btn-sm d-none"
                          >
                            <feather type="lock" size="1rem" class="pull-left mr-1"></feather> Cloturer
                          </button>
                          <button
                            v-if="selectedRequest.archived"
                            @click="reopenRequest"
                            class="btn btn-info btn-sm mr-1"
                          >
                            <feather type="repeat" size="1rem" class="pull-left mr-1"></feather> Réouvrir
                          </button>
                          <button v-if="!selectedRequest.archived" @click="archiveRequest" class="btn bg-dark btn-sm">
                            <feather type="archive" size="1rem" class="pull-left mr-1"></feather> Archiver
                          </button>
                          <button
                            v-if="requestIsNotClosed"
                            class="btn btn-info ml-1 btn-sm"
                            @click="showEditForm = true"
                          >
                            <feather type="edit-3" size="1rem" class="pull-left mr-1"></feather>
                            {{ $t('common.buttons.modify') }}
                          </button>
                        </li>
                      </ul>
                      <h5 class="mb-0 d-inline-block">
                        <EditableText
                          showSaveButton
                          :h4d="selectedRequest.archived"
                          v-model="selectedRequest.title"
                          class=""
                          @change="changeRequestTitle"
                        />
                      </h5>
                      <small class="text-primary ml-2 mt-2">{{ requestCreatedOn }}</small>
                    </div>
                    <div class="card-body p-4">
                      <div class="request-details" style="overflow: auto; width: 100%">
                        <div class="">
                          <div>
                            <small
                              v-html="marked(requestDescription.replace(/\n/gm, '<br/>'), { breaks: true })"
                            ></small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>

              <div class="card draggable" v-if="requestIsNotClosed">
                <div class="card-body p-4">
                  <textarea
                    name=""
                    id="request-detail-answer-input"
                    v-model="newMessageContent"
                    cols="30"
                    rows="10"
                    class="form-control"
                    placeholder="Répondre au client..."
                  ></textarea>
                  <button class="ml-1 btn btn-primary flex-end pull-right mt-1" @click="sendMessage(true)">
                    <feather type="message-square" size="1rem" class="pull-left mr-1 mt-1" v-if="!isSending"></feather>
                    <feather type="loader" animation="spin" class="pull-left mr-1" v-if="isSending"></feather>
                    {{ $t('common.buttons.reply') }}
                  </button>
                  <button class="ml-1 btn btn-info flex-end pull-right mt-1 d-none" @click="sendMessage(true)">
                    <i
                      :class="{
                        'fa fa-paper-plane': !isSending,
                        'fa fa-circle-o-notch fa-spin': isSending,
                      }"
                    ></i>
                    {{ $t('common.buttons.replyByEmail') }}
                  </button>
                </div>
              </div>
              <div class="draggable">
                <template v-if="selectedRequest.Emails && selectedRequest.Emails.length">
                  <ConversationList :messages="sortedMails" />
                </template>
                <div class="card" v-else>
                  <div class="card-header"><h5 class="mb-0 d-inline-block">Emails</h5></div>
                  <div class="card-body"><no-result no-message /></div>
                </div>
              </div>
            </template>
            <TasksWidget
              class="draggable"
              :key="itemId"
              :data-key="itemId"
              v-if="itemId === 'tasks'"
              model="request"
              :modelId="selectedRequest.id"
            />
            <CommentsWidget
              class="draggable"
              :key="itemId"
              :data-key="itemId"
              v-if="itemId === 'comments'"
              model="request"
              :modelId="selectedRequest.id"
              enable-events
            />
            <DocumentsWidget
              class="draggable"
              :key="itemId"
              :data-key="itemId"
              v-if="itemId === 'documents'"
              model="request"
              :modelId="selectedRequest.id"
            ></DocumentsWidget>
            <MetadataWidget
              model="request"
              :modelId="selectedRequest.id"
              :item="selectedRequest"
              :key="itemId"
              v-if="itemId === 'metadata'"
            />
            <CustomFieldValuesWidget
              v-if="itemId === 'custom-field-value'"
              class="draggable"
              model="request"
              :modelId="selectedRequest.id"
              :item="selectedRequest"
              :key="itemId"
            />
          </template>
        </DraggableLayout>
        <div class="row">
          <div class="col-xl-3 box-col-6">
            <div class="email-left-aside">
              <div class="card d-none">
                <div class="card-body">
                  <ul class="nav main-menu" role="tablist">
                    <li class="nav-item">
                      <h6 class="f-w-600">Taches</h6>
                    </li>
                  </ul>
                  <div class="appointment-table table-responsive">
                    <table class="table table-bordernone">
                      <tbody>
                        <tr>
                          <td>
                            <img
                              class="img-fluid img-40 rounded-circle mb-3"
                              src="@/assets/images/appointment/app-ent.jpg"
                              alt="Image description"
                            />
                            <div class="status-circle bg-primary"></div>
                          </td>
                          <td class="img-content-box">
                            <span class="d-block">Mélanie Lauren</span>
                            <span class="font-roboto">Now</span>
                          </td>
                          <td>
                            <p class="m-0 font-primary">28 Sept</p>
                          </td>
                          <td class="text-right">
                            <div class="button btn btn-primary">
                              Done
                              <i class="fa fa-check-circle ml-2"></i>
                            </div>
                          </td>
                        </tr>
                        <tr class="mt-4">
                          <td>
                            <img
                              class="img-fluid img-40 rounded-circle"
                              src="@/assets/images/appointment/app-ent.jpg"
                              alt="Image description"
                            />
                            <div class="status-circle bg-primary"></div>
                          </td>
                          <td class="img-content-box">
                            <span class="d-block">John Loren</span>
                            <span class="font-roboto">11:00</span>
                          </td>
                          <td>
                            <p class="m-0 font-primary">22 Sept</p>
                          </td>
                          <td class="text-right">
                            <div class="button btn btn-danger">
                              Pending
                              <i class="fa fa-check-circle ml-2"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-md-12 box-col-12"></div>
        </div>
      </div>
    </div>
    <awesomeForm
      v-if="showEditForm"
      identity="request"
      :item="selectedRequest"
      :mode="mode"
      :url="`/api/request`"
      @delete="deleteRequest"
      @cancel="hideRequestEditForm()"
      @closeRequested="closeRequestEditForm()"
      :standalone="false"
      :useRouterMode="false"
    ></awesomeForm>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import Swal from 'sweetalert2/dist/sweetalert2';
import dayjs from 'dayjs';
import marked from 'marked';
import _ from 'lodash';

import TasksWidget from '../components/shared/TasksWidget.vue';
import DocumentsWidget from '../components/shared/DocumentsWidget.vue';
import CommentsWidget from '../components/shared/CommentsWidget.vue';
import ClientInfoWidget from '../components/shared/ClientInfoWidget.vue';
import MetadataWidget from '../components/shared/MetadataWidget.vue';
import CustomFieldValuesWidget from '../components/shared/CustomFieldValuesWidget.vue';
import DraggableLayout from '../components/basic/DraggableLayout.vue';
import ConversationList from '../components/shared/ConversationList.vue';

import manageLayoutMixin from '../mixins/manageLayoutMixin';
import avatarMethodsMixin from '../mixins/avatarMethodsMixin';

export default {
  name: 'RequestDetail',
  components: {
    CommentsWidget,
    ClientInfoWidget,
    DocumentsWidget,
    DraggableLayout,
    TasksWidget,
    ConversationList,
    MetadataWidget,
    CustomFieldValuesWidget,
  },
  mixins: [manageLayoutMixin, avatarMethodsMixin],
  data() {
    return {
      newMessageContent: '',
      isSending: false,
      showEditForm: false,
      statusDropdownToggle: false,
      userDropdownToggle: false,
      mode: 'edit',
      requestComments: [],
      requestTaskTitle: '',
      requestTaskUser: null,
      requestTaskDueDate: dayjs().add(1, 'day'),
      requestComment: '',
      isEditingLayout: false,
      defaultPageLayout: [
        {
          id: 'header',
          group: 'layout',
          classes: 'col-12',
          layout: [],
        },
        {
          id: 'left-sidebar',
          group: 'layout',
          classes: 'col-xl-3 box-col-6',
          layout: ['client', 'requestContact', 'tasks', 'comments', 'documents', 'metadata', 'custom-field-value'],
        },
        {
          id: 'main-body',
          group: 'layout',
          displayMode: 'list',
          classes: 'col-xl-9 box-col-6',
          layout: [
            'requestSection',
            // {
            //   id: 'requestSection',
            //   group: 'layout',
            //   classes: 'col-12',
            //   layout: ['request', 'messages', 'replybox'],
            // },
          ],
        },
        {
          id: 'right-sidebar',
          group: 'layout',
          classes: 'd-none',
          layout: [],
        },
        {
          id: 'footer',
          group: 'layout',
          classes: 'col-12',
          layout: [],
        },
      ],
      selectedClientStats: {},
    };
  },
  mounted() {
    this.loadRequest();
    if (!this.requestStatuses || this.requestStatuses.length === 0) {
      this.$store.dispatch('requestStatus/getItems');
    }
  },
  computed: {
    ...mapState('request', ['selectedRequest']),
    ...mapGetters('request', ['requestTitle', 'requestCreatedOn']),
    ...mapState('user', ['user', 'organisation']),
    ...mapGetters('client', ['clientTitle', 'clientAddress']),
    ...mapState({
      requestStatuses: (state) => state.requestStatus.items,
    }),
    sortedMails() {
      return _.orderBy(this.selectedRequest.Emails, ['sentOrReceivedOn'], ['desc']);
    },
    requestModel() {
      const { state } = this.$store;
      const m =
        state.models && Array.isArray(state.models) && state.models.find((model) => model.identity === 'request');
      if (m) {
        m.schema.properties.affectedToUserId.relationUrl = '/api/user';
      }
      return m;
    },

    clientName() {
      return this.selectedRequest && this.selectedRequest.Client
        ? this.selectedRequest.Client.company || this.selectedRequest.Client.name || ''
        : '';
    },
    requestIsNotClosed() {
      return this.selectedRequest && !['done', 'archived'].includes(this.selectedRequest.status);
    },
    requestDescription() {
      return this.selectedRequest && this.selectedRequest.description ? marked(this.selectedRequest.description) : '';
    },
    client() {
      return (this.selectedRequest && this.selectedRequest.Client) || null;
    },
  },
  methods: {
    loadRequest() {
      if (this.$route.params.id && !Number.isNaN(parseInt(this.$route.params.id, 10))) {
        this.$store
          .dispatch('request/getRequest', this.$route.params.id, {})
          .then(() => {
            this.getComments();
          })
          .catch(this.apiErrorCallback);
        this.$store.dispatch('member/getItems', { cache: true });
        this.requestTaskUser = this.user.id;
      }
    },
    marked,
    getImgUrl(path) {
      // eslint-disable-next-line
      return require(`@/assets/images/${path}`);
    },

    async sendMessage(notifyByEmail = false) {
      if (!this.newMessageContent) {
        return;
      }
      this.isSending = true;
      try {
        await this.$http.post(`/api/request/${this.selectedRequest.id}/message`, {
          content: this.newMessageContent,
          notifyByEmail,
        });
        this.newMessageContent = '';
        this.$store.dispatch('request/getRequest', this.selectedRequest.id);
      } catch (err) {
        this.$awNotify(err);
        console.warn('[warnError]', err);
      }
      this.isSending = false;
    },

    changeRequestStatus(status) {
      if (['done'].includes(status)) {
        return this.closeRequest(status);
      }
      this.$store
        .dispatch('request/updateRequest', {
          id: this.selectedRequest.id,
          status,
        })
        .then(() => {
          this.$awNotify('Statut mis à jour ✅');
          this.$store.dispatch('request/getRequest', this.selectedRequest.id);
        });
    },

    affectRequestToUser(user) {
      this.$store
        .dispatch('request/updateRequest', {
          id: this.selectedRequest.id,
          affectedToUserId: user.id,
        })
        .then(() => {
          this.$awNotify('Demande affectée ✅');
          this.$store.dispatch('request/getRequest', this.selectedRequest.id);
        });
    },

    async closeRequest(status) {
      // const { value: targetComment } = await Swal.fire({
      //   title: 'Commentaires',
      //   input: 'textarea',
      //   //  inputLabel: 'ex: demandée traitée | doublon.',
      //   showCancelButton: true,
      //   reverseButtons: true,
      // });
      // if (targetComment) {
      //   await this.$http.post(`/api/request/${this.selectedRequest.id}/comment`, {
      //     model: 'request',
      //     modelId: this.selectedRequest.id,
      //     content: `Fermé par ${this.user.firstName} ${
      //       this.user.lastName
      //     } le ${this.formatDate()}</br>${targetComment}`,
      //   });
      //   this.getComments();
      // }

      this.$store
        .dispatch('request/updateRequest', {
          id: this.selectedRequest.id,
          status: status && typeof status === 'string' ? status : 'done',
        })
        .then(() => {
          this.$awNotify('Requête terminée ! 🎉');
          return this.$store.dispatch('request/getRequest', this.selectedRequest.id);
        });
    },
    async archiveRequest(status) {
      return this.$store
        .dispatch('request/archiveRequest', {
          id: this.selectedRequest.id,
        })
        .then(() => this.$router.push('/app/request'));
    },
    async reopenRequest(status) {
      return this.$store
        .dispatch('request/reopenRequest', {
          id: this.selectedRequest.id,
        })
        .then(() => this.$store.dispatch('request/getRequest', this.selectedRequest.id));
    },

    hideRequestEditForm() {
      this.showEditForm = false;
    },

    showRequestEditForm() {
      this.showEditForm = true;
    },

    closeRequestEditForm() {
      this.$store.dispatch('request/getRequest', this.selectedRequest.id);
      this.showEditForm = false;
    },

    getComments() {
      this.$http.get(`/api/request/${this.selectedRequest.id}/comment`).then(({ data }) => {
        this.requestComments = data.body;
      });
    },

    createComment() {
      if (this.requestComment) {
        this.$http
          .post(`/api/request/${this.selectedRequest.id}/comment`, {
            content: this.requestComment,
          })
          .then(() => {
            this.requestComment = '';
            this.getComments();
          });
      }
    },

    formatDate(date) {
      return dayjs(date).format('DD.MM.YYYY à HH:mm');
    },

    prepareInitials(user) {
      let text = `${user.firstName ? user.firstName.substring(1) : ''}`;
      text += `${user.appName ? user.appName.substring(1) : ''}`;

      return text;
    },

    changeRequestTitle() {
      this.$store
        .dispatch('request/updateRequest', {
          id: this.selectedRequest.id,
          title: this.selectedRequest.title,
        })
        .then(() => {
          this.$store.dispatch('request/getRequest', this.selectedRequest.id);
        });
    },

    deleteRequest() {
      return this.$store
        .dispatch('request/deleteRequest', {
          id: this.selectedItem.id,
        })
        .then(() => this.$router.push('/app/request'));
    },
  },
  watch: {
    $route() {
      this.loadRequest();
    },
  },
};
</script>

<style lang="scss">
.request-details {
  // overflow: auto;
}

.reverse-message {
  text-align: right;
  flex-direction: row-reverse;
}

.task-completed {
  *,
  span {
    text-decoration: line-through;
    color: #999;
  }
}

.comment-section .list-group,
.task-section .list-group {
  img {
    width: 20px;
    height: 20px;
  }

  .comment-content {
    white-space: break-spaces;
  }
}

.vdatetime.form-control {
  .vdatetime-input {
    border: none;
    width: 100%;
  }
}
</style>
