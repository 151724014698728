<template>
  <div>
    <Breadcrumbs
      class=""
      :parents="[
        {
          title: 'Rp',
          url: `/app/recruitment-process?filters[clientId]=${
            this.selectedRecruitmentProcess && this.selectedRecruitmentProcess.clientId
              ? this.selectedRecruitmentProcess.clientId
              : ''
          }`,
        },
      ]"
      :main="this.selectedRecruitmentProcess && this.selectedRecruitmentProcess.id"
      :title="recruitmentProcessTitle"
      :user="this.selectedRecruitmentProcess && this.selectedRecruitmentProcess.AffectedTo"
      :isEditingLayout="isEditingLayout"
      :canEditLayout="false"
      shortMode
      @layout-edit-toggle="toggleLayoutEdit"
      @layout-reset="resetLayout"
    >
      <template #dropdown>
        <router-link to="/app/settings/details/settings.conversation" class="dropdown-item"
          ><feather type="settings" size="1.1em" class="mr-1" />{{ $t('app.sidebar.settings-settings') }}</router-link
        >
      </template>
    </Breadcrumbs>
    <div class="container-fluid">
      <div class="text-center" v-if="!selectedRecruitmentProcess">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" fill="var(--primary, ')">
          <circle transform="translate(8 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
          <circle transform="translate(16 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0.3"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
          <circle transform="translate(24 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0.6"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
        </svg>
      </div>
      <div class="email-wrap bookmark-wrap" v-if="selectedRecruitmentProcess">
        <DraggableLayout
          classes="row"
          dragoverBubble="true"
          @change="onLayoutChange"
          v-model="pageLayout"
          @close="toggleLayoutEdit"
          @reset="resetLayout"
          :isEditing="isEditingLayout"
          responsive
        >
          <template slot-scope="{ itemId }">
            <template v-if="itemId === 'recruitmentProcess'">
              <div class="email-body radius-left draggable" :key="'candidateSection'">
                <div class="pl-0">
                  <div class="card mb-0">
                    <div class="card-header p-4">
                      <ul class="d-flex pull-right flex-row">
                        <li class="mr-1" v-if="selectedRecruitmentProcess">
                          <MemberSelectorDropdown
                            :value="selectedRecruitmentProcess.recruiterId"
                            @change="(id) => affectaffectCandidateToUseroUser({ id })"
                            class="d-inline-flex mr-2"
                          />
                        </li>
                        <li class="mr-1">
                          <div class="dropdown">
                            <button
                              class="btn dropdown-toggle btn-sm text-uppercase"
                              :class="{
                                'btn-dark': !recruitmentProcessIsNotClosed,
                                'btn-primary': recruitmentProcessIsNotClosed,
                              }"
                              id="dropdownMenuButton"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              v-on:click="statusDropdownToggle = !statusDropdownToggle"
                            >
                              {{ selectedRecruitmentProcess.stage || $t('common.buttons.changeStatus') }}
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                              v-if="recruitmentStages && recruitmentStages.length"
                              :class="{ show: statusDropdownToggle }"
                            >
                              <a
                                v-for="status in recruitmentStages"
                                :key="status.code"
                                class="dropdown-item"
                                @click.prevent="changeRecruitmentStage(status)"
                                href="#"
                                >{{ status.label || status.code }}
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <button
                            v-if="recruitmentProcessIsNotClosed"
                            class="btn btn-info ml-1 btn-sm"
                            @click="showEditForm = true"
                          >
                            <feather type="edit-3" size="1rem" class="pull-left mr-1"></feather>
                            {{ $t('common.buttons.modify') }}
                          </button>
                        </li>
                      </ul>
                      <h5 class="mb-0 d-inline-block">{{ recruitmentProcessTitle }}</h5>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </template>

            <template v-if="itemId === 'general'">
              <div class="draggable" :key="itemId">
                <div class="card">
                  <div class="card-body p-4">
                    <div class="row">
                      <div class="col-5">
                        <div class="media m-r-15">
                          <UserAvatar
                            v-if="!selectedRecruitmentProcess.Candidate.avatarUrl"
                            :user="selectedRecruitmentProcess.Candidate"
                            :styles="{
                              fontSize: '1rem',
                              width: '40px',
                              height: '40px',
                              lineHeight: 1.8,
                              fontWeight: '300',
                            }"
                          ></UserAvatar>
                          <div class="media-body">
                            <span
                              >{{ selectedRecruitmentProcess.Candidate.firstName }}
                              {{ selectedRecruitmentProcess.Candidate.lastName }}</span
                            >
                            <div class="font-roboto font-weight-light">
                              {{ selectedRecruitmentProcess.Candidate.email }}
                            </div>
                            <div class="font-roboto font-weight-light">
                              {{ selectedRecruitmentProcess.Candidate.phonenumber }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <span class="fw-400">{{ selectedRecruitmentProcess.Candidate.currentPosition }}</span>
                        <template
                          v-if="
                            selectedRecruitmentProcess.Candidate.currentPosition &&
                            selectedRecruitmentProcess.Candidate.currentCompany
                          "
                        >
                          @
                        </template>
                        <span class="font-weight-light">{{
                          selectedRecruitmentProcess.Candidate.currentCompany
                        }}</span>
                        <a
                          target="_blank"
                          :href="selectedRecruitmentProcess.Candidate.linkedin"
                          :data-tooltip="selectedRecruitmentProcess.Candidate.linkedin"
                          data-tooltip-position="left"
                          class="text-info"
                          v-if="selectedRecruitmentProcess.Candidate.linkedin"
                        >
                          <feather type="linkedin" size="15"></feather>
                        </a>
                      </div>
                      <div class="col-3">
                        <router-link
                          :to="`/app/candidate/${selectedRecruitmentProcess.Candidate.id}`"
                          class="btn child-edit btn-transparent btn-simple mr-0 p-0 pull-right"
                        >
                          <feather type="eye" class="text-primary" size="14"></feather>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="text-bold">{{ $t('app.models.position') }}</h5>
                <div class="card">
                  <div class="card-header pb-0">
                    {{ selectedRecruitmentProcess.Position.title }}
                    <router-link
                      :to="`/app/position/${selectedRecruitmentProcess.Position.id}`"
                      class="btn child-edit btn-transparent btn-simple mr-1 p-0 pull-right"
                    >
                      <feather type="eye" class="text-primary" size="14"></feather>
                    </router-link>
                  </div>
                  <div class="card-body p-4">
                    <span class="badge badge-info">{{ selectedRecruitmentProcess.Position.workMode }}</span>
                    <span class="badge badge-info">{{ selectedRecruitmentProcess.Position.location }}</span>
                    <span class="badge badge-info">{{ selectedRecruitmentProcess.Position.contractType }}</span>
                    <span class="badge badge-info">{{ selectedRecruitmentProcess.Position.minSalary || '' }}</span>
                    <span class="badge badge-info">{{ selectedRecruitmentProcess.Position.maxSalary || '' }}</span>
                  </div>
                </div>
                <h5 class="">{{ $t('app.models.client') }}</h5>
                <div class="card" v-if="selectedRecruitmentProcess.Client">
                  <div class="card-body p-4">
                    <div class="row">
                      <div class="col-5">
                        <div class="media m-r-15">
                          <UserAvatar
                            :user="selectedRecruitmentProcess.Client"
                            :styles="{
                              fontSize: '1rem',
                              width: '40px',
                              height: '40px',
                              lineHeight: 1.8,
                              fontWeight: '300',
                            }"
                          ></UserAvatar>
                          <div class="ml-4">
                            <span>{{ clientName }}</span>
                            <div class="font-roboto font-weight-light">
                              {{ selectedRecruitmentProcess.Client.email }}
                            </div>
                            <div class="font-roboto font-weight-light">
                              {{ selectedRecruitmentProcess.Client.phonenumber }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <span class="fw-400">{{ selectedRecruitmentProcess.Candidate.currentPosition }}</span>
                        <template
                          v-if="
                            selectedRecruitmentProcess.Candidate.currentPosition &&
                            selectedRecruitmentProcess.Candidate.currentCompany
                          "
                        >
                          @
                        </template>
                        <a
                          target="_blank"
                          :href="selectedRecruitmentProcess.Client.websiteUrl"
                          :data-tooltip="selectedRecruitmentProcess.Client.websiteUrl"
                          data-tooltip-position="left"
                          class="text-info"
                          v-if="selectedRecruitmentProcess.Client.websiteUrl"
                        >
                          <feather
                            type="globe"
                            size="15"
                            :class="selectedRecruitmentProcess.Client.websiteUrl ? 'text-info' : 'text-dark'"
                          ></feather>
                        </a>
                        <a
                          target="_blank"
                          :href="selectedRecruitmentProcess.Client.linkedin"
                          :data-tooltip="selectedRecruitmentProcess.Client.linkedin"
                          data-tooltip-position="left"
                          class="text-info"
                          v-if="selectedRecruitmentProcess.Client.linkedin"
                        >
                          <feather
                            type="linkedin"
                            size="15"
                            :class="selectedRecruitmentProcess.Client.linkedin ? 'text-info' : 'text-dark'"
                          ></feather>
                        </a>
                      </div>
                      <div class="col-3">
                        <router-link
                          :to="`/app/client/${selectedRecruitmentProcess.Client.id}`"
                          class="btn child-edit btn-transparent btn-simple mr-0 p-0 pull-right"
                        >
                          <feather type="eye" class="text-primary" size="14"></feather>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="draggable" v-if="itemId === 'interviews'" :key="itemId">
              <h5 class="text-bold">{{ $t('draggableLayout.tabs.interviews') }}</h5>
              <AwesomeCrud
                class="interview-list-card card p-2"
                identity="interview"
                :apiQueryParams="{ filters: { recruitmentProcessId: $route.params.id } }"
                :apiRequestPermanentBodyParams="{ recruitmentProcessId: $route.params.id }"
                :useRouterMode="false"
                detailPageMode="modal"
                :title="false"
                :formOptions="{
                  displayedFields: ['location', 'date', 'completed', 'result', 'interviewNotes'],
                }"
                :listOptions="interviewListOptions"
                :enabledListingModes="['list']"
                :actions="{
                  create: true,
                  read: true,
                  update: true,
                  delete: true,
                }"
              ></AwesomeCrud>
            </div>

            <TasksWidget
              class="draggable"
              :key="itemId"
              :data-key="itemId"
              v-if="itemId === 'tasks'"
              model="recruitmentProcess"
              :modelId="selectedRecruitmentProcess.id"
              :disabled="!recruitmentProcessIsNotClosed"
            />
            <CommentsWidget
              class="draggable"
              :key="itemId"
              :data-key="itemId"
              v-if="itemId === 'comments'"
              model="recruitmentProcess"
              :modelId="selectedRecruitmentProcess.id"
              :disabled="!recruitmentProcessIsNotClosed"
              enable-events
            />
            <DocumentsWidget
              class="draggable"
              :key="itemId"
              :data-key="itemId"
              v-if="itemId === 'documents'"
              model="recruitmentProcess"
              :modelId="selectedRecruitmentProcess.id"
              :disabled="!recruitmentProcessIsNotClosed"
            ></DocumentsWidget>
            <MetadataWidget
              model="recruitmentProcess"
              :item="selectedRecruitmentProcess"
              :modelId="selectedRecruitmentProcess.id"
              :key="itemId"
              v-if="itemId === 'metadata'"
            />
            <CustomFieldValuesWidget
              v-if="itemId === 'custom-field-value'"
              model="recruitmentProcess"
              :modelId="selectedRecruitmentProcess.id"
              :item="selectedRecruitmentProcess"
              :key="itemId"
            />
          </template>
        </DraggableLayout>
      </div>
    </div>
    <awesomeForm
      v-if="showEditForm"
      identity="recruitmentProcess"
      :item="selectedRecruitmentProcess"
      :mode="mode"
      :url="`/api/recruitment-process`"
      @delete="deleteRecruitmentProcess"
      @cancel="hideCandidateEditForm()"
      @closeCandidateed="closeCandidateEditForm()"
      :standalone="false"
      :useRouterMode="false"
    ></awesomeForm>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Swal from 'sweetalert2/dist/sweetalert2';
import dayjs from 'dayjs';
import marked from 'marked';
import _ from 'lodash';
import { AwesomeCrud } from 'vue-aw-components';

import TasksWidget from '../components/shared/TasksWidget.vue';
import DocumentsWidget from '../components/shared/DocumentsWidget.vue';
import CommentsWidget from '../components/shared/CommentsWidget.vue';
import MetadataWidget from '../components/shared/MetadataWidget.vue';
import DraggableLayout from '../components/basic/DraggableLayout.vue';

import manageLayoutMixin from '../mixins/manageLayoutMixin';
import avatarMethodsMixin from '../mixins/avatarMethodsMixin';
import CustomFieldValuesWidget from '../components/shared/CustomFieldValuesWidget.vue';

export default {
  name: 'recruitmentProcessDetail',
  components: {
    AwesomeCrud,
    CommentsWidget,
    DocumentsWidget,
    DraggableLayout,
    TasksWidget,
    MetadataWidget,
    CustomFieldValuesWidget,
  },
  mixins: [manageLayoutMixin, avatarMethodsMixin],
  data() {
    return {
      newMessageContent: '',
      isSending: false,
      showEditForm: false,
      statusDropdownToggle: false,
      userDropdownToggle: false,
      mode: 'edit',
      candidateComments: [],
      candidateTaskTitle: '',
      candidateTaskUser: null,
      candidateTaskDueDate: dayjs().add(1, 'day'),
      candidateComment: '',
      isEditingLayout: false,
      defaultPageLayout: [
        {
          id: 'header',
          group: 'layout',
          classes: 'col-12',
          layout: [],
        },
        {
          id: 'left-sidebar',
          group: 'layout',
          classes: 'col-xl-3 box-col-6',
          layout: ['tasks', 'comments', 'documents', 'metadata', 'custom-field-value'],
        },
        {
          id: 'main-body',
          group: 'layout',
          classes: 'col-xl-9 box-col-6',
          layout: [{ id: 'recruitmentProcess', displayMode: 'list', layout: ['general', 'interviews'] }],
        },
        {
          id: 'right-sidebar',
          group: 'layout',
          classes: 'd-none',
          layout: [],
        },
        {
          id: 'footer',
          group: 'layout',
          classes: 'col-12',
          layout: [],
        },
      ],
      selectedClientStats: {},
      interviewListOptions: {
        displayedFields: ['location', 'date', 'completed', 'result', 'interviewNotes'],
      },
    };
  },
  mounted() {
    this.loadRecruitmentProcess();
    if (!this.recruitmentStages || this.recruitmentStages.length === 0) {
      this.$store.dispatch('recruitmentStage/getItems');
    }
  },
  computed: {
    ...mapState('user', ['user', 'organisation']),
    ...mapState({
      recruitmentStages: (state) => state.recruitmentStage.items,
    }),
    ...mapState({
      selectedRecruitmentProcess: (state) => state.recruitmentProcess?.selectedItem,
    }),
    sortedMails() {
      return _.orderBy(this.selectedRecruitmentProcess.Emails, ['sentOrReceivedOn'], ['desc']);
    },
    recruitmentProcessModel() {
      const { state } = this.$store;
      const m =
        state.models &&
        Array.isArray(state.models) &&
        state.models.find((model) => model.identity === 'recruitmentProcess');
      if (m) {
        m.schema.properties.affectedToUserId.relationUrl = '/api/user';
      }
      return m;
    },

    clientName() {
      return this.selectedRecruitmentProcess && this.selectedRecruitmentProcess.Client
        ? this.selectedRecruitmentProcess.Client.company || this.selectedRecruitmentProcess.Client.name || ''
        : '';
    },

    candidateFullName() {
      return this.selectedRecruitmentProcess && this.selectedRecruitmentProcess.Candidate
        ? `${this.selectedRecruitmentProcess.Candidate.firstName} ${this.selectedRecruitmentProcess.Candidate.lastName}`
        : '';
    },

    recruitmentProcessTitle() {
      let title = this.candidateFullName;
      if (this.selectedRecruitmentProcess && this.selectedRecruitmentProcess.Position) {
        title += ` ✨ ${this.selectedRecruitmentProcess.Position.title}`;
      }
      if (this.clientName) {
        title += ` @ ${this.clientName}`;
      }
      return title;
    },

    recruitmentProcessIsNotClosed() {
      return this.selectedRecruitmentProcess && !['done', 'archived'].includes(this.selectedRecruitmentProcess.status);
    },
    candidateDescription() {
      return this.selectedRecruitmentProcess && this.selectedRecruitmentProcess.description
        ? marked(this.selectedRecruitmentProcess.description)
        : '';
    },
    client() {
      return (this.selectedRecruitmentProcess && this.selectedRecruitmentProcess.Client) || null;
    },
  },
  methods: {
    loadRecruitmentProcess() {
      if (this.$route.params.id && !Number.isNaN(parseInt(this.$route.params.id, 10))) {
        this.$store
          .dispatch('recruitmentProcess/getItem', this.$route.params.id, {})
          .then(() => {
            this.getComments();
          })
          .catch(this.apiErrorCallback);
        this.$store.dispatch('member/getItems', { cache: true });
        this.candidateTaskUser = this.user.id;
      }
    },
    marked,
    getImgUrl(path) {
      // eslint-disable-next-line
      return require(`@/assets/images/${path}`);
    },

    async sendMessage(notifyByEmail = false) {
      if (!this.newMessageContent) {
        return;
      }
      this.isSending = true;
      try {
        await this.$http.post(`/api/candidate/${this.selectedRecruitmentProcess.id}/message`, {
          content: this.newMessageContent,
          notifyByEmail,
        });
        this.newMessageContent = '';
        this.$store.dispatch('candidate/getCandidate', this.selectedRecruitmentProcess.id);
      } catch (err) {
        this.$awNotify(err);
        console.warn('[warnError]', err);
      }
      this.isSending = false;
    },

    changeStatus(status) {
      this.$store
        .dispatch('recruitmentProcess/updateItem', {
          id: this.selectedRecruitmentProcess.id,
          ...this.selectedRecruitmentProcess,
          status,
        })
        .then(() => {
          this.$awNotify('Statut mis à jour ✅');
          this.loadRecruitmentProcess();
        });
    },

    affectaffectCandidateToUseroUser(user) {
      this.$store
        .dispatch('recruitmentProcess/updateItem', {
          id: this.selectedRecruitmentProcess.id,
          ...this.selectedRecruitmentProcess,
          recruiterId: user.id,
        })
        .then(() => {
          this.$awNotify('Process affecté ✅');
          this.loadRecruitmentProcess();
        });
    },

    hideCandidateEditForm() {
      this.showEditForm = false;
    },

    showCandidateEditForm() {
      this.showEditForm = true;
    },

    closeCandidateEditForm() {
      this.loadRecruitmentProcess();
      this.showEditForm = false;
    },

    getComments() {
      this.$http.get(`/api/recruitmentProcess/${this.selectedRecruitmentProcess.id}/comment`).then(({ data }) => {
        this.candidateComments = data.body;
      });
    },

    createComment() {
      if (this.candidateComment) {
        this.$http
          .post(`/api/candidate/${this.selectedRecruitmentProcess.id}/comment`, {
            content: this.candidateComment,
          })
          .then(() => {
            this.candidateComment = '';
            this.getComments();
          });
      }
    },

    formatDate(date) {
      return dayjs(date).format('DD.MM.YYYY à HH:mm');
    },

    deleteRecruitmentProcess() {
      return this.$store
        .dispatch('recruitmentProcess/deleteItem', {
          id: this.selectedItem.id,
        })
        .then(() => this.$router.push('/app/recruitment-process'));
    },

    onChangeRecruitmentStage(status) {
      this.statusDropdownToggle = false;
      this.changeStatus(status.code);
    },
  },
  watch: {
    $route() {
      this.loadRecruitmentProcess();
    },
  },
};
</script>

<style lang="scss">
.candidate-details {
  // overflow: auto;
}

.reverse-message {
  text-align: right;
  flex-direction: row-reverse;
}

.task-completed {
  *,
  span {
    text-decoration: line-through;
    color: #999;
  }
}

.comment-section .list-group,
.task-section .list-group {
  img {
    width: 20px;
    height: 20px;
  }

  .comment-content {
    white-space: break-spaces;
  }
}

.vdatetime.form-control {
  .vdatetime-input {
    border: none;
    width: 100%;
  }
}
.interview-list-card .aw-crud-listing-mode-list .aw-list-card > .card-header,
.interview-list-card .card-header {
  box-shadow: none !important;
}
</style>
