<template>
  <div class="segment-sidebar">
    <h4 class="list-item text-dark pt-2 fw-300">
      {{ $t('common.labels.segmentViews') }}
      <button class="btn-close btn btn-transparent" @click="$emit('close')" data-tooltip="Close">
        <feather type="x" class="" size="1.2em"></feather>
      </button>
      <button class="btn btn-transparent p-0 pt-0 mr-4 float-right" @click="createSegment" data-tooltip="Save">
        <feather type="plus-square" class="text-primary" size="1.2em"></feather>
      </button>
    </h4>
    <hr />
    <ul class="sidebar-links">
      <li
        class="btn p-0 fw-300 btn-transparent w-100 text-left mb-2 display-on-hover-parent"
        :key="-1"
        :class="selectedSegmentId == -1 ? 'text-dark bg-light-primary' : 'text-info'"
        @click="onSegmentClick(-1)"
      >
        <router-link
          :to="{
            name: $route.name,
            query: { segmentId: -1 },
          }"
          class="px-1 pt-3 pb-4"
          :class="selectedSegmentId == -1 ? 'text-dark' : 'text-dark'"
          router-link-exact-active
        >
          <i
            :class="{
              'text-light-primary': selectedSegmentId == -1,
            }"
            class="fa fa-home"
          ></i>
          <span> {{ $t('common.buttons.reset') }}</span>
        </router-link>
      </li>
      <li
        class="btn p-0 fw-300 btn-transparent w-100 text-left mb-2 display-on-hover-parent"
        v-for="segment in items"
        :key="segment.id"
        :class="selectedSegmentId == segment.id ? 'text-dark bg-light-primary' : 'text-info'"
        @click="onSegmentClick(segment.id)"
      >
        <router-link
          :to="{
            name: $route.name,
            query: { ...segment.query, segmentId: segment.id },
          }"
          class="px-1 pt-3 pb-4"
          :class="selectedSegmentId == segment.id ? 'text-dark' : 'text-dark'"
          router-link-exact-active
        >
          <i
            :class="{
              'text-light-primary': selectedSegmentId == segment.id,
              'fa-list': segment.uiState.displayMode === 'list',
              'fa-columns': segment.uiState.displayMode === 'kanban',
              'fa-table': segment.uiState.displayMode === 'table',
              'fa-angle-right': !segment.uiState.displayMode,
            }"
            class="fa"
          ></i>
          <span> {{ segment.name }}</span>
          <feather
            type="trash-2"
            size="16"
            class="ml-2 pull-right text-danger display-on-hover"
            @click.stop.prevent="deleteSegment(segment.id)"
          />
          <feather
            type="save"
            size="16"
            class="ml-2 pull-right text-info display-on-hover"
            @click.stop.prevent="updateSegment(segment.id)"
          />
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import promptUser from '../../services/promptUser';

export default {
  name: 'SegmentSidebar',
  props: {
    identity: String,
    model: Object,
    query: Object,
    uiState: Object,
    useRouterMode: { type: Boolean, default: true },
    enableSidebar: Boolean,
    title: String,
    value: [String, Number, Object],
    selectedSegmentId: [String, Number],
    storePath: { type: String, default: 'state.models' },
  },
  components: {},
  mixins: {},
  computed: {
    ...mapState('user', ['user', 'organisation']),
    ...mapState('displaySegment', ['items']),
    canShowSidebarItems() {
      return this.enableSidebar && this.$route.params.id && this.$route.params.id !== 'new';
    },
    useRouterModeComputed() {
      if (this.useRouterMode !== undefined) {
        return this.useRouterMode;
      }
      if (this.model?.useRouterMode !== undefined) {
        return this.model.useRouterMode;
      }
      return true;
    },
    isMobile() {
      return window.outerWidth < 992;
    },
    selectedSegmentComputed() {
      return this.items?.find((item) => `${item.id}` === `${this.selectedSegmentId}`);
    },
  },
  watch: {
    selectedSegmentComputed(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.$emit('input', this.selectedSegmentComputed);
      }
    },
  },
  data() {
    return {
      apiQueryParams: { filters: {} },
      apiRequestPermanentBodyParams: {},
      renderTimestamp: Date.now(),
    };
  },
  mounted() {
    this.getSegments();
    this.apiQueryParams = { filters: {} };
    if (this.$awEventBus) {
      // this is for the template section
    }
    //   this.registerscrolllistener();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.useRouterMode && to.query.filters) {
        vm.apiQueryParams.filters = to.query.filters;
      }
    });
  },

  beforeRouteUpdate(to, from, next) {
    next();
  },
  beforeDestroy() {
    this.$awEventBus.$off('itemEdited', this.clearLabelsCache);
    window.removeEventListener('scroll', this.onScrollEvent);
  },
  destroyed() {},

  methods: {
    refreshResults() {
      this.$refs.awesomeCrud.refreshLocalData();
    },

    createSegment() {
      promptUser('Nom du segment').then((result) => {
        if (result.isConfirmed) {
          const name = result.value;
          this.$store
            .dispatch('displaySegment/createItem', {
              name,
              model: this.model.identity,
              query: { ...this.query, page: 0 },
              uiState: this.uiState,
            })
            .then(() => this.getSegments());
        }
      });
    },

    async updateSegment(id) {
      this.$store
        .dispatch('displaySegment/updateItem', {
          id,
          query: this.query,
          uiState: this.uiState,
        })
        .then(() => {
          this.$awNotify('Segment mis à jour', 'success');
          this.getSegments();
        });
    },

    async deleteSegment(id) {
      const isConfirmed = await this.$awConfirm(this.$t('common.messages.are_you_sure'));
      if (isConfirmed) {
        this.$store.dispatch('displaySegment/deleteItem', id).then(() => this.getSegments());
      }
    },

    onSegmentClick(id) {
      if (this.isMobile) {
        this.$emit('close', id);
      }
    },

    getSegments() {
      return this.$store.dispatch('displaySegment/getItems', {
        params: {
          filters: { model: this.model?.identity },
        },
      });
    },
  },
};
</script>

<style lang="scss">
.segment-section {
  top: 40px;
  left: 7px;
  height: calc(100vh - 120px);
  box-shadow: 0 0 20px rgba(8, 21, 66, 0.1);
  border-radius: 15px;

  .segment-sidebar {
    position: relative;

    .btn-close {
      position: absolute;
      top: 8px;
      right: 0px;
      padding: 0;
    }
  }
}
</style>
