<template>
  <div v-click-outside="onclickOutside" class="mb-1 editable-text" :class="isEditable ? 'is-editing' : ''">
    <template v-if="isEditable">
      <!-- input text -->
      <!-- input number -->
      <template v-if="['text', 'number', 'email', 'checkbox', 'date', 'select', 'number', 'url'].includes(type)">
        <input
          ref="input"
          v-model="modelvalue"
          :class="'live-edit ' + editClass"
          :type="type"
          :placeholder="placeholder"
          @keyup.enter="saveEdit"
          :disabled="disabled"
          @onfocus="focused = true"
          @keypress="extendField($event)"
          @blur="focused = false"
        />
      </template>
      <!-- select -->
      <template v-if="type === 'select'">
        <select
          v-model="modelvalue"
          :class="'live-edit ' + editClass"
          :placeholder="placeholder"
          :multiple="selectOptions.multiple"
          @onfocus="focused = true"
          @blur="focused = false"
        >
          <option v-for="(option, index) of _selectValues" :key="index" :value="option.value">
            {{ option.label }}
          </option>
        </select>
      </template>
      <!-- textarea -->
      <template v-if="type === 'textarea'">
        <textarea
          v-model="modelvalue"
          :class="'live-edit form-control ' + editClass"
          type="text"
          :placeholder="placeholder"
          @onfocus="focused = true"
          @blur="focused = false"
        />
      </template>
      <button v-if="showSaveButton" class="editable-text-btn" title="Save" @click.stop="saveEdit()">
        <feather type="save" size="0.9rem"></feather>
      </button>
      <button v-if="showCancelButton" title="Clear / reset" class="editable-text-btn" @click.stop="clearEdit()">
        <feather type="x-square" size="0.9rem"></feather>
      </button>
    </template>
    <template v-else>
      <slot :value="value" :onClick="onClick">
        <span @click.stop="onClick" v-html="modelvalue || placeholder" class="pointer" />
        <button v-if="showEditButton" title="Edit" class="btn-edit editable-text-btn" @click.stop="openEditor">
          <feather type="edit-3" size="0.9rem"></feather>
        </button>
      </slot>
    </template>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'EditableText',
  directives: { ClickOutside },
  props: {
    value: {
      type: [Number, String, Object],
      required: false,
    },
    type: {
      type: String,
      default: 'text',
      values: ['text', 'number', 'email', 'checkbox', 'date', 'select', 'number', 'textarea', 'url'],
    },
    editClass: {
      type: String,
      default: '',
    },

    selectValues: {
      type: Array,
      default() {
        return [];
      },
    },
    selectOptions: {
      type: Object,
      default() {
        return { value: 'value', label: 'label', multiple: false };
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    editOnClick: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHtml: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSaveButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isEditable: false,
      focused: false,
      originalValue: this.value,
      modelvalue: this.value,
    };
  },
  computed: {
    _selectValues() {
      if (this.selectValues) {
        return this.selectValues.map((val) => {
          if (typeof val === 'object') {
            return {
              value: val[this.selectOptions.value],
              label: val[this.selectOptions.label],
            };
          }
          return {
            value: val,
            label: val,
          };
        });
      }

      return [];
    },
  },
  watch: {
    modelvalue(val) {
      if (!this.showSaveButton) {
        this.$emit('input', val);
      }
    },
    value(val, oldVal) {
      if (val !== oldVal) {
        this.modelvalue = val;
      }
    },
  },
  methods: {
    saveEdit() {
      this.$emit('input', this.modelvalue);
      this.$emit('change', this.modelvalue);
      this.closeEditor();
    },
    clearEdit() {
      this.closeEditor();
      this.modelvalue = this.originalValue;
      this.$emit('change', this.modelvalue);
    },
    onClick() {
      if (this.editOnClick && !this.disabled) {
        this.openEditor();
      }
    },
    openEditor() {
      this.isEditable = true;
      // the dom needs a few ms to dislay the input, before we can focus it
      setTimeout(() => {
        if (this.$refs.input) {
          this.$refs.input.focus();
          this.extendField({ target: { value: this.modelvalue } });
        }
      }, 100);
    },
    closeEditor() {
      if (this.$refs.input) {
        this.$refs.input.blur();
      }
      this.isEditable = false;
      this.$forceUpdate();
    },
    onclickOutside() {
      if (!this.focused) {
        return;
      }
      // If the edit button is not shown, then we need to save the value on click outside
      if (this.showSaveButton) {
        this.clearEdit();
      } else {
        this.saveEdit();
      }
    },

    extendField($event) {
      if (!this.$refs.input.value) {
        return;
      }
      let len = this.type === 'number' ? `${this.$refs.input.value}`.length : this.$refs.input.value.length;
      if (len < 10) {
        len = 10;
      }

      this.$refs.input.style.minWidth = `${len + 1}ch`;
    },
  },
};
</script>
<style scoped>
.live-edit {
  font-size: 1em !important;
}

.editable-text-btn {
  border: none;
  background: transparent;
  padding: 1px;
}
.btn-edit {
  display: none;
}
.editable-text:hover .btn-edit {
  display: inline-block;
}
</style>
