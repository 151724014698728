export default {
  methods: {
    prepareInitials(item) {
      if (!item) {
        return '';
      }
      let text = `${item.firstName ? item.firstName.substring(0, 1) : ''}`;
      text += `${item.lastName ? item.lastName.substring(0, 1) : ''}`;

      if (item.name) {
        text = `${item.name.substring(2) || ''}`;
      }
      return text;
    },

    getUserInitials(user) {
      if (!user) {
        return '';
      }
      let userTitle = user === 'string' ? user : `${user.firstName || ''} ${user.lastName || ''}`;
      userTitle = userTitle.trim();
      if (!userTitle && user.name) {
        userTitle = user.name;
      }
      if (!userTitle && user.title) {
        userTitle = user.title;
      }
      if (!userTitle) {
        return '';
      }
      if (userTitle.includes(' ')) {
        return userTitle
          .trim()
          .split(' ')
          .map((str) => str.slice(0, 1))
          .join('')
          .substring(0, 2);
      }
      return userTitle.substring(0, 2);
    },
  },
};
