<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-no-border">
              <h3>
                Agenda
                <button class="btn btn-icon">
                  <i class="fa fa-refresh text-muted f-14" @click="getItems()"></i>
                </button>
              </h3>
              <div class="card-header-right d-flex justify-content-between">
                <select name="" id="" class="form-control" v-model="calendar_settings.view_type">
                  <option>day</option>
                  <option>week</option>
                </select>
                <select name="" id="" class="form-control ml-2" v-model="taskStatus" @change="getItems()">
                  <option selected disabled>Task Status</option>
                  <option value="">---</option>
                  <option value="todo">Todo</option>
                  <option value="doing">Doing</option>
                  <option value="done">Done</option>
                </select>
              </div>
            </div>
            <div class="card-body pt-0">
              <kalendar
                :configuration="calendar_settings"
                :events="events"
                :key="calendar_settings.view_type + events.length"
                @day-changed="currentDay = $event"
                @week-changed="currentWeek = $event"
              >
                <template #created-card="{ event_information }">
                  <div
                    class="pointer"
                    @click="onEventClick(event_information)"
                    :style="{ 'background-color': event_information.data.backgroundColor }"
                  >
                    <h4 style="margin-bottom: 5px">{{ event_information.data.title }}</h4>
                    <small>
                      {{ event_information.start_time.substr(11, 5) }} - {{ event_information.end_time.substr(11, 5)
                      }}<br />
                      {{ event_information.data.label }}
                    </small>
                  </div>
                </template>
              </kalendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { Kalendar } from 'kalendar-vue';
import dayjs from 'dayjs';
import { mapState } from 'vuex';

export default {
  name: 'Calendar',
  components: { Kalendar },
  computed: {
    ...mapState('request', ['requests']),
    ...mapState('user', ['user', 'organisation']),
    ...mapState('task', { tasks: (state) => state.items }),

    events () {
      if (!this.tasks || !this.tasks.length) {
        return [];
      }
      return this.tasks
        .filter((task) => task.dueDate)
        .map((task, index) => ({
          // from: dayjs(task.dueDate).subtract(15, 'min'),
          from: this.formatStartDate(task.dueDate, task.dueTime),
          to: this.add30min(this.formatStartDate(task.dueDate, task.dueTime)),
          data: {
            title: task.content,
            label: task.modelData
              ? task.modelData?.name || task.modelData?.title || task.modelData?.firstName
              : task.Affectation?.username,
            ModelData: task,
            backgroundColor: 'var(--theme-info)',
          },
          id: task.id,
        }));
    },
  },
  data: () => ({
    eventsExample: [
      {
        data: 'Déclaration de TVA  Mr Denat',
        from: '2021-04-09T10:00:00',
        to: '2021-04-10T12:00:00',
      },
    ],
    calendar_settings: {
      style: 'material_design',
      cell_height: 10,
      scrollToNow: true,
      current_day: new Date(),
      read_only: false,
      day_starts_at: 6,
      day_ends_at: 24,
      overlap: true,
      hide_dates: [], // Spooky
      hide_days: [],
      past_event_creation: false,
      view_type: 'week',
    },
    new_appointment: {
      title: null,
      description: null,
    },
    currentDay: null,
    currentWeek: null,
    taskStatus: undefined,
  }),
  mounted () {
    this.getItems();
  },
  methods: {
    goToClientDetail (id) {
      this.$router.push(`/app/client/${id}`);
    },
    formatStartDate (date, time) {
      const dateString = typeof date === 'string' ? date : date.toISOString();
      if (dateString.length > 10) {
        return dateString;
      }
      if (time) {
        return `${dateString}T${time}Z`;
      }
      return `${dateString}T08:00:00Z`;
    },
    add30min (date) {
      return dayjs(date).add(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
    },
    onEventClick (event) {
      if (!event.data.ModelData) {
        return;
      }
      const modelType = event.data.ModelData?.model;
      // console.log(event.data.ModelData, modelType);
      switch (modelType) {
      case 'request':
        this.$router.push(`/app/request/${event.data.ModelData?.modelData.id}`);
        break;
      case 'lead':
        this.$router.push(`/app/lead/${event.data.ModelData?.modelData.id}`);
        break;
      default:
      case 'task':
        this.$router.push(`/app/taskboard?taskId=${event.data.ModelData?.id}`);
        break;
      }
    },

    getItems () {
      this.$store.dispatch('task/getItems', {
        save: true,
        params: {
          perPage: 200,
          filters: {
            status: this.taskStatus
              ? this.taskStatus
              : {
                $ne: 'done',
              },
          },
        },
      });
      this.$store.dispatch('request/getRequests', {
        save: true,
        params: {
          perPage: 100,
          include: 'client',
          filters: {
            slaDueDate: { $isNotNull: true },
          },
        },
      });
    },
  },
};
</script>

<style>
.created-event h4 {
  font-size: 15px;
}
.created-event {
  padding: 0px !important;
  overflow: hidden;
}
.created-event:hover {
  padding: 0px !important;
  overflow: visible;
}
.created-event div {
  padding: 4px 6px;
}
</style>
