<template>
  <div :key="$route.url">
    <Breadcrumbs
      :title="(computedParent && computedParent.pageTitle) || $route.params.menu || null"
      :dropdownItems="breadcrumbsDropdownItems"
      back-button
    />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div v-for="item in computedChildren" :key="item.title" class="col-md-4">
          <div class="card mt-3">
            <router-link :to="item.path">
              <div class="card-header card-no-border">
                <i :class="'fa fa-' + item.icon"></i>
                <h4>{{ item.headerTitle }}</h4>
                <hr />
              </div>
              <div class="card-body pt-0 pull-right">
                <p v-if="item.description">{{ $t(item.description) }}</p>
                <button class="btn btn-primary">{{ $t('common.buttons.open') }}</button>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import Menu from '../../data/menu';

export default {
  name: 'SettingsDetailPage',
  props: {
    breadcrumbs: Boolean,
    breadcrumbsTitle: String,
    breadcrumbsDropdownItems: { type: Array, default: () => [] },
  },
  data() {
    return {
      parent: null,
      children: [],
    };
  },
  computed: {
    computedChildren() {
      return this.children.map((item) => ({
        ...item,
        headerTitle: this.$t(`app.sidebar.${item.title}`),
      }));
    },
    computedParent() {
      if (!this.parent) return {};

      return {
        ...this.parent,
        pageTitle: this.$t(`app.sidebar.${this.parent.title}`),
      };
    },
  },
  mounted() {
    this.buildParams();
  },
  methods: {
    findMenuItem(subMenuTitle) {
      let subMenu;
      Menu.data.forEach((item) => {
        let found = item.children && item.children.find((subItem) => subItem.title === subMenuTitle);
        if (!found) {
          found = item.title === subMenuTitle ? item : null;
        }
        if (found) {
          subMenu = found;
          return true;
        }
        return false;
      });
      return subMenu;
    },
    buildParams() {
      const menuTitle = this.$route.params.menu || this.$props.menu || this.$route.meta?.menu;
      const menu = this.findMenuItem(menuTitle);
      if (!menu) return;
      this.parent = menu;
      const { children } = menu;

      if (children && children.length > 0) {
        this.children = [...children];
        this.children.forEach((child) => {
          if (typeof child.path === 'function') {
            // child.path = child.path();
            // child.path = child.path?.path || child.path;
          }
        });
      }
    },
  },
  watch: {
    '$route.path': {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.buildParams();
        }
      },
    },
  },
};
</script>
