<!--
    =================== Component detail ====================
    File Name: PxCard.vue
    Description: Glovbal Card Component
    =================== Component detail ====================
-->

<template>
  <div>
    <b-card no-body :class="{ 'full-card': fullCard }">
      <template v-slot:header v-if="showHeader || this.$slots.headerCustom">
        <h5 v-if="title">{{ title }}</h5>

        <!-- header actions slot -->
        <div v-if="showAction">
          <slot name="headerAction">
            <div class="card-header-right">
              <ul class="list-unstyled card-option" :class="{ show: isActionsOpened }">
                <li>
                  <feather
                    @click="openCardActions()"
                    :type="isActionsOpened ? 'x' : 'more-vertical'"
                    size="20px"
                  ></feather>
                </li>
                <li v-if="isActionsOpened" @click="toggleFullCard()">
                  <feather :type="fullCard ? 'minimize' : 'maximize'" size="20px"></feather>
                </li>
                <li v-if="isActionsOpened" @click="toggleCard()">
                  <feather :type="cardToggle ? 'folder-minus' : 'folder-plus'" size="20px"></feather>
                </li>
              </ul>
            </div>
          </slot>
        </div>

        <!-- header custom content -->
        <slot name="headerCustom"></slot>
      </template>

      <!-- card body with no-padding -->
      <b-card-body
        v-if="this.$slots.default"
        class="p-0"
        :style="cardToggle ? { display: 'block' } : { display: 'none' }"
      >
        <slot></slot>
      </b-card-body>
      <!-- card body with padding -->
      <b-card-body v-else :style="cardToggle ? { display: 'block' } : { display: 'none' }" :class="bodyClasses">
        <slot name="with-padding"></slot>
      </b-card-body>

      <!-- footer -->
      <template v-slot:footer v-if="this.$slots.footer">
        <slot name="footer"></slot>
      </template>

      <!-- card custom content -->
      <slot name="custom-content"></slot>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue';

export default {
  name: 'px-card',
  components: {
    BCard,
    BCardBody,
  },
  props: {
    title: String,
    bodyClasses: String,
    actions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isActionsOpened: false,
      fullCard: false,
      cardToggle: true,
    };
  },
  computed: {
    showHeader() {
      return this.title;
    },
    showAction() {
      return this.$slots.headerAction || this.actions;
    },
  },
  methods: {
    openCardActions() {
      this.isActionsOpened = !this.isActionsOpened;
    },
    toggleFullCard() {
      this.fullCard = !this.fullCard;
      this.isActionsOpened = false;
    },
    toggleCard() {
      this.cardToggle = !this.cardToggle;
      this.isActionsOpened = false;
    },
  },
};
</script>

<style scoped>
.card-option {
  overflow: visible;
}

.card .card-header h5 {
  display: inline-block;
}
</style>
