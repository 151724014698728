import Vue from 'vue';
import Vuex from 'vuex';
import { merge, kebabCase } from 'lodash';
import config from '@/config';
import * as models from '../models';
import ModelCrudWrapper from '../components/page/ModelCrudWrapper.vue';
import modules from './modules';

Vue.use(Vuex);

const prepareModelToRoute = (model, component, authGuard) => ({
  name: model.name || model.identity,
  path: model.routeUrl || kebabCase(model.routerPath || model.route || model.identity),
  component,
  beforeEnter: authGuard,
  props: () => ({
    identity: model.identity,
    enableSidebar: ['candidate', 'interview', 'position', 'recruitmentProcess', 'lead'].includes(model.identity)
  }),
  meta: {
    title: `${model.namePlural || model.name || model.identity}`,
  },
  children: [
    {
      name: `${model.name}-view`,
      path: ':id',
      component,
      meta: {
        title: `${model.namePlural || model.name || model.identity}`,
      },
      props: {
        identity: model.identity,
      },
    },
    {
      name: `${model.name}-edit`,
      path: ':id/edit',
      component,
      props: {
        identity: model.identity,
      },
    },
  ],
});

const authGuard = (store) => (to, from, next) => {
  if (store.state.user.token) {
    return next();
  }
  next('/login');
};
/**
 *
 */
export default new Vuex.Store({
  state: {
    currentLocale: localStorage.getItem(`${config.appKey}_locale`) || config.defaultLocale,
    models: []
  },
  getters: {
    modelsMap: (state) => state.models.reduce((acc, model) => {
      acc[model.identity] = model;
      return acc;
    }, {}),
    currentUser: (state) => state.user && state.user.user,
    currentOrganisation: (state) => state.user && state.user.organisation,
  },
  mutations: {
    models(state, appModels) {
      state.models = appModels;
    },
    currentLocale(state, locale) {
      state.locale = locale;
      if (this._vm && this._vm.$http) {
        this._vm.$http.defaults.headers.common['Accept-Language'] = locale;
        this._vm.$http.defaults.headers.common['x-cimple-locale'] = locale;
      }
      if (this._vm && this._vm.$awApi) {
        this._vm.$awApi.defaults.headers.common['Accept-Language'] = locale;
        this._vm.$awApi.defaults.headers.common['x-cimple-locale'] = locale;
      }
      localStorage.setItem(`${config.appKey}_locale`, locale);
    },
  },
  actions: {
    changeLocale(context, locale) {
      return context.commit('currentLocale', locale);
    },

    getModels({ commit, dispatch }) {
      const promise = this._vm.$http.get('/api/axel-admin/models');
      promise
        .then(res => {
          const apiModels = res.data.body.map(model => {
            const frontModel = models ? Object.values(models).find(m => m.identity === model.identity) : {};
            model = merge(model, frontModel);
            model = {
              ...model,
              nestedDisplayMode: model.nestedDisplayMode || 'object',
            };
            return model;
          });
          commit('models', apiModels);
          return apiModels;
        })
        .catch(err => {
          console.error(err);
        });
      return promise;
    },

    refreshListOfValues(context) {
      const { dispatch } = context;
      dispatch('getModels');
      dispatch('member/getItems');
      return true;
    },

    connectRoutes(context, router) {
      ['candidate',
        'position',
        'interview',
        'recruitmentProcess',
        'hrTemplate',
        'template',
        'contact',
        'client',
        'request',
        'company',
        'lead']
        .forEach(modelName => {
          const model = context.state.models.find(m => m.identity === modelName);
          if (model) {
            router.addRoute('app', prepareModelToRoute(model, ModelCrudWrapper, authGuard(this)));
          }
        });
      return true;
    },
  },
  modules,
});
