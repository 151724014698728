<template>
  <div class="header-wrapper row m-0">
    <portal-target name="header-left"></portal-target>
    <form class="form-inline search-full" @submit.prevent="searchTerm" method="get" :class="{ open: searchIsOpen }">
      <div class="form-group w-100">
        <div class="Typeahead Typeahead--twitterUsers">
          <div class="u-posRelative">
            <input
              class="demo-input Typeahead-input form-control-plaintext w-100"
              type="text"
              ref="searchInput"
              v-on:keyup="searchTermDebounced"
              v-model="terms"
              :placeholder="$t('common.filter.search')"
              name="q"
              title=""
              autofocus
            />
            <div class="spinner-border Typeahead-spinner" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <feather class="close-search" type="x" @click="closeSearch()"></feather>
          </div>
          <div :class="hasSearchResult ? 'Typeahead-menu is-open' : 'Typeahead-menu'" v-if="!searchResultIsEmpty">
            <div class="row">
              <div class="col-3 d-none">
                <small class="ProfileCard u-cf text-info"
                  >Menus <span class="badge badge-primary">{{ menuItems ? menuItems.length : 0 }}</span></small
                >
                <small class="text-muted font-italic" v-if="menuItems && !menuItems.length">
                  {{ $t('common.messages.not_found') }}
                </small>
                <div class="ProfileCard u-cf" v-for="(menuItem, index) in menuItems" :key="index">
                  <div class="ProfileCard-avatar header-search">
                    <!-- <feather :type="menuItem.icon"></feather> -->
                  </div>
                  <div class="ProfileCard-details">
                    <div class="ProfileCard-realName">
                      <span @click="removeFix()">
                        <router-link
                          v-if="menuItem.path"
                          :to="{ path: computePath(menuItem.path) }"
                          class="realname"
                          >{{ getTitle(menuItem) }}</router-link
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                :class="`col-${Math.floor(12 / activeFeaturesModels.length)}`"
                v-for="searchLocation in activeFeaturesModels"
                :key="searchLocation"
              >
                <template v-if="apiSearchResults[searchLocation]">
                  <small class="ProfileCard u-cf text-info"
                    >{{ $t(`app.searchLocations.${searchLocation}`) }}
                    <span class="badge badge-primary">{{ apiSearchResults[searchLocation].totalCount }}</span>
                  </small>
                  <small class="text-muted font-italic" v-if="apiSearchResults[searchLocation].totalCount < 1">
                    {{ $t('common.messages.not_found') }}.
                  </small>
                  <template v-if="apiSearchResults[searchLocation].body">
                    <div
                      class="ProfileCard u-cf"
                      v-for="(menuItem, index) in apiSearchResults[searchLocation].body.slice(0, 8)"
                      :key="index"
                    >
                      <div class="ProfileCard-avatar header-search">
                        <!-- <feather :type="menuItem.icon"></feather> -->
                      </div>
                      <div class="ProfileCard-details pl-0">
                        <div class="ProfileCard-realName">
                          <small @click="removeFix()"
                            ><router-link
                              :to="{
                                path:
                                  menuItem.path ||
                                  `/app/${
                                    menuItem._model === 'recruitmentProcess' ? 'recruitment-process' : menuItem._model
                                  }/${menuItem.id}`,
                              }"
                              class="realname"
                              >{{ menuItem._label || menuItem.id }}</router-link
                            ></small
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div :class="searchResultIsEmpty || isSearching ? 'Typeahead-menu is-open' : 'Typeahead-menu'">
            <div class="tt-dataset tt-dataset-0">
              <div v-if="searchResultIsEmpty" class="EmptyMessage">{{ $t('common.messages.not_found') }}.</div>
              <div v-if="isSearching" class="EmptyMessage text-center">
                <feather type="loader" animation="spin" class=""></feather>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="header-logo-wrapper">
      <div class="toggle-sidebar from-header" @click="toggle_sidebar">
        <feather class="status_toggle middle sidebar-toggle" type="align-justify" id="sidebar-toggle"></feather>
      </div>
    </div>
    <div class="left-header col horizontal-wrapper pl-0">
      <ul class="horizontal-menu" v-if="activeMenu && activeMenu.showInHeader && !isMobile && activeMenu.children">
        <li class="mega-menu outside mr-1" v-for="(activeMenuItem, index) in activeMenu.children" :key="index">
          <router-link class="nav-link" :to="computePath(activeMenuItem.path)" router-link-exact-active>
            <feather
              v-if="activeMenuItem.icon && activeMenuItem.icon.length > 2"
              :type="activeMenuItem.icon || 'layers'"
            ></feather>
            <i v-if="activeMenuItem.icon && activeMenuItem.icon.length === 2" class="emoji-icon">{{
              activeMenuItem.icon
            }}</i>
            <span>{{ getTitle(activeMenuItem) }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="nav-right col-6 col-md-4 pull-right right-header p-0">
      <ul class="nav-menus">
        <li class="search-box" @click="openSearch()">
          <span
            class="header-search d-none"
            @click="closeSearch()"
            v-shortkey="['esc']"
            @shortkey="closeSearch()"
          ></span>
          <span
            class="header-search d-none"
            @click="openSearch()"
            v-shortkey="['meta', 'p']"
            @shortkey="openSearch($event)"
          ></span>
          <span
            class="header-search d-none"
            @click="openSearch()"
            v-shortkey="['ctrl', 'shift', 'f']"
            @shortkey="openSearch($event)"
          ></span>
          <span
            class="header-search d-none"
            @click="openSearch()"
            v-shortkey="['ctrl', 'k']"
            @shortkey="openSearch($event)"
          ></span>
          {{ searchPlaceholder }}
          <div
            class="header-search pull-right"
            @click="openSearch()"
            v-shortkey="['meta', 'k']"
            @shortkey="openSearch($event)"
          >
            <feather type="search"></feather>
          </div>
        </li>
        <li class="onhover-dropdown" :class="{ show: isNotificationDropdownOpen }">
          <div class="notification-box" @click="toggleNotifications()">
            <feather type="bell"></feather
            ><span v-if="notifications && notifications.length" class="badge badge-pill badge-secondary">{{
              notifications.length
            }}</span>
          </div>
          <ul class="notification-dropdown onhover-show-div custom-scrollbar">
            <li>
              <feather type="bell"></feather>
              <h6 class="f-18 mb-0">
                Notifications
                <span
                  v-if="notifications && notifications.length"
                  class="badge badge-pill badge-secondary"
                  style="position: static"
                  >{{ notifications.length }}
                </span>
                <button class="btn btn-sm p-0 pull-right">
                  <feather type="refresh-cw" @click="getNotifications()" class="text-muted" size="12" />
                </button>
              </h6>
            </li>
            <li v-for="notification in notifications" :key="notification.id" @click="readNotification(notification)">
              <router-link
                v-if="notification.url"
                :to="'/app/' + notification.url.split('app/').pop()"
                class="text-dark"
                @click="readNotification(notification)"
              >
                <p>
                  > <i class="fa fa-circle-o mr-3"> </i> {{ notification.title }}
                  <small class="d-block pull-right">{{ fromNow(notification.createdOn) }}</small>
                </p>
              </router-link>
            </li>
            <li class="d-none">
              <p><i class="fa fa-circle-o mr-3 font-success"></i>Order Complete<span class="pull-right">1 hr</span></p>
            </li>

            <li>
              <!-- <button class="btn btn-primary" @click="getNotifications">Toutes les notifications</button> -->
              <button class="btn btn-primary" @click="clearNotifications()">Clear</button>
            </li>
          </ul>
        </li>
        <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" @click="toggle_fullscreen()">
            <feather type="maximize"></feather
          ></a>
        </li>
        <li class="profile-nav onhover-dropdown p-0 mr-0" style="min-width: fit-content" v-if="user && user.id">
          <div class="media profile-media">
            <img
              v-if="user && user.avatarUrl"
              class="b-r-10"
              :src="user.avatarUrl"
              style="width: 37px; height: 37px"
              alt=""
            />
            <img v-else class="b-r-10" src="../assets/images/user/user.png" style="width: 37px; height: 37px" alt="" />
            <div class="media-body">
              <span>{{ user && user.firstName }} {{ user && user.lastName }}</span>
              <p class="mb-0 font-roboto">{{ user.locale }} <i class="middle fa fa-angle-down"></i></p>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div">
            <li class="d-none">
              <router-link :to="`/app/settings/member/${user.id}`"
                ><feather type="user"></feather><span>My account</span></router-link
              >
            </li>
            <li>
              <select class="custom-select" v-model="$i18n.locale" @change="changeLocale">
                <option v-for="(lang, i) in ['fr', 'en']" :key="`Lang${i}`" :value="lang">
                  {{ lang }}
                </option>
              </select>
            </li>
            <li>
              <router-link :to="`/app/agenda`"><feather type="calendar"></feather><span>Agenda</span></router-link>
            </li>
            <li>
              <router-link :to="`/app/settings/member/${user.id}`"
                ><feather type="settings"></feather><span>{{ $t('app.header.parameters') }}</span></router-link
              >
            </li>
            <li>
              <a
                @click="
                  $store.dispatch('user/logout');
                  $router.push('/login');
                "
                ><feather type="log-in"></feather><span>{{ $t('common.labels.disconnect') }}</span></a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <script class="result-template" type="text/x-handlebars-template">
      <div class='ProfileCard u-cf'>
        <div class='ProfileCard-avatar'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            class='feather feather-airplay m-0'
          >
            <path d='M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1'></path>
            <polygon points='12 15 17 21 7 21 12 15'></polygon>
          </svg>
        </div>
        <div class='ProfileCard-details'>
          <div class='ProfileCard-realName'>
            name
          </div>
        </div>
      </div>
    </script>
    <script class="empty-template" type="text/x-handlebars-template">
      <div class='EmptyMessage'>
        Your search turned up 0 results. This most likely means the backend is down, yikes!
      </div>
    </script>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { debounce, startCase } from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const body = document.getElementsByTagName('body')[0];
export default {
  name: 'SearchHeader',
  data() {
    return {
      terms: '',
      searchIsOpen: false,
      hasSearchResult: false,
      isSearching: true,
      close_sidebar_var: false,
      clicked: false,
      mobile_toggle: false,
      mobile_search: false,
      openbonusUI: false,
      openLevelmenu: false,
      openlanguage: false,
      mobile_accordian: false,
      mixLayout: 'light-only',
      apiSearchResults: {
        client: [],
        candidate: [],
        position: [],
        recruitmentProcess: [],
      },
      notifications: [],
      searchLocations: ['client', 'candidate', 'position', 'recruitmentProcess'], // 'request', 'message',
      isNotificationDropdownOpen: false,
    };
  },
  components: {},
  mounted() {
    this.searchTermDebounced = debounce(this.searchTerm, 300);
    setTimeout(() => {
      this.getNotifications();
    }, 1500);

    this.subscribeNotifications();
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      user: (state) => state.user.user,
      organisation: (state) => state.user.organisation,
    }),
    ...mapGetters('user', ['activeFeaturesModels']),
    activeMenu() {
      return this.$store.state.menu.data && this.$store.state.menu.data.find((item) => item.active);
    },
    userRoles() {
      return this.$store.state.user.user.organisationRoles &&
        Array.isArray(this.$store.state.user.user.organisationRoles)
        ? this.$store.state.user.user.organisationRoles.join('x')
        : '';
    },
    searchResultIsEmpty() {
      return (
        this.terms &&
        !this.isSearching &&
        Object.values(this.apiSearchResults).reduce((prev, next) => next.count + prev, 0) === 0
      );
    },
    isMac() {
      return navigator.platform.toUpperCase().indexOf('MAC') === 0;
    },
    searchPlaceholder() {
      return `${this.$t('common.filter.search') + (this.isMac ? ' [ CMD + k ]' : ' [ Ctrl+shift+p ]')} ...`;
    },

    isMobile() {
      return window.outerWidth < 992;
    },
  },
  methods: {
    startCase,
    fromNow(date) {
      const d = dayjs(date);
      return d && dayjs(date).fromNow();
    },
    toggle_sidebar() {
      this.$store.dispatch('menu/openSidebar');
      document.body.classList.add('modal-open');
    },
    setNavActive(item) {
      this.$store.dispatch('menu/setBonusNavActive', item);
    },
    openlangpicker() {
      this.openlanguage = !this.openlanguage;
    },
    openlevelmenu() {
      this.openLevelmenu = !this.openLevelmenu;
    },
    openmegamenu() {
      this.openbonusUI = !this.openbonusUI;
    },
    closeBonusUI() {
      this.openbonusUI = false;
    },
    openSearch($event) {
      this.searchIsOpen = true;
      this.$refs.searchInput.focus();
      this.searchTerm();
    },
    closeSearch() {
      this.searchIsOpen = false;
      this.removeFix();
    },
    async searchTerm() {
      // if (!this.terms) {
      //   return;
      // }
      // menu search is disabled
      // this.$store.dispatch('menu/searchTerm', this.terms || '');
      this.isSearching = true;
      try {
        const p = this.activeFeaturesModels.map((type) =>
          this.$store
            .dispatch('menu/searchItems', { query: this.terms ? `${this.terms}*` : '', type, perPage: 8 })
            .then((data) => {
              this.hasSearchResult = true;
              this.apiSearchResults[type] = data;
            })
        );
        await Promise.all(p);

        this.isSearching = false;
      } catch (err) {
        this.isSearching = false;
      }
    },
    searchTermDebounced() {},
    mobileaccordian() {
      this.mobile_accordian = !this.mobile_accordian;
    },
    logout() {},
    addFix() {
      body.classList.add('offcanvas');
      this.hasSearchResult = true;
    },
    removeFix() {
      body.classList.remove('offcanvas');
      this.hasSearchResult = false;
      this.terms = '';
    },
    toggle_fullscreen() {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    },
    customizeMixLayout(val) {
      this.mixLayout = val;
      this.$store.dispatch('layout/setLayout', val);
    },

    async getNotifications() {
      if (this.$socket) {
        if (!this.$socket.connected) {
          await this.$socket.connect();
        }
        this.$socket
          .get('/api/notifications', { body: { organisationId: this.organisation && this.organisation.id } })
          .then((notifs) => {
            this.notifications = notifs.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
          });
      }
    },

    readNotification(notification) {
      this.isNotificationDropdownOpen = false;
      this.$http
        .put(`/api/notification/${notification.id}`, {
          userId: notification.userId,
          isRead: true,
        })
        .catch(this.apiErrorCallback);
    },
    clearNotifications() {
      this.$socket.delete('/api/notification').catch(this.apiErrorCallback);
      this.notifications = [];
      this.isNotificationDropdownOpen = false;
    },

    computePath(path) {
      switch (typeof path) {
      case 'string':
        return path;
      case 'function':
        return path(this);
      default:
        return '';
      }
    },
    async changeLocale(event) {
      const locale = event.target.value;
      await this.$store.dispatch('user/updateUserLocale', locale);
      await this.$store.dispatch('changeLocale', locale);
      this.$i18n.locale = locale;
      setTimeout(() => {
        this.$router.go(this.$route.path);
      }, 200);
    },

    subscribeNotifications() {
      if (!this.$socket) {
        return;
      }
      this.$socket.on('notifications', (notifs) => {
        this.notifications = notifs;
      });
    },

    toggleNotifications(state) {
      this.isNotificationDropdownOpen = state !== undefined ? state : !this.isNotificationDropdownOpen;
    },

    getTitle(menu) {
      if (this.$te(`app.sidebar.${menu.title}`)) {
        return this.$t(`app.sidebar.${menu.title}`);
      }
      return menu.title;
    },
  },
  watch: {
    // eslint-disable-next-line
    '$i18n.locale': function (to, from) {
      if (from !== to) {
        //  this.$router.go(this.$route.path);
      }
    },
    $route() {
      this.searchIsOpen = false;
      body.classList.remove('offcanvas');
    },
    menuItems() {
      if (this.terms) {
        this.addFix();
      } else {
        this.removeFix();
      }
    },
  },
};
</script>

<style>
.ProfileCard small a {
  font-weight: 300;
}

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: var(--primary);
  color: white;
}

.search-box {
  border: 1px solid var(--primary);
  border-radius: 50px;
  min-width: 240px;
}

.emoji-icon {
  font-size: 16px;
  margin-right: 10px;
  vertical-align: middle;
  font-style: normal;
}
</style>
