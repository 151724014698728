// refactor the following code to add resizable grid layout

<template>
  <draggable
    v-model="myLayout"
    :group="group"
    class="draggable-layout"
    :class="
      classes +
      ' ' +
      (isEditing ? 'draggable-layout draggable-layout--edit' : 'draggable-layout draggable-layout--readonly') +
      ` dl-item-parent-${parent ? parent.id : 'root'} ` +
      (parent && parent.displayFlex ? 'd-flex flex-wrap' : '')
    "
    v-bind="$props"
    :disabled="!isEditing"
    style=""
    handle=".drag-handle"
  >
    <template slot="header">
      <div v-if="!parent && isEditing" class="w-100 text-right" style="position: relative">
        <button class="btn text-danger" @click="addBlock()">
          <feather size="1em" type="layout" class="mr-1"></feather>{{ $t('common.buttons.addBlock') }}
        </button>
        <button class="btn text-danger" @click="$emit('reset')">
          <feather size="1em" type="layout" class="mr-1"></feather>{{ $t('common.buttons.reset') }}
        </button>
        <button class="btn text-info" @click="$emit('close')">
          <feather size="1em" type="x-square" class="mr-1"></feather>{{ $t('common.buttons.close') }}
        </button>
      </div>
      <div
        v-if="isEditing && parent && depth === 1"
        class="zone-header d-flex w-100 align-items-center justify-content-between"
      >
        <template v-if="depth === 1 && isEditing"
          ><div class="text-info bg-white p-1 b-r-5">{{ parent.id || parent }}</div></template
        >
        <div class="d-flex align-items-center">
          {{ $t('common.labels.showLayoutAs') }}
          <div id="" index="-1" text="btn-group btn-group-sm" class="m-md-2" variant="info">
            <button
              class="btn"
              @click="displayMode = 'list'"
              :class="displayMode === 'list' ? 'bg-info' : 'bg-light text-dark'"
            >
              List
            </button>
            <button
              class="btn"
              @click="displayMode = 'tabs'"
              :class="displayMode === 'tabs' ? 'bg-info' : 'bg-light text-dark'"
            >
              Tabs
            </button>
          </div>
        </div>
      </div>
      <ul class="nav nav-pills nav-justified mb-1" v-if="parent.displayMode === 'tabs'">
        <li v-for="item in layout" :key="getItemId(item)" class="nav-item" role="presentation">
          <a
            class="nav-link pt-1 pb-1"
            href="#"
            @click.prevent="toggleTab(item)"
            :class="{ active: tabIndex === getItemId(item) }"
            >{{ $t(`draggableLayout.tabs.${getItemId(item)}`) }}</a
          >
        </li>
      </ul>
    </template>

    <div
      v-for="item in layout"
      :key="getItemId(item)"
      :class="
        ((item && item.classes) || '') +
        ' ' +
        `dl-item-group-${(item && item.group) || item} dl-item-id-${getItemId(item)} ` +
        (isEditing ? 'draggable-layout--edit' : 'draggable-layout--readonly ')
      "
      :data-dl-id="getItemId(item)"
      :style="
        item && item.containerHeight
          ? {
              height: `${item.containerHeight}px`,
              width: `${item.containerWidth}px`,
            }
          : ''
      "
      v-resizeable="item"
    >
      <i
        class="p-1 bg-white text-gray drag-handle fa fa-bars fa-rotate-90 b-r-4"
        style="position: absolute; right: 3px; top: 3px; z-index: 10000; color: #ccc"
        v-if="isEditing"
      ></i>
      <template v-if="isEditing || parent.displayMode !== 'tabs' || tabIndex === getItemId(item)">
        <slot :item="item" :itemId="getItemId(item)"></slot>
      </template>
      <draggableLayout
        v-if="isEditing || canShowLayout(item)"
        v-model="item.layout"
        :responsive="responsive"
        :depth="depth + 1"
        :disabled="!isEditing"
        :group="item.group"
        :isEditing="isEditing"
        :parent="item"
        @change-display-mode="onChangeDisplayMode(item, $event)"
      >
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>
      </draggableLayout>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'draggableLayout',
  model: {
    prop: 'layout',
    event: 'change',
  },
  components: {
    draggable,
  },
  directives: {
    resizeable: {
      bind(el, binding, vnode) {
        if (vnode.context.depth > 0) {
          return;
        }

        function startResize(e) {
          e.preventDefault();
          const item = binding.value;
          // eslint-disable-next-line no-use-before-define
          document.addEventListener('mousemove', doResize);
          // eslint-disable-next-line no-use-before-define
          document.addEventListener('mouseup', stopResize);

          const startX = e.clientX;
          const startY = e.clientY;

          const startWidth = parseInt(window.getComputedStyle(el).getPropertyValue('width'), 10);
          const startHeight = parseInt(window.getComputedStyle(el).getPropertyValue('height'), 10);

          function doResize(evt) {
            el.style.flex = 'none';
            el.style.maxWidth = 'unset';
            el.style.removeProperty('maxWidth');
            const width = startWidth + (evt.clientX - startX);
            const height = startHeight + (evt.clientY - startY);
            el.style.width = width > 200 ? `${width}px` : '200px'; // Limiter la largeur minimale à 200px
            // commenter cette ligne pour empecher les blocks de s'agrandir en largeur
            el.style.height = height > 200 ? `${height}px` : '200px'; // Limiter la hauteur minimale à 200px
          }

          function stopResize() {
            const { colWidth } = vnode.context;
            document.removeEventListener('mousemove', doResize);
            document.removeEventListener('mouseup', stopResize);
            el.style.removeProperty('flex');
            el.style.removeProperty('maxWidth');

            // Calculer la largeur arrondie au plus proche multiple de l'incrément
            const snapWidth = Math.round(parseInt(el.style.width, 10) / colWidth) * colWidth;
            // Limiter la largeur minimale à 200px
            el.style.width = snapWidth > 200 ? `${snapWidth}px` : '200px';
            // Calculer le nombre de colonnes en fonction de la largeur arrondie
            const cols = Math.round(snapWidth / colWidth);

            // console.log('moving', { currentWidth: snapWidth, startWidth });
            // if (snapWidth > startWidth) {
            //   console.log('moving', 'right', cols);
            // } else {
            //   console.log('moving', 'left', cols);
            // }

            item.cols = cols;
            if (vnode.context.responsive) {
              item.classes = `col-12 col-md-${cols}`;
            } else {
              item.classes = `col-${cols}`;
            }
            vnode.context.$forceUpdate();
          }
        }

        const handle = document.createElement('div');
        handle.className = 'resize-handle';
        el.appendChild(handle);
        handle.addEventListener('mousedown', startResize);
      },
    },
  },
  props: {
    classes: { type: [String, Object], default: '' },
    isEditing: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    resizeable: { type: Boolean, default: false },
    responsive: { type: Boolean, default: false },
    depth: { type: Number, default: 0 },
    group: { type: String, default: '' },
    parent: { type: [String, Object], default: '' },
    layout: {
      type: Array,
      default: () => [],
      examples: [
        {
          id: 'left-sidebar',
          classes: '',
          layout: ['contacts', { id: 'tasks2', items: ['comments'] }, 'comments'],
        },
        {
          id: 'main-body',
          classes: '',
          layout: ['requests'],
        },
        {
          id: 'right-sidebar',
          classes: 'd-block bg-primary',
          layout: ['contacts'],
        },
      ],
    },
  },
  watch: {
    displayMode() {
      this.$emit('change-display-mode', this.displayMode);
    },
  },
  mounted() {
    this.displayMode = this.parent?.displayMode || 'list';
    if (this.parent?.displayMode) {
      this.displayMode = this.parent.displayMode || 'list';
      if (this.parent.displayMode === 'tabs') {
        this.tabIndex = this.getItemId(this.layout[0]);
        if (document.location.hash && document.location.hash.startsWith('#drag-tab-')) {
          this.tabIndex = document.location.hash.replace('#drag-tab-', '');
        }
      }
    }
    this.layoutWidth = document.querySelector('.draggable-layout').clientWidth;
    this.colWidth = this.layoutWidth / 12;
  },
  computed: {
    myLayout: {
      get() {
        return this.layout.filter((l) => l);
      },
      set(value) {
        // console.log('set', value);
        this.$emit('change', value);
      },
    },
  },
  data() {
    return {
      tabIndex: '',
      displayMode: 'list',
      isResizing: null,
      layoutWidth: 0,
      layoutCols: 12,
      colWidth: 0,
    };
  },
  methods: {
    getItemId(item) {
      return typeof item === 'object' ? item.id : item;
    },
    canShowLayout(item) {
      if (!item) {
        return false;
      }
      return this.isEditing || (item.layout && item.layout.length > 0);
    },

    onChangeDisplayMode(item, mode) {
      item.displayMode = mode;
    },
    toggleTab(item) {
      this.tabIndex = this.getItemId(item);
      document.location.hash = `drag-tab-${this.tabIndex}`;
    },

    onChangeLog(...rest) {
      console.warn('onChangeLog', ...rest);
    },

    async addBlock() {
      // eslint-disable-next-line
      const name = window.prompt('Block name');
      if (name) {
        this.$emit('change', [
          {
            id: name,
            classes: '',
            cols: 12,
            group: 'layout',
            layout: [],
          },
          ...this.myLayout,
        ]);
      }
    },
  },
};
</script>

<style>
.draggable-layout,
.draggable-layout--edit {
  position: relative;
}
.dl-item-group-layout.draggable-layout--edit {
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 3px;
  margin-top: 3px;
  min-width: 100px;
  min-height: 200px;
  width: 100%;
  background-color: var(--primary-bg-light);
  position: relative;
}
.dl-item-group-layout.draggable-layout--edit .draggable-layout {
  height: 100%;
}

.dl-item-group-layout.draggable-layout--edit .zone-header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
}

.resize-handle {
  position: absolute;
  width: 3px;
  height: auto;
  top: 20px;
  bottom: 20px;
  right: 1px;
  cursor: ew-resize;
  background-color: transparent;
  color: black;
  cursor: ew-resize;
  opacity: 0.1;
  border-right: 10px solid teal;
  z-index: 100;
  content: ' ';
  border-radius: 15px;
  box-shadow: 0 0 0 1px gray;
}

.resize-handle:hover {
  opacity: 0.5;
}

.draggable-layout--readonly .resize-handle {
  display: none;
}
</style>
