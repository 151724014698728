<template>
  <img
    v-if="imageExists"
    class="kanban-user-img rounded-circle"
    :style="{ width: size, height: size }"
    :src="computedUser[imageField]"
    :alt="computedUserTitle"
    :data-tooltip="computedUserTitle"
  />
  <div
    class="p-1 user-initials rounded-circle text-center text-uppercase"
    :class="randomColor + (shadow ? ' shadow' : '')"
    :style="{ lineHeight: 1.5, width: size, height: size, ...styles }"
    :data-tooltip="computedUserTitle"
    v-else
  >
    {{ getUserInitials(computedUser) }}
  </div>
</template>

<script>
import _ from 'lodash';
import avatarMethodsMixin from '@/mixins/avatarMethodsMixin';

export default {
  name: 'UserAvatar',
  classes: String,
  mixins: [avatarMethodsMixin],
  props: {
    user: Object,
    userId: Number,
    imageField: {
      type: String,
      default: 'avatarUrl',
    },
    color: {
      type: String,
    },
    bgColor: {
      type: String,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
    },
    size: {
      type: String,
      default: '40px',
    },
  },
  mounted() {
    this.checkIfImageExists();
    this.randomColor = _.sample([
      'text-dark bg-light-primary',
      'text-dark bg-light-secondary',
      'text-dark bg-light-success',
      'text-dark bg-light-danger',
      'text-dark bg-light-warning',
      'text-dark bg-light-info',
    ]);
  },
  watch: {
    'computedUser.avatarUrl': function () {
      this.checkIfImageExists();
    },
  },
  computed: {
    computedUser() {
      if (this.user) {
        return this.user;
      }
      if (this.userId) {
        return (
          this.$store.state.member.items && this.$store.state.member.items.find((user) => this.userId === user.id)
        );
      }
      return null;
    },

    computedUserTitle() {
      return (
        this.computedUser &&
        (this.computedUser.firstName ||
          this.computedUser.username ||
          this.computedUser.name ||
          this.computedUser.title)
      );
    },
  },
  data() {
    return {
      imageExists: false,
      randomColor: '',
    };
  },
  methods: {
    checkIfImageExists() {
      return new Promise((resolve, reject) => {
        if (!this.computedUser || !this.computedUser[this.imageField]) {
          return resolve(false);
        }
        const img = new Image();
        img.onload = () => {
          this.imageExists = true;
          resolve(true);
        };
        img.onerror = () => {
          this.imageExists = false;
          resolve(false);
        };
        img.src = this.computedUser[this.imageField];
      });
    },
  },
};
</script>
