import { cloneDeep } from 'lodash';

export default {
  data() {
    return {
      isEditingLayout: false,
      pageLayout: [],
      defaultPageLayout: [
        {
          id: 'header',
          group: 'layout',
          classes: 'col-12',
          layout: [],
        },
        {
          id: 'left-sidebar',
          group: 'layout',
          classes: 'col-xl-3 box-col-6',
          layout: ['client', 'contacts', 'tasks', 'comments'],
        },
        {
          id: 'main-body',
          group: 'layout',
          classes: 'col-xl-9 box-col-6',
          layout: ['positions', 'recruitments', 'requests', 'emails'],
        },
        {
          id: 'right-sidebar',
          group: 'layout',
          classes: 'd-none',
          layout: [],
        },
        {
          id: 'footer',
          group: 'layout',
          classes: 'col-12',
          layout: [],
        },
      ],
    };
  },
  watch: {
    pageLayout() {
      this.saveLayout();
    },
  },
  created() {
    if (this.defaultPageLayout) {
      this.pageLayout = cloneDeep(this.defaultPageLayout);
    }
  },
  mounted() {
    this.isEditingLayout = false;
    if (this.defaultPageLayout) {
      this.pageLayout = this.defaultPageLayout;
    }
    this.restoreLayout();
  },
  methods: {
    saveLayout() {
      if (window.localStorage) {
        localStorage.setItem(`layout:${this.$route.name}:${this.identity || ''}`, JSON.stringify(this.pageLayout));
      }
    },
    restoreLayout() {
      if (window.localStorage) {
        const layout = localStorage.getItem(`layout:${this.$route.name}:${this.identity || ''}`);
        if (layout) {
          // Compare saved and page layout to add any missing layout to localstorage
          const flattenPageLayout = [];
          const flattenStoredLayout = [];
          let missingLayouts = [];
          const parsedStorageLayout = JSON.parse(layout);

          this.pageLayout.forEach((layoutZone) => {
            if (layoutZone && layoutZone.layout) {
              layoutZone.layout.forEach((l) => flattenPageLayout.push(l.id || l));
            }
          });

          parsedStorageLayout.forEach((layoutZone) => {
            if (layoutZone && layoutZone.layout) {
              layoutZone.layout.forEach((l) => flattenStoredLayout.push(l.id || l));
            }
          });

          missingLayouts = flattenPageLayout.filter((l) => !flattenStoredLayout.includes(l));

          if (missingLayouts && missingLayouts.length) {
            missingLayouts.forEach((missingLayout) => {
              const zoneLayoutIndex = this.pageLayout.findIndex((zLayout) => {
                if (zLayout && zLayout.layout) {
                  return zLayout.layout.map((l) => l.id || l).includes(missingLayout);
                }
                return false;
              });
              if (parsedStorageLayout[zoneLayoutIndex]?.layout && this.pageLayout?.layout) {
                const layoutObject = this.pageLayout.layout.find((l) => l.id === missingLayout);
                parsedStorageLayout[zoneLayoutIndex].layout.push(layoutObject || missingLayout);
              }
            });
          }
          this.pageLayout = parsedStorageLayout;
          this.$forceUpdate();
        }
      }
    },
    resetLayout() {
      if (this.defaultPageLayout) {
        this.pageLayout = cloneDeep(this.defaultPageLayout);
      }
    },

    onLayoutChange(layout) {
      // this.pageLayout = layout;
    },

    toggleLayoutEdit(state) {
      this.isEditingLayout = !this.isEditingLayout;
      this.saveLayout();
    },
  },
};
