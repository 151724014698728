<template>
  <div class="conversation-widget">
    <div class="card" v-for="message in messages" :key="message.id">
      <div class="card-body p-4">
        <div class="email-app-sidebar">
          <div class="media" :class="message.isOutbound ? 'reverse-message' : ''">
            <div class="media-size-email">
              <img
                v-if="getMessageAvatar(message)"
                class="mr-3 rounded-circle"
                :src="getMessageAvatar(message)"
                alt=""
              />
              <span v-else class="mr-3 rounded-circle">{{
                prepareInitials(message.isOutbound ? message.CreatedBy : message.Client)
              }}</span>
            </div>
            <div class="media-body">
              <h6 class="f-w-600">
                {{ getMessageAuthor(message) }} <small class="pull-right d-none">{{ message.subject }}</small>
              </h6>
              <p>
                <small>{{ formatDate(message.sentOrReceivedOn || message.createdOn) }}</small>
                <br />
                {{ getMessageAuthorCompany(message) }}
              </p>
            </div>
          </div>
          <p
            v-html="
              marked(message.content.replace(/\\r\\n/gi, '<br/>').replace('x-cimple-([a-zA-Z]+): ([0-9]+)', ''), {
                breaks: true,
              })
            "
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import marked from 'marked';
import avatarMethodsMixin from '@/mixins/avatarMethodsMixin';

export default {
  name: 'ConversationList',
  components: {},
  mixins: [avatarMethodsMixin],
  data() {
    return {
      modelFilters: null,
    };
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState('user', ['user', 'organisation']),
  },
  methods: {
    dayjs,
    marked,
    getMessageAuthor(message) {
      if (message.CreatedBy && message.isOutbound) {
        return `${message.CreatedBy.firstName} ${message.CreatedBy.lastName}`;
      }
      if (message.Contact) {
        return `${message.Contact.firstName} ${message.Contact.lastName}`;
      }
      if (message.Client) {
        return `${message.Client.name || message.Client.company || ''}`;
      }

      if (message.Lead) {
        return `${message.Lead.name || message.Lead.company || ''}`;
      }
    },

    getMessageAvatar(message) {
      if (message.CreatedBy && message.isOutbound) {
        return `${message.CreatedBy.avatarUrl || ''}`;
      }
      if (message.Contact) {
        return `${message.Contact.avatarUrl || ''}`;
      }
      if (message.Client) {
        return `${message.Client.avatarUrl || ''}`;
      }
      if (message.leadId && message.leadId === (this.selectedItem && this.selectedItem.id)) {
        return `${this.selectedItem.avatarUrl || ''}`;
      }
      return '';
    },

    getMessageAuthorCompany(message) {
      if (message.CreatedBy && message.isOutbound) {
        return `${this.organisation.name}`;
      }
      if (message.Contact) {
        return `${message.Contact.company || ''}`;
      }
    },

    formatDate(date) {
      return dayjs(date).format('DD.MM.YYYY à HH:mm');
    },
  },
};
</script>
