<template>
  <Rating v-bind="$props" v-on="$listeners">
    <template v-slot:star="{ active }">
      <span style="font-size: 10px" :style="!active ? 'filter: grayscale(1)' : ''">🔥</span>
    </template>
  </Rating>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    maxStars: {
      type: Number,
      default: 5,
    },
    icon: {
      type: String,
      default: 'star',
    },
    hasCounter: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stars: this.value,
    };
  },
  watch: {
    value() {
      this.stars = this.value;
    },
  },
  methods: {
    rate(star) {
      if (typeof star === 'number' && star <= this.maxStars && star >= 0) {
        this.stars = this.stars === star ? star - 1 : star;
      }
      this.$emit('input', this.stars);
      this.$emit('change', this.stars);
    },
  },
};
</script>

<style scoped lang="scss"></style>
