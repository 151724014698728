<template>
  <div>
    <div
      class="d-flex justify-content-end"
      v-if="shortMode"
      :style="
        shortMode
          ? {
              zIndex: '100',
            }
          : {}
      "
    >
      <slot name="dropdown" />
      <b-dropdown
        v-if="canShowDropdown"
        size="sm"
        variant="link"
        toggle-class="text-decoration-none p-1 breadcrumb-menu-toggle"
        :no-caret="true"
        class=""
      >
        <template #button-content>
          <feather id="breadcrumb-dopdown-button" type="settings" class="text-dark pr-2" size="13px"></feather
          ><span class="sr-only">Search</span>
        </template>
        <b-dropdown-item v-for="(item, index) in dropdownItems" @click="dropdownAction(item)" :key="index">
          <feather size="1.1em" :type="item.icon || 'layout'" class="mr-1"></feather><span>{{ $t(item.title) }}</span>
        </b-dropdown-item>

        <template v-if="canEditLayout">
          <b-dropdown-item href="#" @click="$emit('layout-edit-toggle', !isEditingLayout)">
            <feather size="1.1em" type="layout" class="mr-1"></feather
            >{{
              $t(isEditingLayout ? 'common.buttons.closeLayoutEditor' : 'common.buttons.openLayoutEditor')
            }}</b-dropdown-item
          >
          <b-dropdown-item href="#" @click="$emit('layout-reset')">
            <feather size="1.1em" type="layout" class="mr-1 text-danger"></feather
            ><span>{{ $t('common.buttons.resetLayout') }}</span></b-dropdown-item
          >
        </template>
      </b-dropdown>
    </div>

    <div v-if="!shortMode" class="container-fluid">
      <div class="page-title" :class="pageClass">
        <div class="row">
          <div class="col-6 d-flex breadcrumb-left">
            <h3 class="text-truncate">
              <feather v-if="backButton" type="arrow-left" class="pointer fs-10" @click="$router.go(-1)"></feather>
              <slot name="title">{{ title }}</slot>
            </h3>
            <div
              v-if="user && user.avatarUrl"
              class="pull - right"
              :data-tooltip="`${user.firstName} ${user.lastName}`"
            >
              <img
                class="img-fluid img-20 rounded-circle"
                :src="user.avatarUrl"
                :alt="`${user.firstName} ${user.lastName}`"
              />
            </div>
            <slot name="left" />
          </div>
          <div class="col-6 breadcrumb-right d-flex justify-content-end">
            <slot></slot>
            <slot name="right" />
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ path: '/app/dashboard' }"><feather type="home"></feather></router-link>
              </li>
              <li v-for="(path, index) in parents" class="breadcrumb-item" :key="index">
                <router-link :to="{ path: path.url }"> {{ path.title }}</router-link>
              </li>
              <li class="breadcrumb-item" v-if="main">{{ main }}</li>
            </ol>
            <b-dropdown
              v-if="canShowDropdown"
              size="sm"
              variant="link"
              toggle-class="text-decoration-none p-1 breadcrumb-menu-toggle"
              :no-caret="true"
              class=""
              right
              text="Right align"
            >
              <template #button-content>
                <feather id="breadcrumb-dopdown-button" type="settings" size="15px" class="text-dark"></feather
                ><span class="sr-only">Search</span>
              </template>
              <b-dropdown-item v-for="(item, index) in dropdownItems" @click="dropdownAction(item)" :key="index">
                <feather size="1.1em" :type="item.icon || 'layout'" class="mr-1"></feather
                ><span>{{ $t(item.title) }}</span>
              </b-dropdown-item>

              <template v-if="canEditLayout">
                <b-dropdown-item href="#" @click="$emit('layout-edit-toggle', !isEditingLayout)">
                  <feather size="1.1em" type="layout" class="mr-1"></feather
                  >{{
                    $t(isEditingLayout ? 'common.buttons.closeLayoutEditor' : 'common.buttons.openLayoutEditor')
                  }}</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="$emit('layout-reset')">
                  <feather size="1.1em" type="layout" class="mr-1 text-danger"></feather
                  ><span>{{ $t('common.buttons.resetLayout') }}</span></b-dropdown-item
                >
              </template>
              <slot name="dropdown" />
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-6 d-flex">
            <h3>{{title}}</h3>

        </div>
        <div class="col-6">
          <Bookmark />
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import Bookmark from './bookmark.vue';

export default {
  name: 'Breadcrumbs',
  props: {
    title: {
      default: 'home',
    },
    main: {
      default: '',
    },
    pageClass: {
      default: '',
    },
    isEditingLayout: {
      type: Boolean,
      default: false,
    },
    canEditLayout: {
      type: Boolean,
      default: false,
    },
    shortMode: {
      type: Boolean,
      default: false,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
      validate: (item) => item.avatarUrl,
    },
    dropdownItems: {
      type: Array,
      default: () => [],
      validate: (items) => items.every((item) => item.title && (item.action || item.to)),
    },
    parents: {
      default: () => [],
      validate: (items) => items.reduce((prev, current) => prev && current.title && current.url, true),
    },
  },
  components: {
    Bookmark, // eslint-disable-line
    BDropdown,
    BDropdownItem,
  },
  data() {
    return { optionsToggle: false };
  },
  computed: {
    canShowDropdown() {
      return this.dropdownItems?.length > 0 || this.canEditLayout || this.$slots.dropdown;
    },
  },
  methods: {
    dropdownAction(item) {
      if (item && item.to) {
        this.$router.push(item.to);
      } else if (item && item.action) {
        item.action();
      } else {
        this.$emit('dropdown-click', item);
      }
    },
  },
};
</script>

<style></style>
