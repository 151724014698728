<template>
  <div :key="$route.path">
    <Breadcrumbs>
      <template #title>
        {{ $t('app.sidebar.mailboxes') }}
        <span class="badge badge-primary p-1 f-12 mt-0" style="vertical-align: super">{{ messageCount }}</span>
      </template>
      <template #right>
        <select
          name=""
          v-if="mailboxAddresses && mailboxAddresses.length"
          v-model="currentMailBoxConfig"
          class="form-control w-100 mr-2 d-none d-md-inline-block"
          style="width: 400px !important"
          id=""
        >
          <option>--</option>
          <option :key="config.id" :value="config" v-for="config in mailboxAddresses">
            {{ config.email }}
          </option>
        </select>
      </template>
      <template #dropdown>
        <router-link to="/app/settings/details/settings.mailboxes" class="dropdown-item"
          ><feather type="settings" size="1.1em" class="mr-1" />{{ $t('app.sidebar.settings-settings') }}</router-link
        >
      </template>
    </Breadcrumbs>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="email-wrap">
        <div
          v-if="mailboxAddresses && !mailboxAddresses.length"
          class="pointer"
          @click="$router.push('/app/settings/mailbox?action=create')"
        >
          <noResult :message="$t('common.labels.noMailboxes')" />
        </div>
        <div v-else class="row">
          <div class="col-xl-12 col-md-12 d-md-none">
            <div class="email-left-aside">
              <div class="card">
                <div class="card-body">
                  <div class="email-app-sidebar">
                    <div class="media">
                      <div class="media-size-email d-none">
                        <img class="mr-3 rounded-circle img-50" :src="'../../assets/images/user/1.jpg'" alt="" />
                      </div>
                      <div class="media-body">
                        <h6 class="pt-2 mb-3 text-capitalize">{{ $t('app.mailbox.my-mailboxes') }} hrehr</h6>
                        <p>
                          <select
                            name=""
                            v-if="mailboxAddresses && mailboxAddresses.length > 0"
                            v-model="currentMailBoxConfig"
                            class="form-control"
                            id=""
                          >
                            <option>--</option>
                            <option :key="config.id" :value="config" v-for="config in mailboxAddresses">
                              {{ config.email }}
                            </option>
                          </select>
                        </p>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Inbox - emails listing -->
          <inbox-widget
            :mailboxId="currentMailBoxConfig && currentMailBoxConfig.id"
            @count-changed="(count) => (messageCount = count)"
          />
          <email-composer
            :selectedEmail="selectedEmail"
            :mailboxAddresses="mailboxAddresses"
            :client="client"
            :currentMailBoxConfig="currentMailBoxConfig"
          />
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import striptags from 'striptags';
import marked from 'marked';
import EmailComposer from '@/components/mailbox/EmailComposer.vue';
import InboxWidget from '@/components/shared/InboxWidget.vue';

export default {
  name: 'MailBox',
  components: {
    EmailComposer,
    InboxWidget,
  },

  data() {
    return {
      editorData: '',
      editorConfig: {
        config: { height: '300px' },
      },
      menutoogle: false,
      client: null,
      search: '',
      page: 1,
      totalPages: null,
      perPage: 20,
      mailboxAddresses: [],
      currentMailBoxConfig: null,
      isArchived: false,
      isSearching: false,
      displaySnippets: false,
      showEditor: false,
      mailSendMode: 'reply',
      messageCount: 0,
    };
  },
  watch: {},
  computed: {
    ...mapState('email', ['selectedEmail', 'emails']),
    ...mapState('user', ['user', 'organisation']),
    ...mapGetters({
      index: 'email/index',
      send: 'email/send',
      draft: 'email/draft',
      trash: 'email/trash',
      starred: 'email/starred',
    }),

    mailboxAddressesMap() {
      return this.mailboxAddresses
        ? this.mailboxAddresses.reduce((acc, current) => {
          acc[current.id] = current;
          return acc;
        }, {})
        : {};
    },
  },
  mounted() {
    if (!this.$route.query.cc && !this.$route.query.to && !this.$route.query.from) {
      this.getMailboxes();
    }
  },

  methods: {
    dayjs,
    marked,
    striptags,

    formatDate(str, format) {
      return dayjs(str).format(format);
    },
    getImgUrl(path) {
      return path;
      // return require('../../assets/images/' + path);
    },
    async setSelectedEmail(email) {
      this.closeToggles();
      this.client = null;
      this.scrollToTop();
      const { parsedContent } = email;
      if (parsedContent && parsedContent.html === 'undefined') {
        email.parsedContent.html = '';
      }
      if (parsedContent && parsedContent.text === 'undefined') {
        email.parsedContent.text = '';
      }
      this.currentMailBoxConfig = { id: email.mailboxId };
      await this.$store.dispatch('email/setSelectedEmail', email);
      email.isRead = true;
      this.$store.dispatch('email/readEmail', {
        id: email.id,
        isRead: true,
      });
      const [client] = await this.$store.dispatch('client/getClients', {
        save: false,
        params: { filters: { email: email.parsedContent && email.parsedContent.fromEmail } },
      });
      if (client && client.id) {
        this.client = client;
      }
    },
    addfavorites(id) {
      this.$store.dispatch('email/addfavorites', id);
    },

    formatHtml(html) {
      return striptags(marked(html), ['strong', 'a', 'p', 'br', 'img', 'table', 'tr', 'td', 'th']);
    },

    scrollToTop() {
      if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
        window.scrollBy(0, -50);
        requestAnimationFrame(this.scrollToTop);
      }
    },

    goToPage(page = 0) {
      if (page >= 0) {
        this.page = page;
        this.$store.dispatch('email/getEmails', {
          params: {
            filters: { mailboxId: this.currentMailBoxConfig ? this.currentMailBoxConfig.id : undefined },
            sort: { sentOrReceivedOn: 'desc' },
            perPage: this.perPage,
            page,
          },
        });
      }
    },

    getMailboxes() {
      return this.$http.get('/api/mailbox').then(({ data }) => {
        this.mailboxAddresses = data.body;
      });
    },

    /**
     * closes all the open dropdown toggles
     */
    closeToggles() {
      this.menutoogle = false;
    },

    formatAttachments(attachments) {
      return attachments && attachments.length
        ? attachments
          .map((attachment) =>
            attachment.url && attachment.url.startsWith('http')
              ? attachment.url
              : `${document.location.origin}/${attachment.url}`
          )
          .join('\n')
        : '';
    },
  },
};
</script>

<style>
.ck-editor__editable {
  min-height: 80px;
}

.email-wrap .email-app-sidebar .main-menu > li {
  width: auto !important;
}
</style>
